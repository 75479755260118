import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../assets/Constants";

const Help = () => {
    const [message, setMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!message.trim()) {
            toast.error("Message cannot be empty.");
            return;
        }

        setIsLoading(true);

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/feedback/store`,
                { message },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (response.data.status) {
                toast.success("Feedback submitted successfully!");
                setMessage("");
            } else {
                toast.error(response.data.message || "Failed to submit feedback.");
            }
        } catch (error) {
            console.error("API Error:", error);
            toast.error("An error occurred while submitting feedback.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="card py-5">
                <div className="container bg-icon-left position-relative">
                    <h5 className="text-center mb-2">
                        <span className="display-5 fs-4 fw-bold">Help & Support</span>
                    </h5>
                    <p className="text-center fw-medium mb-4 mb-md-12 pb-3">
                        Any complaint or remark? just write us a message
                    </p>
                    <div className="row gy-6">
                        <div className="col-lg-5">
                            <div className="card h-100">
                                <div className="bg-primary rounded-4 text-white card-body p-8">
                                    <p className="fw-medium mb-1_5 tagline">Let’s contact with us</p>
                                    <h4 className="text-white mb-5 title">
                                        Share your remarks or complaints with our experts.
                                    </h4>
                                    <div>
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded">
                                                    <i className="ri-phone-line ri-24px"></i>
                                                </div>
                                            </div>
                                            <div className="ms-3 d-flex flex-column">
                                                <h6 className="mb-1 text-white">+91 9876543210</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded">
                                                    <i className="ri-phone-line ri-24px"></i>
                                                </div>
                                            </div>
                                            <div className="ms-3 d-flex flex-column">
                                                <h6 className="mb-1 text-white">+91 9865327410</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded">
                                                    <i className="ri-mail-line ri-24px"></i>
                                                </div>
                                            </div>
                                            <div className="ms-3 d-flex flex-column">
                                                <h6 className="mb-1 text-white">info@steptonikah.com</h6>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="avatar">
                                                <div className="avatar-initial bg-label-primary rounded">
                                                    <i className="ri-whatsapp-line ri-24px"></i>
                                                </div>
                                            </div>
                                            <div className="ms-3 d-flex flex-column">
                                                <h6 className="mb-1 text-white">+91 7586958425</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <p className="mb-0 description mt-5">
                                        Need assistance for app issues and making payments?
                                        Don’t worry, We’ve provided you with an entire team of experienced
                                        professionals.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <h5 className="mb-6">Share your comments</h5>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-5">
                                            <div className="col-12">
                                                <div className="form-floating form-floating-outline">
                                                    <textarea
                                                        className="form-control h-px-250"
                                                        placeholder="Message"
                                                        aria-label="Message"
                                                        id="basic-default-message"
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                    ></textarea>
                                                    <label htmlFor="basic-default-message">Message</label>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            type="submit"
                                            className="btn btn-primary mt-5 waves-effect waves-light"
                                            disabled={isLoading}
                                        >
                                            {isLoading ? "Sending..." : "Send Message"}
                                        </button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Help;
