import React, { useEffect, useState } from "react";
import profileImage from '../../assets/img/profiles/man1.jpg';
import { API_URL } from "../../assets/Constants";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

const imageStyle = {
    marginLeft: "15px"
};
const AccountDetails = () => {
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/web/homepage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setUser(response.data);

        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);
    return (
        <div className="card">
            <div class="row">
                <div class="col-4 py-5 px-2">
                    {user ?
                        <img src={user.photoUrl} alt="User" class="img-fluid rounded" width="100" height="130" style={imageStyle} />
                        :
                        <Skeleton height={130} width={100} style={{ marginLeft: 10 }} />
                    }
                </div>
                <div class="col-8 py-5 px-5">
                    <div class="card-info mb-3">
                        <h4 class="mb-0 text-nowrap">{user ? user.name : <Skeleton />} </h4>
                        <small class="mb-1 text-nowrap"><b>{user ? `STN${user.profileId}` : ''}</b></small> <br />
                        <small>Account Type : Free</small>
                    </div>
                    <div>
                        <Link to={"/my-profile"}>
                            <button type="button" className="btn btn-sm btn-primary">
                                My Profile
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountDetails;