import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const PhysicalAttributeModal = ({ details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validate = () => {
        let validationErrors = {};
        
        if (!formData.height) {
            validationErrors.height = "height is required.";
        }

        if (!formData.complexion) {
            validationErrors.complexion = "complexion is required.";
        } 

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validate()) return;
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/physicalDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    return (
        <div
            className="modal fade show"
            id="physicalAttributesModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Physical Attributes
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="height"
                                        className="form-control form-control-sm"
                                        value={formData.height || ""}
                                        onChange={handleInputChange}
                                    >
                                        {[...Array(41).keys()].map(height => (
                                            <option key={height} value={height + 140}>{height + 140}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="name">Height</label>
                                    {errors.height && <div className="invalid-feedback">{errors.height}</div>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="weight"
                                        className="form-control form-control-sm"
                                        value={formData.weight || ""}
                                        onChange={handleInputChange}
                                    >
                                        {[...Array(81).keys()].map(height => (
                                            <option key={height} value={height + 40}>{height + 40}</option>
                                        ))}
                                    </select>
                                    <label htmlFor="weight">Weight</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="complexion"
                                        className="form-control form-control-sm"
                                        value={formData.complexion || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Fair">Fair</option>
                                        <option value="Wheatish">Wheatish</option>
                                        <option value="Wheatish Brown">Wheatish Brown</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="complexion">Complexion</label>
                                    {errors.complexion && <div className="invalid-feedback">{errors.complexion}</div>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="bodyType"
                                        className="form-control form-control-sm"
                                        value={formData.bodyType || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Average">Average</option>
                                        <option value="Slim">Slim</option>
                                        <option value="Athletic">Athletic</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="bodyType">Body Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                            name="bloodGroup"
                                            className="form-control form-control-sm"
                                            value={formData.bloodGroup || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="B-">B-</option>
                                        <option value="O-">O-</option>
                                        <option value="O+">O+</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="bloodGroup">Blood Group</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="hairColor"
                                            className="form-control form-control-sm"
                                            value={formData.hairColor || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Black">Black</option>
                                        <option value="Brown">Brown</option>
                                        <option value="Blonde">Blonde</option>
                                        <option value="Red">Red</option>
                                        <option value="White">White</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="hair-color">Hair Color</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="hairType"
                                            className="form-control form-control-sm"
                                            value={formData.hairType || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Wavy">Wavy</option>
                                        <option value="Curly">Curly</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="hairType">Hair Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="facialHair"
                                            className="form-control form-control-sm"
                                            value={formData.facialHair || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Mustache">Mustache</option>
                                        <option value="Beard">Beard</option>
                                        <option value="No facial hairs">No facial hairs</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="facialHair">Facial Hair</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="eyeColor"
                                            className="form-control form-control-sm"
                                            value={formData.eyeColor || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Black">Black</option>
                                        <option value="Blue">Blue</option>
                                        <option value="Brown">Brown</option>
                                        <option value="Light Brown">Light Brown</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="eyeColor">Eye Color</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="eyeWear"
                                            className="form-control form-control-sm"
                                            value={formData.eyeWear || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Glasses">Glasses</option>
                                        <option value="Contact Lenses">Contact Lenses</option>
                                        <option value="No eye wear">No eye wear</option>
                                    </select>
                                    <label htmlFor="eyeWear">Eye Wear</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default PhysicalAttributeModal;