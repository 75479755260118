import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditFamilyInfo = () => {
    const location = useLocation();
    const { familyDetails } = location.state;

    const [selectedFamilyType, setSelectedFamilyType] = useState(familyDetails.family_type);
    const [selectedFinancialStatus, setSelectedFinancialStatus] = useState(familyDetails.financial_status);
    const [selectedHomeType, setSelectedHomeType] = useState(familyDetails.home_type);
    const [selectedLiving, setSelectedLiving] = useState(familyDetails.living_situations);
    const [fatherName, setFatherName] = useState((familyDetails.father_detail || '').split(',')[0]);
      const [motherName, setMotherName] = useState((familyDetails.mother_detail || '').split(',')[0]);
      const [fatherAlive, setFatherAlive] = useState((familyDetails.father_detail || '').split(',')[1]);
      const [motherAlive, setMotherAlive] = useState((familyDetails.mother_detail || '').split(',')[1]);      
    const [fatherProfession, setFatherProfession] = useState(familyDetails.father_profession);
    const [motherProfession, setMotherProfession] = useState(familyDetails.mother_profession);
    const [elderBroCount, setElderBroCount] = useState(familyDetails.no_of_elder_brothers);
    const [elderSisCount, setElderSisCount] = useState(familyDetails.no_of_elder_sisters);
    const [youngerBroCount, setYoungerBroCount] = useState(familyDetails.no_of_younger_brothers);
    const [youngerSisCount, setYoungerSisCount] = useState(familyDetails.no_of_younger_sisters);
    const [marriedBroCount, setMarriedBroCount] = useState(familyDetails.no_of_married_brothers);
    const [marriedSisCount, setMarriedSisCount] = useState(familyDetails.no_of_married_sisters);
    const [selectedFamilyValues, setSelectedFamilyValues] = useState(familyDetails.family_values);

    const navigate = useNavigate();

    const heights = [];
    for (let i = 150; i <= 200; i++) {
        heights.push({ value: `${i} cm`, label: `${i} cm` });
    }

    const weights = [];
    for (let i = 40; i <= 120; i++) {
        weights.push({ value: `${i} Kg`, label: `${i} Kg` });
    }

    const familyType = [
        { value: "Nuclear Family", label: "Nuclear Family" },
        { value: "Single Parent Family", label: "Single Parent Family" },
        { value: "Joint Family", label: "Joint Family" },
        { value: "Extended Family", label: "Extended Family" },
        { value: "Blended Family", label: "Blended Family" },
        { value: "Live Alone", label: "Live Alone" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const financialStatus = [
        { value: "Wealthy/Affluent", label: "Wealthy/Affluent" },
        { value: "Upper Middle Class", label: "Upper Middle Class" },
        { value: "Middle Class", label: "Middle Class" },
        { value: "Lower Middle Class", label: "Lower Middle Class" },
        { value: "Struggling Financially", label: "Struggling Financially" },
        { value: "Working Class", label: "Working Class" },
        { value: "Well-off", label: "Well-off" },
        { value: "Affluent", label: "Affluent" },
        { value: "High net worth family", label: "High net worth family" },
        { value: "Inherited wealth", label: "Inherited wealth" },
        { value: "Business family", label: "Business family" },
        { value: "Entrepreneurial family", label: "Entrepreneurial family" },
        { value: "Established family", label: "Established family" },
        { value: "Retired family income", label: "Retired family income" },
        { value: "Financially stable", label: "Financially stable" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const homeType = [
        { value: "House/Villa", label: "House/Villa" },
        { value: "Rent House", label: "Rent House" },
        { value: "Apartment/Flat", label: "Apartment/Flat" },
        { value: "Rent Apartment/Flat", label: "Rent Apartment/Flat" },
        { value: "Farm", label: "Farm" },
        { value: "Bangalow/Mansion", label: "Bangalow/Mansion" },
        { value: "Town House", label: "Town House" },
        { value: "Other", label: "Other" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const living = [
        { value: "Live Alone", label: "Live Alone" },
        { value: "Live with friends", label: "Live with friends" },
        { value: "Live with Family", label: "Live with Family" },
        { value: "Live with kids", label: "Live with kids" },
        { value: "Living with Parents", label: "Living with Parents" },
        { value: "Living with Roommates", label: "Living with Roommates" },
        { value: "Living in a Dormitory", label: "Living in a Dormitory" },
        { value: "Shared Housing", label: "Shared Housing" },
        { value: "Temporary Housing", label: "Temporary Housing" },
        { value: "Living in a retirement Community", label: "Living in a retirement Community" },
        { value: "Living in a Care Facility", label: "Living in a Care Facility" },
        { value: "Living in Government/Assisted Housing", label: "Living in Government/Assisted Housing" },
        { value: "Living with Pets", label: "Living with Pets" },
        { value: "Living Abroad", label: "Living Abroad" },
        { value: "Living in a Host Family", label: "Living in a Host Family" },
        { value: "Other", label: "Other" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const familyValues = [
        { value: "Conservative", label: "Conservative" },
        { value: "Moderate", label: "Moderate" },
        { value: "Liberal", label: "Liberal" },
        { value: "Traditional Values", label: "Traditional Values" },
        { value: "Cultural Values", label: "Cultural Values" },
        { value: "Religious Values", label: "Religious Values" },
        { value: "Progressive Values", label: "Progressive Values" },
        { value: "Respect and Honor", label: "Respect and Honor" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const handleInfo = async () => {
        console.log(fatherName, fatherAlive);

        const formatData = {
            familyType: selectedFamilyType,
            financialStatus: selectedFinancialStatus,
            homeType: selectedHomeType,
            livingSituations: selectedLiving,
            fatherDetail: `${fatherName},${fatherAlive}`,
            fatherProfession: fatherProfession,
            motherDetail: `${motherName},${motherAlive}`,
            motherProfession: motherProfession,
            numberOfElderBrothers: elderBroCount,
            numberOfYoungerBrothers: youngerBroCount,
            numberOfMarriedBrothers: marriedBroCount,
            numberOfElderSisters: elderSisCount,
            numberOfYoungerSisters: youngerSisCount,
            numberOfMarriedSisters: marriedSisCount,
            familyValues: selectedFamilyValues,
        };

        console.log(formatData, "FormatData");

        try {
            const token = localStorage.getItem("token");
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileUpdate/familyDetails",
                formatData,
                config
            );
            console.log(response.data);

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/my-profile");
                }, 1500);
            }
        } catch (error) {
            console.error(error);

            toast.error(
                error.response?.data?.message || "Something went wrong. Please try again."
            );
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Family Information</h2>

            <div className="input-container">
                <label>Family Type</label>
                <Select
                    options={familyType}
                    onChange={(selected) => setSelectedFamilyType(selected.value)}
                    placeholder="Select height"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedFamilyType ? { value: selectedFamilyType, label: selectedFamilyType } : null}
                />
            </div>

            <div className="input-container">
                <label>Financial Status</label>
                <Select
                    options={financialStatus}
                    onChange={(selected) => setSelectedFinancialStatus(selected.value)}
                    placeholder="Select weight"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedFinancialStatus ? { value: selectedFinancialStatus, label: selectedFinancialStatus } : null}
                />
            </div>

            <div className="input-container">
                <label>Home Type</label>
                <Select
                    options={homeType}
                    onChange={(selected) => setSelectedHomeType(selected.value)}
                    placeholder="Select weight"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHomeType ? { value: selectedHomeType, label: selectedHomeType } : null}
                />
            </div>

            <div className="input-container">
                <label>Living Situation</label>
                <Select
                    options={living}
                    onChange={(selected) => setSelectedLiving(selected.value)}
                    placeholder="Select body type"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedLiving ? { value: selectedLiving, label: selectedLiving } : null}
                />
            </div>

            <div className="input-container">
                <label>Father Name</label>
                <input
                    type="text"
                    placeholder="Enter father name"
                    className="input-field"
                    value={fatherName}
                    onChange={(e) => setFatherName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Father Alive or Not?</label>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="Alive"
                            checked={fatherAlive === 'Alive'}
                            onChange={() => setFatherAlive('Alive')}
                        />
                        Alive
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Not Alive"
                            checked={fatherAlive === 'Not Alive'}
                            onChange={() => setFatherAlive('Not Alive')}
                        />
                        Not Alive
                    </label>
                </div>
            </div>

            <div className="input-container">
                <label>Father Profession</label>
                <input
                    type="text"
                    placeholder="Enter father profession"
                    className="input-field"
                    value={fatherProfession}
                    onChange={(e) => setFatherProfession(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Mother Name</label>
                <input
                    type="text"
                    placeholder="Enter mother name"
                    className="input-field"
                    value={motherName}
                    onChange={(e) => setMotherName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Mother Alive or Not?</label>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="Alive"
                            checked={motherAlive === 'Alive'}
                            onChange={() => setMotherAlive('Alive')}
                        />
                        Alive
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Not Alive"
                            checked={motherAlive === 'Not Alive'}
                            onChange={() => setMotherAlive('Not Alive')}
                        />
                        Not Alive
                    </label>
                </div>
            </div>

            <div className="input-container">
                <label>Mother Profession</label>
                <input
                    type="text"
                    placeholder="Enter mother profession"
                    className="input-field"
                    value={motherProfession}
                    onChange={(e) => setMotherProfession(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of elder brothers</label>
                <input
                    type="number"
                    placeholder="Enter number "
                    className="input-field"
                    value={elderBroCount}
                    onChange={(e) => setElderBroCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of younger brothers</label>
                <input
                    type="number"
                    placeholder="Enter number"
                    className="input-field"
                    value={youngerBroCount}
                    onChange={(e) => setYoungerBroCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of married brothers</label>
                <input
                    type="number"
                    placeholder="Enter number"
                    className="input-field"
                    value={marriedBroCount}
                    onChange={(e) => setMarriedBroCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of elder sisters</label>
                <input
                    type="number"
                    placeholder="Enter number"
                    className="input-field"
                    value={elderSisCount}
                    onChange={(e) => setElderSisCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of younger sisters</label>
                <input
                    type="number"
                    placeholder="Enter number"
                    className="input-field"
                    value={youngerSisCount}
                    onChange={(e) => setYoungerSisCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Number of married sisters</label>
                <input
                    type="number"
                    placeholder="Enter number"
                    className="input-field"
                    value={marriedSisCount}
                    onChange={(e) => setMarriedSisCount(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Family Values</label>
                <Select
                    options={familyValues}
                    onChange={(selected) => setSelectedFamilyValues(selected.value)}
                    placeholder="Select Family Values"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedFamilyValues ? { value: selectedFamilyValues, label: selectedFamilyValues } : null}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleInfo}>Done</button>
            </div>

            <Toaster />
        </div>
    );
};

export default EditFamilyInfo;