import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { API_URL } from '../../assets/Constants';

const PhotoPrivacy = () => {
    const [privacy, setPrivacy] = useState('all'); // Default value
    const [privacyId, setPrivacyId] = useState(null); // For holding the privacy ID

    // Fetch current privacy
    const fetchPrivacy = async () => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.get(`${API_URL}/api/photo/privacy`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.status) {
                setPrivacy(response.data.privacy);
                setPrivacyId(response.data.id);
            } else {
                toast.error('Failed to fetch current privacy.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while fetching privacy settings.');
        }
    };

    // Handle privacy update
    const updatePrivacy = async () => {
        if (!privacyId) {
            toast.error('Privacy ID not found.');
            return;
        }

        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(
                `${API_URL}/api/photo/privacy/${privacyId}/update`,
                { privacy },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200 && response.data.status) {
                toast.success('Privacy updated successfully!');
            } else {
                toast.error(response.data.message || 'Failed to update privacy.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating privacy settings.');
        }
    };

    useEffect(() => {
        fetchPrivacy();
    }, []);

    return (
        <div className="tab-pane fade" id="nav-photo-privacy" role="tabpanel">
            <div>
                <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="radioDefault"
                        id="radioDefault"
                        value="all"
                        checked={privacy === 'all'}
                        onChange={(e) => setPrivacy(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="radioDefault">
                        Visible to All
                    </label>
                </div>
                <div className="form-check">
                    <input
                        type="radio"
                        className="form-check-input"
                        name="radioDefault"
                        id="radioDefault1"
                        value="protected"
                        checked={privacy === 'protected'}
                        onChange={(e) => setPrivacy(e.target.value)}
                    />
                    <label className="form-check-label" htmlFor="radioDefault1">
                        Hide the Photos
                    </label>
                </div>
                <div className="mt-3">
                    <button type="button" className="btn btn-primary" onClick={updatePrivacy}>
                        Submit
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PhotoPrivacy;
