import React, { useEffect, useState } from "react";
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from "../layouts/Header";
import "../login.css";
import axios from 'axios';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API_URL } from '../../assets/Constants';
import { GoogleLogin } from "@react-oauth/google";
import "../assets/fonts/css/switzer.css";
import "../assets/fonts/css/font-awesome.min.css";
// import "../assets/css/plugins/bootstrap-grid.css";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const LoginNew = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_URL}/api/login`, {
        email: email,
        password: password
      });
      localStorage.setItem('token', response.data.token);

      if (response.data.status === "active") {
        toast.success('Logged in Successfully!');
        navigate('/home');
      } else {
        toast.success('Profile creation incomplete. Redirecting...');
        navigate('/profilecreation');
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setErrorMessage('Invalid email or password. Please try again.');
      } else {
        setErrorMessage('An error occurred. Please try again later.');
      }
    }
  };

  useEffect(() => {
    let timeline = gsap.timeline();
    timeline
      .to(".mil-preloader", {
        height: 0,
        ease: "sine",
        duration: 0.4,
        delay: 2.3,
      })
      .to(".mil-preloader .mil-load", {
        width: "calc(100% - 30px)",
        ease: "linear",
        duration: 1.3,
        delay: -2.3,
      })
      .to(".mil-preloader .mil-load", {
        opacity: 0,
        ease: "sine",
        duration: 0.4,
        delay: -0.6,
      })
      .to(".mil-preloader p", {
        scale: 0.5,
        opacity: 0,
        ease: "sine",
        duration: 0.4,
        delay: -0.7,
        onComplete: () => {
          ScrollTrigger.refresh();
        },
      });

    // Progress animation
    gsap.to(".mil-progress", {
      height: "100%",
      ease: "sine",
      scrollTrigger: { scrub: 0.3 },
    });

    // Progress-wrap animation
    gsap.fromTo(
      ".progress-wrap",
      { yPercent: 100, autoAlpha: 0 },
      {
        yPercent: 0,
        autoAlpha: 1,
        scrollTrigger: {
          start: 500,
          toggleActions: "play none none reverse",
        },
      }
    );

    // ScrollTrigger for top panel
    ScrollTrigger.create({
      start: "top -70",
      end: 99999,
      toggleClass: { className: "mil-active", targets: ".mil-top-panel" },
    });

    // Menu button click handler (without jQuery)
    const menuBtn = document.querySelector(".mil-menu-btn");
    if (menuBtn) {
      menuBtn.addEventListener("click", () => {
        menuBtn.classList.toggle("mil-active");
        document.querySelector(".mil-top-menu").classList.toggle("mil-active");
      });
    }

    // Scroll event handler (without jQuery)
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const isWideScreen = window.innerWidth > 768;
      const topPanel = document.querySelector(".mil-top-panel");

      if ((isWideScreen && scrollTop >= 60) || !isWideScreen) {
        topPanel.classList.add("mil-active");
      } else {
        topPanel.classList.remove("mil-active");
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Back to top button click handler
    const backToTopBtn = document.querySelector(".mil-back-to-top");
    if (backToTopBtn) {
      backToTopBtn.addEventListener("click", () => {
        gsap.to(window, { scrollTo: 0, duration: 1, ease: "sine" });
      });
    }

    // Cleanup event listeners on component unmount
    return () => {
      if (menuBtn) {
        menuBtn.removeEventListener("click", () => {
          menuBtn.classList.toggle("mil-active");
          document
            .querySelector(".mil-top-menu")
            .classList.toggle("mil-active");
        });
      }
      window.removeEventListener("scroll", handleScroll);
      if (backToTopBtn) {
        backToTopBtn.removeEventListener("click", () => {
          gsap.to(window, { scrollTo: 0, duration: 1, ease: "sine" });
        });
      }
    };
  }, []);

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      console.log(credentialResponse.credential);
      const response = await axios.post(`${API_URL}/api/login/google`, {
        token: credentialResponse.credential,
      });

      localStorage.setItem('token', response.data.token);
      toast.success('Logged in with Google successfully!');
      navigate('/home');
    } catch (error) {
      if (error.response?.status === 401) {
        toast.error('User not registered.');
      } else {
        console.log(error);
        toast.error('Google login failed. Please try again.');
      }
    }
  };

  const handleGoogleFailure = () => {
    toast.error('Google login was unsuccessful. Please try again.');
  };

  // useEffect(() => {
  //   const token = localStorage.getItem("token") ?? '';
  //   if (token) {
  //     navigate('/home');
  //   }
  // }, []);

  return (
    <div id="smooth-wrapper" className="mil-wrapper">
      <div className="progress-wrap active-progress"></div>
      <Header />
      <div id="smooth-content">
        <div className="login-container mil-p-100-80" style={{ padding: "150px 0 150px 0" }}>
          <div className="container">
            <div className="row flex-sm-row-reverse justify-content-between align-items-center">
              <div className="col-xl-7 reg-form">
                <h4
                  className="mil-text-center mb-4"
                  style={{ fontWeight: "700" }}
                >
                  Login Now!!
                </h4>
                <div className="form-container col-12" >
                  <div className="row">
                    <form action="" method="post" className="col-12" onSubmit={handleLogin}>
                      <div className="form-floating form-floating-outline mb-2 col-md-12 " >
                        <input type="email" id="userEmail" className="form-control" name="email" placeholder="Enter your email" autoFocus value={email} onChange={(e) => setEmail(e.target.value)} />
                        <label htmlFor="email"> Email or Username</label>
                      </div>
                      <div className="col-md-12">
                        <div className="form-password-toggle">
                          <div className="input-group input-group-merge">
                            <div className="form-floating form-floating-outline">
                              <input type={showPassword ? "text" : "password"} id="userPassword" className="form-control"
                                name="password" placeholder="&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;"
                                aria-describedby="password" value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              <label htmlFor="password">Password</label>
                            </div>
                            <span className="input-group-text cursor-pointer" onClick={togglePasswordVisibility}>
                              <i className={showPassword ? "ri-eye-line" : "ri-eye-off-line"}></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      {errorMessage && (
                        <div style={{ fontSize: "14px", padding: "10px", marginTop: "10px", color: "red", backgroundColor: "lightgoldenrodyellow", }}>
                          {errorMessage}
                        </div>
                      )}
                      <div className="mb-2 d-flex justify-content-between">
                        <div className="form-check mt-2">
                          <input className="form-check-input" type="checkbox" id="remember-me" />
                          <label className="form-check-label" htmlFor="remember-me">
                            Remember Me
                          </label>
                        </div>
                        <a href="#!" className="float-end mb-1 mt-2">
                          {/* <span>Forgot Password?</span> */}
                        </a>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group d-flex justify-content-center mb-2">
                          <button
                            type="submit"
                            className="button primary-button"
                          >
                            Log in
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="col-xl-12 d-flex justify-content-center mb-2">
                      {/* <p className="mil-text-xs">
                        <a href="#!">Forget Password?</a>
                      </p> */}
                    </div>
                    <div className="separator">OR</div>
                    <div className="col-md-6 mt-2 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="button primary-button "
                        style={{
                          backgroundColor: "#53bdeb",
                          width: "60%",
                          fontSize: "14px",
                        }}
                        onClick={() => navigate('/login-otp-new')}
                      >
                        <i
                          className="fa fa-mobile-alt"
                          style={{ marginRight: "10px", color: "#fff" }}
                        ></i>
                        Login with SMS OTP
                      </button>
                    </div>
                    <div className="col-md-6 mt-2 d-flex justify-content-center">
                      <div>
                        <GoogleLogin
                          onSuccess={handleGoogleSuccess}
                          onError={handleGoogleFailure}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-12 d-flex justify-content-center mt-3 mb-3">
                  <p className="mil-text-m" style={{ color: "black" }}>
                    Don't have an account? <a href="/register">Register</a>
                  </p>
                </div>
              </div>
              <div className="col-xl-5">
                <div className="mil-image-frame mil-visible-overflow">
                  <img src={homeImage} alt="img" className="mil-up" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div >
  );
};

export default LoginNew;
