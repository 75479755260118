import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const LifeStyleModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };


    //options
    const eatingHabitsOptions = [
        "Veg",
        "Non-Veg",
        "Occasionally Non-Veg",
        "Eggetarian",
        "Prefer Not to say",
    ];
    const habitsOptions = [
        "No, never",
        "Yes, regularly",
        "Occasionally",
        "Trying to Quit",
        "Socially",
        "Rarely",
        "Quit",
        "Prefer Not to say",
    ];

    const hobbiesOptions = [
        "Acting",
        "Anime",
        "Board Games",
        "Cinema",
        "Dancing",
        "Design",
        "Fashion",
        "Islamic",
        "Makeup",
        "Mehandi",
        "Music",
        "Painting",
        "Singing",
        "Sports",
        "Technology",
        "Writing",
    ];
    const personalityOptions = [
        "Active Listener",
        "Adventurous",
        "Affectionate",
        "Ambitious",
        "Bookworm",
        "Carefree",
        "Cheerful",
        "Competitive",
        "Confident",
        "Creative",
        "Extroverted",
        "Family-Oriented",
        "Funny",
        "Intelligent",
        "Introverted",
        "Liberal",
        "Nerdy",
        "Open-minded",
        "Self-aware",
        "Thoughtful",
    ];

    
    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/lifeStyle`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    return (
        <div
            className="modal fade show"
            id="lifeStyleModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Life Style Detail
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="eatingHabits"
                                        value={formData.eatingHabits || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Eating Habits</option>
                                        {eatingHabitsOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="eatingHabits">Eating Habits</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="smokingHabits"
                                        value={formData.smokingHabits || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Smoking Habit</option>
                                        {habitsOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="smokingHabits">Smoking Habit</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="drinkingHabits"
                                        value={formData.drinkingHabits || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Drinking Habit</option>
                                        {habitsOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="drinkingHabits">Drinking Habit</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="favoriteFood"
                                        value={formData.favoriteFood || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="favoriteFood">Favorite Food</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="favoriteSport"
                                        value={formData.favoriteSport || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="favoriteSport">Favorite Sport</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="favoritePlace"
                                        value={formData.favoritePlace || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="favoritePlace">Favorite Place</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="favoriteBooks"
                                        value={formData.favoriteBooks || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="favoriteBooks">Favorite Books</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="placesVisited"
                                        value={formData.placesVisited || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="placesVisited">Places Visited</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="movieAndMusic"
                                        value={formData.movieAndMusic || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="movieAndMusic">Movies and Music</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="senseOfHumor"
                                        value={formData.senseOfHumor || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="senseOfHumor">Sense Of Humor</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="dressSense"
                                        value={formData.dressSense || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="dressSense">Dress Sense</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="hobbies"
                                        value={formData.hobbies || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Hobbies & Interests</option>
                                        {hobbiesOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="hobbies">Hobbies & Interests</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="personality"
                                        value={formData.personality || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Personalities</option>
                                        {personalityOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="personality">Personalities</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default LifeStyleModal;