//css
// import './assets/vendor/css/rtl/core.css';
// import './assets/vendor/css/rtl/theme-default.css';
// import './assets/css/demo.css';
// import './assets/vendor/libs/node-waves/node-waves.css';
// import './assets/vendor/libs/typeahead-js/typeahead.css';
// import './assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css';
// import './assets/vendor/fonts/remixicon/remixicon.css';
// import './assets/vendor/js/menu.js';
// import './assets/js/main.js';

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import { BrowserRouter as Router, Route, Routes, useNavigate } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google"; // Import GoogleOAuthProvider
import Login from "./Auth/Login.jsx";
import Home from "./components/Home/Home.jsx";
import Layout from "./layouts/Layout.jsx";
import ProfileDetails from "./components/Profiles/ProfileDetails/ProfileDetails.jsx";
import MyProfile from "./components/Profiles/MyProfile/MyProfile.jsx";
import ManagePhoto from "./components/Photos/ManagePhotos.jsx";
import Search from "./components/Search/Search.jsx";
import Upgrade from "./components/Upgrade/Upgrade.jsx";
import Help from "./components/Help/Help.jsx";
import Settings from "./components/Settings/Settings.jsx";
import SendOtp from "./Auth/SendOtp.jsx";
import IdSearch from "./components/Search/IdSearch.jsx";
import SavedSearch from "./components/Search/SavedSearch.jsx";
import SearchResults from "./components/Search/SearchResults.jsx";
import { Toaster } from "react-hot-toast";
import PhotoUpload from "./components/Photos/PhotoUpload.jsx";
import Explore from "./components/Explore/Explore.jsx";
import ChatList from "./components/Chat/ChatList.jsx";
import LoginNew from "./Auth/components/Login.jsx";
import ApplySearch from "./components/Search/ApplySearch.jsx";
import SendOtpNew from "./Auth/components/SendOtp.jsx";
import useMediaQuery from "./media-query.jsx";
import LoginScreen from "./mobile/components/LoginScreen.jsx";
import EmailVerification from "./mobile/EmailVerification/EmailVerification.jsx";
import MobileHome from "./mobile/components/TabNavigation/Home.jsx";
import Matches from "./mobile/components/TabNavigation/Matches";
import MobileSearch from "./mobile/components/TabNavigation/Search";
import Account from "./mobile/components/TabNavigation/Account";
import ViewProfile from "./mobile/components/Account/ViewProfile";
import EditPhoto from "./mobile/components/Account/EditPhoto";
import EditContact from "./mobile/components/Account/EditContact";
import EditEducation from "./mobile/components/Account/EditEducation";
import EditAppearance from "./mobile/components/Account/EditAppearance";
import EditBasicInfo from "./mobile/components/Account/EditBasicInfo";
import EditFamilyInfo from "./mobile/components/Account/EditFamilyInfo";
import EditReligionInfo from "./mobile/components/Account/EditReligionInfo";
import EditLifestyle from "./mobile/components/Account/EditLifestyle";
import EditPreference from "./mobile/components/Account/EditPreference";
import Notification from "./mobile/components/Notification";
import AccountSettings from "./mobile/components/Account/AccountSettings";
import Support from "./mobile/components/Account/Support";
import DetailsScreen from "./mobile/components/DetailsScreen";
import ImageCarouselScreen from "./mobile/components/ImageCarosalScreen";
import SelectProfile from "./mobile/components/Registration/SelectProfile";
import SelectGender from "./mobile/components/Registration/SelectGender";
import SelectDob from "./mobile/components/Registration/SelectDob";
import RegisterScreen from "./mobile/components/Registration/RegisterScreen";
import CreateProfile from "./mobile/components/CreateProfile";
import EducationDetails from "./mobile/components/EducationDetails";
import ReligiousDetails from "./mobile/components/ReligiousDetails";
import UploadImageScreen from "./mobile/components/UploadImageScreen";
import ChatScreen from "./mobile/components/ChatScreen";
import ForgetPassword from "./mobile/components/ForgetPassword";
import ForgetPasswordOtp from "./mobile/components/ForgetPasswordOtp";
import ResetPassword from "./mobile/components/ResetPassword";
import OtpScreen from "./mobile/components/OtpVerification/OtpScreen";
import OtpVerification from "./mobile/components/OtpVerification/OtpVerification";
import IdVerification from "./mobile/components/Account/IdVerification";
import Register from "./Auth/components/Register.jsx";
import CreateProfileWeb from "./Auth/components/CreateProfile.jsx";
import Education from "./Auth/components/Education.jsx";
import Religious from "./Auth/components/Religious.jsx";
import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const GOOGLE_CLIENT_ID = "391926974968-dceip2gq6cgpdkrbbtaamcd7njohig6k.apps.googleusercontent.com";

function App() {
    const navigate = useNavigate;
    const isMobile = useMediaQuery("(max-width: 768px)");
    const loadScript = (src) => {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`script[src="${src}"]`)) {
                resolve();
                return;
            }
            const script = document.createElement("script");
            script.src = src;
            script.async = true;
            script.onload = () => resolve();
            script.onerror = (err) =>
                reject(new Error(`Failed to load script: ${src}`));
            document.body.appendChild(script);
        });
    };

    const loadCSS = (href) => {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`link[href="${href}"]`)) {
                resolve();
                return;
            }
            const link = document.createElement("link");
            link.rel = "stylesheet";
            link.href = href;
            link.onload = () => resolve();
            link.onerror = (err) => reject(new Error(`Failed to load CSS: ${href}`));
            document.head.appendChild(link);
        });
    };

    useEffect(() => {
        const loadResources = async () => {
            try {
                if (isMobile) {
                    await import("./App-Mobile.css");
                } else {
                    await Promise.all([
                        loadCSS("assets/vendor/css/rtl/core.css"),
                        loadCSS("assets/vendor/css/rtl/theme-default.css"),
                        loadCSS("assets/css/demo.css"),
                        loadCSS(
                            "assets/vendor/libs/node-waves/node-waves.css"
                        ),
                        loadCSS(
                            "assets/vendor/libs/typeahead-js/typeahead.css"
                        ),
                        loadCSS(
                            "assets/vendor/libs/perfect-scrollbar/perfect-scrollbar.css"
                        ),
                        loadCSS("assets/vendor/fonts/remixicon/remixicon.css"),
                    ]);
                    await Promise.all([
                        loadScript("assets/vendor/js/helpers.js"),
                        loadScript("assets/js/config.js"),
                        loadScript("assets/vendor/js/menu.js"),
                        loadScript("assets/js/main.js"),
                    ]);
                }
            } catch (error) {
                console.error("Error loading resources:", error);
            }
        };

        loadResources();
    }, [isMobile]);

    useEffect(() => {
        let timeline = gsap.timeline();
        timeline
            .to(".mil-preloader", {
                height: 0,
                ease: "sine",
                duration: 0.4,
                delay: 2.3,
            })
            .to(".mil-preloader .mil-load", {
                width: "calc(100% - 30px)",
                ease: "linear",
                duration: 1.3,
                delay: -2.3,
            })
            .to(".mil-preloader .mil-load", {
                opacity: 0,
                ease: "sine",
                duration: 0.4,
                delay: -0.6,
            })
            .to(".mil-preloader p", {
                scale: 0.5,
                opacity: 0,
                ease: "sine",
                duration: 0.4,
                delay: -0.7,
                onComplete: () => {
                    ScrollTrigger.refresh();
                },
            });

        gsap.to(".mil-progress", {
            height: "100%",
            ease: "sine",
            scrollTrigger: { scrub: 0.3 },
        });

        gsap.fromTo(
            ".progress-wrap",
            { yPercent: 100, autoAlpha: 0 },
            {
                yPercent: 0,
                autoAlpha: 1,
                scrollTrigger: {
                    start: 500,
                    toggleActions: "play none none reverse",
                },
            }
        );

        ScrollTrigger.create({
            start: "top -70",
            end: 99999,
            toggleClass: { className: "mil-active", targets: ".mil-top-panel" },
        });

        const menuBtn = document.querySelector(".mil-menu-btn");
        if (menuBtn) {
            menuBtn.addEventListener("click", () => {
                menuBtn.classList.toggle("mil-active");
                document.querySelector(".mil-top-menu").classList.toggle("mil-active");
            });
        }

        const backToTopBtn = document.querySelector(".mil-back-to-top");
        if (backToTopBtn) {
            backToTopBtn.addEventListener("click", () => {
                gsap.to(window, { scrollTo: 0, duration: 1, ease: "sine" });
            });
        }
    }, []);

    useEffect(() => {
        const checkUserStatus = async () => {
            try {
                const value = localStorage.getItem("userStatus");
                console.log("User status:", value);

                if (value === null) {
                    navigate("/");
                } else if (value === "active") {
                    navigate("/home");
                } else {
                    navigate("/create-profile");
                }
            } catch (error) {
                console.error("Failed to fetch data from localStorage", error);
            }
        };

        const timer = setTimeout(() => {
            checkUserStatus();
        }, 1500);

        return () => clearTimeout(timer);
    }, [navigate]);

    return (
        <>
            <Toaster />
            {/* Wrap with GoogleOAuthProvider */}
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                <Router>
                    <Routes>
                        {isMobile ? (
                            <>
                                <Route path="/" element={<LoginScreen />} />
                                <Route
                                    path="/email-verification"
                                    element={<EmailVerification />}
                                />
                                <Route path="home" element={<MobileHome />} />
                                <Route path="/matches" element={<Matches />} />
                                <Route path="/search" element={<MobileSearch />} />
                                <Route path="/account" element={<Account />} />
                                <Route path="/my-profile" element={<ViewProfile />} />
                                <Route path="/edit-photo" element={<EditPhoto />} />
                                <Route path="/edit-basicinfo" element={<EditBasicInfo />} />
                                <Route path="/edit-contact" element={<EditContact />} />
                                <Route path="/edit-education" element={<EditEducation />} />
                                <Route path="/edit-apprearance" element={<EditAppearance />} />
                                <Route path="/edit-family-info" element={<EditFamilyInfo />} />
                                <Route
                                    path="/edit-religion-info"
                                    element={<EditReligionInfo />}
                                />
                                <Route path="/edit-lifestyle" element={<EditLifestyle />} />
                                <Route path="/edit-preference" element={<EditPreference />} />
                                <Route path="/notification" element={<Notification />} />
                                <Route path="/settings" element={<AccountSettings />} />
                                <Route path="/help" element={<Support />} />
                                <Route path="/profile-view" element={<DetailsScreen />} />
                                <Route
                                    path="/image-carousel"
                                    element={<ImageCarouselScreen />}
                                />
                                <Route path="/select-profile" element={<SelectProfile />} />
                                <Route path="/select-gender" element={<SelectGender />} />
                                <Route path="/select-dob" element={<SelectDob />} />
                                <Route path="/register" element={<RegisterScreen />} />
                                <Route path="/create-profile" element={<CreateProfile />} />
                                <Route
                                    path="/education-details"
                                    element={<EducationDetails />}
                                />
                                <Route
                                    path="/religious-details"
                                    element={<ReligiousDetails />}
                                />
                                <Route path="/upload-image" element={<UploadImageScreen />} />
                                <Route path="/chat" element={<ChatScreen />} />
                                <Route path="/forget-password" element={<ForgetPassword />} />
                                <Route path="/reset-password" element={<ResetPassword />} />
                                <Route path="/password-otp" element={<ForgetPasswordOtp />} />
                                <Route path="/otp" element={<OtpScreen />} />
                                <Route path="/otp-verification" element={<OtpVerification />} />
                                <Route path="/idVerification" element={<IdVerification />} />
                            </>
                        ) : (
                            <>
                                <Route path="/" element={<LoginNew />} />
                                <Route path="/login-otp" element={<SendOtp />} />
                                <Route path="/login-otp-new" element={<SendOtpNew />} />
                                <Route path="/register" element={<Register />} />
                                <Route path="/profilecreation" element={<CreateProfileWeb />} />
                                <Route path="/education" element={<Education />} />
                                <Route path="/religious" element={<Religious />} />
                                <Route path="home" element={<Home />} />
                                {/* routes that use the Layout */}
                                <Route path="/" element={<Layout />}>
                                    {/* routes inside Layout */}
                                    <Route path="/profile-view" element={<ProfileDetails />} />
                                    <Route path="/my-profile" element={<MyProfile />} />
                                    <Route path="/manage-photos" element={<ManagePhoto />} />
                                    <Route
                                        path="/manage-photos/upload"
                                        element={<PhotoUpload />}
                                    />
                                    <Route path="/search" element={<Search />} />
                                    <Route path="/search-view" element={<SearchResults />} />
                                    <Route path="/search-id" element={<IdSearch />} />
                                    <Route path="/search-saved" element={<SavedSearch />} />
                                    <Route path="/search-apply" element={<ApplySearch />} />
                                    <Route path="/explore" element={<Explore />} />
                                    <Route path="/chat" element={<ChatList />} />
                                    <Route path="/upgrade-plan" element={<Upgrade />} />
                                    <Route path="/help" element={<Help />} />
                                    <Route path="/settings" element={<Settings />} />
                                </Route>
                            </>
                        )}
                    </Routes>
                </Router>
            </GoogleOAuthProvider>
        </>
    );
}

export default App;
