import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { API_URL } from '../../assets/Constants';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const Settings = () => {
    const [privacy, setPrivacy] = useState('all'); // Default value
    const [privacyId, setPrivacyId] = useState(null); // For holding the privacy ID
    const navigate = useNavigate();

    // Fetch current privacy
    const fetchPrivacy = async () => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.get(`${API_URL}/api/photo/privacy`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            if (response.status === 200 && response.data.status) {
                setPrivacy(response.data.privacy);
                setPrivacyId(response.data.id);
            } else {
                toast.error('Failed to fetch current privacy.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while fetching privacy settings.');
        }
    };

    // Handle privacy update
    const updatePrivacy = async () => {
        if (!privacyId) {
            toast.error('Privacy ID not found.');
            return;
        }

        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(
                `${API_URL}/api/photo/privacy/${privacyId}/update`,
                { privacy },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.status === 200 && response.data.status) {
                toast.success('Privacy updated successfully!');
            } else {
                toast.error(response.data.message || 'Failed to update privacy.');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating privacy settings.');
        }
    };

    useEffect(() => {
        fetchPrivacy();
    }, []);

    //logout function
    const handleLogout = async (e) => {
        try {
            const token = localStorage.getItem("token") ?? '';
            
            if (!token) {
                console.warn('No token found, redirecting to login.');
                navigate('/');
                return;
            }
    
            //send the logout request with the token
            const response = await axios.post(`${API_URL}/api/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            localStorage.removeItem('token');
            toast.success('Logged out Successfully!');
            navigate('/');
    
        } catch (error) {
            console.error('Logout failed:', error);
            toast.error('An error occurred!');
        }
    };

    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div class="card">
                <div class="card-header border">
                    <h5 class="mb-0">Manage Alert</h5>
                    <p class="mb-0 card-subtitle mt-0">Disabling these notification will prevent you from receiving updates any activity fro other members on your profile.</p>
                </div>
                <div class="card-body border py-3">
                    <div class="d-flex mb-4 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">Email Alert</h6>
                                <small>You will receive an email alert</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">SMS Alert</h6>
                                <small>You will receive an SMS alert</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-1 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">Notification Alert</h6>
                                <small>You will receive the notification alerts</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="card mt-3">
                <div class="card-header border">
                    <h5 class="mb-0">Mobile Number Privacy Management</h5>
                    <p class="mb-0 card-subtitle mt-0">You can manage the profiles that can view your mobile numbers.</p>
                </div>
                <div class="card-body border py-3">
                    <div class="d-flex mb-4 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">All Paid Member</h6>
                                <small>Your mobile number can be viewed by all paid members</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-2 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">Paid Members from my community</h6>
                                <small>Your mobile numbers only visible to all paid members from my community</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input type="checkbox" class="form-check-input" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='card mt-3'>
                <div class="card-header border">
                    <h5 class="mb-0">Photo Privacy Management</h5>
                    <p class="mb-0 card-subtitle mt-0">You can manage the profiles that can view your photos.</p>
                </div>
                <div class="card-body border py-3">
                    <div class="d-flex mb-4 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">Visible to all</h6>
                                <small>Your photos will be visible to all</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="radioDefault"
                                        id="radioDefault"
                                        value="all"
                                        checked={privacy === 'all'}
                                        onChange={(e) => setPrivacy(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center">
                        <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                            <div class="mb-sm-0 mb-2">
                                <h6 class="mb-0">Lock my photo</h6>
                                <small>The photo will be available after you accept the request.</small>
                            </div>
                            <div class="text-end">
                                <div class="form-check form-switch mb-0">
                                    <input
                                        type="radio"
                                        className="form-check-input"
                                        name="radioDefault"
                                        id="radioDefault1"
                                        value="protected"
                                        checked={privacy === 'protected'}
                                        onChange={(e) => setPrivacy(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='card mt-3'>
                <div class="card-header border">
                    <h5 class="mb-0">Account Settings</h5>
                </div>
                <div class="card-body border py-3">
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0">Change Password</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-primary btn-icon waves-effect">
                                    <i class="ri-pencil-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0">Privacy and Policy</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-primary btn-icon waves-effect">
                                    <i class="ri-eye-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0">Terms and Conditions</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-primary btn-icon waves-effect">
                                    <i class="ri-eye-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0 text-danger">Deactivate Profile</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-danger btn-icon waves-effect">
                                    <i class="ri-close-circle-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0 text-danger">Delete Profile</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-danger btn-icon waves-effect">
                                    <i class="ri-delete-bin-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mb-4 align-items-center me-2">
                        <div class="flex-grow-1 row align-items-center">
                            <div class="col-7">
                                <h6 class="mb-0 text-danger">Sign Out</h6>
                            </div>
                            <div class="col-5 text-end">
                                <button class="btn btn-outline-danger btn-icon waves-effect" onClick={handleLogout}>
                                    <i class="ri-logout-box-r-line ri-22px"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
