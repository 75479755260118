import React, { useState } from 'react';
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from '../layouts/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { height, weight, maritalStatus, bodyType, physicallyChallenged, complexion, ethnicGroup, highestEducation, professionType, profession, homeCountry, homeDistrict, homeState, homePlace, presentCountry, presentDistrict, presentState, presentPlace, preferredContactType, secondaryNumber, primaryNumber } = location.state || {};

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedEatingHabits, setSelectedEatingHabits] = useState('');
    const [description, setDescription] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedRating, setSelectedRating] = useState('');
    const [selectedNamazTiming, setSelectedNamazTiming] = useState('');

    const handleSubmit = async () => {
        if (![selectedEatingHabits, selectedNamazTiming, selectedRating, selectedSection, description].every(Boolean)) {
            toast.error('Please Enter Required Fields');
            return;
        }

        const token = localStorage.getItem("token");
        const userId = localStorage.getItem('userId');
        const formData = {
            height,
            weight,
            maritalStatus,
            bodyType,
            physicallyChallenged,
            complexion,
            ethnicGroup,
            highestEducation,
            professionType,
            profession,
            homeCountry,
            homeDistrict,
            homeState,
            homePlace,
            presentCountry,
            presentDistrict,
            presentState,
            presentPlace,
            preferredContactType,
            secondaryNumber,
            primaryNumber,
            eatingHabits: selectedEatingHabits,
            performNamaz: selectedNamazTiming,
            religiousness: selectedRating,
            community: selectedSection,
            profileDescription: description,
            contactPerson: "Myself",
            userId: userId,
        };
        console.log(formData, "formData completed");

        try {
            const response = await axios.post(
                "http://admin.steptonikah.com/api/profileCreation",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.success === true) {
                console.log("Profile creation successful:", response.data);

                toast.success(response.data.message || 'Profile successfully created');
                setTimeout(() => {
                    navigate("/home");
                }, 1500);

                localStorage.setItem("userStatus", response.data.userStatus);
            } else {
                toast.error(response.data.message || "An error occurred!! Please try again");
            }
        } catch (error) {
            if (error.response) {
                console.error("Server responded with a status:", error.response.status);
                console.error("Error data:", error.response.data);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }

            toast.error(error.response?.data?.message || 'An error occurred. Please try again');
        };

    }

    return (
        <div id="smooth-wrapper" className="mil-wrapper">
            <div className="progress-wrap active-progress"></div>
            <Header />
            <div id="smooth-content">
                <div className="login-container mil-features mil-p-100-80" style={{ padding: '150px 0 150px 0' }}>
                    <div className="container">
                        <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                            <div className="col-xl-7 reg-form">
                                <div className="form-container">
                                    <div className="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="mother-tongue">Mother Tongue *</label>
                                                <select class="form-control" id="mother-tongue" name="mother-tongue"
                                                    value={selectedLanguage}
                                                    onChange={(e) => setSelectedLanguage(e.target.value)}>
                                                    <option value="" disabled selected>Select your Mother Tongue</option>
                                                    <option value="acoli">Acoli</option>
                                                    <option value="afrikaans">Afrikaans</option>
                                                    <option value="aka">Aka</option>
                                                    <option value="akan">Akan</option>
                                                    <option value="albanian">Albanian</option>
                                                    <option value="amharic">Amharic</option>
                                                    <option value="angika">Angika</option>
                                                    <option value="arabic">Arabic</option>
                                                    <option value="armenian">Armenian</option>
                                                    <option value="arunachali">Arunachali</option>
                                                    <option value="assamese">Assamese</option>
                                                    <option value="awadhi">Awadhi</option>
                                                    <option value="aymara">Aymara</option>
                                                    <option value="azerbaijani">Azerbaijani</option>
                                                    <option value="badaga">Badaga</option>
                                                    <option value="balinese">Balinese</option>
                                                    <option value="balochi">Balochi</option>
                                                    <option value="baluchi">Baluchi</option>
                                                    <option value="bambara">Bambara</option>
                                                    <option value="basaa">Basaa</option>
                                                    <option value="basque">Basque</option>
                                                    <option value="beary">Beary</option>
                                                    <option value="belarusian">Belarusian</option>
                                                    <option value="bemba">Bemba</option>
                                                    <option value="bengali">Bengali</option>
                                                    <option value="berber">Berber</option>
                                                    <option value="bhojpuri">Bhojpuri</option>
                                                    <option value="bihari">Bihari</option>
                                                    <option value="bosnian">Bosnian</option>
                                                    <option value="breton">Breton</option>
                                                    <option value="brij">Brij</option>
                                                    <option value="bulgarian">Bulgarian</option>
                                                    <option value="burmese">Burmese</option>
                                                    <option value="catalan">Catalan</option>
                                                    <option value="chagatai">Chagatai</option>
                                                    <option value="chamorro">Chamorro</option>
                                                    <option value="chatisgarhi">Chatisgarhi</option>
                                                    <option value="chichewa">Chichewa</option>
                                                    <option value="chinese-cantonese">Chinese (Cantonese)</option>
                                                    <option value="chinese-hakka">Chinese (Hakka)</option>
                                                    <option value="chinese-mandarin">Chinese (Mandarin)</option>
                                                    <option value="chuvash">Chuvash</option>
                                                    <option value="coorgi">Coorgi</option>
                                                    <option value="corsican">Corsican</option>
                                                    <option value="cree">Cree</option>
                                                    <option value="croatian">Croatian</option>
                                                    <option value="czech">Czech</option>
                                                    <option value="danish">Danish</option>
                                                    <option value="dari">Dari</option>
                                                    <option value="dinka">Dinka</option>
                                                    <option value="dogri">Dogri</option>
                                                    <option value="dutch">Dutch</option>
                                                    <option value="dzongkha">Dzongkha</option>
                                                    <option value="english">English</option>
                                                    <option value="esperanto">Esperanto</option>
                                                    <option value="estonian">Estonian</option>
                                                    <option value="ewe">Ewe</option>
                                                    <option value="fang">Fang</option>
                                                    <option value="farsi">Farsi (Persian)</option>
                                                    <option value="fijian">Fijian</option>
                                                    <option value="filipino">Filipino (Tagalog)</option>
                                                    <option value="finnish">Finnish</option>
                                                    <option value="french">French</option>
                                                    <option value="frisian">Frisian</option>
                                                    <option value="fula">Fula (Fulani)</option>
                                                    <option value="galician">Galician</option>
                                                    <option value="garhwali">Garhwali</option>
                                                    <option value="garifuna">Garifuna</option>
                                                    <option value="garo">Garo</option>
                                                    <option value="georgian">Georgian</option>
                                                    <option value="german">German</option>
                                                    <option value="gikuyu">Gikuyu (Kikuyu)</option>
                                                    <option value="greek">Greek</option>
                                                    <option value="greenlandic">Greenlandic (Kalaallisut)</option>
                                                    <option value="guarani">Guarani</option>
                                                    <option value="gujarati">Gujarati</option>
                                                    <option value="haitian-creole">Haitian Creole</option>
                                                    <option value="haryanvi">Haryanvi</option>
                                                    <option value="hausa">Hausa</option>
                                                    <option value="hawaiian">Hawaiian</option>
                                                    <option value="hebrew">Hebrew</option>
                                                    <option value="herero">Herero</option>
                                                    <option value="himachali-pahari">Himachali/Pahari</option>
                                                    <option value="hindi">Hindi</option>
                                                    <option value="hindko">Hindko</option>
                                                    <option value="hungarian">Hungarian</option>
                                                    <option value="icelandic">Icelandic</option>
                                                    <option value="ilocano">Ilocano</option>
                                                    <option value="indonesian">Indonesian</option>
                                                    <option value="inuktitut">Inuktitut</option>
                                                    <option value="irish">Irish (Gaelic)</option>
                                                    <option value="italian">Italian</option>
                                                    <option value="japanese">Japanese</option>
                                                    <option value="javanese">Javanese</option>
                                                    <option value="kanauji">Kanauji</option>
                                                    <option value="kannada">Kannada</option>
                                                    <option value="karakalpak">Karakalpak</option>
                                                    <option value="kashmiri">Kashmiri</option>
                                                    <option value="kazakh">Kazakh</option>
                                                    <option value="khandesi">Khandesi</option>
                                                    <option value="khasi">Khasi</option>
                                                    <option value="khmer">Khmer (Cambodian)</option>
                                                    <option value="kinyarwanda">Kinyarwanda</option>
                                                    <option value="kirundi">Kirundi</option>
                                                    <option value="konkani">Konkani</option>
                                                    <option value="korean">Korean</option>
                                                    <option value="koshali">Koshali</option>
                                                    <option value="kpelle">Kpelle</option>
                                                    <option value="kumaoni">Kumaoni</option>
                                                    <option value="kurdish">Kurdish</option>
                                                    <option value="kutchi">Kutchi</option>
                                                    <option value="kyrgyz">Kyrgyz</option>
                                                    <option value="ladacki">Ladacki</option>
                                                    <option value="lao">Lao</option>
                                                    <option value="latvian">Latvian</option>
                                                    <option value="lepcha">Lepcha</option>
                                                    <option value="lingala">Lingala</option>
                                                    <option value="lithuanian">Lithuanian</option>
                                                    <option value="luo">Luo</option>
                                                    <option value="luxembourgish">Luxembourgish</option>
                                                    <option value="macedonian">Macedonian</option>
                                                    <option value="magahi">Magahi</option>
                                                    <option value="maithili">Maithili</option>
                                                    <option value="makhuwa">Makhuwa</option>
                                                    <option value="malagasy">Malagasy</option>
                                                    <option value="malay">Malay</option>
                                                    <option value="malayalam">Malayalam</option>
                                                    <option value="maltese">Maltese</option>
                                                    <option value="mandinka">Mandinka</option>
                                                    <option value="manipuri">Manipuri</option>
                                                    <option value="manx">Manx</option>
                                                    <option value="maori">Maori</option>
                                                    <option value="marathi">Marathi</option>
                                                    <option value="marwari">Marwari</option>
                                                    <option value="miji">Miji</option>
                                                    <option value="mizo">Mizo (Lushai)</option>
                                                    <option value="mohegan-pequot">Mohegan-Pequot</option>
                                                    <option value="mongolian">Mongolian</option>
                                                    <option value="mountain">Mountain</option>
                                                    <option value="navajo">Navajo</option>
                                                    <option value="nepali">Nepali</option>
                                                    <option value="nigerian-pidgin">Nigerian Pidgin</option>
                                                    <option value="niuean">Niuean</option>
                                                    <option value="norwegian">Norwegian</option>
                                                    <option value="odisha">Odia (Oriya)</option>
                                                    <option value="oregon">Oregon</option>
                                                    <option value="oregon">Oregon</option>
                                                    <option value="pali">Pali</option>
                                                    <option value="pangasinan">Pangasinan</option>
                                                    <option value="panjabi">Panjabi</option>
                                                    <option value="pashto">Pashto</option>
                                                    <option value="persian">Persian</option>
                                                    <option value="pipil">Pipil</option>
                                                    <option value="polish">Polish</option>
                                                    <option value="portuguese">Portuguese</option>
                                                    <option value="punjabi">Punjabi</option>
                                                    <option value="quechua">Quechua</option>
                                                    <option value="quiche">Quiché (K'iche')</option>
                                                    <option value="rajasthani">Rajasthani</option>
                                                    <option value="romani">Romani</option>
                                                    <option value="romanian">Romanian</option>
                                                    <option value="rundi">Rundi</option>
                                                    <option value="russian">Russian</option>
                                                    <option value="samburu">Samburu</option>
                                                    <option value="samoan">Samoan</option>
                                                    <option value="sanskrit">Sanskrit</option>
                                                    <option value="santhali">Santhali</option>
                                                    <option value="sardinian">Sardinian</option>
                                                    <option value="scots-gaelic">Scots Gaelic</option>
                                                    <option value="serbian">Serbian</option>
                                                    <option value="seselwa-creole-french">Seselwa Creole French</option>
                                                    <option value="sesotho">Sesotho</option>
                                                    <option value="sesotho-sa-leboa">Sesotho sa Leboa (Northern Sotho)</option>
                                                    <option value="setswana">Setswana</option>
                                                    <option value="shanghainese">Shanghainese</option>
                                                    <option value="shona">Shona</option>
                                                    <option value="silesian">Silesian</option>
                                                    <option value="sindhi">Sindhi</option>
                                                    <option value="sinhala">Sinhala</option>
                                                    <option value="siswati">Siswati (Swazi)</option>
                                                    <option value="slovak">Slovak</option>
                                                    <option value="slovenian">Slovenian</option>
                                                    <option value="somali">Somali</option>
                                                    <option value="songhay">Songhay</option>
                                                    <option value="sotho">Sotho</option>
                                                    <option value="sourashtra">Sourashtra</option>
                                                    <option value="spanish">Spanish</option>
                                                    <option value="sundanese">Sundanese</option>
                                                    <option value="swahili">Swahili</option>
                                                    <option value="swedish">Swedish</option>
                                                    <option value="tajik">Tajik</option>
                                                    <option value="tamasheq">Tamasheq</option>
                                                    <option value="tamil">Tamil</option>
                                                    <option value="tatar">Tatar</option>
                                                    <option value="telugu">Telugu</option>
                                                    <option value="thai">Thai</option>
                                                    <option value="tibetan">Tibetan</option>
                                                    <option value="tigray">Tigray</option>
                                                    <option value="tigrinya">Tigrinya</option>
                                                    <option value="tripuri">Tripuri</option>
                                                    <option value="tsonga">Tsonga</option>
                                                    <option value="tswana">Tswana</option>
                                                    <option value="tuareg">Tuareg</option>
                                                    <option value="tulu">Tulu</option>
                                                    <option value="tumbuka">Tumbuka</option>
                                                    <option value="turkish">Turkish</option>
                                                    <option value="turkmen">Turkmen</option>
                                                    <option value="twi">Twi</option>
                                                    <option value="ukrainian">Ukrainian</option>
                                                    <option value="urdu">Urdu</option>
                                                    <option value="uzbek">Uzbek</option>
                                                    <option value="venda">Venda</option>
                                                    <option value="vietnamese">Vietnamese</option>
                                                    <option value="waray">Waray</option>
                                                    <option value="wolaytta">Wolaytta</option>
                                                    <option value="wolof">Wolof</option>
                                                    <option value="xhosa">Xhosa</option>
                                                    <option value="yiddish">Yiddish</option>
                                                    <option value="yoruba">Yoruba</option>
                                                    <option value="zapotec">Zapotec</option>
                                                    <option value="zaza">Zaza</option>
                                                    <option value="zulu">Zulu</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="eating-habits">Eating Habits *</label>
                                                <select class="form-control" id="eating-habits" name="eating-habits"
                                                    value={selectedEatingHabits}
                                                    onChange={(e) => setSelectedEatingHabits(e.target.value)}>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="veg">Veg </option>
                                                    <option value="eggetarian">Eggetarian</option>
                                                    <option value="occasionally-non-veg">Occasionally Non-Veg</option>
                                                    <option value="non-veg">Non-Veg</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="community">Community *</label>
                                                <select class="form-control" id="community" name="community"
                                                    value={selectedSection}
                                                    onChange={(e) => setSelectedSection(e.target.value)}>
                                                    <option value="" disabled selected>Select your Community</option>
                                                    <option value="ahmediya">Ahmediya</option>
                                                    <option value="bohras">Bohras</option>
                                                    <option value="deobandi">Deobandi</option>
                                                    <option value="hanafi">Hanafi</option>
                                                    <option value="islamic">Islamic</option>
                                                    <option value="ishtahadi">Ishtahadi</option>
                                                    <option value="jamaati">Jamaati</option>
                                                    <option value="jafari">Jafari</option>
                                                    <option value="khanqahi">Khanqahi</option>
                                                    <option value="maliki">Maliki</option>
                                                    <option value="naqshbandi">Naqshbandi</option>
                                                    <option value="qadri">Qadri</option>
                                                    <option value="shafii">Shafii</option>
                                                    <option value="shia">Shia</option>
                                                    <option value="sunni">Sunni</option>
                                                    <option value="sufi">Sufi</option>
                                                    <option value="wahhabi">Wahhabi</option>
                                                    <option value="chisti">Chisti</option>
                                                    <option value="quranist">Quranist</option>
                                                    <option value="sufi-chisti">Sufi-Chisti</option>
                                                    <option value="ahlu-sunnah">Ahlu Sunnah</option>
                                                    <option value="tijani">Tijani</option>
                                                    <option value="murid">Murid</option>
                                                    <option value="ashari">Ashari</option>
                                                    <option value="maturidi">Maturidi</option>
                                                    <option value="ibadi">Ibadi</option>
                                                    <option value="ismaili">Ismaili</option>
                                                    <option value="zahiri">Zahiri</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="religiousness">Religiousness *</label>
                                                <select class="form-control" id="religiousness" name="religiousness"
                                                    value={selectedRating}
                                                    onChange={(e) => setSelectedRating(e.target.value)}>
                                                    <option value="" disabled selected>Select </option>
                                                    <option value="very-religious">Very religious</option>
                                                    <option value="religious">Religious</option>
                                                    <option value="not-religious">Not religious</option>
                                                    <option value="prefer-not-to-say">Prefer not to say</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="namaz">Pray Namaz *</label>
                                                <select class="form-control" id="namaz" name="namaz"
                                                    value={selectedNamazTiming}
                                                    onChange={(e) => setSelectedNamazTiming(e.target.value)}>
                                                    <option value="" disabled selected>Select</option>
                                                    <option value="always">Always</option>
                                                    <option value="sometimes">Sometimes</option>
                                                    <option value="never">Never</option>
                                                    <option value="prefer-not-to-say">Prefer not to say</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="description">Personal Description</label>
                                                <textarea cols="" rows="" class="mil-up" style={{ resize: 'none', borderRadius: '10px' }} placeholder="Text here..." name="message"
                                                    value={description}
                                                    onChange={(e) => setDescription(e.target.value)}></textarea>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <button
                                                type="submit"
                                                className="button primary-button w-100"
                                                onClick={handleSubmit}
                                            >
                                                Finish
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="mil-image-frame">
                                    <img src={homeImage} alt="Login" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Register;
