import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { IoCamera } from 'react-icons/io5';

const UploadImageScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { height, weight, maritalStatus, bodyType, hairColor, eyeColor, physicallyChallenged, complexion, ethnicGroup, highestEducation, professionType, profession, homeCountry, homeDistrict, homeState, homePlace, presentCountry, presentDistrict, presentState, presentPlace, preferredContactType, secondaryNumber, primaryNumber, eatingHabits, financialStatus, performNamaz, religiousness, community, profileDescription, contactPerson, } = location.state || {};
    console.log(height, weight, maritalStatus, bodyType, hairColor, eyeColor, physicallyChallenged, complexion, ethnicGroup, highestEducation, professionType, profession, homeCountry, homeDistrict, homeState, homePlace, presentCountry, presentDistrict, presentState, presentPlace, preferredContactType, secondaryNumber, primaryNumber, eatingHabits, financialStatus, performNamaz, religiousness, community, profileDescription, contactPerson, "Data in upload screen");

    const [photo, setPhoto] = useState(null);

    const pickImage = () => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';
        input.onchange = (event) => {
            const file = event.target.files[0];
            if (file) {
                if (!file.type.startsWith('image/')) {
                    toast.error('Please upload a valid image file.');
                    return;
                }
                if (file.size > 5 * 1024 * 1024) {
                    toast.error('File size exceeds 5MB. Please upload a smaller image.');
                    return;
                }

                const reader = new FileReader();
                reader.onload = () => setPhoto(reader.result);
                reader.readAsDataURL(file);
            }
        };
        input.click();
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem("token");
        const userId = localStorage.getItem("userId");
        console.log("token", token, "userId", userId);

        const formatData = {
            height,
            weight,
            maritalStatus,
            bodyType,
            hairColor,
            eyeColor,
            physicallyChallenged,
            complexion,
            ethnicGroup,
            highestEducation,
            professionType,
            profession,
            homeCountry,
            homeDistrict,
            homeState,
            homePlace,
            presentCountry,
            presentDistrict,
            presentState,
            presentPlace,
            preferredContactType,
            secondaryNumber,
            primaryNumber,
            eatingHabits,
            financialStatus,
            performNamaz,
            religiousness,
            community,
            profileDescription,
            contactPerson,
            userId: userId,
        };

        console.log(formatData, "formatData in upload image");

        const formData = new FormData();

        for (const key in formatData) {
            formData.append(key, formatData[key]);
        }

        if (photo) {
            const photoName = photo.split('/').pop();
            const photoType = `image/${photoName.split('.').pop()}`;
            formData.append('photo', {
                uri: photo,
                name: photoName,
                type: photoType,
            });
        }

        console.log("FormData prepared", formData);

        try {
            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileCreation",
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.data.success === true) {
                console.log("Profile creation successful:", response.data);

                toast.success(response.data.message || 'Profile successfully created');
                setTimeout(() => {
                    navigate("/home");
                }, 1500);

                localStorage.setItem("userStatus", response.data.userStatus);
            } else {
                toast.error(response.data.message || "An error occurred. Please try again");
            }
        } catch (error) {
            if (error.response) {
                console.error("Server responded with a status:", error.response.status);
                console.error("Error data:", error.response.data);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }

            toast.error(error.response?.data?.message || 'An error occurred. Please try again.');
        }
    };

    return (
        <div className="upload-image-container">
            <h1 className="title">Create Account</h1>
            <p className="subtitle">Upload Image</p>

            <div className="upload-container" onClick={pickImage}>
                {photo ? (
                    <img src={photo} alt="Uploaded" className="uploaded-image" />
                ) : (
                    <>
                        <IoCamera style={{ fontSize: '24px', color: 'grey' }} />
                        <p className="upload-text">Upload Photo</p>
                    </>
                )}
            </div>

            {!photo && (
                <div className="divider">
                    <div className="line"></div>
                    <p className="or-text">or</p>
                    <div className="line"></div>
                </div>
            )}

            <button className="submit-button" onClick={handleSubmit}>
                {photo ? 'Create' : 'Skip'}
            </button>
            <Toaster />
        </div>
    );
};

export default UploadImageScreen;
