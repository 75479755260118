import React, { useEffect, useState } from "react";
import LifeStyleModal from "./Modals/LifeStyleModal";

const LifeStyle = ({ lifestyleDetails : initialLifestyleDetails }) => {
    const [showModal, setShowModal] = useState(false);
    const [lifestyleDetails, setLifestyleDetails] = useState(initialLifestyleDetails);

    useEffect(() => {
        setLifestyleDetails(initialLifestyleDetails);
    }, [initialLifestyleDetails]);

    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return []; // Return an empty array if parsing fails
        }
    };
    
    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setLifestyleDetails(prev => ({
            ...prev,
            eatingHabits: updatedDetails.eatingHabits,
            smokingHabits: updatedDetails.smokingHabits,
            drinkingHabits: updatedDetails.drinkingHabits,
            hobbies: updatedDetails.hobbies,
            favoriteFood: updatedDetails.favoriteFood,
            favoriteSport: updatedDetails.favoriteSport,
            favoritePlace: updatedDetails.favoritePlace,
            favoriteBooks: updatedDetails.favoriteBooks,
            placesVisited: updatedDetails.placesVisited,
            movieAndMusic: updatedDetails.movieAndMusic,
            personality: updatedDetails.personality,
            senseOfHumor: updatedDetails.senseOfHumor,
            dressSense: updatedDetails.dressSense,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Lifestyle</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table class="table border">
                <tbody>
                        <tr>
                            <th width="200">Smoking Habits</th>
                            <td>: {lifestyleDetails?.smoking_habits || 'N/A'}</td>
                            <th width="200">Drinking Habits</th>
                            <td>: {lifestyleDetails?.drinking_habits || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Eating Habits</th>
                            <td>: {lifestyleDetails?.eating_habits || 'N/A'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <th>Personalities</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>
                                : {parseArray(lifestyleDetails?.personality).join(", ") || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th>Hobbies & Interests</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>
                                : {parseArray(lifestyleDetails?.hobbies).join(", ") || "N/A"}
                            </td>
                        </tr>
                        <tr>
                            <th>Favorite Food</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_food || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Sport</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_sport || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Place</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_place || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Books</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_books || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Places Visited</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.places_visited || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Movie and Music Preferences</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.movie_and_music || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Dress Sense</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.dress_sense || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Sense of Humor</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.sense_of_humor || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <LifeStyleModal
                    details={{
                        eatingHabits: lifestyleDetails?.eating_habits,
                        smokingHabits: lifestyleDetails?.smoking_habits,
                        drinkingHabits: lifestyleDetails?.drinking_habits,
                        hobbies: lifestyleDetails?.hobbies,
                        favoriteFood: lifestyleDetails?.favorite_food,
                        favoriteSport: lifestyleDetails?.favorite_sport,
                        favoritePlace: lifestyleDetails?.favorite_place,
                        favoriteBooks: lifestyleDetails?.favorite_books,
                        placesVisited: lifestyleDetails?.places_visited,
                        movieAndMusic: lifestyleDetails?.movie_and_music,
                        personality: lifestyleDetails?.personality,
                        senseOfHumor: lifestyleDetails?.sense_of_humor,
                        dressSense: lifestyleDetails?.dress_sense,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default LifeStyle;