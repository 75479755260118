import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const ReligiousInformationModal = ({ details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFormData(details);
    }, [details]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validate = () => {
        let validationErrors = {};
        
        if (!formData.religion) {
            validationErrors.religion = "*required";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validate()) return; // Stop if validation fails

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/religiousDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };

    return (
        <div
            className="modal fade show"
            id="religiousInformationModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Religious Information
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                            name="religion"
                                            className="form-control form-control-sm"
                                            value={formData.religion || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Muslim">Muslim</option>
                                        <option value="Christian">Christian</option>
                                    </select>
                                    <label htmlFor="religion">Religion</label>
                                    {errors.religion && <div className="invalid-feedback">{errors.religion}</div>}
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="community"
                                        className="form-control form-control-sm"
                                        value={formData.community || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Sunni">Sunni</option>
                                        <option value="Salafi">Salafi</option>
                                        <option value="Jama-at Islami">Jama-at Islami</option>
                                    </select>
                                    <label htmlFor="community">Community</label>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="religiousness"
                                        className="form-control form-control-sm"
                                        value={formData.religiousness || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Very Religious">Very Religious</option>
                                        <option value="Non Religious">Non Religious</option>
                                        <option value="Religious">Religious</option>
                                    </select>
                                    <label htmlFor="religiousness">Religiousness</label>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="performNamaz"
                                        className="form-control form-control-sm"
                                        value={formData.performNamaz || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Always">Always</option>
                                        <option value="Five times daily">Five times daily</option>
                                        <option value="Rarely">Rarely</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="performNamaz">Perform Namaz</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                            name="readQuran"
                                            className="form-control form-control-sm"
                                            value={formData.readQuran || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Daily">Daily</option>
                                        <option value="Weekly">Weekly</option>
                                        <option value="Only on fridays">Only on fridays</option>
                                        <option value="Prefer not to say">Prefer not to say</option>
                                    </select>
                                    <label htmlFor="readQuran">Read Quran</label>
                                </div>
                            </div>

                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        name="mahalluName"
                                        className="form-control form-control-sm"
                                        placeholder="Mahallu Name"
                                        value={formData.mahalluName || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="mahalluName">Mahallu Name</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="madrasaEducation"
                                        className="form-control form-control-sm"
                                        value={formData.madrasaEducation || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                        <option value="8">8</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                    </select>
                                    <label htmlFor="madrasaEducation">Madrasa Education</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReligiousInformationModal;
