import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import { API_URL } from '../../../assets/Constants';
import axios from 'axios';
import imgEmpty from '../../../assets/img/illustrations/illustration-upgrade-account.png';

const ViewedContacts = () => {
    const [profiles, setProfiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const token = localStorage.getItem('token') || '';
            const response = await axios.get(`${API_URL}/api/contactView/viewedProfiles`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.status === 200 && response.data.contactViews) {
                setProfiles(response.data.contactViews);
            } else {
                setProfiles([]);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch profiles');
        } finally {
            setIsLoading(false);
        }
    };

    const declineRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/contactRequest/delete`,
                { requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                toast.success('Request declined successfully');
                fetchData(); // Refresh data
            } else {
                toast.error(response.data.message || 'Failed to decline request');
            }
        } catch (error) {
            console.error('Error declining request:', error);
            toast.error('An error occurred while declining the request');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="demo-inline-spacing mt-4">
            {isLoading ? (
                <div className="text-center">Loading...</div>
            ) : profiles.length > 0 ? (
                <div className="list-group">
                    {profiles.map((profile) => (
                        <div
                            key={profile.id}
                            className="list-group-item list-group-item-action d-flex align-items-center cursor-pointer waves-effect"
                        >
                            <img
                                src={profile.photo || 'default-image.jpg'}
                                alt={profile.name}
                                className="rounded-circle me-3"
                                width="120"
                            />
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="user-info">
                                        <h6 className="mb-1">{profile.name}</h6>
                                        <div>
                                            <p className="mb-1">{profile.age}, {profile.height} cm</p>
                                            <p className="mb-1">Profile Id: {profile.profileId}</p>
                                        </div>
                                        <small className="text-muted">Viewed On: {profile.viewedOn}</small>
                                    </div>
                                    <div className="action-btn">
                                        {profile.status === 'pending' && (
                                            <button
                                                className="btn btn-danger btn-sm waves-effect waves-light"
                                                onClick={() => declineRequest(profile.id)}
                                            >
                                                Decline
                                            </button>
                                        )}
                                        {profile.status === 'accepted' && (
                                            <span className="badge bg-success py-3">
                                                <i className='ri ri-check-fill me-2'></i>
                                                Accepted
                                            </span>
                                        )}
                                        {profile.status === 'rejected' && (
                                            <span className="badge bg-danger py-3">
                                                <i className='ri ri-close-circle-fill me-2'></i>
                                                Rejected
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="misc-wrapper border rounded">
                    <div className="text-center">
                        <h4 className="mb-2">List is empty</h4>
                        <p className="mb-6 mx-2">We couldn't find any accepted profiles</p>
                    </div>
                    <div className="d-flex justify-content-center mt-9">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src={imgEmpty}
                                alt="misc-error"
                                className="img-fluid z-1"
                                width="150"
                            />
                            <div>
                                <Link to="/home">
                                    <button className="btn btn-primary btn-sm text-center my-10 waves-effect waves-light">
                                        Back to home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ViewedContacts;
