import React, { useEffect, useState } from "react";
import LocationPreferenceModal from "./Modals/LocationPreferenceModal";
const LocationPreference = ({locationPreference : initialLocationPreference}) => {
    const [showModal, setShowModal] = useState(false);
    const [locationPreference, setLocationPreference] = useState(initialLocationPreference);

    useEffect(() => {
        setLocationPreference(initialLocationPreference);
    }, [initialLocationPreference]);

    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return []; // Return an empty array if parsing fails
        }
    };

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setLocationPreference(prev => ({
            ...prev,
            districtsPreference: updatedDetails.districtsPreference,
            presentCountry: updatedDetails.presentCountry,
        }));

        setShowModal(false);
    };

    return(
        <div className="card mt-3 p-0">
            <div class="card-header d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Location Preferences</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                         onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="card-body">
                <div class="table-responsive">
                    <table class="table border">
                        <tbody>
                            <tr>
                                <td>Present Country</td>
                                <td>
                                    : {parseArray(locationPreference?.present_country).join(", ") || "N/A"}
                                </td>
                                <td>District Preference</td>
                                <td>
                                    : {parseArray(locationPreference?.districts_preference).join(", ") || "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <LocationPreferenceModal
                    details={{
                        districtsPreference: locationPreference?.districts_preference,
                        presentCountry: locationPreference?.present_country,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default LocationPreference;