import React, { useState } from 'react';
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from '../layouts/Header';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Register = () => {
    const navigate = useNavigate();
    const [selectedHeight, setSelectedHeight] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('');
    const [selectedBodyType, setSelectedBodyType] = useState('');
    const [selectedComplexion, setSelectedComplexion] = useState('');
    const [selectedEthnicGroup, setSelectedEthnicGroup] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [physicallyChallenged, setPhysicallyChallenged] = useState('');

    const handlePhysicalDetails = () => {
        console.log(selectedHeight, selectedWeight, selectedOption, selectedBodyType, selectedComplexion, physicallyChallenged);
        if (!selectedHeight || !selectedWeight || !selectedOption || !selectedBodyType || !selectedComplexion || !physicallyChallenged) {
            toast.error('Please enter all required fields');
            return;
        }

        navigate('/education', {
            state: {
                height: selectedHeight,
                weight: selectedWeight,
                maritalStatus: selectedOption,
                bodyType: selectedBodyType,
                complexion: selectedComplexion,
                physicallyChallenged,
                ethnicGroup: selectedEthnicGroup
            }
        });
    };

    return (
        <div id="smooth-wrapper" className="mil-wrapper">
            <div className="progress-wrap active-progress"></div>
            <Header />
            <div id="smooth-content">
                <div className="login-container">
                    <div className="mil-features mil-p-100-80" style={{ padding: '150px 0 150px 0' }}>
                        <div className="container">
                            <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                                <div className="col-xl-7 reg-form">
                                    <div className="form-container">
                                        {/* <!-- <p className="head">Register Now</p> --> */}
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="height">Height *</label>
                                                    <select className="form-control" id="height" name="height"
                                                        value={selectedHeight}
                                                        onChange={(e) => setSelectedHeight(e.target.value)}>
                                                        <option value="" disabled selected>Select your height</option>
                                                        <option value="130">130 cm</option>
                                                        <option value="131">131 cm</option>
                                                        <option value="132">132 cm</option>
                                                        <option value="133">133 cm</option>
                                                        <option value="134">134 cm</option>
                                                        <option value="135">135 cm</option>
                                                        <option value="136">136 cm</option>
                                                        <option value="137">137 cm</option>
                                                        <option value="138">138 cm</option>
                                                        <option value="139">139 cm</option>
                                                        <option value="140">140 cm</option>
                                                        <option value="141">141 cm</option>
                                                        <option value="142">142 cm</option>
                                                        <option value="143">143 cm</option>
                                                        <option value="144">144 cm</option>
                                                        <option value="145">145 cm</option>
                                                        <option value="146">146 cm</option>
                                                        <option value="147">147 cm</option>
                                                        <option value="148">148 cm</option>
                                                        <option value="149">149 cm</option>
                                                        <option value="150">150 cm</option>
                                                        <option value="151">151 cm</option>
                                                        <option value="152">152 cm</option>
                                                        <option value="153">153 cm</option>
                                                        <option value="154">154 cm</option>
                                                        <option value="155">155 cm</option>
                                                        <option value="156">156 cm</option>
                                                        <option value="157">157 cm</option>
                                                        <option value="158">158 cm</option>
                                                        <option value="159">159 cm</option>
                                                        <option value="160">160 cm</option>
                                                        <option value="161">161 cm</option>
                                                        <option value="162">162 cm</option>
                                                        <option value="163">163 cm</option>
                                                        <option value="164">164 cm</option>
                                                        <option value="165">165 cm</option>
                                                        <option value="166">166 cm</option>
                                                        <option value="167">167 cm</option>
                                                        <option value="168">168 cm</option>
                                                        <option value="169">169 cm</option>
                                                        <option value="170">170 cm</option>
                                                        <option value="171">171 cm</option>
                                                        <option value="172">172 cm</option>
                                                        <option value="173">173 cm</option>
                                                        <option value="174">174 cm</option>
                                                        <option value="175">175 cm</option>
                                                        <option value="176">176 cm</option>
                                                        <option value="177">177 cm</option>
                                                        <option value="178">178 cm</option>
                                                        <option value="179">179 cm</option>
                                                        <option value="180">180 cm</option>
                                                        <option value="181">181 cm</option>
                                                        <option value="182">182 cm</option>
                                                        <option value="183">183 cm</option>
                                                        <option value="184">184 cm</option>
                                                        <option value="185">185 cm</option>
                                                        <option value="186">186 cm</option>
                                                        <option value="187">187 cm</option>
                                                        <option value="188">188 cm</option>
                                                        <option value="189">189 cm</option>
                                                        <option value="190">190 cm</option>
                                                        <option value="191">191 cm</option>
                                                        <option value="192">192 cm</option>
                                                        <option value="193">193 cm</option>
                                                        <option value="194">194 cm</option>
                                                        <option value="195">195 cm</option>
                                                        <option value="196">196 cm</option>
                                                        <option value="197">197 cm</option>
                                                        <option value="198">198 cm</option>
                                                        <option value="199">199 cm</option>
                                                        <option value="200">200 cm</option>
                                                        <option value="201">201 cm</option>
                                                        <option value="202">202 cm</option>
                                                        <option value="203">203 cm</option>
                                                        <option value="204">204 cm</option>
                                                        <option value="205">205 cm</option>
                                                        <option value="206">206 cm</option>
                                                        <option value="207">207 cm</option>
                                                        <option value="208">208 cm</option>
                                                        <option value="209">209 cm</option>
                                                        <option value="210">210 cm</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="weight">Weight *</label>
                                                    <select className="form-control" id="weight" name="weight"
                                                        value={selectedWeight}
                                                        onChange={(e) => setSelectedWeight(e.target.value)}>
                                                        <option value="" disabled selected>Select your weight</option>
                                                        <option value="40">40 kg</option>
                                                        <option value="41">41 kg</option>
                                                        <option value="42">42 kg</option>
                                                        <option value="43">43 kg</option>
                                                        <option value="44">44 kg</option>
                                                        <option value="45">45 kg</option>
                                                        <option value="46">46 kg</option>
                                                        <option value="47">47 kg</option>
                                                        <option value="48">48 kg</option>
                                                        <option value="49">49 kg</option>
                                                        <option value="50">50 kg</option>
                                                        <option value="51">51 kg</option>
                                                        <option value="52">52 kg</option>
                                                        <option value="53">53 kg</option>
                                                        <option value="54">54 kg</option>
                                                        <option value="55">55 kg</option>
                                                        <option value="56">56 kg</option>
                                                        <option value="57">57 kg</option>
                                                        <option value="58">58 kg</option>
                                                        <option value="59">59 kg</option>
                                                        <option value="60">60 kg</option>
                                                        <option value="61">61 kg</option>
                                                        <option value="62">62 kg</option>
                                                        <option value="63">63 kg</option>
                                                        <option value="64">64 kg</option>
                                                        <option value="65">65 kg</option>
                                                        <option value="66">66 kg</option>
                                                        <option value="67">67 kg</option>
                                                        <option value="68">68 kg</option>
                                                        <option value="69">69 kg</option>
                                                        <option value="70">70 kg</option>
                                                        <option value="71">71 kg</option>
                                                        <option value="72">72 kg</option>
                                                        <option value="73">73 kg</option>
                                                        <option value="74">74 kg</option>
                                                        <option value="75">75 kg</option>
                                                        <option value="76">76 kg</option>
                                                        <option value="77">77 kg</option>
                                                        <option value="78">78 kg</option>
                                                        <option value="79">79 kg</option>
                                                        <option value="80">80 kg</option>
                                                        <option value="81">81 kg</option>
                                                        <option value="82">82 kg</option>
                                                        <option value="83">83 kg</option>
                                                        <option value="84">84 kg</option>
                                                        <option value="85">85 kg</option>
                                                        <option value="86">86 kg</option>
                                                        <option value="87">87 kg</option>
                                                        <option value="88">88 kg</option>
                                                        <option value="89">89 kg</option>
                                                        <option value="90">90 kg</option>
                                                        <option value="91">91 kg</option>
                                                        <option value="92">92 kg</option>
                                                        <option value="93">93 kg</option>
                                                        <option value="94">94 kg</option>
                                                        <option value="95">95 kg</option>
                                                        <option value="96">96 kg</option>
                                                        <option value="97">97 kg</option>
                                                        <option value="98">98 kg</option>
                                                        <option value="99">99 kg</option>
                                                        <option value="100">100 kg</option>
                                                        <option value="101">101 kg</option>
                                                        <option value="102">102 kg</option>
                                                        <option value="103">103 kg</option>
                                                        <option value="104">104 kg</option>
                                                        <option value="105">105 kg</option>
                                                        <option value="106">106 kg</option>
                                                        <option value="107">107 kg</option>
                                                        <option value="108">108 kg</option>
                                                        <option value="109">109 kg</option>
                                                        <option value="110">110 kg</option>
                                                        <option value="111">111 kg</option>
                                                        <option value="112">112 kg</option>
                                                        <option value="113">113 kg</option>
                                                        <option value="114">114 kg</option>
                                                        <option value="115">115 kg</option>
                                                        <option value="116">116 kg</option>
                                                        <option value="117">117 kg</option>
                                                        <option value="118">118 kg</option>
                                                        <option value="119">119 kg</option>
                                                        <option value="120">120 kg</option>
                                                        <option value="121">121 kg</option>
                                                        <option value="122">122 kg</option>
                                                        <option value="123">123 kg</option>
                                                        <option value="124">124 kg</option>
                                                        <option value="125">125 kg</option>
                                                        <option value="126">126 kg</option>
                                                        <option value="127">127 kg</option>
                                                        <option value="128">128 kg</option>
                                                        <option value="129">129 kg</option>
                                                        <option value="130">130 kg</option>
                                                        <option value="131">131 kg</option>
                                                        <option value="132">132 kg</option>
                                                        <option value="133">133 kg</option>
                                                        <option value="134">134 kg</option>
                                                        <option value="135">135 kg</option>
                                                        <option value="136">136 kg</option>
                                                        <option value="137">137 kg</option>
                                                        <option value="138">138 kg</option>
                                                        <option value="139">139 kg</option>
                                                        <option value="140">140 kg</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="maritalStatus">Select Marital Status *</label>
                                                    <select className="form-control" id="maritalStatus" name="maritalStatus" value={selectedOption}
                                                        onChange={(e) => setSelectedOption(e.target.value)}>
                                                        <option value="" disabled selected>Select your marital status</option>
                                                        <option value="never-married">Never Married</option>
                                                        <option value="divorced">Divorced</option>
                                                        <option value="awaiting-divorce">Awaiting Divorce</option>
                                                        <option value="widowed">Widowed/Widower</option>
                                                        <option value="nikah-divorce">Nikah Divorce</option>
                                                        <option value="married">Married</option>
                                                    </select>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="children">Number of Children *</label>
                                                    <select className="form-control" id="children" name="children" required>
                                                        <option value="" disabled selected>Select no. of children</option>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="4">4</option>
                                                        <option value="5-or-more">5 or more</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="living">Children Living Status *</label>
                                                    <select className="form-control" id="living" name="living" required>
                                                        <option value="" disabled selected>Select </option>
                                                        <option value="living-with-me">Living with me</option>
                                                        <option value="not-living-with-me">Not Living with me</option>
                                                        <option value="sometimes-live-with-me">Sometimes Live with me</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="children-age">Age of Children *</label>
                                                    <select className="form-control" id="children-age" name="children-age" required>
                                                        <option value="" disabled selected>Select age</option>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="older-than-18">Older than 18</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="younger-age">Age of Younger Children *</label>
                                                    <select className="form-control" id="younger-age" name="younger-age" required>
                                                        <option value="" disabled selected>Select age</option>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="older-than-18">Older than 18</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="elder-age">Age of Elder Children *</label>
                                                    <select className="form-control" id="elder-age" name="elder-age" required>
                                                        <option value="" disabled selected>Select age</option>
                                                        <option value="0">0</option>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                        <option value="5">5</option>
                                                        <option value="6">6</option>
                                                        <option value="7">7</option>
                                                        <option value="8">8</option>
                                                        <option value="9">9</option>
                                                        <option value="10">10</option>
                                                        <option value="11">11</option>
                                                        <option value="12">12</option>
                                                        <option value="13">13</option>
                                                        <option value="14">14</option>
                                                        <option value="15">15</option>
                                                        <option value="16">16</option>
                                                        <option value="17">17</option>
                                                        <option value="older-than-18">Older than 18</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="skin-tone">Skin Tone *</label>
                                                    <select className="form-control" id="skintone" name="skintone"
                                                        value={selectedComplexion}
                                                        onChange={(e) => setSelectedComplexion(e.target.value)}>
                                                        <option value="" disabled selected>Select your skin tone</option>
                                                        <option value="very fair">Very Fair</option>
                                                        <option value="fair">Fair</option>
                                                        <option value="wheatish">Wheatish</option>
                                                        <option value="wheatish brown">Wheatish Brown</option>
                                                        <option value="dark">Dark</option>
                                                        <option value="prefer not to say">Prefer Not To Say</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="ethnic">Ethnic Group *</label>
                                                    <select className="form-control" id="ethnic" name="ethnic"
                                                        value={selectedEthnicGroup}
                                                        onChange={(e) => setSelectedEthnicGroup(e.target.value)}>
                                                        <option value="" disabled selected>Select your ethnic group</option>
                                                        <option value="afghan">Afghan</option>
                                                        <option value="african">African</option>
                                                        <option value="african-american">African American</option>
                                                        <option value="american">American</option>
                                                        <option value="arab">Arab</option>
                                                        <option value="asian">Asian</option>
                                                        <option value="australian">Australian</option>
                                                        <option value="bangladeshi">Bangladeshi</option>
                                                        <option value="berber">Berber</option>
                                                        <option value="caribbean">Caribbean</option>
                                                        <option value="caucasian">Caucasian/White</option>
                                                        <option value="central-african">Central African</option>
                                                        <option value="chinese">Chinese</option>
                                                        <option value="east-african">East African</option>
                                                        <option value="egyptian">Egyptian</option>
                                                        <option value="european">European</option>
                                                        <option value="far-east-asian">Far East Asian</option>
                                                        <option value="hispanic">Hispanic</option>
                                                        <option value="indian">Indian</option>
                                                        <option value="indonesian">Indonesian</option>
                                                        <option value="iraqi">Iraqi</option>
                                                        <option value="japanese">Japanese</option>
                                                        <option value="jewish">Jewish</option>
                                                        <option value="korean">Korean</option>
                                                        <option value="kurdish">Kurdish</option>
                                                        <option value="latino">Latino</option>
                                                        <option value="malay">Malay</option>
                                                        <option value="mixed-race">Mixed Race</option>
                                                        <option value="nigerian">Nigerian</option>
                                                        <option value="north-african">North African</option>
                                                        <option value="north-american">North American</option>
                                                        <option value="other">Other</option>
                                                        <option value="pakistani">Pakistani</option>
                                                        <option value="persian">Persian</option>
                                                        <option value="philippines">Philippines</option>
                                                        <option value="russian">Russian</option>
                                                        <option value="somalian">Somalian</option>
                                                        <option value="south-african">South African</option>
                                                        <option value="south-american">South American</option>
                                                        <option value="sri-lankan">Sri Lankan</option>
                                                        <option value="sudanese">Sudanese</option>
                                                        <option value="turkish">Turkish</option>
                                                        <option value="west-african">West African</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="bodytype">Body Type *</label>
                                                    <select className="form-control" id="bodytype" name="bodytype"
                                                        value={selectedBodyType}
                                                        onChange={(e) => setSelectedBodyType(e.target.value)}>
                                                        <option value="" disabled selected>Select your body type</option>
                                                        <option value="slim">Slim</option>
                                                        <option value="athletic">Athletic</option>
                                                        <option value="average">Average</option>
                                                        <option value="muscular">Muscular</option>
                                                        <option value="curvy">Curvy</option>
                                                        <option value="heavy">Heavy</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>Physically challenged *</label>
                                                    <div className="d-flex align-items-center mt-3">
                                                        <div className="form-check ms-2">
                                                            <input type="radio" id="customRadioInline1" name="physically-impaired" value="yes" className="form-check-input"
                                                                checked={physicallyChallenged === 'Yes'}
                                                                onChange={() => setPhysicallyChallenged('Yes')} />
                                                            <label className="custom-control-label" style={{ marginLeft: '5px' }} for="customRadioInline1">Yes</label>
                                                        </div>
                                                        <div className="form-check ms-4">
                                                            <input type="radio" id="customRadioInline2" name="physically-impaired" value="no" className="form-check-input"
                                                                checked={physicallyChallenged === 'No'}
                                                                onChange={() => setPhysicallyChallenged('No')} />
                                                            <label className="custom-control-label" style={{ marginLeft: '5px' }} for="customRadioInline2">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="physicalstatus">Physical Status *</label>
                                                    <select className="form-control" id="physicalstatus" name="physicalstatus" required>
                                                        <option value="">Select your Physical Status</option>
                                                        <option value="vision-impaired">Vision Impaired</option>
                                                        <option value="hearing-impaired">Hearing Impaired</option>
                                                        <option value="mentally-impaired">Mentally Impaired</option>
                                                        <option value="physically-impaired">Physically Impaired</option>
                                                        <option value="differently-abled">Differently Abled</option>
                                                    </select>
                                                </div>
                                            </div> */}
                                            {/* <div className="col-md-12">
                                                <div className="form-group">
                                                    <label for="disability-details">Disability Details</label>
                                                    <textarea cols="" rows="" className="mil-up" style={{ resize: 'none', fontWeight: 100, borderRadius: '10px', }} placeholder="Text here..."
                                                        name="message"></textarea>
                                                </div>
                                            </div> */}
                                            <div className="col-md-12 mt-4">
                                                <button
                                                    type="submit"
                                                    className="button primary-button w-100"
                                                    onClick={handlePhysicalDetails}
                                                >
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="mil-image-frame">
                                        <img src={homeImage} alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Register;
