import React from 'react'

const Offices = () => {
    return (
        <div className='p-5'>
            <div className="card card-action mb-6">
                <div className="card-header">
                    <h5 className="card-action-title mb-0 text-primary">Pay at Our Offices</h5>
                    <div className="card-action-element">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="#!" className="card-collapsible">
                                    <i className="tf-icons ri-arrow-down-s-line"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="collapse show">
                    <div className="card-body">
                    <table className="table table-hover border rounded">
                            <thead>
                                <tr>
                                    <th>Place</th>
                                    <th>Address</th>
                                </tr>
                            </thead>
                            <tbody className="border">
                                <tr>
                                    <th>Calicut</th>
                                    <th>
                                        Test Address<br />
                                        Test Building<br/>
                                        Pincode : 978654<br />
                                        Kerala, India
                                        9876543210
                                    </th>
                                </tr>
                                <tr>
                                    <th>Malappuram</th>
                                    <th>
                                        Test Address<br />
                                        Test Building<br/>
                                        Pincode : 978654<br />
                                        Kerala, India
                                        9876543210
                                    </th>
                                </tr>
                                <tr>
                                    <th>Kottakkal</th>
                                    <th>
                                        Test Address<br />
                                        Test Building<br/>
                                        Pincode : 978654<br />
                                        Kerala, India
                                        9876543210
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Offices
