import React, { useState } from "react";


const Footer = () =>{

    const currentYear = useState(new Date().getFullYear());
    return(
        <footer className="content-footer footer bg-footer-theme">
            <hr />
            <div className="container-xxl">
                <div className="footer-container d-flex align-items-center justify-content-between flex-md-row flex-column">
                    <div className="text-body mb-3">
                        step to nikah © { currentYear }
                    </div>
                    <div className="text-body mb-3">
                        <div className="text-end">
                            design and developed by 
                            &nbsp;<a href="#!" target="_blank" className="footer-link">techsoul</a>.
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;