import React from "react";
import TopNav from "./Header/TopNav";
import BottomNav from "./Header/BottomNav";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";

const Layout = () => {
    return(
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div className="layout-container">
                <TopNav />
                <div className="layout-page">
                    <div className="content-wrapper">
                        <BottomNav />
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <Sidebar />
                                <Outlet />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;