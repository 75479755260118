import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/images/logo.png'
import { Toaster, toast } from 'react-hot-toast';
import { IoClose, IoChatbubblesSharp, IoStarSharp, IoHeart, IoNotificationsOutline, IoAlertCircleOutline, IoBanOutline, IoEyeOffOutline, IoInformationCircleOutline, IoLockClosedSharp, IoCheckmarkCircleOutline, IoCheckmarkCircleSharp } from 'react-icons/io5';
import { BsShieldExclamation } from "react-icons/bs";
import { MdMoreVert } from 'react-icons/md';
import Modal from 'react-modal';
import axios from 'axios';
import HomeScreen from '../HomeScreen';

Modal.setAppElement('#root');

const MobileHome = () => {
    const [activeTab, setActiveTab] = useState('Matches');
    const [data, setData] = useState([]);
    const [elseReasonModal, setElseReasonModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [blockModal, setBlockModal] = useState(false);
    const [reason, setReason] = useState('');
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [interestModal, setInterestModal] = useState(false);
    // const [fontsLoaded, setFontsLoaded] = useState(false);
    const [photoStatus, setPhotoStatus] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const fetchData = async () => {
        setIsLoading(true);
        const token = localStorage.getItem('token');
        console.log(token, "token");

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };

        try {
            const response = await axios.get('https://admin.steptonikah.com/api/homepage/matchingProfiles', config);
            console.log(response.data, "Response");

            const formattedData = response.data.map((profile) => {
                let imageUrl = '../../assets/images/manDummy.jpg';
                if (profile.gender.toLowerCase() === 'female') {
                    imageUrl = '../../../assets/images/womenDummy.jpg';
                }

                if (profile.photo) {
                    imageUrl = profile.photo;
                }

                return {
                    id: profile.id.toString(),
                    name: profile.name,
                    age: `${profile.age} yrs`,
                    height: `${profile.height} cm`,
                    maritalStatus: profile.maritalStatus || 'Not Specified',
                    education: profile.education || 'Not Specified',
                    location: [profile.homeCity, profile.homeDistrict, profile.homeState, profile.homeCountry]
                        .filter((value) => value && value !== 'null' && value.trim() !== '')
                        .join(', '),
                    imageUrl: imageUrl,
                    liked: false,
                    images: profile.photo ? [profile.photo] : [imageUrl],
                    photoPrivacy: profile.photoPrivacy,
                    interestStatus: profile.interest_status,
                    favouriteStatus: profile.shortlist_status,
                };
            });

            setData(formattedData);
            console.log(formattedData, "Formatted data");

            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const toggleLike = (id) => {
        setData((prevData) => {
            return prevData.map((profile) =>
                profile.id === id ? { ...profile, liked: !profile.liked } : profile
            );
        });
        setModalVisible(false);
        setSelectedProfileId(null);
    };

    const handleBlockModal = async () => {
        console.log(selectedProfileId, "id");

        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            console.log(token, "Token", selectedProfileId, "Selected id");

            const response = await axios.post(
                `https://admin.steptonikah.com/api/blockList/${selectedProfileId}/store`,
                {},
                config
            );

            console.log(response.data, "In Block");

            setModalVisible(false);

            if (response.data.status) {
                toast.success(response.data.message);

                setTimeout(() => {
                    fetchData();
                }, 2000);
            }
        } catch (error) {
            setModalVisible(false);
            const errorMessage =
                error.response?.data?.message || "An error occurred. Please try again.";
            toast.error(errorMessage);
        }
    };

    const handleElseModal = () => {
        setBlockModal(false);
        setElseReasonModal(true);
    };

    const handleReasonSubmit = () => {
        setElseReasonModal(false);
    };

    const handleBlock = async () => {
        setBlockModal(false);
        toast.success("You have blocked this account");
    };

    const handleReportModal = () => {
        setModalVisible(false);
        setReportModal(true);
    };

    const sendPhotoRequest = async (profileId) => {
        console.log(profileId, "Profile in send");

        const token = localStorage.getItem("token");
        if (!token) {
            console.error("Token is missing");
            toast.error("Token is missing");
            return;
        }

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        try {
            const response = await axios.post(
                "https://admin.steptonikah.com/api/partnerProfile/photoRequest/store",
                { receiver_id: profileId },
                config
            );
            console.log(response.data, "Response in send request");

            if (response.data.status) {
                toast.success("Photo request sent successfully!");
            } else {
                toast.error(response.data.message || "Something went wrong.");
            }
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);

            if (error.response) {
                const statusCode = error.response.status;

                if (
                    statusCode === 400 &&
                    error.response.data.message ===
                    "A photo request has already been sent to this user."
                ) {
                    toast.error("A photo request has already been sent to this user.");
                } else {
                    toast.error("Something went wrong: " + error.message);
                }
            } else {
                toast.error("Network Error: Please check your internet connection.");
            }
        }
    };

    // const filteredData = filterProfiles(activeTab);

    // const swipeHandlers = useSwipeable({
    //     onSwipedLeft: () => swipeNext(),
    //     onSwipedRight: () => swipePrev(),
    // });

    // const swipeNext = () => {
    //     const tabs = ['Matches', 'Location', 'Profession', 'Differently Abled', 'Poor Girls'];
    //     const currentIndex = tabs.indexOf(activeTab);
    //     if (currentIndex < tabs.length - 1) {
    //         setActiveTab(tabs[currentIndex + 1]);
    //     }
    // };

    // const swipePrev = () => {
    //     const tabs = ['Matches', 'Location', 'Profession', 'Differently Abled', 'Poor Girls'];
    //     const currentIndex = tabs.indexOf(activeTab);
    //     if (currentIndex > 0) {
    //         setActiveTab(tabs[currentIndex - 1]);
    //     }
    // };

    const handlePass = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            console.log(token, "Token", id, "Selected ID");

            const response = await axios.post(
                `https://admin.steptonikah.com/api/ignoreList/${id}/store`,
                {},
                config
            );

            console.log(response.data, "In Pass");

            if (response.data.status) {
                toast.success("You've successfully skipped this profile.");

                setTimeout(() => {
                    fetchData();
                }, 2000);
            }
        } catch (error) {
            console.error("Error in handlePass:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    const handleFav = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            console.log(token, "Token", id, "Selected ID");

            const response = await axios.post(
                `https://admin.steptonikah.com/api/shortlist/${id}/store`,
                {},
                config
            );

            console.log(response.data, "In fav");

            if (response.data.status) {
                toast.success("Added to favourites");
                setData((prevData) =>
                    prevData.map((profile) =>
                        profile.id === id
                            ? { ...profile, favouriteStatus: "active" }
                            : profile
                    )
                );
            }
        } catch (error) {
            console.error("Error in handleFav:", error);
            toast.error("An error occurred while adding to favourites.");
        }
    };

    const handleHeartPress = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found');
                return;
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.post(
                `https://admin.steptonikah.com/api/interest/${id}/store`,
                {},
                config
            );

            console.log(response.data, "Interest data");

            if (response.data.status) {
                toast.success(response.data.message);
                setData((prevData) =>
                    prevData.map((profile) =>
                        profile.id === id
                            ? { ...profile, interestStatus: "pending" }
                            : profile
                    )
                );
            } else {
                toast.error(response.data.message || "An error occurred while processing your request.");
            }
        } catch (error) {
            console.error("Error in handleHeartPress:", error);
            toast.error(error.message || "An error occurred. Please try again.");
        }
    };

    const renderProfiles = (item) => (
        <div key={item.id} className="profile-card" >
            <div className="image-container" onClick={() => navigate(`/profile-view`, { state: { item } })} >
                <div className='button-container' onClick={(e) => {
                    e.stopPropagation();
                    setModalVisible(true)
                    setSelectedProfileId(item.id);
                }}>
                    <button className="close-button" >
                        <MdMoreVert size={25} color="white" />
                    </button>
                </div>
                <img src={item.imageUrl} alt={item.name} className="profile-image" />
                {item.photoPrivacy === 'protected' && (
                    <div className="image-overlay">
                        <IoLockClosedSharp size={24} color="white" />
                        <p className="lockedText">Photo Locked</p>
                        <button className="requestButton" onClick={(e) => {
                            e.stopPropagation();
                            sendPhotoRequest(item.id);
                        }}>
                            <p className="requestButtonText">{photoStatus === "pending" ? "Pending" : "Request to View"}</p>
                        </button>
                    </div>
                )}
                <div className="overlay-container">
                    <p className="profile-name">{item.name}</p>
                    <p className="profile-details">{item.age}, {item.height}, {item.maritalStatus}</p>
                    <p className="profile-details">{item.location}</p>
                </div>
            </div>
            <div className="icon-row" style={{
                justifyContent:
                    item.interestStatus === "pending"
                        ? "space-between"
                        : "space-around",
            }}>
                <button className="icon-container chat-button" aria-label={`Chat with ${item.name}`} onClick={() => navigate('/chat')}>
                    <IoChatbubblesSharp size={25} />
                </button>
                {item.interestStatus === "pending" ? (
                    <>
                        <div className="interest-button-container">
                            <IoCheckmarkCircleSharp size={15} color="white" />
                            <p className="interest-button-text">Interest Sent</p>
                        </div>
                    </>
                ) : (
                    <>
                        <button className="icon-container close" onClick={() => handlePass(item.id)}>
                            <IoClose size={25} />
                        </button>
                        <button
                            className={`icon-container star-button ${item.favouriteStatus === 'active' ? 'active' : 'inactive'}`}
                            aria-label={`Star ${item.name}`}
                            onClick={() => handleFav(item.id)}
                        >
                            <IoStarSharp size={24} />
                        </button>
                        <button
                            onClick={() => handleHeartPress(item.id)}
                            className={`icon-container heart-button ${item.liked ? "liked" : ""}`}
                            aria-label={item.liked ? `Unlike ${item.name}` : `Like ${item.name}`}
                        >
                            <IoHeart size={24} className={item.liked ? "liked" : ""} />
                        </button>
                    </>
                )}
            </div>
        </div>
    );

    return (
        <div className="home-container">
            <Toaster />
            <div className="header">
                <div className="logo-container">
                    <img src={Logo} alt="logo" size={150} className="logo" />
                </div>
                <div className="notification-container">
                    <button className="notification" onClick={() => navigate("/notification")} aria-label="Notifications">
                        <IoNotificationsOutline size={20} color="#572d71" />
                    </button>
                    <div className="notification-badge"></div>
                </div>
            </div>
            {/* <div {...swipeHandlers} className="swipe-container">
                <div className="tab-bar">
                    {['Matches', 'Location', 'Profession', 'Differently Abled', 'Poor Girls'].map(tab => (
                        <Button
                            key={tab}
                            className={`tab-item ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </Button>
                    ))}
                </div>
            </div> */}
            {isLoading ? (
                <div style={{
                    display: 'flex',
                    height: '100vh',
                    backgroundColor: '#FDF7FD',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div className="spinner" style={{ width: '50px', height: '50px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>
                </div>
            ) : (
                <div className="profile-list">
                    {data.map(item => renderProfiles(item))}
                </div>
            )}

            <HomeScreen />

            <Modal
                isOpen={modalVisible}
                onRequestClose={() => setModalVisible(!modalVisible)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setModalVisible(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <div
                    className="modal-option"
                    onClick={handleBlockModal}
                    style={{ marginTop: "12px" }}
                >
                    <IoBanOutline size={22} color="#007BFF" />
                    <span>Block this Profile</span>
                </div>
                {/* <div
                    className="modal-option"
                    onClick={handleReportModal}
                >
                    <IoAlertCircleOutline
                        size={22}
                        color="#007BFF"
                    />
                    <span>Report this Profile</span>
                </div> */}
            </Modal>

            <Modal
                isOpen={blockModal}
                onRequestClose={() => setBlockModal(!blockModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setBlockModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Block Profile</h2>
                <p>Please tell us why you are blocking this profile, we will not reveal your identity.</p>
                <div className="report-buttons">
                    <div className='report-option'>
                        <IoEyeOffOutline size={22} color='#572d71' />
                        <button onClick={handleBlock} className="report-option-button">I just don't want them to see me</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate messages</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate profile</button>
                    </div>
                    <div className='report-option'>
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Scam</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Something else</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={reportModal}
                onRequestClose={() => setReportModal(!reportModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setReportModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Report Profile</h2>
                <p>Please tell us why you are reporting this profile, we will not reveal your identity.</p>
                <div className="report-buttons">
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate messages</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate profile</button>
                    </div>
                    <div className="report-option">
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button"
                        >Scam</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleBlock} className="report-option-button">Marriage fixed</button>
                    </div>
                    <div className="report-option">
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Wrong contact details</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Something else</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={elseReasonModal}
                onRequestClose={() => setElseReasonModal(!elseReasonModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setElseReasonModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Tell us more</h2>
                <p>Please tell us why you are blocking this profile in detail, we will not reveal your identity.</p>
                <textarea
                    placeholder="Enter Reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="modal-textarea"
                />
                <button onClick={handleReasonSubmit} className="modal-submit-button">Submit</button>
            </Modal>

            <Modal
                isOpen={interestModal}
                onRequestClose={() => setInterestModal(false)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <div className="popup_modal-content">
                    <h2>Do you want to send interest?</h2>
                    <div className="modal-buttons">
                        <button onClick={() => toggleLike(selectedProfileId)} className="modal-button yes">Yes</button>
                        <button onClick={() => setInterestModal(false)} className="modal-button no">No</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};


export default MobileHome;
