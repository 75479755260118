import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../assets/Constants';
import { Link } from 'react-router-dom';
import imgEmpty from '../../../assets/img/illustrations/illustration-upgrade-account.png';
import toast from 'react-hot-toast';

const AcceptedProfiles = () => {
    const [profiles, setProfiles] = useState([]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.get(`${API_URL}/api/interests/accepted/view`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setProfiles(response.data.acceptedInterests || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleDecline = async (id) => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/cancelAccepted`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 201) {
                toast.success(response.data.message);
                setProfiles((prevProfiles) =>
                    prevProfiles.filter((profile) => profile.id !== id)
                );
            }
        } catch (error) {
            console.error("Error declining interest:", error);
            toast.error("Failed to decline interest.");
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="demo-inline-spacing mt-4">
            {profiles.length > 0 ? (
                <div className="list-group">
                    {profiles.map((profile) => (
                        <div
                            key={profile.id}
                            className="list-group-item list-group-item-action d-flex align-items-center cursor-pointer waves-effect"
                        >
                            <img
                                src={profile.photo}
                                alt="User"
                                className="rounded-circle me-3"
                                width="120"
                            />
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="user-info">
                                        <h6 className="mb-1">{profile.name}</h6>
                                        <div>
                                            <p className="mb-1">{profile.age}, {profile.height} cm</p>
                                            <p className="mb-1">Profile ID: {profile.profileId}</p>
                                            <p className="mb-1">{profile.location}</p>
                                        </div>
                                        <small className="text-muted">Accepted On: {profile.accepted_on}</small>
                                    </div>
                                    <div className="add-btn">
                                        <button
                                            className="btn btn-danger btn-sm waves-effect waves-light"
                                            onClick={() => handleDecline(profile.id)}
                                        >
                                            Decline
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="misc-wrapper border rounded">
                    <div className="text-center">
                        <h4 className="mb-2">List is empty</h4>
                        <p className="mb-6 mx-2">We couldn't find any accepted profiles</p>
                    </div>
                    <div className="d-flex justify-content-center mt-9">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src={imgEmpty}
                                alt="misc-error"
                                className="img-fluid z-1"
                                width="150"
                            />
                            <div>
                                <Link to="/home">
                                    <button className="btn btn-primary btn-sm text-center my-10 waves-effect waves-light">
                                        Back to home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AcceptedProfiles;
