import React, { useEffect, useState } from "react";
import BasicPreferenceModal from "./Modals/BasicPreferenceModal";

const BasicPreference = ({basicPreference : initialBasicPreference}) => {

    const [showModal, setShowModal] = useState(false);
    const [basicPreference, setBasicPreference] = useState(initialBasicPreference);

    useEffect(() => {
        setBasicPreference(initialBasicPreference);
    }, [initialBasicPreference]);

    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return []; // Return an empty array if parsing fails
        }
    };

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setBasicPreference(prev => ({
            ...prev,
            ageFrom: updatedDetails.ageFrom,
            ageTo: updatedDetails.ageTo,
            heightFrom: updatedDetails.heightFrom,
            heightTo: updatedDetails.heightTo,
            maritalStatus: updatedDetails.maritalStatus,
            physicalStatus: updatedDetails.physicalStatus,
            eatingPreference: updatedDetails.eatingPreference,
            drinkingPreference: updatedDetails.drinkingPreference,
            smokingPreference: updatedDetails.smokingPreference,
            languagesPreference: updatedDetails.languagesPreference,
        }));

        setShowModal(false);
    };

    return(
        <div className="card mt-3 p-0">
            <div class="card-header d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Basic Preferences</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="card-body mt-0">
                <div class="table-responsive text-nowrap">
                    <table class="table border">
                        <tbody>
                            <tr>
                                <th>Age Preferences</th>
                                <td>: { `${basicPreference?.age_from} - ${basicPreference?.age_to} Yrs` || 'N/A'}</td>
                                <th>Marital Status</th>
                                <td>
                                    : {parseArray(basicPreference?.marital_status).join(", ") || "N/A"} 
                                </td>
                            </tr>
                            <tr>
                                <th>Height Preferences</th>
                                <td>: { `${basicPreference?.height_from} - ${basicPreference?.height_to} cm` || 'N/A'}</td>
                                <th>Physical Status</th>
                                <td>
                                    : {parseArray(basicPreference?.physical_status).join(", ") || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th>Eating Habits</th>
                                <td>
                                    : {parseArray(basicPreference?.eating_preference).join(", ") || "N/A"}
                                </td>
                                <th>Drinking Habits</th>
                                <td>
                                    : {parseArray(basicPreference?.drinking_preference).join(", ") || "N/A"}
                                </td>
                            </tr>
                            <tr>
                                <th>Smoking Habits</th>
                                <td>
                                    : {parseArray(basicPreference?.smoking_preference).join(", ") || "N/A"}
                                </td>
                                <th>Language Preference</th>
                                <td>
                                    : {parseArray(basicPreference?.languages_preference).join(", ") || "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <BasicPreferenceModal
                    details={{
                        ageFrom: basicPreference?.age_from,
                        ageTo: basicPreference?.age_to,
                        heightFrom: basicPreference?.height_from,
                        heightTo: basicPreference?.height_to,
                        maritalStatus: basicPreference?.marital_status,
                        physicalStatus: basicPreference?.physical_status,
                        eatingPreference: basicPreference?.eating_preference,
                        drinkingPreference: basicPreference?.drinking_preference,
                        smokingPreference: basicPreference?.smoking_preference,
                        languagesPreference: basicPreference?.languages_preference,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default BasicPreference;