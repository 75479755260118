import React from 'react'
import ReceivedProfiles from './Interests/ReceivedProfiles'
import SentProfiles from './Interests/SentProfiles'
import EmptyList from './EmptyList'
import VisitedProfiles from './ProfileVisits/VisitedProfiles'
import AcceptedProfiles from './Interests/AcceptedPorfiles'
import IgnoredProfiles from './Interests/IgnoredProfile'
import ProfileVisitors from './ProfileVisits/ProfileVisitors'
import ViewedContacts from './Contacts/ViewedContacts'
import ContactViewers from './Contacts/ContactViewers'
import Shortlist from './ShortList/Shortlist'
import Blocked from './Blocked/Blocked'
import Skipped from './Skipped/Skipped'
const Explore = () => {
    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="nav-align-top mb-6">
                <ul className="nav nav-pills mb-4 nav-fill" role="tablist">
                    <li className="nav-item mb-1 mb-sm-0" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light active" role="tab" data-bs-toggle="tab" data-bs-target="#nav-interest" aria-controls="nav-interest" aria-selected="true">
                            <i className="tf-icons ri-heart-line me-2"></i> 
                            Interests
                        </button>
                    </li>
                    <li className="nav-item mb-1 mb-sm-0" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#nav-profile-visits" aria-controls="nav-profile-visits" aria-selected="false" tabindex="-1">
                            <i className="tf-icons ri-user-3-line me-2"></i>
                            Profile Visits
                        </button>
                    </li>
                    <li className="nav-item mb-1 mb-sm-0" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#nav-contact-views" aria-controls="nav-contact-views" aria-selected="false" tabindex="-1">
                            <i className="tf-icons ri-message-2-line me-2"></i>
                            Contacts
                        </button>
                    </li>
                    <li className="nav-item mb-1 mb-sm-0" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#nav-shortlist" aria-controls="nav-shortlist" aria-selected="true">
                            <i className="tf-icons ri-check-line me-2"></i> 
                            Shortlist
                        </button>
                    </li>
                    <li className="nav-item mb-1 mb-sm-0" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#nav-blocked" aria-controls="nav-blocked" aria-selected="false" tabindex="-1">
                            <i className="tf-icons ri-spam-2-line me-2"></i>
                            Blocked
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button type="button" className="nav-link waves-effect waves-light" role="tab" data-bs-toggle="tab" data-bs-target="#nav-skipped" aria-controls="nav-skipped" aria-selected="false" tabindex="-1">
                            <i className="tf-icons ri-close-line me-2"></i>
                            Skipped
                        </button>
                    </li>
                </ul>
                <div className="tab-content">
                    <div className="tab-pane fade active show" id="nav-interest" role="tabpanel">
                        <div className="card-header p-0">
                            <div className="nav-align-top">
                                <ul className="nav nav-tabs nav-fill" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#nav-received" aria-controls="nav-received" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Received
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-sent" aria-controls="nav-sent" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Sent
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-accepted" aria-controls="nav-accepted" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Accepted
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-ignored" aria-controls="nav-ignored" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Ignored
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body pt-5">
                            <div className="tab-content p-0">
                                <div class="tab-pane fade active show" id="nav-received" role="tabpanel">
                                    <h4 class="card-title text-center">Interests Received</h4>
                                    <p class="card-text text-center">You received Interests from these people</p>
                                    <ReceivedProfiles />
                                </div>
                                <div class="tab-pane fade" id="nav-sent" role="tabpanel">
                                    <h4 class="card-title text-center">Interests Sent</h4>
                                    <p class="card-text text-center">You have sent an Interest to these people</p>
                                    {/* <p class="text-center">Upgrade your profile to premium to contact them.</p> */}
                                    <SentProfiles />
                                </div>
                                <div class="tab-pane fade" id="nav-accepted" role="tabpanel">
                                    <h4 class="card-title text-center">Interests Accepted</h4>
                                    <p class="card-text text-center">These people have accepted your Interest or you accepted the Interest sent by them</p>
                                    {/* <p class="text-center">Upgrade your profile to premium to contact them.</p> */}
                                    <AcceptedProfiles />
                                </div>
                                <div class="tab-pane fade" id="nav-ignored" role="tabpanel">
                                    <h4 class="card-title text-center">Interests Declined</h4>
                                    <p class="card-text text-center">Profiles who have declined by you / your interest will appear here </p>
                                    <IgnoredProfiles />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-profile-visits" role="tabpanel">
                        <div className="card-header p-0">
                            <div className="nav-align-top">
                                <ul className="nav nav-tabs nav-fill" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#sub-nav-i-visited" aria-controls="sub-nav-i-visited" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Profile I visited
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#sub-nav-visitors" aria-controls="sub-nav-visitors" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                My Profile Visitors
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body pt-5">
                            <div className="tab-content p-0">
                                <div class="tab-pane fade active show" id="sub-nav-i-visited" role="tabpanel">
                                    <h4 class="card-title text-center">Profiles I Visited</h4>
                                    <p class="card-text text-center">The profiles you've recently viewed are listed here.</p>
                                    <VisitedProfiles />
                                </div>
                                <div class="tab-pane fade" id="sub-nav-visitors" role="tabpanel">
                                    <h4 class="card-title text-center">My Profile Visitors</h4>
                                    <p class="card-text text-center">People who have recently viewed your Profiles are listed here.</p>
                                    {/* <p class="card-text text-center">Upgrade to Premium to view the complete profiles of everyone listed here.</p> */}
                                    <ProfileVisitors />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-contact-views" role="tabpanel">
                        <div className="card-header p-0">
                            <div className="nav-align-top">
                                <ul className="nav nav-tabs nav-fill" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#sub-nav-contact-viewed" aria-controls="sub-nav-contact-viewed" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                Contacts I Viewed
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#sub-nav-contact-viewers" aria-controls="sub-nav-contact-viewers" aria-selected="false" tabindex="-1">
                                            <span className="d-none d-sm-block">
                                                My Contact Viewers
                                            </span>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body pt-5">
                            <div className="tab-content p-0">
                                <div class="tab-pane fade active show" id="sub-nav-contact-viewed" role="tabpanel">
                                    <h4 class="card-title text-center">Contact I Viewed</h4>
                                    <p class="card-text text-center">The contact you've recently viewed are listed here.</p>
                                    <ViewedContacts />
                                </div>
                                <div class="tab-pane fade" id="sub-nav-contact-viewers" role="tabpanel">
                                    <h4 class="card-title text-center">My Contact Viewers</h4>
                                    <p class="card-text text-center">People who have recently viewed your contact are listed here.</p>
                                    {/* <p class="card-text text-center">Upgrade to Premium to view the profiles of everyone listed here.</p> */}
                                    <ContactViewers />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="nav-shortlist" role="tabpanel">
                        <h4 class="card-title text-center">Profiles I Shortlisted</h4>
                        <p class="card-text text-center">The profiles you have shortlisted are listed here for quick access.</p>
                        <p class="card-text text-center">They won't know you have added them in this list.</p>
                        <Shortlist />
                    </div>
                    <div className="tab-pane fade" id="nav-blocked" role="tabpanel">
                        <h4 class="card-title text-center">Profiles I Blocked</h4>
                        <p class="card-text text-center">The profiles you have blocked are listed here, so they can't interact with your profile.</p>
                        <p class="card-text text-center">They won't know you have added them in this list.</p>
                        <Blocked />
                    </div>
                    <div className="tab-pane fade" id="nav-skipped" role="tabpanel">
                        <h4 class="card-title text-center">Profiles I Skipped</h4>
                        <p class="card-text text-center">The profiles you have skipped are listed here, so you can focus on better matches</p>
                        <p class="card-text text-center">They won't know you have added them in this list.</p>
                        <Skipped />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Explore