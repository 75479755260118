import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';
import Slider from 'react-slider';

const EditPreference = () => {
    const location = useLocation();
    const { partnerPreferences } = location.state;

    const [heightRange, setHeightRange] = useState([parseInt(partnerPreferences.height_from, 10), parseInt(partnerPreferences.height_to, 10)]);
    const [ageRange, setAgeRange] = useState([parseInt(partnerPreferences.age_from, 10), parseInt(partnerPreferences.age_to, 10),]);
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState(
        partnerPreferences.marital_status ? JSON.parse(partnerPreferences.marital_status).map((item) => ({ value: item, label: item, })) : []
    );
    const [selectedPhysicalStatus, setSelectedPhysicalStatus] = useState(
        partnerPreferences.physical_status ? JSON.parse(partnerPreferences.physical_status).map((item) => ({ value: item, label: item, })) : []
    );
    const [selectedEatingHabits, setSelectedEatingHabits] = useState(
        partnerPreferences.eating_preference ? JSON.parse(partnerPreferences.eating_preference).map((item) => ({ value: item, label: item, })) : []
    );
    const [selectedSmokingHabits, setSelectedSmokingHabits] = useState(
        partnerPreferences.smoking_preference ? JSON.parse(partnerPreferences.smoking_preference).map((item) => ({ value: item, label: item, })) : []
    );
    const [selectedDrinkingHabits, setSelectedDrinkingHabits] = useState(
        partnerPreferences.drinking_preference ? JSON.parse(partnerPreferences.drinking_preference).map((item) => ({ value: item, label: item, })) : []
    );
    const [selectedLanguage, setSelectedLanguage] = useState(
        partnerPreferences.languages_preference ? JSON.parse(partnerPreferences.languages_preference).map((item) => ({ value: item, label: item, })) : []
    );

    const navigate = useNavigate();
    const maritalStatus = [
        { value: "Never Married", label: "Never Married" },
        { value: "Divorced", label: "Divorced" },
        { value: "Nikah Divorce", label: "Nikah Divorce" },
        { value: "Widower", label: "Widower" },
        { value: "Awaiting Divorce", label: "Awaiting Divorce" },
        { value: "Separated", label: "Separated" },
    ];

    const physicalStatus = [
        { value: "Does not matter", label: "Does not matter" },
        { value: "Physically fit", label: "Physically fit" },
        { value: "Physically Challenged", label: "Physically Challenged" },
        { value: "Hearing Impaired", label: "Hearing Impaired" },
        { value: "Vision Impaired", label: "Vision Impaired" },
        { value: "Speech Impaired", label: "Speech Impaired" },
        { value: "Minor Health Issues", label: "Minor Health Issues" },
        { value: "Major Health Issues", label: "Major Health Issues" },
        { value: "Mental Health Issues", label: "Mental Health Issues" },
    ];

    const eatingHabits = [
        { value: "Veg", label: "Veg" },
        { value: "Eggetarian", label: "Eggetarian" },
        { value: "Occasionally Non-veg", label: "Occasionally Non-veg" },
        { value: "Non-Veg", label: "Non-Veg" },
    ];

    const smokingHabits = [
        { value: "Does not matter", label: "Does not matter" },
        { value: "Yes,regularly", label: "Yes,regularly" },
        { value: "No,never", label: "No,never" },
        { value: "Occasionally", label: "Occasionally" },
        { value: "Trying to quit", label: "Trying to quit" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const drinkingHabits = [
        { value: "Does not matter", label: "Does not matter" },
        { value: "Yes,regularly", label: "Yes,regularly" },
        { value: "No,never", label: "No,never" },
        { value: "Occasionally", label: "Occasionally" },
        { value: "Trying to quit", label: "Trying to quit" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const language = [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'English', label: 'English' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Mizo', label: 'Mizo' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Odia', label: 'Odia' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Sikkimese', label: 'Sikkimese' },
        { value: 'Nepali', label: 'Nepali' }
    ];

    const handleChange = (setter) => (selectedOptions) => {
        setter(selectedOptions || []);
    };

    const onHeightChange = (values) => {
        setHeightRange(values);
    };

    const onValuesChange = (values) => {
        setAgeRange(values);
    };

    const handleInfo = async () => {
        const formatData = {
            ageFrom: ageRange[0],
            ageTo: ageRange[1],
            heightFrom: heightRange[0],
            heightTo: heightRange[1],
            maritalStatus: selectedMaritalStatus.map(option => option.value),
            physicalStatus: selectedPhysicalStatus.map(option => option.value),
            eatingPreference: selectedEatingHabits.map(option => option.value),
            smokingPreference: selectedSmokingHabits.map(option => option.value),
            drinkingPreference: selectedDrinkingHabits.map(option => option.value),
            languagesPreference: selectedLanguage.map(option => option.value),
        };
        console.log(formatData, "format data");
        try {
            const token = await localStorage.getItem("token");
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.post(
                "https://admin.steptonikah.com/api/basicPreferences/store",
                formatData,
                config
            );
            console.log(response.data);
            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/my-profile");
                }, 1500);
            }
        } catch (error) {
            console.error(error);

            toast.error(
                error.response?.data?.message || "Something went wrong. Please try again."
            );
        }
    }

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
        option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#007bff' : 'white',
            color: isFocused ? 'white' : 'black',
            cursor: 'pointer'
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#f0f0f0',
            borderRadius: '4px'
        }),
        multiValueLabel: (base) => ({
            ...base,
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#007bff',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white'
            }
        })
    };

    const handleHeightChange = (index, value) => {
        const newRange = [...heightRange];
        newRange[index] = Number(value);
        if (newRange[0] <= newRange[1]) setHeightRange(newRange);
    };

    const onValueChange = (index, value) => {
        const newRange = [...ageRange];
        newRange[index] = Number(value);
        if (newRange[0] <= newRange[1]) setAgeRange(newRange);
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Partner Basic Preferences</h2>

            <div
                style={{
                    margin: "10px",
                    backgroundColor: "#fff",
                    padding: "15px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    borderRadius: "10px",
                    marginBottom: "20px",
                }}
            >
                <div style={{ marginBottom: "20px" }}>
                    <span className="label">Height</span>
                    <div className="sliderContainer">
                        <Slider
                            min={101}
                            max={250}
                            step={1}
                            value={heightRange}
                            onChange={onHeightChange}
                            renderTrack={(props, state) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        backgroundColor: state.index === 0 ? "#fe5d94" : "#ddd",
                                    }}
                                />
                            )}
                            renderThumb={(props, state) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        backgroundColor: "#fe5d94",
                                        borderRadius: "50%",
                                        height: "12px",
                                        width: "12px",
                                        marginTop: "-5px"
                                    }}
                                />
                            )}
                        />
                        <span className="selectedRange">{heightRange[0]} - {heightRange[1]} cm</span>
                    </div>
                </div>

                <div>
                    <span className="label">Age</span>
                    <div className="sliderContainer">
                        <Slider
                            min={18}
                            max={60}
                            step={1}
                            value={ageRange}
                            onChange={onValuesChange}
                            renderTrack={(props, state) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        backgroundColor: state.index === 0 ? "#fe5d94" : "#ddd",
                                    }}
                                />
                            )}
                            renderThumb={(props, state) => (
                                <div
                                    {...props}
                                    style={{
                                        ...props.style,
                                        backgroundColor: "#fe5d94",
                                        borderRadius: "50%",
                                        height: "12px",
                                        width: "12px",
                                        marginTop: "-5px"
                                    }}
                                />
                            )}
                        />
                        <span className="selectedRange">{ageRange[0]} - {ageRange[1]} Yrs</span>
                    </div>
                </div>
            </div>

            <div className="input-container">
                <label>Marital Status</label>
                <Select
                    isMulti
                    options={maritalStatus}
                    onChange={handleChange(setSelectedMaritalStatus)}
                    placeholder="Select Marital Status"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedMaritalStatus}
                />
            </div>

            <div className="input-container">
                <label>Physical Status</label>
                <Select
                    isMulti
                    options={physicalStatus}
                    onChange={handleChange(setSelectedPhysicalStatus)}
                    placeholder="Select Physical Status"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedPhysicalStatus}
                />
            </div>

            <div className="input-container">
                <label>Eating Habits</label>
                <Select
                    isMulti
                    options={eatingHabits}
                    onChange={handleChange(setSelectedEatingHabits)}
                    placeholder="Select Eating Habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEatingHabits}
                />
            </div>

            <div className="input-container">
                <label>Smoking Habits</label>
                <Select
                    isMulti
                    options={smokingHabits}
                    onChange={handleChange(setSelectedSmokingHabits)}
                    placeholder="Select Smoking Habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedSmokingHabits}
                />
            </div>

            <div className="input-container">
                <label>Drinking Habits</label>
                <Select
                    isMulti
                    options={drinkingHabits}
                    onChange={handleChange(setSelectedDrinkingHabits)}
                    placeholder="Select Drinking Habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedDrinkingHabits}
                />
            </div>

            <div className="input-container">
                <label>Preferred Language</label>
                <Select
                    isMulti
                    options={language}
                    onChange={handleChange(setSelectedLanguage)}
                    placeholder="Select Preferred Language"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedLanguage}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleInfo}>Done</button>
            </div>
            <Toaster />
        </div>
    )
}

export default EditPreference
