import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack, IoArrowBackCircleOutline } from 'react-icons/io5';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';

const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    // const [passwordError, setPasswordError] = useState("");

    const navigate = useNavigate();
    const location = useLocation();

    const { email, otp } = location.state || {};

    const handleResetPassword = async () => {
        // setPasswordError("");
        setLoading(true);

        if (newPassword !== confirmPassword) {
            toast.error("Passwords do not match");
            setLoading(false);
            return;
        }

        if (newPassword.length < 6) {
            toast.error("Password should be at least 6 characters");
            setLoading(false);
            return;
        }

        if (!email || !otp || !newPassword) {
            toast.error("Missing required fields");
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                "https://admin.steptonikah.com/api/forgot-password/reset-password",
                {
                    email: email,
                    otp: otp,
                    password: newPassword,
                }
            );
            console.log("Reset Password Response:", response.data);

            if (response.data) {
                toast.success("Password reset successful");
                setNewPassword("");
                setConfirmPassword("");
            }
            // setTimeout(() => {
            //     navigate('/');
            // }, 1000);
        } catch (error) {
            console.error("Error during reset password:", error.response?.data || error.message);
            toast.error(error.response?.data?.message || "An error occurred while resetting the password");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="reset-password-container">
            <Toaster />
            <div className="scroll-container">
                <div className="content-container">
                    <button className="back-button" onClick={() => navigate(-1)}>
                        <IoArrowBack size={25} color="#572d71" />
                    </button>
                    <div className="form-content">
                        <h2 className="title">Reset Password</h2>
                        <p className="subtitle">Change your password here</p>
                        <input
                            type="password"
                            className="input-field"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            className="input-field"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            style={{ marginBottom: "10px" }}
                        />
                        {/* {passwordError && <p style={{ color: "red", fontSize: "14px", margin: "0 0 5px", textAlign: "left", width: "100%" }}>{passwordError}</p>} */}
                        <button
                            className="submit-button"
                            onClick={handleResetPassword}
                            disabled={loading}
                        >
                            {loading ? (
                                <div
                                    className="spinner"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        marginLeft: "45%",
                                        border: "5px solid lightgray",
                                        borderRadius: "50%",
                                        borderTopColor: "#572d71",
                                        animation: "spin 1s linear infinite",
                                    }}
                                ></div>
                            ) : (
                                "RESET"
                            )}
                        </button>
                        <div style={{ display: 'flex', }} onClick={() => navigate('/')}>
                            <IoArrowBackCircleOutline size={24} color='#572d71' style={{ margin: "16px 5px" }} />
                            <p style={{ textDecorationLine: 'underline' }}>Back to login</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
