import React, { useEffect, useState } from "react";
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from "../layouts/Header";
import "../login.css";
import axios from 'axios';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { API_URL } from "../../assets/Constants";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const SendOtpNew = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [errorMessage, setErrorMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);

    useEffect(() => {
        let timeline = gsap.timeline();
        timeline
            .to(".mil-preloader", {
                height: 0,
                ease: "sine",
                duration: 0.4,
                delay: 2.3,
            })
            .to(".mil-preloader .mil-load", {
                width: "calc(100% - 30px)",
                ease: "linear",
                duration: 1.3,
                delay: -2.3,
            })
            .to(".mil-preloader .mil-load", {
                opacity: 0,
                ease: "sine",
                duration: 0.4,
                delay: -0.6,
            })
            .to(".mil-preloader p", {
                scale: 0.5,
                opacity: 0,
                ease: "sine",
                duration: 0.4,
                delay: -0.7,
                onComplete: () => {
                    ScrollTrigger.refresh();
                },
            });

        // Progress animation
        gsap.to(".mil-progress", {
            height: "100%",
            ease: "sine",
            scrollTrigger: { scrub: 0.3 },
        });

        // Progress-wrap animation
        gsap.fromTo(
            ".progress-wrap",
            { yPercent: 100, autoAlpha: 0 },
            {
                yPercent: 0,
                autoAlpha: 1,
                scrollTrigger: {
                    start: 500,
                    toggleActions: "play none none reverse",
                },
            }
        );

        // ScrollTrigger for top panel
        ScrollTrigger.create({
            start: "top -70",
            end: 99999,
            toggleClass: { className: "mil-active", targets: ".mil-top-panel" },
        });

        // Menu button click handler (without jQuery)
        const menuBtn = document.querySelector(".mil-menu-btn");
        if (menuBtn) {
            menuBtn.addEventListener("click", () => {
                menuBtn.classList.toggle("mil-active");
                document.querySelector(".mil-top-menu").classList.toggle("mil-active");
            });
        }

        // Scroll event handler (without jQuery)
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const isWideScreen = window.innerWidth > 768;
            const topPanel = document.querySelector(".mil-top-panel");

            if ((isWideScreen && scrollTop >= 60) || !isWideScreen) {
                topPanel.classList.add("mil-active");
            } else {
                topPanel.classList.remove("mil-active");
            }
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Back to top button click handler
        const backToTopBtn = document.querySelector(".mil-back-to-top");
        if (backToTopBtn) {
            backToTopBtn.addEventListener("click", () => {
                gsap.to(window, { scrollTo: 0, duration: 1, ease: "sine" });
            });
        }

        // Cleanup event listeners on component unmount
        return () => {
            if (menuBtn) {
                menuBtn.removeEventListener("click", () => {
                    menuBtn.classList.toggle("mil-active");
                    document
                        .querySelector(".mil-top-menu")
                        .classList.toggle("mil-active");
                });
            }
            window.removeEventListener("scroll", handleScroll);
            if (backToTopBtn) {
                backToTopBtn.removeEventListener("click", () => {
                    gsap.to(window, { scrollTo: 0, duration: 1, ease: "sine" });
                });
            }
        };
    }, []);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/api/login/sendOtp`, {
                email: email,
            });
            console.log(response);
            setOtpSent(true);
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    const handleChangeOtp = (element, index) => {
        if (isNaN(element.value)) return;
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleVerifyOtp = async () => {
        try {
            if (otp.length !== 6 || otp.some((digit) => isNaN(digit))) {
                toast.error('Please enter a valid 6-digit OTP.');
                return;
            }

            const response = await axios.post(`${API_URL}/api/login/verifyOtp`, {
                email: email,
                otp: otp.join(''),
            });

            localStorage.setItem('token', response.data.token);
            toast.success('Logged in Successfully!');

            setOtp([]);
            setErrorMessage('');
            navigate('/home');

        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Invalid OTP. Please try again.');
                setErrorMessage('Invalid OTP. Please try again.');
            } else if (error.response && error.response.status === 404) {
                toast.error('OTP not found or expired.');
                setErrorMessage('OTP not found or expired.');
            } else {
                toast.error('Something went wrong. Please try again later.');
                setErrorMessage('Something went wrong. Please try again later.');
            }
        }
    };

    return (
        <div id="smooth-wrapper" className="mil-wrapper">
            <div className="progress-wrap active-progress"></div>
            <Header />
            <div id="smooth-content">
                <div className="login-container mil-p-100-80" style={{ padding: "150px 0 150px 0" }}>
                    <div className="container">
                        <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                            <div className="col-xl-6 reg-form">
                                <h4
                                    className="mil-text-center mb-4"
                                    style={{ fontWeight: "700" }}
                                >
                                    Login with OTP
                                </h4>
                                <div className="form-container col-12">
                                    <div className="row">
                                        <form id="formAuthentication" className="" onSubmit={handleSendOtp}>
                                            {/* Email input and OTP request button */}
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter your email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                            <div className="col-md-12">
                                                <div className="form-group d-flex justify-content-center mb-0 mt-5">
                                                    <button
                                                        type="submit"
                                                        className="button primary-button"
                                                        style={{ width: "100%" }}
                                                    >
                                                        Send OTP
                                                    </button>
                                                </div>
                                            </div>
                                        </form>

                                        {/* OTP input and verification button */}
                                        {otpSent && (
                                            <div className="mb-5 mt-3">
                                                <div className="auth-input-wrapper d-flex align-items-center justify-content-between">
                                                    {otp.map((data, index) => (
                                                        <input
                                                            key={index}
                                                            type="tel"
                                                            className="form-control text-center mx-sm-1 my-2"
                                                            maxLength="1"
                                                            value={data}
                                                            onChange={(e) => handleChangeOtp(e.target, index)}
                                                            autoFocus={index === 0}
                                                        />
                                                    ))}
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group d-flex justify-content-center mb-0 mt-5">
                                                        <button
                                                            type="submit"
                                                            className="button primary-button"
                                                            onClick={handleVerifyOtp}
                                                            style={{ width: "100%" }}
                                                        >
                                                            Verify OTP
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}

                                        {errorMessage && (
                                            <div className="alert alert-danger mt-2">
                                                {errorMessage}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-xl-12 d-flex justify-content-center mt-3 mb-3">
                                    <p className="mil-text-m" style={{ color: "black" }}>
                                        Don't have an account? <a href="/register">Register</a>
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="mil-image-frame mil-visible-overflow">
                                    <img src={homeImage} alt="img" className="mil-up" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </div >
    );
};

export default SendOtpNew;
