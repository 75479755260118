import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoHeart, IoHome, IoPerson, IoSearch } from 'react-icons/io5';

const CustomTabBar = ({ activeTab, setActiveTab }) => {
    const tabs = [
        { name: 'Home', icon: IoHome, label: 'Home' },
        { name: 'Matches', icon: IoHeart, label: 'Matches' },
        { name: 'Search', icon: IoSearch, label: 'Search' },
        { name: 'Account', icon: IoPerson, label: 'Account' },
    ];

    return (
        <div className="nav-tab-bar">
            {tabs.map((tab, index) => {
                const Icon = tab.icon;
                const isFocused = activeTab === tab.name;

                return (
                    <button
                        key={index}
                        onClick={() => setActiveTab(tab.name)}
                        className={`nav-tab-button ${isFocused ? 'active' : ''}`}
                    >
                        <Icon size={20} color={isFocused ? '#572d71' : '#B4A5B8'} />
                        {isFocused && <span className="nav-tab-label">{tab.label}</span>}
                    </button>
                );
            })}
        </div>
    );
};

const HomeScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('Home');

    const syncWithPath = (name) => {
        setActiveTab(name);
        navigate(`/${name.toLowerCase()}`);
    };

    useEffect(() => {
        const currentPath = location.pathname.slice(1);
        const tabName = currentPath.charAt(0).toUpperCase() + currentPath.slice(1);
        setActiveTab(tabName);
    }, [location.pathname]);

    return (
        <div>
            <CustomTabBar activeTab={activeTab} setActiveTab={syncWithPath} />
        </div>
    );
};

export default HomeScreen;
