import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIos } from 'react-icons/md';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import Modal from 'react-modal';
import { AiOutlineDelete, AiOutlineLogout } from 'react-icons/ai';

const AccountSettings = () => {
    const navigate = useNavigate();
    const [logoutModal, setLogoutModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const handleLogout = async () => {
        setLogoutModal(false);
        if (!navigator.onLine) {
            toast.error("Please check your internet connection and try again");
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            const { data } = await axios.post('https://admin.steptonikah.com/api/logout', {}, config);

            if (data.success) {
                toast.success(data.message || 'Logged out successfully');
                localStorage.removeItem('token');
                setTimeout(() => navigate('/'), 1500);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message || 'An error occurred');
        }
    };

    const handleDelete = async () => {
        setDeleteModal(false);

        const isOnline = navigator.onLine;
        if (!isOnline) {
            toast.error('Please check your internet connection and try again.');
            return;
        }

        try {
            const token = localStorage.getItem('token');

            if (!token) {
                throw new Error('Authorization token not found.');
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.post('https://admin.steptonikah.com/api/delete', {}, config);
            const { data } = response;

            if (data.success) {
                toast.success(data.message || 'Account deleted successfully.');

                localStorage.removeItem('userStatus');
                localStorage.removeItem('token');
                localStorage.removeItem('userId');

                setTimeout(() => {
                    navigate('/');
                }, 2000);
            }
        } catch (error) {
            console.error('Error while deleting account:', error);
            toast.error(
                error.response?.data?.message || error.message || 'An error occurred while deleting the account.'
            );
        }
    };

    const Colors = {
        primary: '#572d71',
        grey: '#C0C0C0',
        background: '#f4f4f5'
    };

    return (
        <div className="account-settings-container" style={{ backgroundColor: Colors.background }}>

            <div className="status-bar">
                <button className="back-button" onClick={() => navigate(-1)}>
                    <MdArrowBackIos size={24} color={Colors.primary} />
                </button>
            </div>

            <div className="content-section">
                <div className="button-row">
                    <button className="action-button" style={{ backgroundColor: '#e3dbf1' }} onClick={() => setLogoutModal(true)}>
                        <AiOutlineLogout size={24} color={Colors.primary} />
                        <span className="action-text" style={{ color: Colors.primary }}>Log Out</span>
                    </button>
                    <button className="action-button" style={{ backgroundColor: '#e3dbf1' }} onClick={() => setDeleteModal(true)}>
                        <AiOutlineDelete size={24} color={Colors.primary} />
                        <span className="action-text" style={{ color: Colors.primary }}>Delete</span>
                    </button>
                </div>
            </div>

            <Modal
                isOpen={logoutModal}
                onRequestClose={() => setLogoutModal(false)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <div className="popup_modal-content">
                    <h2>Do you want to logout?</h2>
                    <div className="modal-buttons">
                        <button onClick={handleLogout} className="modal-button yes" style={{ padding: '10px 30px' }}>Yes</button>
                        <button onClick={() => setLogoutModal(false)} className="modal-button no" style={{ padding: '10px 30px' }}>No</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={deleteModal}
                onRequestClose={() => setDeleteModal(false)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <div className="popup_modal-content">
                    <h2>Do you want to Delete Account?</h2>
                    <div className="modal-buttons">
                        <button onClick={handleDelete} className="modal-button yes" style={{ padding: '10px 30px' }}>Yes</button>
                        <button onClick={() => setDeleteModal(false)} className="modal-button no" style={{ padding: '10px 30px' }}>No</button>
                    </div>
                </div>
            </Modal>

            <div className="footer-section">
                <p className="footer-text" style={{ color: Colors.grey }}>Privacy Policy</p>
                <p className="footer-text" style={{ color: Colors.grey }}>Terms and Conditions</p>
            </div>
            <Toaster />
        </div>
    );
};

export default AccountSettings;
