import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const ContactDetailsModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const validate = () => {
        let validationErrors = {};
        
        if (!formData.primaryNumber) {
            validationErrors.primaryNumber = "primary number is required.";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validate()) return;
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/contactDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    return (
        <div
            className="modal fade show"
            id="contactDetailsModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Contact Attributes
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="primaryNumber"
                                        value={formData.primaryNumber || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="primaryNumber">Primary Number</label>
                                    {errors.primaryNumber && <div className="invalid-feedback">{errors.primaryNumber}</div>}
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="secondaryNumber"
                                        value={formData.secondaryNumber || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="secondaryNumber">Secondary Number</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="preferredContactType"
                                        value={formData.preferredContactType || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Call">Call</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Email">Email</option>
                                    </select>
                                    <label htmlFor="preferredContactType">Preferred Contact Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="preferredContactTime"
                                        value={formData.preferredContactTime || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="preferredContactTime">Preferred Contact Time</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm" placeholder="Name, Relation"
                                        name="contactPerson"
                                        value={formData.contactPerson || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="contactPerson">Contact Person</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default ContactDetailsModal;