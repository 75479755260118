import React, { useEffect, useState } from "react";
import { API_URL } from "../../../assets/Constants";
import axios from "axios";
import toast from "react-hot-toast";

const ContactDetails = ({ basicDetails,contactDetails,contactPrivacy }) => {
    const [contactRequestStatus, setContactRequestStatus] = useState(false);
    const [contactRequestReceivedStatus, setContactRequestReceivedStatus] = useState(false);

    //contact request
    const fetchContactRequest = async () => {
        if (!basicDetails || !basicDetails.id) {
            return;
        }

        try {
            const token = localStorage.getItem("token");
            
            if (!token) {
                console.error("No authentication token found");
                return;
            }

            const response = await axios.post(
                `${API_URL}/api/partnerProfile/getContactRequest`, 
                { profileId: basicDetails.id }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data && response.data.status) {
                if(response.data.contactRequest){
                    setContactRequestStatus(response.data.contactRequest); 
                }
                if(response.data.contactReceivedRequest){
                    console.log('received');
                    setContactRequestReceivedStatus(response.data.contactReceivedRequest); 
                }
            } else {
                console.warn("Failed to fetch contact request");
            }
        } catch (error) {
            if (error.response) {
                console.error("Data fetch error:", error.response.data);
                console.error("Status code:", error.response.status);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
            toast.error("Failed to load contact request");
        }
    };
    useEffect(() => {
        if (basicDetails && basicDetails.id) {
            fetchContactRequest();
        }
    }, [basicDetails]);

    const handleSendRequest = async (receiver_id) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/contactRequest/store`,
                { receiver_id: receiver_id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchContactRequest();
                toast.success('Contact request sent successfully.');
            } else {
                toast.error(response.data.message || 'Failed to send contact request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };

    const declineSentRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/contactRequest/delete`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchContactRequest();
                toast.success('Contact request deleted successfully.');
            } else {
                toast.error(response.data.message || 'Failed to delete contact request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };
    const acceptContactRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/contactRequest/accept`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchContactRequest();
                toast.success('Contact request accepted successfully.');
            } else {
                toast.error(response.data.message || 'Failed to accept contact request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };
    const rejectContactRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/contactRequest/reject`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchContactRequest();
                toast.success('Contact request rejected successfully.');
            } else {
                toast.error(response.data.message || 'Failed to reject contact request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };
    return (
        <div className="card mb-3 border border-primary border-4">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Contact Details</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        {(contactPrivacy && contactPrivacy == 'protected' && !contactRequestStatus) ? (
                            <tr>
                                <td colSpan={4}>
                                    <div className="mb-3">
                                        <h6 className="fw-bolder mb-1">Contact this member?</h6>
                                        <small>You can send a request to view the contact details</small>
                                    </div>
                                    <button 
                                        className="btn btn-primary btn-sm text-white me-2" 
                                        type="button"
                                        onClick={() => handleSendRequest(basicDetails.id)} 
                                    >
                                        <i className="ri-phone-fill ri-22px me-2"></i>
                                        VIEW CONTACT DETAILS
                                    </button>
                                </td>
                            </tr>
                        ):(
                            <>
                                {(contactRequestStatus && contactRequestStatus.status === 'pending') &&(
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="mb-3">
                                                <h6 className="fw-bolder mb-1">Contact Request sent</h6>
                                                <p>You have sent a contact request to this member</p>   
                                            </div>
                                            <button className="btn btn-danger btn-sm text-white" type="button"
                                                onClick={() => declineSentRequest(contactRequestStatus.id)}
                                            >
                                                <i className="ri-close-circle-fill ri-22px me-2"></i>
                                                Cancel Request
                                            </button>
                                        </td>
                                    </tr>
                                )}
                                {(contactRequestStatus && contactRequestStatus.status === 'accepted') &&(
                                    <>
                                        <tr>
                                            <td colSpan={4}>
                                                <div className="mb-3">
                                                    <h6 className="fw-bolder mb-1 text-success">Contact Request Accepted</h6>
                                                    <p className="text-success">Your contact request is accepted by this member</p>        
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Primary Number</th>
                                            <td>: {contactDetails?.primary_number || 'NA'}</td>
                                            <th>Secondary Number</th>
                                            <td>: {contactDetails?.secondary_number || 'NA'}</td>
                                        </tr>
                                        <tr>
                                            <th>Preferred Contact Type</th>
                                            <td>: {contactDetails?.preferred_contact_type || 'NA'}</td>
                                            <th>Preferred Contact Time</th>
                                            <td>: {contactDetails?.preferred_contact_time || 'NA'}</td>
                                        </tr>
                                        <tr>
                                            <th>Contact Person</th>
                                            <td>: {contactDetails?.contact_person || 'NA'}</td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </>
                                )}
                                {(contactRequestStatus && contactRequestStatus.status === 'rejected') &&(
                                    <tr>
                                        <td colSpan={4}>
                                            <div className="mb-3">
                                                <h6 className="fw-bolder mb-1 text-danger">Contact Request Rejected</h6>
                                                <p className="text-danger">This member choose not to accept your contact request</p>    
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
            {(contactRequestReceivedStatus) &&(
            <>
                <div className="card-header border px-5 py-3">
                    <h5 className="mb-0">Contact Request Details</h5>
                </div>
                <div className="table-responsive text-nowrap">
                    <table className="table border">
                        <tbody>
                            {(contactRequestReceivedStatus && contactRequestReceivedStatus.status === 'pending') &&(
                                <tr>
                                    <td colSpan={4}>
                                        <div className="mb-3">
                                            <h6 className="fw-bolder mb-1">Contact Request Received</h6>
                                            <p>You have received a contact request from this member</p>        
                                        </div>
                                        <button className="btn btn-success btn-sm text-white me-2" type="button"
                                            onClick={() => acceptContactRequest(contactRequestReceivedStatus.id)}
                                        >
                                            <i className="ri-check-fill ri-22px me-2"></i>
                                            Accept Request
                                        </button>
                                        <button className="btn btn-danger btn-sm text-white" type="button"
                                            onClick={() => rejectContactRequest(contactRequestReceivedStatus.id)}
                                        >
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            Cancel Request
                                        </button>
                                    </td>
                                </tr>
                            )}
                            {(contactRequestReceivedStatus && contactRequestReceivedStatus.status === 'accepted') &&(
                                <tr>
                                    <td colSpan={4}>
                                        <div className="mb-3">
                                            <h6 className="fw-bolder mb-1">Contact Request Accepted</h6>
                                            <p>You have accepted the contact request from this member</p>        
                                        </div>
                                        <button className="btn btn-danger btn-sm text-white" type="button"
                                            onClick={() => rejectContactRequest(contactRequestReceivedStatus.id)}
                                        >
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            Decline Request
                                        </button>
                                    </td>
                                </tr>
                            )}
                            {(contactRequestReceivedStatus && contactRequestReceivedStatus.status === 'rejected') &&(
                                <tr>
                                    <td colSpan={4}>
                                        <div className="mb-3">
                                            <h6 className="fw-bolder mb-1 text-danger">Contact Request Rejected</h6>
                                            <p className="text-danger">You have rejected the contact request from this member</p>     
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
            )}
        </div>
    );
};

export default ContactDetails;
