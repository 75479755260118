import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { IoHelpOutline, IoNotificationsOutline, IoPersonOutline, IoSettingsOutline } from 'react-icons/io5';
import axios from 'axios';
import HomeScreen from '../HomeScreen';

const Account = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [basicDetails, setBasicDetails] = useState([]);
    const [photo, setPhoto] = useState('https://admin.steptonikah.com/assets/img/man.jpg');

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = await localStorage.getItem('token');
            console.log(token);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.get('https://admin.steptonikah.com/api/myProfileDetails', config);
            if (response.data.status) {
                setLoading(false);
                setBasicDetails(response.data.basicDetails);
                const profilePicture = response.data.photos.find(photo => photo.profile_picture === "yes");
                setPhoto(profilePicture ? profilePicture.photo_url : null);
            }
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        console.log(basicDetails, "baisc details");
    }, [basicDetails]);
    useEffect(() => {
        console.log(photo, "Photo");
    }, [photo]);

    return (
        <div className="account-container">
            {/* <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} />
            </div> */}

            <div className="profile-image-container">
                <div className="inner-profile-image-container">
                    {photo ? (
                        <img className="profile-image" src={photo} alt="Profile" />
                    ) : (
                        <p style={{ textAlign: 'center' }}>No profile picture available</p>
                    )}
                </div>
            </div>

            <h2 className="account-title">{basicDetails.name}</h2>
            <p className="account-id">{basicDetails.profile_id}</p>

            <div className="item-container" onClick={() => navigate("/my-profile")}>
                <div className="sub-item">
                    <div className="icon-container">
                        <IoPersonOutline size={24} color='#572d71' />
                    </div>
                    <span className="item-text">Profile</span>
                </div>
                <MdArrowForwardIos size={18} />
            </div>

            <div className="item-container" onClick={() => navigate("/notification")}>
                <div className="sub-item">
                    <div className="icon-container">
                        <IoNotificationsOutline size={24} color='#572d71' />
                    </div>
                    <span className="item-text">Notifications</span>
                </div>
                <MdArrowForwardIos size={18} />
            </div>

            <div className="item-container" onClick={() => navigate("/settings")}>
                <div className="sub-item">
                    <div className="icon-container">
                        <IoSettingsOutline size={24} color='#572d71' />
                    </div>
                    <span className="item-text">Account Settings</span>
                </div>
                <MdArrowForwardIos size={18} />
            </div>

            <div className="item-container" onClick={() => navigate("/help")}>
                <div className="sub-item">
                    <div className="icon-container">
                        <IoHelpOutline size={24} color='#572d71' />
                    </div>
                    <span className="item-text">Help & Support</span>
                </div>
                <MdArrowForwardIos size={18} />
            </div>

            <HomeScreen />

        </div>
    );
};

export default Account;
