import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const EducationPreferenceModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    //options
    const educationOptions = [
        'Doctorate',
        'High/Secondary School', 
        'Diploma',
        'Bachelors Degree',
        'Masters Degree',
        'PhD',
        'Trade School/TTC/ITI',
        'Other',
    ];

    const occupations = [
        'Not Working',
        'Student',
        'Non-Mainstream Professional',
        'Acting professional',
        'Administration Professional',
        'Air Hostess', 
        'Doctor',
        'Engineer',
        'Teacher',
        'Lawyer',
        'Accountant',
        'Software Developer',
        'Architect',
        'Designer',
        'Writer',
        'Artist',
        'Musician', 
        'Actor',
        'Salesperson',
        'Marketing Professional',
        'Finance Professional', 
        'Human Resources Professional',
        'Customer Service Representative', 
        'Administrative Assistant',
        'Technician',
    ];

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
    
            const payload = {
                ...formData,
                education: formData.education ? [formData.education] : [],
                profession: formData.profession ? [formData.profession] : [],
            };
    
            const response = await axios.post(
                `${API_URL}/api/educationPreferences/store`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(payload);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    
    return (
        <div
            className="modal fade show"
            id="educationPreferenceModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Education Preferences
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Education Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="education"
                                    value={formData.education || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Select Education Preference</option>
                                    {educationOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Profession Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="profession"
                                    value={formData.profession || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Select Profession Preferences</option>
                                    {occupations.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default EducationPreferenceModal;