import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import logo from '../../assets/img/logo.png';
import { API_URL } from "../../assets/Constants";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";

const TopNav = () => {
    const [user, setUser] = useState(null);
    const fetchUser = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/web/homepage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setUser(response.data);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };
    
    const navigate = useNavigate();

    const handleLogout = async (e) => {
        try {
            const token = localStorage.getItem("token") ?? '';
            
            if (!token) {
                console.warn('No token found, redirecting to login.');
                navigate('/');
                return;
            }
    
            const response = await axios.post(`${API_URL}/api/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            localStorage.removeItem('token');
            toast.success('Logged out Successfully!');
            navigate('/');
    
        } catch (error) {
            console.error('Logout failed:', error);
            toast.error('An error occurred!');
        }
    };

    const [notifications, setNotifications] = useState([]);

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(`${API_URL}/api/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status) {
                setNotifications(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchUser();
        fetchNotifications();
    }, []);
    
    const partnerProfileView = (profileId) => {        
        localStorage.setItem("profile-details", profileId);
        navigate("/profile-view"); 
    }

    return (
        <nav className="layout-navbar navbar navbar-expand-xl align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="container-xxl">
                <div className="navbar-brand app-brand demo d-none d-xl-flex py-0 me-6">
                    <a href="#!" className="app-brand-link gap-2">
                        <img src={logo} width={80} />
                    </a>

                    <a href="#!" className="layout-menu-toggle menu-link text-large ms-auto d-xl-none">
                        <i className="ri-close-fill align-middle"></i>
                    </a>
                </div>

                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-4 me-xl-0  d-xl-none  ">
                    <a className="nav-item nav-link px-0 me-xl-6" href="#!">
                        <i className="ri-menu-fill ri-22px"></i>
                    </a>
                </div>

                <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <ul className="navbar-nav flex-row align-items-center ms-auto">

                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-4 me-xl-1">
                            <a className="nav-link btn btn-text-secondary rounded-pill btn-icon dropdown-toggle hide-arrow"
                                href="#!" data-bs-toggle="dropdown" data-bs-auto-close="outside"
                                aria-expanded="false">
                                <i className="ri-notification-2-line ri-22px"></i>
                                <span className="position-absolute top-0 start-50 translate-middle-y badge badge-dot bg-danger mt-2 border"></span>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end py-0">
                                <li className="dropdown-menu-header border-bottom py-50">
                                    <div className="dropdown-header d-flex align-items-center py-2">
                                        <h6 className="mb-0 me-auto">Notification</h6>
                                        <div className="d-flex align-items-center">
                                            <span className="badge rounded-pill bg-label-primary fs-xsmall me-2">8 New</span>
                                            <a href="#!"
                                                className="btn btn-text-secondary rounded-pill btn-icon dropdown-notifications-all"
                                                data-bs-toggle="tooltip" data-bs-placement="top" title="Mark all as read">
                                                <i className="ri-mail-open-line text-heading ri-20px"></i>
                                            </a>
                                        </div>
                                    </div>
                                </li>
                                <li className="border-top">
                                    <div className="d-grid p-4">
                                        {notifications.slice(0, 6).length > 0 ? (
                                            notifications.slice(0, 6).map((notification) => (
                                                <div
                                                    key={notification.id}
                                                    className="justify-content-between align-items-center mb-0"
                                                    onClick={() => partnerProfileView(notification.profileId)}
                                                >
                                                    <div className="d-flex">
                                                        <div className="me-4">
                                                            <img
                                                                src={notification.photo}
                                                                alt="Avatar"
                                                                className="rounded-circle"
                                                                height="50"
                                                                width="50"
                                                            />
                                                        </div>
                                                        <div>
                                                            <h6
                                                                className="mb-0 justify-content"
                                                                style={{ fontSize: "13px" }}
                                                            >
                                                                {notification.title}
                                                            </h6>
                                                            <p
                                                                className="justify-content mb-0"
                                                                style={{ fontSize: "12px" }}
                                                            >
                                                                {notification.content}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <p
                                                        className="text-muted text-end"
                                                        style={{ fontSize: "12px" }}
                                                    >
                                                        {notification.sent_on}
                                                    </p>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-center text-muted">No notifications available</p>
                                        )}
                                    </div>
                                </li>
                                {/* <li className="border-top">
                                    <div className="d-grid p-4">
                                        <a className="btn btn-primary btn-sm d-flex" href="#!">
                                            <small className="align-middle">View all notifications</small>
                                        </a>
                                    </div>
                                </li> */}
                            </ul>
                        </li>
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <a className="nav-link dropdown-toggle hide-arrow" href="#!" data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                    {user ? 
                                    <img className="rounded-circle" src={user.photoUrl}  alt="View Profile" />
                                    :
                                    <Skeleton height={200} width={150}/>}
                                </div>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                    <Link to={"/my-profile"}>
                                        <a className="dropdown-item" href="my-profile">
                                            <div className="d-flex">
                                                <div className="flex-shrink-0 me-2">
                                                    <div className="avatar avatar-online">
                                                        {user ? 
                                                        <img className="rounded-circle" src={user.photoUrl}  alt="View Profile" />
                                                        :
                                                        <Skeleton height={200} width={150}/>}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1">
                                                    <span className="fw-medium d-block small">{user ? user.name : <Skeleton />} </span>
                                                    <small className="text-muted">{user ? `STN${user.profileId}` : <Skeleton />}</small>
                                                </div>
                                            </div>
                                        </a>
                                    </Link>
                                </li>
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <div className="d-grid px-4 pt-2 pb-1">
                                        <a className="btn btn-sm btn-danger d-flex" href="#!" onClick={handleLogout}>
                                            <small className="align-middle">Logout</small>
                                            <i className="ri-logout-box-r-line ms-2 ri-16px"></i>
                                        </a>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};
export default TopNav;
