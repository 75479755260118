import React from "react";
import PhotoList from "./PhotoList";
import PhotoPrivacy from "./PhotoPrivacy";

const ManagePhoto = () =>{

    return(
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="card mb-6">
                <div className="card-header p-0">
                    <div className="nav-align-top">
                        <ul className="nav nav-tabs nav-fill" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#nav-manage-photo" aria-controls="nav-manage-photo" aria-selected="false" tabindex="-1">
                                    <span className="d-none d-sm-block">
                                        <i className="tf-icons ri-image-2-line me-2"></i> 
                                        Manage Photos
                                    </span>
                                    <i className="ri-home-smile-line ri-20px d-sm-none"></i>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-photo-privacy" aria-controls="nav-photo-privacy" aria-selected="false" tabindex="-1">
                                    <span className="d-none d-sm-block">
                                        <i className="tf-icons ri-git-repository-private-line me-2"></i>
                                        Photo Privacy
                                    </span>
                                    <i className="ri-git-repository-private-line ri-20px d-sm-none"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="tab-content p-0">
                        <PhotoList/>
                        <PhotoPrivacy />
                    </div>
                </div>
            </div>
        </div>
        
    );

};
export default ManagePhoto;