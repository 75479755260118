import React from "react";

const PhysicalAttributes = ({physicalDetails}) => {
    return(
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Physical Attributes</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Height</th>
                            <td>: {physicalDetails?.height || 'N/A'}</td>
                            <th>Weight</th>
                            <td>: {physicalDetails?.weight || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Complexion</th>
                            <td>: {physicalDetails?.skin_tone || 'N/A'}</td>
                            <th>Body Type</th>
                            <td>: {physicalDetails?.body_type || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Blood Group</th>
                            <td>: {physicalDetails?.blood_group || 'N/A'}</td>
                            <th>Hair Color</th>
                            <td>: {physicalDetails?.hair_color || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Hair Type</th>
                            <td>: {physicalDetails?.hair_type || 'N/A'}</td>
                            <th>Facial Hair</th>
                            <td>: {physicalDetails?.facial_hair || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Eye Color</th>
                            <td>: {physicalDetails?.eye_color || 'N/A'}</td>
                            <th>Eye Wear</th>
                            <td>: {physicalDetails?.eye_wear || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default PhysicalAttributes;