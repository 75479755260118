import React from "react";

const LocationPreference = ({locationPreference}) => {
    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return []; // Return an empty array if parsing fails
        }
    };
    return(
        <div className="card mt-3 p-0">
            <div className="card-header px-5 py-3">
                <h5 className="mb-0">Location Preferences</h5>
            </div>
            <div className="card-body mt-0">
                <div class="table-responsive">
                    <table class="table border">
                        <tbody>
                            {
                                locationPreference.districts_preference && (
                                    <tr>
                                        <th style={{ whiteSpace:"normal" }} width={200}>District Preferences</th>
                                        <td className="text-start" style={{ whiteSpace:"normal" }}>
                                            : {parseArray(locationPreference.districts_preference).join(", ")}
                                        </td>
                                        <td className={`text-end ${locationPreference.districtsPreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                locationPreference.present_country && (
                                    <tr>
                                        <th style={{ whiteSpace:"normal" }} width={200}>Present Country</th>
                                        <td className="text-start" style={{ whiteSpace:"normal" }}>
                                            : {parseArray(locationPreference.present_country).join(", ")}
                                        </td>
                                        <td className={`text-end ${locationPreference.presentCountryMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default LocationPreference;