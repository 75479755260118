import React from "react";
import BasicPreference from "./BasicPreferences";
import EducationPreference from "./EducationPreference";
import LookingFor from "./LookingFor";
import LocationPreference from "./LocationPreferences";

const PartnerPreference = ({ partnerPreferenceDetails }) => {
    return (
        <div className="card mt-3 px-5 py-2">
            <div className="card-body border rounded p-2">
                <div className="text-center">
                    <h4>Partner Preferences</h4>
                </div>
            </div>

            <BasicPreference basicPreference={partnerPreferenceDetails} />
            <EducationPreference educationPreference={partnerPreferenceDetails} />
            <LocationPreference locationPreference={partnerPreferenceDetails} />
            <LookingFor lookingFor={partnerPreferenceDetails} />
        </div>
    );
};

export default PartnerPreference;
