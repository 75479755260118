import React, { useState, useEffect, useRef } from 'react';
import { IoArrowBack, IoArrowForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';

const Colors = {
    primary: "#572d71",
    background: "#f4f4f5",
    grey: "#C0C0C0",
}

const SelectDob = () => {
    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');
    const [focusedInput, setFocusedInput] = useState(null);
    const [isNavigationAllowed, setIsNavigationAllowed] = useState(false);

    const monthRef = useRef(null);
    const yearRef = useRef(null);
    const navigate = useNavigate();

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    const handleBlur = () => {
        setFocusedInput(null);
    };

    const showToast = (message) => {
        toast.error(message);
    };

    const isValidDate = (day, month, year) => {
        const currentDate = new Date();
        const inputDate = new Date(year, month - 1, day);
        const age = currentDate.getFullYear() - inputDate.getFullYear();
        const isOver18 = age > 18 || (age === 18 && currentDate >= new Date(year + 18, month - 1, day));

        if (day < 1 || day > 31) return false;
        if (month < 1 || month > 12) return false;
        if (year.toString().length !== 4 || year > currentDate.getFullYear()) return false;
        if (!isOver18) return false;

        return true;
    };

    useEffect(() => {
        if (day !== '' &&
            month !== '' &&
            year !== '' &&
            parseInt(day) >= 1 &&
            parseInt(day) <= 31 &&
            parseInt(month) >= 1 &&
            parseInt(month) <= 12 &&
            year.length === 4) {
            setIsNavigationAllowed(true);
        } else {
            setIsNavigationAllowed(false);
        }
    }, [day, month, year]);

    const handleDayChange = (value) => {
        setDay(value);

        if (parseInt(value) > 31) {
            showToast('Please enter a valid day');
        } else if (value.length === 2) {
            monthRef.current?.focus();
        }
    };

    const handleMonthChange = (value) => {
        setMonth(value);

        if (parseInt(value) > 12) {
            showToast('Please enter a valid month');
        } else if (value.length === 2) {
            yearRef.current?.focus();
        }
    };

    const handleNext = () => {
        if (!day || !month || !year) {
            showToast('Please enter your full date of birth');
            return;
        }
        if (!isValidDate(parseInt(day), parseInt(month), parseInt(year))) {
            showToast('Invalid date or you must be 18 years or older');
            return;
        }
        const formattedDate = day + '-' + month + '-' + year;
        localStorage.setItem("selected_dob", formattedDate);
        navigate('/register');
    };

    return (
        <div className="select-dob-container">
            <div className="content">
                <h1 className="title">Create Account</h1>
                <p className="subTitle">How old are you?</p>
                <div className="inputRow">
                    <div className="inputWrapper">
                        {focusedInput === 'day' || day ? <label className="label">Date</label> : null}
                        <input
                            className={`input ${focusedInput === 'day' || day > 31 ? 'active' : ''}`}
                            type='number'
                            placeholder="DD"
                            maxLength={2}
                            value={day}
                            onChange={(e) => handleDayChange(e.target.value)}
                            onFocus={() => handleFocus('day')}
                            onBlur={handleBlur}
                        />
                    </div>
                    <div className="inputWrapper">
                        {focusedInput === 'month' || month ? <label className="label">Month</label> : null}
                        <input
                            className={`input ${focusedInput === 'month' || month > 12 ? 'active' : ''}`}
                            type='number'
                            placeholder="MM"
                            maxLength={2}
                            value={month}
                            onChange={(e) => handleMonthChange(e.target.value)}
                            onFocus={() => handleFocus('month')}
                            onBlur={handleBlur}
                            ref={monthRef}
                        />
                    </div>
                    <div className="inputWrapper">
                        {focusedInput === 'year' || year ? <label className="label">Year</label> : null}
                        <input
                            className={`input ${focusedInput === 'year' ? 'active' : ''}`}
                            type='number'
                            placeholder="YYYY"
                            maxLength={4}
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                            onFocus={() => handleFocus('year')}
                            onBlur={handleBlur}
                            ref={yearRef}
                        />
                    </div>
                </div>
            </div>
            <Toaster />
            <div className="navigationContainer">
                <button className="navButton" onClick={() => navigate(-1)} style={{ backgroundColor: Colors.primary }}>
                    <IoArrowBack type="arrow-left" style={{ fontSize: '24px', color: Colors.background }} />
                </button>
                <button
                    className={`navButton ${isNavigationAllowed ? 'active' : ''}`}
                    onClick={handleNext}
                    disabled={!isNavigationAllowed}
                >
                    <IoArrowForward type="arrow-right" style={{ fontSize: '24px', color: isNavigationAllowed ? Colors.background : Colors.grey }} />
                </button>
            </div>
        </div>
    );
};

export default SelectDob;
