import React from "react";


const EducationPreference = ({educationPreference}) => {
    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return []; 
        }
    };
    return(
        <div className="card mt-3 p-0">
            <div className="card-header px-5 py-3">
                <h5 className="mb-0">Education Preferences</h5>
            </div>
            <div className="card-body mt-0">
                <div class="table-responsive">
                    <table class="table border">
                        <tbody>
                            {
                                educationPreference.education && (
                                    <tr>
                                        <th style={{ whiteSpace:"normal" }} width={200}>Education</th>
                                        <td className="text-start" style={{ whiteSpace:"normal" }}>
                                            : {parseArray(educationPreference.education).join(", ")}
                                        </td>
                                        <td className={`text-end ${educationPreference.educationMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                educationPreference.profession && (
                                    <tr>
                                        <th style={{ whiteSpace:"normal" }} width={200}>Profession</th>
                                        <td className="text-start" style={{ whiteSpace:"normal" }}>
                                            : {parseArray(educationPreference.profession).join(", ")}
                                        </td>
                                        <td className={`text-end ${educationPreference.professionMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default EducationPreference;