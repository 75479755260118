import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';

const ForgetPassword = () => {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const handleForgetPassword = async () => {
        if (!email) {
            toast.error("Please enter an email address");
            return;
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post("https://admin.steptonikah.com/api/forgot-password/send-otp", { email });

            console.log(response.data, "response");

            if (response.data?.message) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/password-otp', { state: { email } });
                }, 1500);
            }
        } catch (error) {
            console.error("Error:", error);

            toast.error(error.response?.data?.message || "An error occurred while sending OTP");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="forget-password-container">
            <Toaster />
            <div className="status-bar"></div>
            <button className="back-button" onClick={() => navigate(-1)}>
                <IoArrowBack size={25} color='#572d71' />
            </button>
            <div className="scroll-container">
                <div className="content-container">
                    <div className="form-content">
                        <h2 className="title">Forget Password</h2>
                        <p className="subtitle">Enter your email below to reset the password</p>
                        <input
                            type="email"
                            className="input-field"
                            placeholder="Enter your Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <button className="submit-button" onClick={handleForgetPassword} disabled={loading}>
                            {loading ? (
                                <div className="spinner" style={{
                                    width: '20px', height: '20px', marginLeft: '45%', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite'
                                }}></div>) : (
                                <span>SEND</span>
                            )}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgetPassword;
