import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';
import { IoDocumentAttachOutline, IoTrashOutline } from 'react-icons/io5';

const IdVerification = () => {
    const [idname, setIdName] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [badgeData, setBadgeData] = useState([]);
    const [showId, setId] = useState('');
    const [loading, setLoading] = useState(false);
    const [badgeStatus, setStatus] = useState('');
    const navigate = useNavigate();

    // Fetch Badge Data
    const fetchBadgeData = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token not found! Please login again.');
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get(
                'https://admin.steptonikah.com/api/myProfileDetails',
                config
            );

            if (response.data.status) {
                setStatus(response.data.badgeStatus);
                const data = response.data.badgeData;
                setBadgeData(data);
                setUploadedFile(data.idFile);
                setIdName(data.idName);
                setId(data.id);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to fetch badge data.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchBadgeData();
    }, []);

    useEffect(() => {
        console.log('Badge Status:', badgeStatus);
    }, [badgeStatus]);

    // Handle File Upload
    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate file size (e.g., max 5MB) and type
            if (file.size > 5 * 1024 * 1024) {
                toast.error('File size exceeds 5MB limit.');
                return;
            }
            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png'];
            if (!allowedTypes.includes(file.type)) {
                toast.error('Only PDF, JPEG, or PNG files are allowed.');
                return;
            }
            setUploadedFile(file);
        }
    };

    // Handle File Removal
    const handleFileRemove = async () => {
        if (!showId) {
            toast.error('No file to remove.');
            return;
        }
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.post(
                `https://admin.steptonikah.com/api/badge/${showId}/idDelete`,
                {},
                config
            );

            if (response.data.status) {
                toast.success(response.data.message);
                fetchBadgeData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Failed to remove file.');
        }
    };

    // Handle Verification Submission
    const handleVerify = async () => {
        if (!idname || !uploadedFile) {
            toast.error('Please provide ID name and upload a file.');
            return;
        }

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('idName', idname);
            formData.append('idFile', uploadedFile);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await axios.post(
                'https://admin.steptonikah.com/api/badge/idStore',
                formData,
                config
            );

            if (response.data.status) {
                toast.success(response.data.message);
                fetchBadgeData();
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Verification failed.');
        }
    };

    if (loading) {
        return (
            <div
                style={{
                    display: 'flex',
                    height: '100vh',
                    backgroundColor: '#FDF7FD',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <div
                    className="spinner"
                    style={{
                        width: '50px',
                        height: '50px',
                        border: '5px solid lightgray',
                        borderRadius: '50%',
                        borderTopColor: '#572d71',
                        animation: 'spin 1s linear infinite',
                    }}
                ></div>
                <span style={{ marginLeft: '10px', color: '#572d71' }}>Loading...</span>
            </div>
        );
    }

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color="#572d71" />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>ID Verification</h2>

            <div className="input-container">
                <label>ID Name</label>
                <input
                    type="text"
                    placeholder="Enter ID name"
                    className="input-field"
                    value={idname}
                    onChange={(e) => setIdName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Add File</label>
                <div className="upload-container" style={{ height: '150px' }}>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileUpload}
                    />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        <IoDocumentAttachOutline name="addfile" size={24} color="grey" />
                        <span className="upload-text">Upload File</span>
                    </div>
                </div>

                {uploadedFile && (
                    <div style={styles.fileInfoContainer}>
                        <span style={styles.fileName}>{uploadedFile.name}</span>
                        <button
                            onClick={handleFileRemove}
                            style={{ border: 'none', background: 'none', cursor: 'pointer' }}
                        >
                            <IoTrashOutline size={20} color="black" />
                        </button>
                    </div>
                )}
            </div>

            <div style={{ textAlign: 'center', marginBottom: '15px' }}>
                {badgeStatus === 'pending' ? (
                    <span style={{ color: 'orange' }}>Your verification is under review.</span>
                ) : badgeStatus === 'approved' ? (
                    <span style={{ color: 'green' }}>Your ID has been approved.</span>
                ) : (
                    ''
                )}
            </div>

            <div style={{ textAlign: 'center' }}>
                {badgeStatus === 'pending' ? (
                    <button className="submit-button" disabled>
                        Pending
                    </button>
                ) : (
                    <button className="submit-button" onClick={handleVerify}>
                        Verify
                    </button>
                )}
            </div>
            <Toaster />
        </div>
    );
};

const styles = {
    fileInfoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '15px',
        padding: '10px',
        border: '1px solid grey',
        borderRadius: '5px',
    },
    fileName: {
        flex: 1,
        marginRight: '10px',
    },
};

export default IdVerification;
