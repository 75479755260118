import React, { useState } from 'react';
import axios from 'axios';
import Cropper from 'react-easy-crop';
import { API_URL } from '../../assets/Constants';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';

const PhotoUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedFile(file);
            const url = URL.createObjectURL(file); // Create temporary URL for image
            setImageUrl(url);
            setIsUploading(true); // Show the cropping frame
        }
    };

    const onCropComplete = (_, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    };

    const getCroppedImage = async () => {
        // Crop and return the image as a file
        const canvas = document.createElement('canvas');
        const image = new Image();
        image.src = imageUrl;

        return new Promise((resolve) => {
            image.onload = () => {
                canvas.width = croppedAreaPixels.width;
                canvas.height = croppedAreaPixels.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(
                    image,
                    croppedAreaPixels.x,
                    croppedAreaPixels.y,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height,
                    0,
                    0,
                    croppedAreaPixels.width,
                    croppedAreaPixels.height
                );

                canvas.toBlob((blob) => {
                    resolve(blob);
                }, 'image/jpeg');
            };
        });
    };

    const handleUpload = async () => {
        const croppedImageBlob = await getCroppedImage();
        const formData = new FormData();
        formData.append('photo', croppedImageBlob, 'croppedImage.jpg');

        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(`${API_URL}/api/photos/store`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                toast.success('Photo uploaded successfully');
                setImageUrl(null);
                setIsUploading(false);
                navigate('/manage-photos')
            }
        } catch (error) {
            console.error(error);
            toast.error('Upload failed');
        }
    };

    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="card mb-6">
                <div className="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                    <div className="card-title mb-0">
                        <h5 className="m-0 me-2 mb-1">Photo Upload</h5>
                    </div>
                </div>
                <div className='card-body border py-5'>
                    <div style={{ textAlign: 'center' }}>
                        {/* Upload Section */}
                        {!isUploading ? (
                            <div>
                                <input type="file" accept="image/*" onChange={handleFileChange} />
                                <div className='mt-5'>
                                    <Link to={'/manage-photos'}>
                                        <button className="btn btn-primary me-12">
                                            Go back to Photo List
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ) : (
                            <div style={{ position: 'relative', width: '320px', height: '400px', margin: '0 auto' }}>
                                <Cropper
                                    image={imageUrl}
                                    crop={crop}
                                    zoom={zoom}
                                    aspect={320 / 400}
                                    onCropChange={setCrop}
                                    onZoomChange={setZoom}
                                    onCropComplete={onCropComplete}
                                />
                                {/* Zoom Slider */}
                                <input
                                    type="range"
                                    min="1"
                                    max="3"
                                    step="0.1"
                                    value={zoom}
                                    onChange={(e) => setZoom(e.target.value)}
                                    style={{ width: '320px', margin: '10px auto' }}
                                />
                            </div>
                        )}

                        {/* Action Buttons */}
                        {isUploading && (
                            <div style={{ marginTop: '20px' }}>
                                <button className="btn btn-success me-2" onClick={handleUpload}>
                                    Done
                                </button>
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        setIsUploading(false);
                                        setImageUrl(null);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
};

export default PhotoUpload;
