import React, { useEffect, useState } from "react";
import EducationPreferenceModal from "./Modals/EducationPreferenceModal";


const EducationPreference = ({educationPreference : initialEducationPreference}) => {
    const [showModal, setShowModal] = useState(false);
    const [educationPreference, setEducationPreference] = useState(initialEducationPreference);

    useEffect(() => {
        setEducationPreference(initialEducationPreference);
    }, [initialEducationPreference]);

    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return [];
        }
    };

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setEducationPreference(prev => ({
            ...prev,
            education: updatedDetails.education,
            profession: updatedDetails.profession,
        }));

        setShowModal(false);
    };

    return(
        <div className="card mt-3 p-0">
            <div class="card-header d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Education Preferences</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="card-body mt-0">
                <div class="table-responsive">
                    <table class="table border">
                        <tbody>
                            <tr>
                                <td>Education</td>
                                <td>
                                    : {parseArray(educationPreference?.education).join(", ") || "N/A"}
                                </td>
                                <td>Profession</td>
                                <td>
                                    : {parseArray(educationPreference?.profession).join(", ") || "N/A"}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {showModal && (
                <EducationPreferenceModal
                    details={{
                        education: educationPreference?.education,
                        profession: educationPreference?.profession,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default EducationPreference;