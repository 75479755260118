import React, { useState } from "react";

const BlockModal = ({ onClose, onSave }) => {
    const [blockReason, setBlockReason] = useState("");

    const handleSave = () => {
        if (!blockReason.trim()) {
            alert("Block reason is required!");
            return;
        }
        onSave(blockReason); // Pass blockReason to parent
    };

    return (
        <div
            className="modal fade show"
            id="blockModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Do you want to block this user?
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-floating form-floating-outline">
                            <textarea
                                className="form-control"
                                name="blockReason"
                                id="blockReason"
                                rows="4"
                                value={blockReason}
                                onChange={(e) => setBlockReason(e.target.value)}
                            ></textarea>
                            <label htmlFor="blockReason">Block Reason</label>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Block Profile
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlockModal;
