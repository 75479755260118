import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import ProfileDescriptionModal from "./Modals/ProfileDescriptionModal";
import { API_URL } from "../../../assets/Constants";
import toast from "react-hot-toast";

const ProfileDescription = ({ personalDetails }) => {
    const [description, setDescription] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (personalDetails?.profile_description) {
            setDescription(personalDetails.profile_description);
        }
    }, [personalDetails]);

    const handleSaveDescription = async (newDescription) => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.post(
                `${API_URL}/api/profileUpdate/profileDescription`,
                { profileDescription : newDescription },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200 && response.data.status) {
                setDescription(newDescription); // Update local state
                setShowModal(false); // Close the modal
                toast.success("Description updated");
            } else {
                toast.error(response.data.message || "Failed to update description");
            }
        } catch (error) {
            toast.error("Error updating description:", error);
        }
    };

    return (
        <div className="card mt-3 mb-3 p-0">
            <div className="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div className="card-title mb-0">
                    <h5 className="m-0 me-2 mb-1">Profile Description</h5>
                </div>
                <div>
                    <button
                        className="btn btn-sm btn-primary float-end"
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="card-body border py-5">
                <p className="justify-content">
                    {personalDetails ? (
                        description || "No description available."
                    ) : (
                        <Skeleton count={3} />
                    )}
                </p>
            </div>

            {showModal && (
                <ProfileDescriptionModal
                    existingDescription={description}
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveDescription}
                />
            )}
        </div>
    );
};

export default ProfileDescription;
