import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdAdd, MdArrowBackIosNew } from 'react-icons/md';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { IoAdd } from 'react-icons/io5';

const EditPhoto = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [photos, setPhotos] = useState(Array(6).fill(null));
    const [photoDetails, setPhotoDetails] = useState([]);
    const [showPrivacy, setPrivacy] = useState('');
    const [privacyId, setPrivacyId] = useState('');
    const [visibleToAll, setVisibleToAll] = useState(true);
    const [lockPhotos, setLockPhotos] = useState(false);

    const togglePrivacyOption = (option) => {
        if (option === 'visibleToAll') {
            setVisibleToAll(true);
            setLockPhotos(false);
        } else {
            setVisibleToAll(false);
            setLockPhotos(true);
        }
    };

    const fetchImage = async () => {
        setLoading(true);
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get('https://admin.steptonikah.com/api/photos/view', config);
            const fetchedPhotos = response.data.photos.map(photo => ({
                id: photo.id,
                photo_url: `https://admin.steptonikah.com/storage/${photo.photo}`,
            }));

            const updatedPhotos = Array(6).fill(null).map((_, i) => fetchedPhotos[i]?.photo_url || null);
            setPhotos(updatedPhotos);
            setPhotoDetails(fetchedPhotos);
        } catch (error) {
            console.error('Error fetching photos:', error);
            toast.error('Failed to fetch images.');
        } finally {
            setLoading(false);
        }
    };

    const photoStore = async (file) => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('photo', file);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await axios.post('https://admin.steptonikah.com/api/photos/store', formData, config);

            if (response.data.status) {
                toast.success('Image uploaded successfully.');
                fetchImage();
            }
        } catch (error) {
            console.error('Error storing photo:', error);
            toast.error('Failed to upload image. Please try again.');
        }
    };

    const photoUpdate = async (file, photoId) => {
        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();
            formData.append('photo', file);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data',
                },
            };

            const response = await axios.post(`https://admin.steptonikah.com/api/photo/${photoId}/update`, formData, config);

            if (response.data.status) {
                toast.success('Image updated successfully.');
                fetchImage();
            }
        } catch (error) {
            console.error('Error updating photo:', error);
            toast.error('Failed to update image. Please try again.');
        }
    };

    const photoPrivacy = async () => {
        const token = await localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };
        try {
            const response = await axios.get('https://admin.steptonikah.com/api/photo/privacy', config);
            console.log(response.data, "Photo privacy");
            if (response.data) {
                setPrivacy(response.data.privacy);
                setPrivacyId(response.data.id);
            }
            if (response.data.privacy === 'protected') {
                setLockPhotos(true);
                setVisibleToAll(false);
            } else if (response.data.privacy === 'all') {
                setLockPhotos(false);
                setVisibleToAll(true);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updatePrivacy = async () => {
        const token = await localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
            },
        };
        const privacyOption = visibleToAll ? 'all' : 'protected';
        try {
            console.log(privacyId, "Privacy Id");
            const response = await axios.post(` https://admin.steptonikah.com/api/photo/privacy/${privacyId}/update`,
                { privacy: privacyOption },
                config
            );
            console.log(response.data, "Update data response");
            toast.success('Photo Privacy Updated');
        } catch (error) {
            toast.error('Failed to Update Photo Privacy');
        }
    };

    const pickImage = async (index) => {
        const input = document.createElement('input');
        input.type = 'file';
        input.accept = 'image/*';

        input.onchange = async (event) => {
            const file = event.target.files[0];
            if (file) {
                const uri = URL.createObjectURL(file);
                const newPhotos = [...photos];
                newPhotos[index] = uri;
                setPhotos(newPhotos);

                const photoId = photoDetails[index]?.id;
                if (photoId) {
                    await photoUpdate(file, photoId);
                } else {
                    await photoStore(file);
                }
            }
        };

        input.click();
    };

    const renderPhotoOption = (index) => (
        <div key={index} className="photo-container" onClick={() => pickImage(index)}>
            {photos[index] ? (
                <img src={photos[index]} alt={`Photo ${index + 1}`} className="photo" />
            ) : (
                <label className="add-photo-icon">
                    <IoAdd size={26} color='grey' />
                </label>
            )}
        </div>
    );

    useEffect(() => {
        photoPrivacy();
    }, []);
    useEffect(() => {
        fetchImage();
    }, []);

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                height: '100vh',
                backgroundColor: '#FDF7FD',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="spinner" style={{ width: '50px', height: '50px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>
            </div>
        );
    }

    return (
        <div className="edit-photo-container">
            <div className="header">
                <button onClick={() => navigate(-1)} className="back-button">
                    <MdArrowBackIosNew size={24} color="#333" />
                </button>
                <h2>Manage Your Pics</h2>
            </div>

            <div className="photo-grid">
                {photos.map((_, index) => renderPhotoOption(index))}
            </div>

            <div style={{ marginTop: "20px" }}>
                <p style={{ fontSize: 18, marginBottom: 10 }}>Photo Privacy</p>

                <div className="privacyOption">
                    <p className="privacyText">Visible to all</p>
                    <label className={`switch ${visibleToAll ? 'active' : ''}`}>
                        <input
                            type="checkbox"
                            checked={visibleToAll}
                            onChange={() => togglePrivacyOption("visibleToAll")}
                            style={{ display: "none" }}
                        />
                        <span
                            className={`switch-thumb ${visibleToAll ? 'active' : ''}`}
                        ></span>
                    </label>
                </div>

                <div className="privacyOption">
                    <p className="privacyText">Lock my Photos</p>
                    <label className={`switch ${lockPhotos ? 'active' : ''}`}>
                        <input
                            type="checkbox"
                            checked={lockPhotos}
                            onChange={() => togglePrivacyOption("lockPhotos")}
                            style={{ display: "none" }}
                        />
                        <span
                            className={`switch-thumb ${lockPhotos ? 'active' : ''}`}
                        ></span>
                    </label>
                </div>
            </div>
            <div className="submit-container" style={{ marginTop: "50px" }}>
                <button className="submit-button" onClick={updatePrivacy}>
                    Update Privacy
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default EditPhoto;
