import React, { useState } from 'react';
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from '../layouts/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const Register = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {
        height,
        weight,
        maritalStatus,
        bodyType,
        physicallyChallenged,
        complexion,
        ethnicGroup,
    } = location.state || {};

    const [selectedEducation, setSelectedEducation] = useState("");
    const [selectedOccupation, setSelectedOccupation] = useState("");
    const [selectedProfession, setSelectedProfession] = useState("");
    const [nationality, setNationality] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [place, setPlace] = useState("");
    const [presentnationality, setPresentNationality] = useState("");
    const [presentState, setPresentState] = useState("");
    const [presentDistrict, setPresentDistrict] = useState("");
    const [presentPlace, setPresentPlace] = useState("");
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
    const [selectedPreferedContactType, setSelectedPreferedContactType] =
        useState("");
    const [sameLocation, setSameLocation] = useState(false);

    const coursesByEducation = {
        "Doctorate": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Masters Degree": [
            { value: 'IES', label: 'IES' },
            { value: 'Master of Veterinary Science', label: 'Master of Veterinary Science' },
            { value: 'MSc Nursing', label: 'MSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'M Pharm', label: 'M Pharm' },
            { value: 'Master of Law', label: 'Master of Law' },
            { value: 'LLM', label: 'LLM' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA', label: 'CA' },
            { value: 'ICWA', label: 'ICWA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'IRS', label: 'IRS' },
            { value: 'MAMS', label: 'MAMS' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'MUMS', label: 'MUMS' },
            { value: 'Pharm D', label: 'Pharm D' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'MSc Biotechnology', label: 'MSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'MSc Visual Communication', label: 'MSc Visual Communication' },
            { value: 'Msc Radiology', label: 'Msc Radiology' },
            { value: 'M.Voc Cardiac Care Technology', label: 'M.Voc Cardiac Care Technology' },
            { value: 'M.Design', label: 'M.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'M.Sc', label: 'M.Sc' },
            { value: 'MBA', label: 'MBA' },
            { value: 'M.Arch', label: 'M.Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'MA', label: 'MA' },
            { value: 'MCA', label: 'MCA' },
            { value: 'M Com', label: 'M Com' },
            { value: 'MEd', label: 'MEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'MFM(Financial Management)', label: 'MFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'MHM', label: 'MHM' },
            { value: 'MD/MS(Medical)', label: 'MD/MS(Medical)' },
            { value: 'MPT', label: 'MPT' },
            { value: 'MLIS', label: 'MLIS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'MSc Computer Science', label: 'MSc Computer Science' },
            { value: 'MSc IT', label: 'MSc IT' },
            { value: 'MS(Engg)', label: 'MS(Engg)' },
            { value: 'PGDCA', label: 'PGDCA' },
            { value: 'M Phill', label: 'M Phill' },
            { value: 'MSW', label: 'MSW' },
            { value: 'PGDM', label: 'PGDM' },
            { value: 'MDS', label: 'MDS' },
            { value: 'MHMS', label: 'MHMS' },
            { value: 'M Tech', label: 'M Tech' },
            { value: 'ME', label: 'ME' }
        ],
        "Bachelors Degree": [
            { value: 'IRS', label: 'IRS' },
            { value: 'BAMS', label: 'BAMS' },
            { value: 'Bachelor of Veterinary Science', label: 'Bachelor of Veterinary Science' },
            { value: 'B Pharm', label: 'B Pharm' },
            { value: 'BSc Nursing', label: 'BSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'Bachelor of Law', label: 'Bachelor of Law' },
            { value: 'LLB', label: 'LLB' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA Intern', label: 'CA Intern' },
            { value: 'CA', label: 'CA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'Finance', label: 'Finance' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'BHMS', label: 'BHMS' },
            { value: 'IES', label: 'IES' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'BUMS', label: 'BUMS' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'BSc Biotechnology', label: 'BSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'BSc Visual Communication', label: 'BSc Visual Communication' },
            { value: 'BSc Radiology', label: 'BSc Radiology' },
            { value: 'B.Voc Cardiac Care Technology', label: 'B.Voc Cardiac Care Technology' },
            { value: 'B.Design', label: 'B.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'BPT', label: 'BPT' },
            { value: 'BBA', label: 'BBA' },
            { value: 'B Arch', label: 'B Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'BA', label: 'BA' },
            { value: 'BCom', label: 'BCom' },
            { value: 'BCA', label: 'BCA' },
            { value: 'BEd', label: 'BEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'BGL', label: 'BGL' },
            { value: 'BFM(Financial Management)', label: 'BFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'BHM', label: 'BHM' },
            { value: 'MBBS', label: 'MBBS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'BLIS', label: 'BLIS' },
            { value: 'BSc', label: 'BSc' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'BFA', label: 'BFA' },
            { value: 'B Plan', label: 'B Plan' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'BSc Computer Science', label: 'BSc Computer Science' },
            { value: 'BSc IT', label: 'BSc IT' },
            { value: 'Bachelor of Engineering', label: 'Bachelor of Engineering' },
            { value: 'B phil', label: 'B phil' },
            { value: 'BSW', label: 'BSW' },
            { value: 'BDS', label: 'BDS' },
            { value: 'BTech', label: 'BTech' },
            { value: 'BE', label: 'BE' }
        ],
        "Diploma": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Trade School/TTC/ITI": [
            { value: 'TTC', label: 'TTC' },
            { value: 'ITI', label: 'ITI' }
        ],
        "Other": [
            { value: 'Other Course', label: 'Other Course' },
        ]
    };

    const states = [
        { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
        { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
        { value: 'Assam', label: 'Assam' },
        { value: 'Bihar', label: 'Bihar' },
        { value: 'Chhattisgarh', label: 'Chhattisgarh' },
        { value: 'Goa', label: 'Goa' },
        { value: 'Gujarat', label: 'Gujarat' },
        { value: 'Haryana', label: 'Haryana' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Karnataka', label: 'Karnataka' },
        { value: 'Kerala', label: 'Kerala' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Manipur', label: 'Manipur' },
        { value: 'Meghalaya', label: 'Meghalaya' },
        { value: 'Mizoram', label: 'Mizoram' },
        { value: 'Nagaland', label: 'Nagaland' },
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Punjab', label: 'Punjab' },
        { value: 'Rajasthan', label: 'Rajasthan' },
        { value: 'Sikkim', label: 'Sikkim' },
        { value: 'Tamil Nadu', label: 'Tamil Nadu' },
        { value: 'Telangana', label: 'Telangana' },
        { value: 'Tripura', label: 'Tripura' },
        { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
        { value: 'Uttarakhand', label: 'Uttarakhand' },
        { value: 'West Bengal', label: 'West Bengal' },
        { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
        { value: 'Chandigarh', label: 'Chandigarh' },
        { value: 'Lakshadweep', label: 'Lakshadweep' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Puducherry', label: 'Puducherry' },
        { value: 'Ladakh', label: 'Ladakh' },
        { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' }
    ];

    const handleEducationChange = (e) => {
        setSelectedEducation(e.target.value);
        setSelectedCourse('');
    };

    const courses = coursesByEducation[selectedEducation] || [];

    const handleEducationalDetails = () => {
        console.log(selectedEducation, phoneNumber, secondaryPhoneNumber, selectedPreferedContactType, presentnationality);
        if (
            !selectedEducation ||
            !phoneNumber ||
            !secondaryPhoneNumber ||
            !selectedPreferedContactType ||
            !presentnationality
        ) {
            toast.error("Please Enter Required Fields");
            return;
        }

        navigate('/religious', {
            state: {
                height,
                weight,
                maritalStatus,
                bodyType,
                physicallyChallenged,
                complexion,
                ethnicGroup,
                highestEducation: selectedEducation,
                course: selectedCourse,
                professionType: selectedProfession,
                profession: selectedOccupation,
                homeCountry: nationality,
                homeDistrict: district,
                homeState: state,
                homePlace: place,
                presentCountry: presentnationality,
                presentDistrict,
                presentState,
                presentPlace,
                preferredContactType: selectedPreferedContactType,
                secondaryNumber: secondaryPhoneNumber,
                primaryNumber: phoneNumber,
            }
        });
    };

    return (
        <div id="smooth-wrapper" className="mil-wrapper">
            <div className="progress-wrap active-progress"></div>
            <Header />
            <div id="smooth-content">
                <div className="login-container mil-features mil-p-100-80" style={{ padding: '150px 0 150px 0' }}>
                    <div className="container">
                        <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                            <div className="col-xl-7 reg-form">
                                <div className="form-container">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="education">Highest Education *</label>
                                                <select className="form-control" id="education" name="education"
                                                    value={selectedEducation}
                                                    onChange={handleEducationChange}>
                                                    <option value="" disabled selected>Select Education</option>
                                                    <option value="Doctorate">Doctorate</option>
                                                    <option value="High/Secondary School">High/Secondary School</option>
                                                    <option value="Diploma">Diploma</option>
                                                    <option value="Bachelors Degree">Bachelors Degree</option>
                                                    <option value="Masters Degree">Masters Degree</option>
                                                    <option value="PhD">PhD</option>
                                                    <option value="Trade School/TTC/ITI">Trade School/TTC/ITI</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        {selectedEducation && (coursesByEducation[selectedEducation]) && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="course">Course</label>
                                                    <select className="form-control" id="course" name="course"
                                                        value={selectedCourse}
                                                        onChange={(e) => setSelectedCourse(e.target.value)}>
                                                        <option value="" disabled>Select Course</option>
                                                        {courses.map((course) => (
                                                            <option key={course.value} value={course.value}>
                                                                {course.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="profession">Profession *</label>
                                                <select className="form-control" id="profession" name="profession"
                                                    value={selectedOccupation}
                                                    onChange={(e) => setSelectedOccupation(e.target.value)}>
                                                    <option value="" disabled selected>Select Profession</option>
                                                    <option value="Not Working">Not Working</option>
                                                    <option value="Student">Student</option>
                                                    <option value="Non-Mainstream Professional">Non-Mainstream Professional</option>
                                                    <option value="Acting professional">Acting professional</option>
                                                    <option value="Administration Professional">Administration Professional</option>
                                                    <option value="Air Hostess">Air Hostess</option>
                                                    <option value="Doctor">Doctor</option>
                                                    <option value="Engineer">Engineer</option>
                                                    <option value="Teacher">Teacher</option>
                                                    <option value="Lawyer">Lawyer</option>
                                                    <option value="Accountant">Accountant</option>
                                                    <option value="Software Developer">Software Developer</option>
                                                    <option value="Architect">Architect</option>
                                                    <option value="Designer">Designer</option>
                                                    <option value="Writer">Writer</option>
                                                    <option value="Artist">Artist</option>
                                                    <option value="Musician">Musician</option>
                                                    <option value="Actor">Actor</option>
                                                    <option value="Salesperson">Salesperson</option>
                                                    <option value="Marketing Professional">Marketing Professional</option>
                                                    <option value="Finance Professional">Finance Professional</option>
                                                    <option value="Human Resources Professional">Human Resources Professional</option>
                                                    <option value="Customer Service Representative">Customer Service Representative</option>
                                                    <option value="Administrative Assistant">Administrative Assistant</option>
                                                    <option value="Technician">Technician</option>
                                                    <option value="Other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        {selectedOccupation === "Not Working" ||
                                            ("Student" && (
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="profession-type">Profession Type *</label>
                                                        <select className="form-control" id="profession-type" name="profession-type"
                                                            value={selectedProfession}
                                                            onChange={(e) => setSelectedProfession(e.target.value)}>
                                                            <option value="" disabled selected>Select Profession Type</option>
                                                            <option value="Student">Student</option>
                                                            <option value="Professional">Professional</option>
                                                            <option value="Business Owner">Business Owner</option>
                                                            <option value="Government Employee">Government Employee</option>
                                                            <option value="Homemaker">Homemaker</option>
                                                            <option value="Retired">Retired</option>
                                                            <option value="Other">Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            ))}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label for="home-location">Home Location *</label>
                                                <select className="form-control" id="home-location" name="home-location"
                                                    value={nationality}
                                                    onChange={(e) => setNationality(e.target.value)}>
                                                    <option value="" disabled selected>Select Home Location</option>
                                                    <option value="India">India</option>
                                                    <option value="north-america">North America</option>
                                                    <option value="south-america">South America</option>
                                                    <option value="europe">Europe</option>
                                                    <option value="asia">Asia</option>
                                                    <option value="africa">Africa</option>
                                                    <option value="australia">Australia</option>
                                                    <option value="antarctica">Antarctica</option>
                                                    <option value="middle-east">Middle East</option>
                                                    <option value="caribbean">Caribbean</option>
                                                    <option value="central-america">Central America</option>
                                                    <option value="west-africa">West African</option>
                                                    <option value="east-africa">East African</option>
                                                    <option value="north-africa">North African</option>
                                                    <option value="south-africa">South African</option>
                                                    <option value="indian-subcontinent">Indian Subcontinent</option>
                                                    <option value="southeast-asia">Southeast Asia</option>
                                                    <option value="far-east">Far East</option>
                                                    <option value="pacific-islands">Pacific Islands</option>
                                                    <option value="united-states">United States</option>
                                                    <option value="canada">Canada</option>
                                                    <option value="mexico">Mexico</option>
                                                    <option value="brazil">Brazil</option>
                                                    <option value="argentina">Argentina</option>
                                                    <option value="united-kingdom">United Kingdom</option>
                                                    <option value="france">France</option>
                                                    <option value="germany">Germany</option>
                                                    <option value="italy">Italy</option>
                                                    <option value="spain">Spain</option>
                                                    <option value="pakistan">Pakistan</option>
                                                    <option value="bangladesh">Bangladesh</option>
                                                    <option value="sri-lanka">Sri Lanka</option>
                                                    <option value="japan">Japan</option>
                                                    <option value="china">China</option>
                                                    <option value="south-korea">South Korea</option>
                                                    <option value="australia">Australia</option>
                                                    <option value="new-zealand">New Zealand</option>
                                                    <option value="nigeria">Nigeria</option>
                                                    <option value="ghana">Ghana</option>
                                                    <option value="kenya">Kenya</option>
                                                    <option value="ethiopia">Ethiopia</option>
                                                    <option value="egypt">Egypt</option>
                                                    <option value="morocco">Morocco</option>
                                                    <option value="saudi-arabia">Saudi Arabia</option>
                                                    <option value="uae">United Arab Emirates</option>
                                                    <option value="israel">Israel</option>
                                                    <option value="turkey">Turkey</option>
                                                    <option value="vietnam">Vietnam</option>
                                                    <option value="thailand">Thailand</option>
                                                    <option value="philippines">Philippines</option>
                                                    <option value="indonesia">Indonesia</option>
                                                    <option value="malaysia">Malaysia</option>
                                                    <option value="singapore">Singapore</option>
                                                    <option value="other">Other</option>
                                                </select>
                                            </div>
                                        </div>
                                        {nationality === 'India' && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="State">State *</label>
                                                    <select
                                                        className="form-control"
                                                        id="home-location"
                                                        name="home-location"
                                                        value={state}
                                                        onChange={(e) => setState(e.target.value)}
                                                    >
                                                        <option value="" disabled>Select State</option>
                                                        {states.map((stateItem) => (
                                                            <option key={stateItem.value} value={stateItem.value}>
                                                                {stateItem.label}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        )}
                                        {state && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="District">District *</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="district"
                                                        value={district}
                                                        onChange={(e) => setDistrict(e.target.value)}
                                                        placeholder="Enter District"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {district && (
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label for="Place">Place *</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="place"
                                                        value={place}
                                                        onChange={(e) => setPlace(e.target.value)}
                                                        placeholder="Enter Place"
                                                    />
                                                </div>
                                                <div style={{display:'flex'}}>
                                                    <input
                                                        type="checkbox"
                                                        checked={sameLocation}
                                                        style={{ marginRight: "10px" }}
                                                        onChange={(e) => {
                                                            setSameLocation(e.target.checked);
                                                            if (e.target.checked) {
                                                                setPresentNationality(nationality);
                                                                setPresentState(state);
                                                                setPresentDistrict(district);
                                                                setPresentPlace(place);
                                                            } else {
                                                                setPresentNationality("");
                                                                setPresentState("");
                                                                setPresentDistrict("");
                                                                setPresentPlace("");
                                                            }
                                                        }}
                                                    />
                                                    <p style={{ fontSize: "12px" }}>Present Location Similiar as Home Location</p>
                                                </div>
                                            </div>
                                        )}

                                        {!sameLocation && (
                                            <>
                                                <div className="col-md-6">
                                                    <div className="form-group">
                                                        <label for="present-location">Present Location *</label>
                                                        <select className="form-control" id="present-location" name="present-location"
                                                            value={presentnationality}
                                                            onChange={(e) => setPresentNationality(e.target.value)}>
                                                            <option value="" disabled selected>Select Present location</option>
                                                            <option value="India">India</option>
                                                            <option value="north-america">North America</option>
                                                            <option value="south-america">South America</option>
                                                            <option value="europe">Europe</option>
                                                            <option value="asia">Asia</option>
                                                            <option value="africa">Africa</option>
                                                            <option value="australia">Australia</option>
                                                            <option value="antarctica">Antarctica</option>
                                                            <option value="middle-east">Middle East</option>
                                                            <option value="caribbean">Caribbean</option>
                                                            <option value="central-america">Central America</option>
                                                            <option value="west-africa">West African</option>
                                                            <option value="east-africa">East African</option>
                                                            <option value="north-africa">North African</option>
                                                            <option value="south-africa">South African</option>
                                                            <option value="indian-subcontinent">Indian Subcontinent</option>
                                                            <option value="southeast-asia">Southeast Asia</option>
                                                            <option value="far-east">Far East</option>
                                                            <option value="pacific-islands">Pacific Islands</option>
                                                            <option value="united-states">United States</option>
                                                            <option value="canada">Canada</option>
                                                            <option value="mexico">Mexico</option>
                                                            <option value="brazil">Brazil</option>
                                                            <option value="argentina">Argentina</option>
                                                            <option value="united-kingdom">United Kingdom</option>
                                                            <option value="france">France</option>
                                                            <option value="germany">Germany</option>
                                                            <option value="italy">Italy</option>
                                                            <option value="spain">Spain</option>
                                                            <option value="pakistan">Pakistan</option>
                                                            <option value="bangladesh">Bangladesh</option>
                                                            <option value="sri-lanka">Sri Lanka</option>
                                                            <option value="japan">Japan</option>
                                                            <option value="china">China</option>
                                                            <option value="south-korea">South Korea</option>
                                                            <option value="australia">Australia</option>
                                                            <option value="new-zealand">New Zealand</option>
                                                            <option value="nigeria">Nigeria</option>
                                                            <option value="ghana">Ghana</option>
                                                            <option value="kenya">Kenya</option>
                                                            <option value="ethiopia">Ethiopia</option>
                                                            <option value="egypt">Egypt</option>
                                                            <option value="morocco">Morocco</option>
                                                            <option value="saudi-arabia">Saudi Arabia</option>
                                                            <option value="uae">United Arab Emirates</option>
                                                            <option value="israel">Israel</option>
                                                            <option value="turkey">Turkey</option>
                                                            <option value="vietnam">Vietnam</option>
                                                            <option value="thailand">Thailand</option>
                                                            <option value="philippines">Philippines</option>
                                                            <option value="indonesia">Indonesia</option>
                                                            <option value="malaysia">Malaysia</option>
                                                            <option value="singapore">Singapore</option>
                                                            <option value="other">Other</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {presentnationality === 'India' && (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="State">Present State *</label>
                                                            <select
                                                                className="form-control"
                                                                id="home-location"
                                                                name="home-location"
                                                                value={presentState}
                                                                onChange={(e) => setPresentState(e.target.value)}
                                                            >
                                                                <option value="" disabled>Select Present State</option>
                                                                {states.map((stateItem) => (
                                                                    <option key={stateItem.value} value={stateItem.value}>
                                                                        {stateItem.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                )}
                                                {presentState && (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="District">Present District *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="district"
                                                                value={presentDistrict}
                                                                onChange={(e) => setPresentDistrict(e.target.value)}
                                                                placeholder="Enter Present District"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                                {presentDistrict && (
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label for="Place">Present Place *</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="place"
                                                                value={presentPlace}
                                                                onChange={(e) => setPresentPlace(e.target.value)}
                                                                placeholder="Enter Present Place"
                                                            />
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="mobile">Primary Number *</label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    id="mobile"
                                                    value={phoneNumber}
                                                    onChange={(e) => setPhoneNumber(e.target.value)}
                                                    placeholder="Enter Phone Number"
                                                    pattern="\d{10}"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="mobile">Secondary Number *</label>
                                                <input
                                                    type="tel"
                                                    className="form-control"
                                                    id="mobile"
                                                    value={secondaryPhoneNumber}
                                                    onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                                                    placeholder="Enter Phone Number"
                                                    pattern="\d{10}"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="education">Prefered Contact Type *</label>
                                                <select className="form-control" id="education" name="education"
                                                    value={selectedPreferedContactType}
                                                    onChange={(e) => setSelectedPreferedContactType(e.target.value)}>
                                                    <option value="" disabled selected>Select Prefered Contact Type</option>
                                                    <option value="Call my primary number">Call my primary number</option>
                                                    <option value="Call my secondary number">Call my secondary number</option>
                                                    <option value="Call me on primary or secondary number">Call me on primary or secondary number</option>
                                                    <option value="Message me here(in-app)">Message me here (in-app)</option>
                                                    <option value="Whatsapp me on primary number">WhatsApp me on primary number</option>
                                                    <option value="WhatsApp me secondary number">WhatsApp me on secondary number</option>
                                                    <option value="Text my primary number">Text my primary number</option>
                                                    <option value="Text my secondary number">Text my secondary number</option>
                                                    <option value="No preference">No preference</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-12 mt-4">
                                            <button
                                                type="submit"
                                                className="button primary-button w-100"
                                                onClick={handleEducationalDetails}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5">
                                <div className="mil-image-frame">
                                    <img src={homeImage} alt="Login" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Register;
