import React, { useEffect, useState } from 'react';
import profileImage1 from '../../../assets/img/profiles/woman1.jpg';
import { Link } from 'react-router-dom';
import imgEmpty from '../../../assets/img/illustrations/illustration-upgrade-account.png';
import { API_URL } from '../../../assets/Constants';
import axios from 'axios';
import toast from 'react-hot-toast';

const VisitedProfiles = () => {
    const [profiles, setProfiles] = useState([]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.get(`${API_URL}/api/profileVisits`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setProfiles(response.data.visitedProfiles || []);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const removeVisit = async (id) =>{
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(`${API_URL}/api/profileVisits/${id}/delete`,{}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchData();
                toast.success('Profile visit deleted')
            }
        } catch (error) {
            console.log(error);
            toast.error('an error occurred !!')
        }
    }
    return (
        <div className="demo-inline-spacing mt-4">
           {profiles.length > 0 ? (
                <div className="list-group">
                    {profiles.map((profile) => (
                        <div
                            key={profile.id}
                            className="list-group-item list-group-item-action d-flex align-items-center cursor-pointer waves-effect"
                        >
                            <img
                                src={profile.photo || profileImage1}
                                alt={profile.name}
                                className="rounded-circle me-3"
                                width="120"
                            />
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="user-info">
                                        <h6 className="mb-1">{profile.name}</h6>
                                        <div>
                                            <p className="mb-1">{profile.age}, {profile.height}</p>
                                            <p className="mb-1">Profile ID  : {profile.profileId}</p>
                                        </div>
                                        <small className="text-muted">Visited On: {profile.visited_on}</small>
                                    </div>
                                    <div className="add-btn">
                                        <button
                                            className="btn btn-danger btn-sm waves-effect waves-light"
                                            onClick={ () => removeVisit(profile.id) }
                                        >
                                            <i className="ri ri-close-circle-line me-2"></i>
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="misc-wrapper border rounded">
                    <div className="text-center">
                        <h4 className="mb-2">List is empty</h4>
                        <p className="mb-6 mx-2">We couldn't find any accepted profiles</p>
                    </div>
                    <div className="d-flex justify-content-center mt-9">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src={imgEmpty}
                                alt="misc-error"
                                className="img-fluid z-1"
                                width="150"
                            />
                            <div>
                                <Link to="/home">
                                    <button className="btn btn-primary btn-sm text-center my-10 waves-effect waves-light">
                                        Back to home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default VisitedProfiles;

