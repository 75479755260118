import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const EducationDetailsModal = ({ details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    // options
    const education = [
        'Doctorate',
        'High/Secondary School', 
        'Diploma',
        'Bachelors Degree',
        'Masters Degree',
        'PhD',
        'Trade School/TTC/ITI',
        'Other',
    ];
    const occupation = [
        'Not Working',
        'Student',
        'Non-Mainstream Professional',
        'Acting professional',
        'Administration Professional',
        'Air Hostess', 
        'Doctor',
        'Engineer',
        'Teacher',
        'Lawyer',
        'Accountant',
        'Software Developer',
        'Architect',
        'Designer',
        'Writer',
        'Artist',
        'Musician', 
        'Actor',
        'Salesperson',
        'Marketing Professional',
        'Finance Professional', 
        'Human Resources Professional',
        'Customer Service Representative', 
        'Administrative Assistant',
        'Technician',
    ];
    const professionTypes = [
       'Student',
       'Full Time',
       'Part Time',
       'Professional',
       'Business Owner',
       'Government Employee',
       'Homemaker',
       'Retired',
       'Other',
       'Prefer Not to Say',
    ];
    const incomeOptions = [
       'Student',
       'No Income',
       'Less than 50000',
       '50000 - 1 lakhs',
       '1 - 2 lakhs',
       '2 - 3 lakhs',
       '3 - 5 lakhs',
       '5 - 10 lakhs',
       '10 - 15 lakhs',
       '15 lakhs and above',
       'Prefer Not to Say',
    ];
    const coursesByEducation = {
        "Doctorate": [
            'Science', 
            'Agriculture',
            'Mass Communication',
            'D Pharm',
            'Draftman',
            'Religious Education',
            'Other Education', 
            'Nursing',
            'Medicine - Other',
            'Administrative Services', 
            'Social Works',
            'Philosophy',
            'Aeronautical Engineering',
            'Fine Arts', 
            'Travel & Tourism',
            'Shipping', 
            'Advertising/Marketing', 
            'Office Administration', 
            'Paramedical', 
            'Medicine - Allopathic',
            'Law',
            'Home Science',
            'Finance',
            'Fashion', 
            'Education', 
            'Computers/IT',
            'Commerce', 
            'Arts', 
            'Armed Forces', 
            'Architecture',
            'Administration/Management', 
            'Engineering/Technology',
        ],

        "Masters Degree": [
            'IES', 
            'Master of Veterinary Science', 
            'MSc Nursing',
            'Medical Laboratory Technology', 
            'M Pharm', 
            'Master of Law', 
            'LLM', 
            'Religious Education',
            'Other Education',
            'Course in Legal', 
            'CA',
            'ICWA',
            'Company Secretary(CS)',
            'CFA(Chartered Financial Analyst)', 
            'IAS',
            'IPS',
            'IRS',
            'MAMS', 
            'IFS',
            'Civil Service',
            'MUMS',
            'Pharm D', 
            'Audiology',
            'MSc Biotechnology',
            'ACCA',
            'MSc Visual Communication', 
            'Msc Radiology',
            'M.Voc Cardiac Care Technology', 
            'M.Design', 
            'Agriculture',
            'M.Sc',
            'MBA',
            'M.Arch',
            'Armed Forces',
            'MA', 
            'MCA',
            'M Com', 
            'MEd', 
            'Fashion',
            'MFM(Financial Management)',
            'Home Science', 
            'MHM', 
            'MD/MS(Medical)',
            'MPT', 
            'MLIS',
            'Advertising/Marketing',
            'Shipping',
            'Fine Arts', 
            'Travel & Tourism',
            'Finance',
            'Aeronautical Engineering',
            'MSc Computer Science',
            'MSc IT', 
            'MS(Engg)',
            'PGDCA', 
            'M Phill',
            'MSW', 
            'PGDM',
            'MDS', 
            'MHMS', 
            'M Tech',
            'ME', 
        ],
        "Bachelors Degree": [
            'IRS',
            'BAMS',
            'Bachelor of Veterinary Science',
            'B Pharm',
            'BSc Nursing',
            'Medical Laboratory Technology',
            'Bachelor of Law',
            'LLB',
            'Course in Legal',
            'CA Intern',
            'CA',
            'Company Secretary(CS)',
            'CFA(Chartered Financial Analyst)',
            'Finance',
            'IAS',
            'IPS',
            'BHMS',
            'IES',
            'IFS',
            'Civil Service',
            'Other Education',
            'BUMS',
            'Religious Education',
            'Audiology',
            'BSc Biotechnology',
            'ACCA',
            'BSc Visual Communication',
            'BSc Radiology',
            'B.Voc Cardiac Care Technology',
            'B.Design',
            'Agriculture',
            'BPT',
            'BBA',
            'B Arch',
            'Armed Forces',
            'BA',
            'BCom',
            'BCA',
            'BEd',
            'Fashion',
            'BGL',
            'BFM(Financial Management)',
            'Home Science',
            'BHM',
            'MBBS',
            'Advertising/Marketing',
            'BLIS',
            'BSc',
            'Shipping',
            'Travel & Tourism',
            'BFA',
            'B Plan',
            'Aeronautical Engineering',
            'BSc Computer Science',
            'BSc IT',
            'Bachelor of Engineering',
            'B phil',
            'BSW',
            'BDS',
            'BTech',
            'BE'
        ],
        "Diploma": [
            'Science',
            'Agriculture',
            'Mass Communication',
            'D Pharm',
            'Draftman',
            'Religious Education',
            'Other Education',
            'Nursing',
            'Medicine - Other',
            'Administrative Services',
            'Social Works',
            'Philosophy',
            'Aeronautical Engineering',
            'Fine Arts',
            'Travel & Tourism',
            'Shipping',
            'Advertising/Marketing',
            'Office Administration',
            'Paramedical',
            'Medicine - Allopathic',
            'Law',
            'Home Science',
            'Finance',
            'Fashion',
            'Education',
            'Computers/IT',
            'Commerce',
            'Arts',
            'Armed Forces',
            'Architecture',
            'Administration/Management',
            'Engineering/Technology'
        ],

        "Trade School/TTC/ITI": [
            'TTC',
            'ITI'
        ]

    };

    Object.freeze(coursesByEducation);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        if (name === "highestEducation") {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
                course: "", // Reset course
            }));
        } else {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    };

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/educationDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };

    return (
        <div
            className="modal fade show"
            id="educationDetailsModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Education Details
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="highestEducation"
                                        value={formData.highestEducation || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Highest Education</option>
                                        {education.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="highestEducation">Highest Education</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="course"
                                        value={formData.course || ""}
                                        onChange={handleInputChange}
                                        disabled={!formData.highestEducation}
                                    >
                                        <option value="">Select Course</option>
                                        {formData.highestEducation &&
                                            coursesByEducation[formData.highestEducation]?.map((option) => (
                                                <option key={option} value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                    </select>
                                    <label htmlFor="course">Course</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="educationInstitute"
                                        value={formData.educationInstitute || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="educationInstitute">Education Institute</label>
                                </div>
                            </div>          
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="educationDetails"
                                        value={formData.educationDetails || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="educationDetails">Education Details</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="profession"
                                        value={formData.profession || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Profession</option>
                                        {occupation.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="profession">Profession</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="professionType"
                                        value={formData.professionType || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Profession Type</option>
                                        {professionTypes.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="professionType">Profession Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="companyName"
                                        value={formData.companyName || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="companyName">Company Name</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="jobDetails"
                                        value={formData.jobDetails || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="jobDetails">Job Details</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="annualIncome"
                                        value={formData.annualIncome || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Annual Income</option>
                                        {incomeOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="annualIncome">Annual Income</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EducationDetailsModal;
