import React, {useEffect, useState} from "react";
import ProfileCard from "./ProfileCard";
import Skeleton from "react-loading-skeleton";
import toast from "react-hot-toast";
import axios from "axios";
import { API_URL } from "../../assets/Constants";


const ProfileList = () => {
    const [profiles, setProfiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPageUrl, setNextPageUrl] = useState(null);
    const [previousPageUrl, setPreviousPageUrl] = useState(null);
    const [totalPages, setTotalPages] = useState(null);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.get(`${API_URL}/api/web/homepage/profiles?page=${currentPage}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if(response.status === 200) {
                setProfiles(response.data.data);
                setNextPageUrl(response.data.next_page);
                setPreviousPageUrl(response.data.prev_page);
                setTotalPages(response.data.total_pages);
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        setProfiles([]);
        fetchData();
    },[currentPage]);

    return(
        <div className="col-lg-9 col-xl-9 col-xxl-9 col-md-12 col-sm-12">
            <div className="row">
                <div className="col-12 text-end">
                    <nav aria-label="Page navigation">
                    <ul className="pagination pagination-rounded pagination-outline-primary justify-content-end">
                        <li className="page-item prev" onClick={previousPageUrl ? () => setCurrentPage(currentPage - 1) : undefined}>
                            <a className="page-link" href="#!"><i className="tf-icon ri-arrow-left-s-line ri-20px"></i></a>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i} className="page-item" onClick={() => setCurrentPage(i + 1)}>
                                <a className={`page-link ${currentPage === i+1 ? 'border border-primary border-2' : ''}`} href="#!">{i + 1}</a>
                            </li>
                        ))}
                        <li className="page-item next" onClick={nextPageUrl ? () => setCurrentPage(currentPage + 1) : undefined}>
                            <a className="page-link" href="#!"><i className="tf-icon ri-arrow-right-s-line ri-20px"></i></a>
                        </li>
                    </ul>
                    </nav>
                </div>
                {
                    profiles.length > 0 ? (
                        profiles.map((profileObj, index) => {
                            return <ProfileCard key={index}
                                        id={profileObj.id}
                                        name={`${profileObj.name} (${profileObj.profileId})`}
                                        ageAndGender={`${profileObj.age} yrs, ${profileObj.height} cm, ${profileObj.gender}`} 
                                        place={`${profileObj.homeCity}, ${profileObj.homeDistrict}, ${profileObj.homeCountry}`}
                                        religion={`${profileObj.religion}, ${profileObj.community}`}
                                        education={profileObj.education}
                                        profileImage={profileObj.photo}
                                        profileId={profileObj.profileId}
                                        interestSent={profileObj.interestSent}
                                        interestReceived={profileObj.interestReceived}
                                        shortlistStatus={profileObj.shortlistStatus}
                                        fetchData={fetchData}

                                    />
                        })
                    ) : (
                        <div className="row mb-5">
                            <div className="col-6">
                                <Skeleton count={5} height={150} style={{ marginBottom: 10, borderRadius:'3%' }}/>
                            </div>
                            <div className="col-6">
                                <Skeleton count={5} height={150} style={{ marginBottom: 10, borderRadius:'3%' }}/>
                            </div>
                        </div>
                    )
                }
                
            </div>
            <div>
                <nav aria-label="Page navigation">
                    <ul className="pagination pagination-rounded pagination-outline-primary justify-content-end">
                        <li className="page-item prev" onClick={previousPageUrl ? () => setCurrentPage(currentPage - 1) : undefined}>
                            <a className="page-link" href="#!"><i className="tf-icon ri-arrow-left-s-line ri-20px"></i></a>
                        </li>
                        {Array.from({ length: totalPages }, (_, i) => (
                            <li key={i} className="page-item" onClick={() => setCurrentPage(i + 1)}>
                                <a className={`page-link ${currentPage === i+1 ? 'border border-primary border-2' : ''}`} href="#!">{i + 1}</a>
                            </li>
                        ))}
                        <li className="page-item next" onClick={nextPageUrl ? () => setCurrentPage(currentPage + 1) : undefined}>
                            <a className="page-link" href="#!"><i className="tf-icon ri-arrow-right-s-line ri-20px"></i></a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default ProfileList;