import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import image1 from '../assets/img/illustrations/auth-login-illustration-light.png';
import image2 from '../assets/img/illustrations/auth-cover-login-mask-light.png';
import logo from '../assets/img/logo.png';
import '../assets/vendor/css/pages/page-auth.css';
import { API_URL } from '../assets/Constants';
import toast from 'react-hot-toast';

const SendOtp = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [errorMessage, setErrorMessage] = useState('');
    const [otpSent, setOtpSent] = useState(false);

    const handleSendOtp = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_URL}/api/login/sendOtp`, {
                email: email,
            });
            console.log(response);
            setOtpSent(true);
        } catch (error) {
            setErrorMessage('An error occurred. Please try again later.');
        }
    };

    const handleChangeOtp = (element, index) => {
        if (isNaN(element.value)) return;
        const newOtp = [...otp];
        newOtp[index] = element.value;
        setOtp(newOtp);

        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleVerifyOtp = async () => {
        try {
            if (otp.length !== 6 || otp.some((digit) => isNaN(digit))) {
                toast.error('Please enter a valid 6-digit OTP.');
                return;
            }
    
            const response = await axios.post(`${API_URL}/api/login/verifyOtp`, {
                email: email,
                otp: otp.join(''),
            });
    
            localStorage.setItem('token', response.data.token); 
            toast.success('Logged in Successfully!');
            
            setOtp([]);
            setErrorMessage('');
            navigate('/home');
            
        } catch (error) {
            if (error.response && error.response.status === 401) {
                toast.error('Invalid OTP. Please try again.');
                setErrorMessage('Invalid OTP. Please try again.');
            } else if (error.response && error.response.status === 404) {
                toast.error('OTP not found or expired.');
                setErrorMessage('OTP not found or expired.');
            } else {
                toast.error('Something went wrong. Please try again later.');
                setErrorMessage('Something went wrong. Please try again later.');
            }
        }
    };
    


    return (
        <div className="authentication-wrapper authentication-cover">
            <a href="#!" className="auth-cover-brand d-flex align-items-center gap-2">
                <img src={logo} width={80} alt="logo" />
            </a>
            <div className="authentication-inner row m-0">
                <div className="d-none d-lg-flex col-lg-7 col-xl-8 align-items-center justify-content-center p-12 pb-2">
                    <img src={image1} className="auth-cover-illustration w-100" alt="auth-illustration" data-app-light-img={image1} data-app-dark-img="" />
                    <img src={image2} className="authentication-image" alt="mask" data-app-light-img={image2} data-app-dark-img="" />
                </div>
                <div className="d-flex col-12 col-lg-5 col-xl-4 align-items-center authentication-bg position-relative py-sm-12 px-12 py-6">
                    <div className="w-px-400 mx-auto pt-5 pt-lg-0">
                        <h4 className="mb-1">Welcome to Step to Nikah! 👋</h4>
                        <p className="mb-5">Please sign-in to your account and find your partner</p>
                        <form id="formAuthentication" className="mb-5" onSubmit={handleSendOtp}>
                            {/* Email input and OTP request button */}
                            <input 
                                type="email" 
                                className="form-control" 
                                placeholder="Enter your email" 
                                value={email} 
                                onChange={(e) => setEmail(e.target.value)} 
                            />
                            <button className="btn btn-primary d-grid w-100 mt-3" type="submit">
                                Send OTP
                            </button>
                        </form>
                        
                        {/* OTP input and verification button */}
                        {otpSent && (
                            <div className="mb-5 mt-3">
                                <div className="auth-input-wrapper d-flex align-items-center justify-content-between">
                                    {otp.map((data, index) => (
                                        <input
                                            key={index}
                                            type="tel"
                                            className="form-control text-center mx-sm-1 my-2"
                                            maxLength="1"
                                            value={data}
                                            onChange={(e) => handleChangeOtp(e.target, index)}
                                            autoFocus={index === 0}
                                        />
                                    ))}
                                </div>
                                <button className="btn btn-primary d-grid w-100 mt-3" onClick={handleVerifyOtp}>
                                    Verify OTP
                                </button>
                            </div>
                        )}
                        
                        {errorMessage && (
                            <div className="alert alert-danger mt-2">
                                {errorMessage}
                            </div>
                        )}
                        <div className="divider my-5">
                            <div className="divider-text">or</div>
                        </div>

                        <div className="d-flex justify-content-center gap-2">
                            <button className="btn btn-primary btn-sm d-grid w-100">
                                Sign in with Username
                            </button>
                            <button className="btn btn-danger btn-sm d-grid w-100">
                                Sign in with Google
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendOtp;
