import React, { useEffect, useState } from "react";
import EducationDetailsModal from "./Modals/EducationModal";

const EducationDetails = ({ educationDetails : initialEducationDetails}) => {
    const [showModal, setShowModal] = useState(false);
    const [educationDetails, setEducationDetails] = useState(initialEducationDetails);

    useEffect(() => {
        setEducationDetails(initialEducationDetails);
    }, [initialEducationDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setEducationDetails(prev => ({
            ...prev,
            highestEducation: updatedDetails.highestEducation,
            course: updatedDetails.course,
            educationInstitute: updatedDetails.educationInstitute,
            educationDetails: updatedDetails.educationDetails,
            profession: updatedDetails.profession,
            professionType: updatedDetails.professionType,
            companyName: updatedDetails.companyName,
            jobDetails: updatedDetails.jobDetails,
            annualIncome: updatedDetails.annualIncome,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Education Information</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table class="table border">
                <tbody>
                        <tr>
                            <th>Education</th>
                            <td>: {educationDetails?.highest_education || 'N/A'} ({educationDetails?.qualification || 'N/A'})</td>
                            <th style={{ whiteSpace: "normal" }}>Education Institute</th>
                            <td style={{ whiteSpace: "normal" }}>: {educationDetails?.education_institute || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Education Details</th>
                            <td>: {educationDetails?.education_details || 'N/A'}</td>
                            <th>Profession</th>
                            <td>: {educationDetails?.profession || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Profession Type</th>
                            <td>: {educationDetails?.profession_type || 'N/A'}</td>
                            <th>Company Name</th>
                            <td>: {educationDetails?.company_name || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Job Details</th>
                            <td>: {educationDetails?.job_details || 'N/A'}</td>
                            <th>Annual Income</th>
                            <td>: {educationDetails?.annual_income || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <EducationDetailsModal
                    details={{
                        highestEducation: educationDetails?.highest_education,
                        course: educationDetails?.qualification,
                        educationInstitute: educationDetails?.education_institute,
                        educationDetails: educationDetails?.education_details,
                        profession: educationDetails?.profession,
                        professionType: educationDetails?.profession_type,
                        companyName: educationDetails?.company_name,
                        jobDetails: educationDetails?.job_details,
                        annualIncome: educationDetails?.annual_income,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default EducationDetails;