import React from "react";

const EducationDetails = ({ educationDetails }) => {
    return (
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Education Information</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Education</th>
                            <td>: {educationDetails?.highest_education || 'N/A'} ({educationDetails?.qualification || 'N/A'})</td>
                            <th style={{ whiteSpace: "normal" }}>Education Institute</th>
                            <td style={{ whiteSpace: "normal" }}>: {educationDetails?.education_institute || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Education Details</th>
                            <td>: {educationDetails?.education_details || 'N/A'}</td>
                            <th>Profession</th>
                            <td>: {educationDetails?.profession || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Profession Type</th>
                            <td>: {educationDetails?.profession_type || 'N/A'}</td>
                            <th>Company Name</th>
                            <td>: {educationDetails?.company_name || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Job Details</th>
                            <td>: {educationDetails?.job_details || 'N/A'}</td>
                            <th>Annual Income</th>
                            <td>: {educationDetails?.annual_income || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default EducationDetails;
