import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../assets/Constants';
import toast from 'react-hot-toast';

function IdSearch() {
    const [profileId, setProfileId] = useState('');
    const navigate = useNavigate();

    const profileSearch = async () => {
        if (!profileId) {
            toast.error('Please enter a Profile ID!');
            return;
        }
    
        try {
            const token = localStorage.getItem("token") || '';
    
            const response = await axios.get(`${API_URL}/api/web/profile/searchById`, {
                params: { profileId },
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            if (response.status === 200 && response.data.status) {
                localStorage.setItem("profile-details", profileId);
                toast.success('Profile found !!');
                navigate("/profile-view");
            } else {
                toast.error(response.data.message || 'Incorrect Id !!');
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                toast.error(error.response.data.message || 'Incorrect Id !!');
            } else {
                toast.error('Some error occurred !!');
            }
        }
    };

    return (
        <div className="tab-pane fade" id="nav-search-id" role="tabpanel">
            <h5 className="card-header">Search Profile by ID</h5>
            <div className="card-body">
                <div className="form-floating form-floating-outline">
                    <input
                        type="text"
                        className="form-control"
                        id="profileId"
                        placeholder="STN123456"
                        value={profileId}
                        onChange={(e) => setProfileId(e.target.value)}
                    />
                    <label htmlFor="profileId">Profile ID</label>
                </div>
                <div className='mt-5'>
                    <button className='btn btn-primary' type='button' onClick={profileSearch}>
                        <i className='ri ri-search-2-line me-2'></i>
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
}

export default IdSearch;
