import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditAppearance = () => {
    const location = useLocation();
    const { physicalDetails } = location.state;

    const [selectedHeight, setSelectedHeight] = useState(physicalDetails?.height);
    const [selectedWeight, setSelectedWeight] = useState(physicalDetails?.weight);
    const [selectedBodyType, setSelectedBodyType] = useState(physicalDetails?.body_type);
    const [selectedHair, setSelectedHair] = useState(physicalDetails?.hair_color);
    const [selectedEyes, setSelectedEyes] = useState(physicalDetails?.eye_color);
    const [selectedComplexion, setSelectedComplexion] = useState(physicalDetails?.skin_tone);
    const [selectedBloodGroup, setSelectedBloodGroup] = useState(physicalDetails?.blood_group);
    const [selectedHairType, setSelectedHairType] = useState(physicalDetails?.hair_type);
    const [selectedFacialHair, setSelectedFacialHair] = useState(physicalDetails?.facial_hair);
    const [selectedEyeWear, setSelectedEyeWear] = useState(physicalDetails?.eye_wear);

    const navigate = useNavigate();

    const heights = [];
    for (let i = 101; i <= 250; i++) {
        heights.push({ value: `${i} cm`, label: `${i} cm` });
    }

    const weights = [];
    for (let i = 40; i <= 140; i++) {
        weights.push({ value: `${i} Kg`, label: `${i} Kg` });
    }

    const bodyTypes = [
        { value: 'Slim', label: 'Slim' },
        { value: 'Average', label: 'Average' },
        { value: 'Athletic', label: 'Athletic' },
        { value: 'Heavy', label: 'Heavy' }
    ];

    const hairs = [
        { value: 'Black', label: 'Black' },
        { value: 'Brown', label: 'Brown' },
        { value: 'Blonde', label: 'Blonde' },
        { value: 'Gray', label: 'Gray' },
        { value: 'White', label: 'White' },
        { value: 'Salt and pepper', label: 'Salt and pepper' },
        { value: 'Other', label: 'Other' }
    ];

    const eyes = [
        { value: 'Black', label: 'Black' },
        { value: 'Brown', label: 'Brown' },
        { value: 'Blue', label: 'Blue' },
        { value: 'Green', label: 'Green' },
        { value: 'Hazel', label: 'Hazel' },
        { value: 'White', label: 'White' },
        { value: 'Other', label: 'Other' }
    ];

    const complexion = [
        { value: 'Fair', label: 'Fair' },
        { value: 'Wheatish', label: 'Wheatish' },
        { value: 'Dark', label: 'Dark' },
        { value: 'Fair to medium', label: 'Fair to medium' },
        { value: 'Medium to dark', label: 'Medium to dark' }
    ];

    const bloodGroup = [
        { value: 'A+', label: 'A+' },
        { value: 'A-', label: 'A-' },
        { value: 'AB+', label: 'AB+' },
        { value: 'AB-', label: 'AB-' },
        { value: 'B+', label: 'B+' },
        { value: 'B-', label: 'B-' },
        { value: 'O+', label: 'O+' },
        { value: 'O-', label: 'O-' }
    ];

    const hairType = [
        { value: 'Straight', label: 'Straight' },
        { value: 'Wavy', label: 'Wavy' },
        { value: 'Curly', label: 'Curly' },
        { value: 'Other', label: 'Other' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const facialHair = [
        { value: 'Clean Shaven', label: 'Clean Shaven' },
        { value: 'Sideburns', label: 'Sideburns' },
        { value: 'Mustache', label: 'Mustache' },
        { value: 'Goatee', label: 'Goatee' },
        { value: 'Short Beard', label: 'Short Beard' },
        { value: 'Medium Beard', label: 'Medium Beard' },
        { value: 'Long Beard', label: 'Long Beard' },
        { value: 'Other', label: 'Other' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const eyeWear = [
        { value: 'Contacts', label: 'Contacts' },
        { value: 'Glasses', label: 'Glasses' },
        { value: 'None', label: 'None' },
        { value: 'Other', label: 'Other' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const handlePhysic = async () => {
        const formatData = {
            height: selectedHeight,
            weight: selectedWeight,
            complexion: selectedComplexion,
            bodyType: selectedBodyType,
            bloodGroup: selectedBloodGroup,
            hairColor: selectedHair,
            hairtype: selectedHairType,
            facialHair: selectedFacialHair,
            eyeColor: selectedEyes,
            eyeWear: selectedEyeWear
        };

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.post('https://admin.steptonikah.com/api/profileUpdate/physicalDetails', formatData, config);

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/my-profile');
                }, 1500);
            }
        } catch (error) {
            console.error(error);
            toast.error(error.response?.data?.message || 'Something went wrong');
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Physical Details</h2>

            <div className="input-container">
                <label>Height</label>
                <Select
                    options={heights}
                    onChange={(selected) => setSelectedHeight(selected.value)}
                    placeholder="Select height"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHeight ? { value: selectedHeight, label: selectedHeight } : null}
                />
            </div>

            <div className="input-container">
                <label>Weight</label>
                <Select
                    options={weights}
                    onChange={(selected) => setSelectedWeight(selected.value)}
                    placeholder="Select weight"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedWeight ? { value: selectedWeight, label: selectedWeight } : null}
                />
            </div>

            <div className="input-container">
                <label>Body Type</label>
                <Select
                    options={bodyTypes}
                    onChange={(selected) => setSelectedBodyType(selected.value)}
                    placeholder="Select body type"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedBodyType ? { value: selectedBodyType, label: selectedBodyType } : null}
                />
            </div>

            <div className="input-container">
                <label>Complexion</label>
                <Select
                    options={complexion}
                    onChange={(selected) => setSelectedComplexion(selected.value)}
                    placeholder="Select complexion"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedComplexion ? { value: selectedComplexion, label: selectedComplexion } : null}
                />
            </div>

            <div className="input-container">
                <label>Hair Color</label>
                <Select
                    options={hairs}
                    onChange={(selected) => setSelectedHair(selected.value)}
                    placeholder="Select hair color"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHair ? { value: selectedHair, label: selectedHair } : null}
                />
            </div>
            <div className="input-container">
                <label>Hair Type</label>
                <Select
                    options={hairType}
                    onChange={(selected) => setSelectedHairType(selected.value)}
                    placeholder="Select hair type"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHairType ? { value: selectedHairType, label: selectedHairType } : null}
                />
            </div>
            <div className="input-container">
                <label>Eye Color</label>
                <Select
                    options={eyes}
                    onChange={(selected) => setSelectedEyes(selected.value)}
                    placeholder="Select eye color"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEyes ? { value: selectedEyes, label: selectedEyes } : null}
                />
            </div>
            <div className="input-container">
                <label>Blood Group</label>
                <Select
                    options={bloodGroup}
                    onChange={(selected) => setSelectedBloodGroup(selected.value)}
                    placeholder="Select blood group"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedBloodGroup ? { value: selectedBloodGroup, label: selectedBloodGroup } : null}
                />
            </div>
            <div className="input-container">
                <label>Eye Wear</label>
                <Select
                    options={eyeWear}
                    onChange={(selected) => setSelectedEyeWear(selected.value)}
                    placeholder="Select eye wear"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEyeWear ? { value: selectedEyeWear, label: selectedEyeWear } : null}
                />
            </div>
            <div className="input-container">
                <label>Facial Hair</label>
                <Select
                    options={facialHair}
                    onChange={(selected) => setSelectedFacialHair(selected.value)}
                    placeholder="Select facial hair"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedFacialHair ? { value: selectedFacialHair, label: selectedFacialHair } : null}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handlePhysic}>Done</button>
            </div>

            <Toaster />
        </div>
    );
};

export default EditAppearance;