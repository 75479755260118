import React, { useState } from 'react';
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';

const EducationDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const {
        height,
        weight,
        maritalStatus,
        bodyType,
        hairColor,
        eyeColor,
        physicallyChallenged,
        complexion,
        ethnicGroup,
    } = location.state || {};

    console.log(
        height,
        weight,
        maritalStatus,
        bodyType,
        hairColor,
        eyeColor,
        physicallyChallenged,
        complexion
    );

    const [selectedEducation, setSelectedEducation] = useState("");
    const [selectedOccupation, setSelectedOccupation] = useState("");
    const [selectedProfession, setSelectedProfession] = useState("");
    const [nationality, setNationality] = useState("");
    const [state, setState] = useState("");
    const [district, setDistrict] = useState("");
    const [place, setPlace] = useState("");
    const [presentnationality, setPresentNationality] = useState("");
    const [presentState, setPresentState] = useState("");
    const [presentDistrict, setPresentDistrict] = useState("");
    const [presentPlace, setPresentPlace] = useState("");
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState("");
    const [selectedPreferedContactType, setSelectedPreferedContactType] =
        useState("");
    const [sameLocation, setSameLocation] = useState(false);

    const education = [
        { value: 'Doctorate', label: 'Doctorate' },
        { value: 'High/Secondary School', label: 'High/Secondary School' },
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Bachelors Degree', label: 'Bachelors Degree' },
        { value: 'Masters Degree', label: 'Masters Degree' },
        { value: 'PhD', label: 'PhD' },
        { value: 'Trade School/TTC/ITI', label: 'Trade School/TTC/ITI' },
        { value: 'Other', label: 'Other' },
    ];

    const occupation = [
        { value: 'Not Working', label: 'Not Working' },
        { value: 'Student', label: 'Student' },
        { value: 'Non-Mainstream Professional', label: 'Non-Mainstream Professional' },
        { value: 'Acting professional', label: 'Acting professional' },
        { value: 'Administration Professional', label: 'Administration Professional' },
        { value: 'Air Hostess', label: 'Air Hostess' },
        { value: 'Doctor', label: 'Doctor' },
        { value: 'Engineer', label: 'Engineer' },
        { value: 'Teacher', label: 'Teacher' },
        { value: 'Lawyer', label: 'Lawyer' },
        { value: 'Accountant', label: 'Accountant' },
        { value: 'Software Developer', label: 'Software Developer' },
        { value: 'Architect', label: 'Architect' },
        { value: 'Designer', label: 'Designer' },
        { value: 'Writer', label: 'Writer' },
        { value: 'Artist', label: 'Artist' },
        { value: 'Musician', label: 'Musician' },
        { value: 'Actor', label: 'Actor' },
        { value: 'Salesperson', label: 'Salesperson' },
        { value: 'Marketing Professional', label: 'Marketing Professional' },
        { value: 'Finance Professional', label: 'Finance Professional' },
        { value: 'Human Resources Professional', label: 'Human Resources Professional' },
        { value: 'Customer Service Representative', label: 'Customer Service Representative' },
        { value: 'Administrative Assistant', label: 'Administrative Assistant' },
        { value: 'Technician', label: 'Technician' }
    ];

    const professionType = [
        { value: 'Student', label: 'Student' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Business Owner', label: 'Business Owner' },
        { value: 'Government Employee', label: 'Government Employee' },
        { value: 'Homemaker', label: 'Homemaker' },
        { value: 'Retired', label: 'Retired' },
        { value: 'Other', label: 'Other' }
    ];

    const preferedContactType = [
        { value: 'Call my primary number', label: 'Call my primary number' },
        { value: 'Call my secondary number', label: 'Call my secondary number' },
        { value: 'Call me on primary or secondary number', label: 'Call me on primary or secondary number' },
        { value: 'Message me here(in-app)', label: 'Message me here (in-app)' },
        { value: 'Whatsapp me on primary number', label: 'WhatsApp me on primary number' },
        { value: 'WhatsApp me secondary number', label: 'WhatsApp me on secondary number' },
        { value: 'Text my primary number', label: 'Text my primary number' },
        { value: 'Text my secondary number', label: 'Text my secondary number' },
        { value: 'No preference', label: 'No preference' }
    ];

    const indianStates = [
        { value: 'Andhra Pradesh', label: 'Andhra Pradesh' },
        { value: 'Arunachal Pradesh', label: 'Arunachal Pradesh' },
        { value: 'Assam', label: 'Assam' },
        { value: 'Bihar', label: 'Bihar' },
        { value: 'Chhattisgarh', label: 'Chhattisgarh' },
        { value: 'Goa', label: 'Goa' },
        { value: 'Gujarat', label: 'Gujarat' },
        { value: 'Haryana', label: 'Haryana' },
        { value: 'Himachal Pradesh', label: 'Himachal Pradesh' },
        { value: 'Jharkhand', label: 'Jharkhand' },
        { value: 'Karnataka', label: 'Karnataka' },
        { value: 'Kerala', label: 'Kerala' },
        { value: 'Madhya Pradesh', label: 'Madhya Pradesh' },
        { value: 'Maharashtra', label: 'Maharashtra' },
        { value: 'Manipur', label: 'Manipur' },
        { value: 'Meghalaya', label: 'Meghalaya' },
        { value: 'Mizoram', label: 'Mizoram' },
        { value: 'Nagaland', label: 'Nagaland' },
        { value: 'Odisha', label: 'Odisha' },
        { value: 'Punjab', label: 'Punjab' },
        { value: 'Rajasthan', label: 'Rajasthan' },
        { value: 'Sikkim', label: 'Sikkim' },
        { value: 'Tamil Nadu', label: 'Tamil Nadu' },
        { value: 'Telangana', label: 'Telangana' },
        { value: 'Tripura', label: 'Tripura' },
        { value: 'Uttar Pradesh', label: 'Uttar Pradesh' },
        { value: 'Uttarakhand', label: 'Uttarakhand' },
        { value: 'West Bengal', label: 'West Bengal' },
        { value: 'Andaman and Nicobar Islands', label: 'Andaman and Nicobar Islands' },
        { value: 'Chandigarh', label: 'Chandigarh' },
        { value: 'Lakshadweep', label: 'Lakshadweep' },
        { value: 'Delhi', label: 'Delhi' },
        { value: 'Puducherry', label: 'Puducherry' },
        { value: 'Ladakh', label: 'Ladakh' },
        { value: 'Jammu and Kashmir', label: 'Jammu and Kashmir' }
    ];

    const countryOptions = [
        { value: 'Afghanistan', label: 'Afghanistan', flag: '🇦🇫' },
        { value: 'Albania', label: 'Albania', flag: '🇦🇱' },
        { value: 'Algeria', label: 'Algeria', flag: '🇩🇿' },
        { value: 'American Samoa', label: 'American Samoa', flag: '🇦🇸' },
        { value: 'Andorra', label: 'Andorra', flag: '🇦🇩' },
        { value: 'Angola', label: 'Angola', flag: '🇦🇴' },
        { value: 'Anguilla', label: 'Anguilla', flag: '🇦🇮' },
        { value: 'Antarctica', label: 'Antarctica', flag: '🇦🇶' },
        { value: 'Antigua and Barbuda', label: 'Antigua and Barbuda', flag: '🇦🇬' },
        { value: 'Argentina', label: 'Argentina', flag: '🇦🇷' },
        { value: 'Armenia', label: 'Armenia', flag: '🇦🇲' },
        { value: 'Aruba', label: 'Aruba', flag: '🇦🇼' },
        { value: 'Australia', label: 'Australia', flag: '🇦🇺' },
        { value: 'Austria', label: 'Austria', flag: '🇦🇹' },
        { value: 'Azerbaijan', label: 'Azerbaijan', flag: '🇦🇿' },
        { value: 'Bahamas', label: 'Bahamas', flag: '🇧🇸' },
        { value: 'Bahrain', label: 'Bahrain', flag: '🇧🇭' },
        { value: 'Bangladesh', label: 'Bangladesh', flag: '🇧🇩' },
        { value: 'Barbados', label: 'Barbados', flag: '🇧🇧' },
        { value: 'Belarus', label: 'Belarus', flag: '🇧🇾' },
        { value: 'Belgium', label: 'Belgium', flag: '🇧🇪' },
        { value: 'Belize', label: 'Belize', flag: '🇧🇿' },
        { value: 'Benin', label: 'Benin', flag: '🇧🇯' },
        { value: 'Bermuda', label: 'Bermuda', flag: '🇧🇲' },
        { value: 'Bhutan', label: 'Bhutan', flag: '🇧🇹' },
        { value: 'Bolivia', label: 'Bolivia', flag: '🇧🇴' },
        { value: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina', flag: '🇧🇦' },
        { value: 'Botswana', label: 'Botswana', flag: '🇧🇼' },
        { value: 'Brazil', label: 'Brazil', flag: '🇧🇷' },
        { value: 'Brunei', label: 'Brunei', flag: '🇧🇳' },
        { value: 'Bulgaria', label: 'Bulgaria', flag: '🇧🇬' },
        { value: 'Burkina Faso', label: 'Burkina Faso', flag: '🇧🇫' },
        { value: 'Burundi', label: 'Burundi', flag: '🇧🇮' },
        { value: 'Cambodia', label: 'Cambodia', flag: '🇰🇭' },
        { value: 'Cameroon', label: 'Cameroon', flag: '🇨🇲' },
        { value: 'Canada', label: 'Canada', flag: '🇨🇦' },
        { value: 'Cape Verde', label: 'Cape Verde', flag: '🇨🇻' },
        { value: 'Cayman Islands', label: 'Cayman Islands', flag: '🇰🇾' },
        { value: 'Central African Republic', label: 'Central African Republic', flag: '🇨🇫' },
        { value: 'Chad', label: 'Chad', flag: '🇹🇩' },
        { value: 'Chile', label: 'Chile', flag: '🇨🇱' },
        { value: 'China', label: 'China', flag: '🇨🇳' },
        { value: 'Colombia', label: 'Colombia', flag: '🇨🇴' },
        { value: 'Comoros', label: 'Comoros', flag: '🇰🇲' },
        { value: 'Congo', label: 'Congo', flag: '🇨🇬' },
        { value: 'Congo (DRC)', label: 'Congo (DRC)', flag: '🇨🇩' },
        { value: 'Costa Rica', label: 'Costa Rica', flag: '🇨🇷' },
        { value: 'Côte d\'Ivoire', label: 'Côte d\'Ivoire', flag: '🇨🇮' },
        { value: 'Croatia', label: 'Croatia', flag: '🇭🇷' },
        { value: 'Cuba', label: 'Cuba', flag: '🇨🇺' },
        { value: 'Cyprus', label: 'Cyprus', flag: '🇨🇾' },
        { value: 'Czech Republic', label: 'Czech Republic', flag: '🇨🇿' },
        { value: 'Denmark', label: 'Denmark', flag: '🇩🇰' },
        { value: 'Djibouti', label: 'Djibouti', flag: '🇩🇯' },
        { value: 'Dominica', label: 'Dominica', flag: '🇩🇲' },
        { value: 'Dominican Republic', label: 'Dominican Republic', flag: '🇩🇴' },
        { value: 'Ecuador', label: 'Ecuador', flag: '🇪🇨' },
        { value: 'Egypt', label: 'Egypt', flag: '🇪🇬' },
        { value: 'El Salvador', label: 'El Salvador', flag: '🇸🇻' },
        { value: 'Equatorial Guinea', label: 'Equatorial Guinea', flag: '🇬🇶' },
        { value: 'Eritrea', label: 'Eritrea', flag: '🇪🇷' },
        { value: 'Estonia', label: 'Estonia', flag: '🇪🇪' },
        { value: 'Eswatini', label: 'Eswatini', flag: '🇸🇿' },
        { value: 'Ethiopia', label: 'Ethiopia', flag: '🇪🇹' },
        { value: 'Fiji', label: 'Fiji', flag: '🇫🇯' },
        { value: 'Finland', label: 'Finland', flag: '🇫🇮' },
        { value: 'France', label: 'France', flag: '🇫🇷' },
        { value: 'Gabon', label: 'Gabon', flag: '🇬🇦' },
        { value: 'Gambia', label: 'Gambia', flag: '🇬🇲' },
        { value: 'Georgia', label: 'Georgia', flag: '🇬🇪' },
        { value: 'Germany', label: 'Germany', flag: '🇩🇪' },
        { value: 'Ghana', label: 'Ghana', flag: '🇬🇭' },
        { value: 'Greece', label: 'Greece', flag: '🇬🇷' },
        { value: 'Grenada', label: 'Grenada', flag: '🇬🇩' },
        { value: 'Guam', label: 'Guam', flag: '🇬🇺' },
        { value: 'Guatemala', label: 'Guatemala', flag: '🇬🇹' },
        { value: 'Guinea', label: 'Guinea', flag: '🇬🇳' },
        { value: 'Guinea-Bissau', label: 'Guinea-Bissau', flag: '🇬🇼' },
        { value: 'Guyana', label: 'Guyana', flag: '🇬🇾' },
        { value: 'Haiti', label: 'Haiti', flag: '🇭🇹' },
        { value: 'Honduras', label: 'Honduras', flag: '🇭🇳' },
        { value: 'Hungary', label: 'Hungary', flag: '🇭🇺' },
        { value: 'Iceland', label: 'Iceland', flag: '🇮🇸' },
        { value: 'India', label: 'India', flag: '🇮🇳' },
        { value: 'Indonesia', label: 'Indonesia', flag: '🇮🇩' },
        { value: 'Iran', label: 'Iran', flag: '🇮🇷' },
        { value: 'Iraq', label: 'Iraq', flag: '🇮🇶' },
        { value: 'Ireland', label: 'Ireland', flag: '🇮🇪' },
        { value: 'Israel', label: 'Israel', flag: '🇮🇱' },
        { value: 'Italy', label: 'Italy', flag: '🇮🇹' },
        { value: 'Jamaica', label: 'Jamaica', flag: '🇯🇲' },
        { value: 'Japan', label: 'Japan', flag: '🇯🇵' },
        { value: 'Jordan', label: 'Jordan', flag: '🇯🇴' },
        { value: 'Kazakhstan', label: 'Kazakhstan', flag: '🇰🇿' },
        { value: 'Kenya', label: 'Kenya', flag: '🇰🇪' },
        { value: 'Kiribati', label: 'Kiribati', flag: '🇰🇮' },
        { value: 'Kosovo', label: 'Kosovo', flag: '🇽🇰' },
        { value: 'Kuwait', label: 'Kuwait', flag: '🇰🇼' },
        { value: 'Kyrgyzstan', label: 'Kyrgyzstan', flag: '🇰🇬' },
        { value: 'Laos', label: 'Laos', flag: '🇱🇦' },
        { value: 'Latvia', label: 'Latvia', flag: '🇱🇻' },
        { value: 'Lebanon', label: 'Lebanon', flag: '🇱🇧' },
        { value: 'Lesotho', label: 'Lesotho', flag: '🇱🇸' },
        { value: 'Liberia', label: 'Liberia', flag: '🇱🇸' },
        { value: 'Libya', label: 'Libya', flag: '🇱🇾' },
        { value: 'Liechtenstein', label: 'Liechtenstein', flag: '🇱🇮' },
        { value: 'Lithuania', label: 'Lithuania', flag: '🇱🇹' },
        { value: 'Luxembourg', label: 'Luxembourg', flag: '🇱🇺' },
        { value: 'Madagascar', label: 'Madagascar', flag: '🇲🇬' },
        { value: 'Malawi', label: 'Malawi', flag: '🇲🇼' },
        { value: 'Malaysia', label: 'Malaysia', flag: '🇲🇾' },
        { value: 'Maldives', label: 'Maldives', flag: '🇲🇻' },
        { value: 'Mali', label: 'Mali', flag: '🇲🇱' },
        { value: 'Malta', label: 'Malta', flag: '🇲🇹' },
        { value: 'Marshall Islands', label: 'Marshall Islands', flag: '🇲🇭' },
        { value: 'Martinique', label: 'Martinique', flag: '🇲🇶' },
        { value: 'Mauritania', label: 'Mauritania', flag: '🇲🇷' },
        { value: 'Mauritius', label: 'Mauritius', flag: '🇲🇺' },
        { value: 'Mayotte', label: 'Mayotte', flag: '🇾🇹' },
        { value: 'Mexico', label: 'Mexico', flag: '🇲🇽' },
        { value: 'Micronesia', label: 'Micronesia', flag: '🇫🇲' },
        { value: 'Moldova', label: 'Moldova', flag: '🇲🇩' },
        { value: 'Monaco', label: 'Monaco', flag: '🇲🇨' },
        { value: 'Mongolia', label: 'Mongolia', flag: '🇲🇳' },
        { value: 'Montenegro', label: 'Montenegro', flag: '🇲🇪' },
        { value: 'Montserrat', label: 'Montserrat', flag: '🇲🇸' },
        { value: 'Morocco', label: 'Morocco', flag: '🇲🇦' },
        { value: 'Mozambique', label: 'Mozambique', flag: '🇲🇿' },
        { value: 'Myanmar', label: 'Myanmar', flag: '🇲🇲' },
        { value: 'Namibia', label: 'Namibia', flag: '🇳🇦' },
        { value: 'Nauru', label: 'Nauru', flag: '🇳🇷' },
        { value: 'Nepal', label: 'Nepal', flag: '🇳🇵' },
        { value: 'Netherlands', label: 'Netherlands', flag: '🇳🇱' },
        { value: 'New Zealand', label: 'New Zealand', flag: '🇳🇿' },
        { value: 'Nicaragua', label: 'Nicaragua', flag: '🇳🇮' },
        { value: 'Niger', label: 'Niger', flag: '🇳🇪' },
        { value: 'Nigeria', label: 'Nigeria', flag: '🇳🇬' },
        { value: 'Niue', label: 'Niue', flag: '🇳🇺' },
        { value: 'Norfolk Island', label: 'Norfolk Island', flag: '🇳🇫' },
        { value: 'North Macedonia', label: 'North Macedonia', flag: '🇲🇰' },
        { value: 'Northern Mariana Islands', label: 'Northern Mariana Islands', flag: '🇲🇵' },
        { value: 'Norway', label: 'Norway', flag: '🇳🇴' },
        { value: 'Oman', label: 'Oman', flag: '🇴🇲' },
        { value: 'Pakistan', label: 'Pakistan', flag: '🇵🇰' },
        { value: 'Palau', label: 'Palau', flag: '🇵🇼' },
        { value: 'Panama', label: 'Panama', flag: '🇵🇦' },
        { value: 'Papua New Guinea', label: 'Papua New Guinea', flag: '🇵🇬' },
        { value: 'Paraguay', label: 'Paraguay', flag: '🇵🇾' },
        { value: 'Peru', label: 'Peru', flag: '🇵🇪' },
        { value: 'Philippines', label: 'Philippines', flag: '🇵🇭' },
        { value: 'Pitcairn Islands', label: 'Pitcairn Islands', flag: '🇵🇬' },
        { value: 'Poland', label: 'Poland', flag: '🇵🇱' },
        { value: 'Portugal', label: 'Portugal', flag: '🇵🇹' },
        { value: 'Puerto Rico', label: 'Puerto Rico', flag: '🇵🇷' },
        { value: 'Qatar', label: 'Qatar', flag: '🇶🇦' },
        { value: 'Romania', label: 'Romania', flag: '🇷🇴' },
        { value: 'Russia', label: 'Russia', flag: '🇷🇺' },
        { value: 'Rwanda', label: 'Rwanda', flag: '🇷🇼' },
        { value: 'Reunion', label: 'Reunion', flag: '🇷🇪' },
        { value: 'Saint Barthélemy', label: 'Saint Barthélemy', flag: '🇧🇱' },
        { value: 'Saint Helena', label: 'Saint Helena', flag: '🇸🇭' },
        { value: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis', flag: '🇰🇳' },
        { value: 'Saint Lucia', label: 'Saint Lucia', flag: '🇱🇨' },
        { value: 'Saint Martin', label: 'Saint Martin', flag: '🇲🇶' },
        { value: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon', flag: '🇵🇲' },
        { value: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines', flag: '🇻🇨' },
        { value: 'Samoa', label: 'Samoa', flag: '🇼🇸' },
        { value: 'San Marino', label: 'San Marino', flag: '🇸🇲' },
        { value: 'Sao Tome and Principe', label: 'Sao Tome and Principe', flag: '🇸🇹' },
        { value: 'Saudi Arabia', label: 'Saudi Arabia', flag: '🇸🇦' },
        { value: 'Senegal', label: 'Senegal', flag: '🇸🇳' },
        { value: 'Serbia', label: 'Serbia', flag: '🇷🇸' },
        { value: 'Seychelles', label: 'Seychelles', flag: '🇸🇨' },
        { value: 'Sierra Leone', label: 'Sierra Leone', flag: '🇸🇱' },
        { value: 'Singapore', label: 'Singapore', flag: '🇸🇬' },
        { value: 'Sint Maarten', label: 'Sint Maarten', flag: '🇸🇽' },
        { value: 'Slovakia', label: 'Slovakia', flag: '🇸🇰' },
        { value: 'Slovenia', label: 'Slovenia', flag: '🇸🇮' },
        { value: 'Solomon Islands', label: 'Solomon Islands', flag: '🇸🇧' },
        { value: 'Somalia', label: 'Somalia', flag: '🇸🇴' },
        { value: 'South Africa', label: 'South Africa', flag: '🇿🇦' },
        { value: 'South Korea', label: 'South Korea', flag: '🇰🇷' },
        { value: 'South Sudan', label: 'South Sudan', flag: '🇸🇸' },
        { value: 'Spain', label: 'Spain', flag: '🇪🇸' },
        { value: 'Sri Lanka', label: 'Sri Lanka', flag: '🇱🇰' },
        { value: 'Sudan', label: 'Sudan', flag: '🇸🇩' },
        { value: 'Suriname', label: 'Suriname', flag: '🇸🇷' },
        { value: 'Svalbard', label: 'Svalbard', flag: '🇸🇯' },
        { value: 'Sweden', label: 'Sweden', flag: '🇸🇪' },
        { value: 'Switzerland', label: 'Switzerland', flag: '🇨🇭' },
        { value: 'Syria', label: 'Syria', flag: '🇸🇾' },
        { value: 'Taiwan', label: 'Taiwan', flag: '🇹🇼' },
        { value: 'Tajikistan', label: 'Tajikistan', flag: '🇹🇯' },
        { value: 'Tanzania', label: 'Tanzania', flag: '🇹🇿' },
        { value: 'Thailand', label: 'Thailand', flag: '🇹🇭' },
        { value: 'Timor-Leste', label: 'Timor-Leste', flag: '🇹🇱' },
        { value: 'Togo', label: 'Togo', flag: '🇹🇬' },
        { value: 'Tokelau', label: 'Tokelau', flag: '🇹🇰' },
        { value: 'Tonga', label: 'Tonga', flag: '🇹🇴' },
        { value: 'Trinidad and Tobago', label: 'Trinidad and Tobago', flag: '🇹🇹' },
        { value: 'Tunisia', label: 'Tunisia', flag: '🇹🇳' },
        { value: 'Turkey', label: 'Turkey', flag: '🇹🇷' },
        { value: 'Turkmenistan', label: 'Turkmenistan', flag: '🇹🇲' },
        { value: 'Tuvalu', label: 'Tuvalu', flag: '🇹🇻' },
        { value: 'Uganda', label: 'Uganda', flag: '🇺🇬' },
        { value: 'Ukraine', label: 'Ukraine', flag: '🇺🇦' },
        { value: 'United Arab Emirates', label: 'United Arab Emirates', flag: '🇦🇪' },
        { value: 'United Kingdom', label: 'United Kingdom', flag: '🇬🇧' },
        { value: 'United States', label: 'United States', flag: '🇺🇸' },
        { value: 'Uruguay', label: 'Uruguay', flag: '🇺🇾' },
        { value: 'Uzbekistan', label: 'Uzbekistan', flag: '🇺🇿' },
        { value: 'Vanuatu', label: 'Vanuatu', flag: '🇻🇺' },
        { value: 'Vatican City', label: 'Vatican City', flag: '🇻🇦' },
        { value: 'Venezuela', label: 'Venezuela', flag: '🇻🇪' },
        { value: 'Vietnam', label: 'Vietnam', flag: '🇻🇳' },
        { value: 'Western Sahara', label: 'Western Sahara', flag: '🇪🇭' },
        { value: 'Yemen', label: 'Yemen', flag: '🇾🇪' },
        { value: 'Zambia', label: 'Zambia', flag: '🇿🇲' },
        { value: 'Zimbabwe', label: 'Zimbabwe', flag: '🇿🇼' }
    ];

    const coursesByEducation = {
        "Doctorate": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Masters Degree": [
            { value: 'IES', label: 'IES' },
            { value: 'Master of Veterinary Science', label: 'Master of Veterinary Science' },
            { value: 'MSc Nursing', label: 'MSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'M Pharm', label: 'M Pharm' },
            { value: 'Master of Law', label: 'Master of Law' },
            { value: 'LLM', label: 'LLM' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA', label: 'CA' },
            { value: 'ICWA', label: 'ICWA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'IRS', label: 'IRS' },
            { value: 'MAMS', label: 'MAMS' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'MUMS', label: 'MUMS' },
            { value: 'Pharm D', label: 'Pharm D' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'MSc Biotechnology', label: 'MSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'MSc Visual Communication', label: 'MSc Visual Communication' },
            { value: 'Msc Radiology', label: 'Msc Radiology' },
            { value: 'M.Voc Cardiac Care Technology', label: 'M.Voc Cardiac Care Technology' },
            { value: 'M.Design', label: 'M.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'M.Sc', label: 'M.Sc' },
            { value: 'MBA', label: 'MBA' },
            { value: 'M.Arch', label: 'M.Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'MA', label: 'MA' },
            { value: 'MCA', label: 'MCA' },
            { value: 'M Com', label: 'M Com' },
            { value: 'MEd', label: 'MEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'MFM(Financial Management)', label: 'MFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'MHM', label: 'MHM' },
            { value: 'MD/MS(Medical)', label: 'MD/MS(Medical)' },
            { value: 'MPT', label: 'MPT' },
            { value: 'MLIS', label: 'MLIS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'MSc Computer Science', label: 'MSc Computer Science' },
            { value: 'MSc IT', label: 'MSc IT' },
            { value: 'MS(Engg)', label: 'MS(Engg)' },
            { value: 'PGDCA', label: 'PGDCA' },
            { value: 'M Phill', label: 'M Phill' },
            { value: 'MSW', label: 'MSW' },
            { value: 'PGDM', label: 'PGDM' },
            { value: 'MDS', label: 'MDS' },
            { value: 'MHMS', label: 'MHMS' },
            { value: 'M Tech', label: 'M Tech' },
            { value: 'ME', label: 'ME' }
        ],
        "Bachelors Degree": [
            { value: 'IRS', label: 'IRS' },
            { value: 'BAMS', label: 'BAMS' },
            { value: 'Bachelor of Veterinary Science', label: 'Bachelor of Veterinary Science' },
            { value: 'B Pharm', label: 'B Pharm' },
            { value: 'BSc Nursing', label: 'BSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'Bachelor of Law', label: 'Bachelor of Law' },
            { value: 'LLB', label: 'LLB' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA Intern', label: 'CA Intern' },
            { value: 'CA', label: 'CA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'Finance', label: 'Finance' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'BHMS', label: 'BHMS' },
            { value: 'IES', label: 'IES' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'BUMS', label: 'BUMS' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'BSc Biotechnology', label: 'BSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'BSc Visual Communication', label: 'BSc Visual Communication' },
            { value: 'BSc Radiology', label: 'BSc Radiology' },
            { value: 'B.Voc Cardiac Care Technology', label: 'B.Voc Cardiac Care Technology' },
            { value: 'B.Design', label: 'B.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'BPT', label: 'BPT' },
            { value: 'BBA', label: 'BBA' },
            { value: 'B Arch', label: 'B Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'BA', label: 'BA' },
            { value: 'BCom', label: 'BCom' },
            { value: 'BCA', label: 'BCA' },
            { value: 'BEd', label: 'BEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'BGL', label: 'BGL' },
            { value: 'BFM(Financial Management)', label: 'BFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'BHM', label: 'BHM' },
            { value: 'MBBS', label: 'MBBS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'BLIS', label: 'BLIS' },
            { value: 'BSc', label: 'BSc' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'BFA', label: 'BFA' },
            { value: 'B Plan', label: 'B Plan' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'BSc Computer Science', label: 'BSc Computer Science' },
            { value: 'BSc IT', label: 'BSc IT' },
            { value: 'Bachelor of Engineering', label: 'Bachelor of Engineering' },
            { value: 'B phil', label: 'B phil' },
            { value: 'BSW', label: 'BSW' },
            { value: 'BDS', label: 'BDS' },
            { value: 'BTech', label: 'BTech' },
            { value: 'BE', label: 'BE' }
        ],
        "Diploma": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Trade School/TTC/ITI": [
            { value: 'TTC', label: 'TTC' },
            { value: 'ITI', label: 'ITI' }
        ]
    };

    const handleEducationalDetails = () => {

        const phoneRegex = /^[0-9]{10}$/;

        if (
            !selectedEducation ||
            !phoneNumber ||
            !secondaryPhoneNumber ||
            !selectedPreferedContactType ||
            !presentnationality
        ) {
            toast.error("Please Enter Required Fields");
            return;
        }

        if (!phoneRegex.test(phoneNumber)) {
            toast.error('Please enter a valid 10-digit phone number');
            return;
        }

        if (!phoneRegex.test(secondaryPhoneNumber)) {
            toast.error('Please enter a valid 10-digit phone number');
            return;
        }

        navigate('/religious-details', {
            state: {
                height,
                weight,
                maritalStatus,
                bodyType,
                hairColor,
                eyeColor,
                physicallyChallenged,
                complexion,
                ethnicGroup,
                highestEducation: selectedEducation,
                course: selectedCourse,
                professionType: selectedProfession,
                profession: selectedOccupation,
                homeCountry: nationality,
                homeDistrict: district,
                homeState: state,
                homePlace: place,
                presentCountry: presentnationality,
                presentDistrict,
                presentState,
                presentPlace,
                preferredContactType: selectedPreferedContactType,
                secondaryNumber: secondaryPhoneNumber,
                primaryNumber: phoneNumber,
            }
        });
    }

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <h2>Create Profile</h2>
            <p>Educational Details</p>

            <div className="input-container">
                <label>Highest Education</label>
                <Select
                    options={education}
                    onChange={(selected) => setSelectedEducation(selected.value)}
                    placeholder="Search Education"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedEducation ? { value: selectedEducation, label: selectedEducation } : null}
                />
            </div>

            {selectedEducation && (coursesByEducation[selectedEducation]) && (
                <div className="input-container">
                    <label>Course</label>
                    <Select
                        options={coursesByEducation[selectedEducation]}
                        onChange={(selected) => setSelectedCourse(selected.value)}
                        placeholder="Select Course"
                        className="custom-select"
                        styles={customSelectStyles}
                        value={selectedCourse ? { value: selectedCourse, label: selectedCourse } : null}
                    />
                </div>
            )}

            <div className="input-container">
                <label>Profession</label>
                <Select
                    options={occupation}
                    onChange={(selected) => setSelectedOccupation(selected.value)}
                    placeholder="Search Occupation"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedOccupation ? { value: selectedOccupation, label: selectedOccupation } : null}
                />
            </div>

            {selectedOccupation === "Not Working" ||
                ("Student" && (
                    <div className="input-container">
                        <label>Profession Type</label>
                        <Select
                            options={professionType}
                            onChange={(selected) => setSelectedProfession(selected.value)}
                            placeholder="Select profession type"
                            className="custom-select"
                            styles={customSelectStyles}
                            value={selectedProfession ? { value: selectedProfession, label: selectedProfession } : null}
                        />
                    </div>
                ))}

            <p style={{ padding: '10px 0' }}>Location Details</p>

            <div className="input-container">
                <label htmlFor="home-country">Home Country</label>
                <Select
                    id="home-country"
                    options={countryOptions}
                    onChange={(selected) => setNationality(selected?.value)}
                    placeholder="Select Country"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={nationality ? { value: nationality, label: nationality } : null}
                />
            </div>

            {nationality === 'India' && (
                <div className="input-container">
                    <label htmlFor="home-state">Home State</label>
                    <Select
                        id="home-state"
                        options={indianStates}
                        onChange={(selected) => setState(selected?.value)}
                        placeholder="Select State"
                        className="custom-select"
                        styles={customSelectStyles}
                        value={state ? { value: state, label: state } : null}
                    />
                </div>
            )}

            {state && (
                <div className="input-container">
                    <label htmlFor="home-district">Home District</label>
                    <input
                        id="home-district"
                        type="text"
                        placeholder="Enter your district"
                        className="input-field"
                        value={district}
                        onChange={(e) => setDistrict(e.target.value)}
                        required
                    />
                </div>
            )}

            {district && (
                <div className="input-container">
                    <label htmlFor="home-place">Home Place</label>
                    <input
                        id="home-place"
                        type="text"
                        placeholder="Enter your place"
                        className="input-field"
                        value={place}
                        onChange={(e) => setPlace(e.target.value)}
                        required
                    />
                </div>
            )}

            <div className="input-container">
                <label>
                    <input
                        type="checkbox"
                        checked={sameLocation}
                        style={{ marginRight: "10px" }}
                        onChange={(e) => {
                            setSameLocation(e.target.checked);
                            if (e.target.checked) {
                                setPresentNationality(nationality);
                                setPresentState(state);
                                setPresentDistrict(district);
                                setPresentPlace(place);
                            } else {
                                setPresentNationality("");
                                setPresentState("");
                                setPresentDistrict("");
                                setPresentPlace("");
                            }
                        }}
                    />
                    Present Location Similiar as Home Location
                </label>
            </div>

            {!sameLocation && (
                <>
                    <div className="input-container">
                        <label>Present Country</label>
                        <Select
                            options={countryOptions}
                            onChange={(selected) => setPresentNationality(selected?.value)}
                            placeholder="Select Country"
                            className="custom-select"
                            styles={customSelectStyles}
                            value={presentnationality ? { value: presentnationality, label: presentnationality } : null}
                        />
                    </div>

                    {presentnationality === 'India' && (
                        <div className="input-container">
                            <label>Present State</label>
                            <Select
                                options={indianStates}
                                onChange={(selected) => setPresentState(selected?.value)}
                                placeholder="Select State"
                                className="custom-select"
                                styles={customSelectStyles}
                                value={presentState ? { value: presentState, label: presentState } : null}
                            />
                        </div>
                    )}

                    {presentState && (
                        <div className="input-container">
                            <label>Present District</label>
                            <input
                                type="text"
                                placeholder="Enter your district"
                                className="input-field"
                                value={presentDistrict}
                                onChange={(e) => setPresentDistrict(e.target.value)}
                            />
                        </div>
                    )}

                    {presentDistrict && (
                        <div className="input-container">
                            <label>Present Place</label>
                            <input
                                type="text"
                                placeholder="Enter your place"
                                className="input-field"
                                value={presentPlace}
                                onChange={(e) => setPresentPlace(e.target.value)}
                            />
                        </div>
                    )}
                </>
            )}

            <p style={{ padding: '10px 0' }}>Contact Details</p>

            <div className="input-container">
                <label>Primary Number</label>
                <input
                    type="number"
                    placeholder="Enter Number"
                    className="input-field"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Secondary Number</label>
                <input
                    type="number"
                    placeholder="Enter Number"
                    className="input-field"
                    value={secondaryPhoneNumber}
                    onChange={(e) => setSecondaryPhoneNumber(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Prefered Contact Type</label>
                <Select
                    options={preferedContactType}
                    onChange={(selected) => setSelectedPreferedContactType(selected.value)}
                    placeholder="Select prefered contact type"
                    className="custom-select"
                    styles={customSelectStyles}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleEducationalDetails}>
                    NEXT
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default EducationDetails;