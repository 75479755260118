import React from 'react'
import Plans from './Plans'
import BankDetails from './BankDetails'
import Offices from './Offices'

const Upgrade = () => {
    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="card py-5">
                <h4 className="text-center mb-3 mt-0">Pricing Plans</h4>
                <Plans />
                <BankDetails />
                <Offices />
            </div>
        </div>
    )
}

export default Upgrade
