import React from "react";

const ViewBadgeModal = ({ onClose, badgeDetails }) => {
    return (
        <div
            className="modal fade show"
            id="viewBadgeModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            View Identity File
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <table className="table border">
                            <tbody>
                                <tr>
                                    <th>Name</th>
                                    <th>Profile ID</th>
                                </tr>
                                <tr>
                                    <td>{badgeDetails?.idName || ''}</td>
                                    <td>{badgeDetails?.idFile ? 
                                    (
                                        <a className="btn btn-primary btnsim" href={badgeDetails?.idFile} alt="id-file" target="_blank">
                                            <i className="ri ri-file-fill me-2"></i> View File
                                        </a>
                                    )
                                    : ''}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="modal-footer text-center">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewBadgeModal;
