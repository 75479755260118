import React from "react";
const BasicPreference = ({basicPreference}) => {
    const parseArray = (data) => {
        try {
            return JSON.parse(data) || [];
        } catch {
            return [];
        }
    };
    return(
        <div className="card mt-3 p-0">
            <div className="card-header px-5 py-3">
                <h5 className="mb-0">Basic Preferences</h5>
            </div>
            <div className="card-body mt-0">
                <div class="table-responsive text-nowrap">
                    <table class="table border">
                        <tbody>
                            {
                                basicPreference.agePreference && (
                                    <tr>
                                        <th>Age Preference</th>
                                        <td className="text-start">: {basicPreference.agePreference} Yrs</td>
                                        <td className={`text-end ${basicPreference.agePreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.maritalStatus && (
                                    <tr>
                                        <th>Marital Status</th>
                                        <td className="text-start">: {parseArray(basicPreference.maritalStatus).join(", ")}</td>
                                        <td className={`text-end ${basicPreference.maritalStatusMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.heightPreference && (
                                    <tr>
                                        <th>Height Preference</th>
                                        <td className="text-start">
                                            : {basicPreference.heightPreference} cm
                                        </td>
                                        <td className={`text-end ${basicPreference.heightPreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.physical_status && (
                                    <tr>
                                        <th>Physical Status</th>
                                        <td className="text-start">: {parseArray(basicPreference.physical_status).join(", ")}</td>
                                        <td className={`text-end ${basicPreference.physicalStatusMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.drinkingPreference && (
                                    <tr>
                                        <th>Drinking Preference</th>
                                        <td className="text-start">: {parseArray(basicPreference.drinkingPreference).join(", ")}</td>
                                        <td className={`text-end ${basicPreference.drinkingPreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.smokingPreference && (
                                    <tr>
                                        <th>Smoking Preference</th>
                                        <td className="text-start">: {parseArray(basicPreference.smokingPreference).join(", ")}</td>
                                        <td className={`text-end ${basicPreference.smokingPreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                            {
                                basicPreference.languagesPreference && (
                                    <tr>
                                        <th>Language Preference</th>
                                        <td className="text-start">: {parseArray(basicPreference.languagesPreference).join(", ")}</td>
                                        <td className={`text-end ${basicPreference.languagePreferenceMatch === 'yes' ? 'text-success' : ''}`}>
                                            <i className="ri-checkbox-circle-fill ri-28px"></i>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BasicPreference;