import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const BasicPreferenceModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState(() => {
        const parsedDetails = {
            ...details,
            maritalStatus: Array.isArray(details.maritalStatus)
                ? details.maritalStatus
                : JSON.parse(details.maritalStatus || "[]"),
        };
        return parsedDetails;
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    const handleCheckboxChange = (status) => {
        setFormData((prevState) => {
            const currentStatuses = prevState.maritalStatus || [];
            const updatedStatuses = currentStatuses.includes(status)
                ? currentStatuses.filter((s) => s !== status) // Remove if already selected
                : [...currentStatuses, status]; // Add if not selected
            return { ...prevState, maritalStatus: updatedStatuses };
        });
    };
    
    

    //options
    const maritalStatusOptions = [
        'Any',
        'Never Married',
        'Widower',
        'Divorced',
        'Nikah Divorce',
        'Awaiting Divorce',
        'Separated',
        'Annulled',
    ];

    const physicalStatusOptions = [
        'Any',
        'Normal Person',
        'Physically Challenged',
        'Hearing Impaired',
        'Vision Impaired',
        'Speech Impaired',
        'Minor Health Issues',
        'Any Other Physical Disability',
        'Mental Health Issues',
    ];

    const eatingHabitsOptions = [
        "Veg",
        "Non-Veg",
        "Occasionally Non-Veg",
        "Eggetarian",
        "Prefer Not to say",
    ];
    const habitsOptions = [
        "No, never",
        "Yes, regularly",
        "Occasionally",
        "Trying to Quit",
        "Socially",
        "Rarely",
        "Quit",
        "Prefer Not to say",
    ];

    const languageOptions = [
        "Any",
        "Malayalam",
        "Arabic",
        "Chinese",
        "English",
        "French",
        "German",
        "Hindi",
        "Japanese",
        "Kannada",
        "Korean",
        "Tamil",
        "Telugu",
        "Urdu",
    ];

    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
    
            const payload = {
                ...formData,
                maritalStatus: formData.maritalStatus || [],
                eatingPreference: formData.eatingPreference ? [formData.eatingPreference] : [],
                drinkingPreference: formData.drinkingPreference ? [formData.drinkingPreference] : [],
                smokingPreference: formData.smokingPreference ? [formData.smokingPreference] : [],
                languagesPreference: formData.languagesPreference ? [formData.languagesPreference] : [],
            };
    
            const response = await axios.post(
                `${API_URL}/api/basicPreferences/store`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(payload);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    
    return (
        <div
            className="modal fade show"
            id="basicPreferenceModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Contact Attributes
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <label className="col-3">Age</label>
                            <div className="col-3">
                                <select
                                    className="form-control form-control-sm"
                                    name="ageFrom"
                                    value={formData.ageFrom || ""}
                                    onChange={handleInputChange}
                                >
                                    {[...Array(43).keys()].map(age => (
                                        <option key={age} value={age + 18}>{age + 18}</option>
                                    ))}
                                </select>
                            </div>
                            <label className="col-1">To</label>
                            <div className="col-3 text-start">
                                <select
                                    className="form-control form-control-sm"
                                    name="ageTo"
                                    value={formData.ageTo || ""} 
                                    onChange={handleInputChange}
                                >
                                    {[...Array(43).keys()].map(age => (
                                        <option key={age} value={age + 18}>{age + 18}</option>
                                    ))}
                                </select>
                            </div>
                        </div>

                        <div className="row mb-3">
                            <label for="exampleInputEmail2" className="col-sm-3">Height</label>
                            <div className="col-3">
                                <select
                                    className="form-control form-control-sm"
                                    name="heightFrom"
                                    value={formData.heightFrom || ""} 
                                    onChange={handleInputChange}
                                >
                                    {[...Array(41).keys()].map(height => (
                                        <option key={height} value={height + 140}>{height + 140}</option>
                                    ))}
                                </select>
                            </div>
                            <label className="col-1">To</label>
                            <div className="col-3 text-start">
                                <select
                                    className="form-control form-control-sm"
                                    name="heightTo"
                                    value={formData.heightTo || ""} 
                                    onChange={handleInputChange}
                                >
                                    {[...Array(41).keys()].map(height => (
                                        <option key={height} value={height + 140}>{height + 140}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label htmlFor="exampleInputMobile" className="col-sm-3">
                                Marital Status
                            </label>
                            <div className="col-sm-9">
                                <div>
                                    {maritalStatusOptions.map((status, index) => (
                                        <div key={index} className="form-check form-check-inline">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={`status-${index}`}
                                                checked={formData.maritalStatus?.includes(status)}
                                                onChange={() => handleCheckboxChange(status)}
                                            />
                                            <label className="form-check-label" htmlFor={`status-${index}`}>
                                                {status}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Physical Status</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="physicalStatus"
                                    value={formData.physicalStatus || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Physical Status</option>
                                    {physicalStatusOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Eating Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="eatingPreference"
                                    value={formData.eatingPreference || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Eating Preferences</option>
                                    {eatingHabitsOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Drinking Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="drinkingPreference"
                                    value={formData.drinkingPreference || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Drinking Preference</option>
                                    {habitsOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Smoking Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="smokingPreference"
                                    value={formData.smokingPreference || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Smoking Preference</option>
                                    {habitsOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Language Preferences</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="languagesPreference"
                                    value={formData.languagesPreference || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Language Preference</option>
                                    {languageOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default BasicPreferenceModal;