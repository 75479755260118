import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const LocationDetailsModal = ({ details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const countries = [
        "United States",
        "Canada",
        "Mexico",
        "Brazil",
        "Argentina",
        "Chile",
        "China",
        "Japan",
        "India",
        "Australia",
        "United Kingdom",
        "France",
        "Germany",
        "Italy",
        "Spain",
        "Russia",
        "South Africa",
        "Egypt",
        "Nigeria",
        "South Korea",
        "Indonesia",
        "Saudi Arabia",
        "United Arab Emirates",
        "Qatar",
        "Kuwait",
        "Bahrain",
        "Oman",
        "Yemen"
    ];
    const states = {
        India: [
            "Andhra Pradesh",
            "Arunachal Pradesh",
            "Assam",
            "Bihar",
            "Chhattisgarh",
            "Goa",
            "Gujarat",
            "Haryana",
            "Himachal Pradesh",
            "Jharkhand",
            "Karnataka",
            "Kerala",
            "Madhya Pradesh",
            "Maharashtra",
            "Manipur",
            "Meghalaya",
            "Mizoram",
            "Nagaland",
            "Odisha",
            "Punjab",
            "Rajasthan",
            "Sikkim",
            "Tamil Nadu",
            "Telangana",
            "Tripura",
            "Uttar Pradesh",
            "Uttarakhand",
            "West Bengal"
        ]
    };
    const districts = {
        Kerala: [
            "Alappuzha",
            "Ernakulam",
            "Idukki",
            "Kannur",
            "Kasaragod",
            "Kollam",
            "Kottayam",
            "Kozhikode",
            "Malappuram",
            "Palakkad",
            "Pathanamthitta",
            "Thiruvananthapuram",
            "Thrissur",
            "Wayanad",
        ],
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let updatedFormData = { ...formData, [name]: value };

        if (name === "presentCountry" || name === "homeCountry") {
            if (value !== "India") {
                updatedFormData = {
                    ...updatedFormData,
                    [`${name === "presentCountry" ? "present" : "home"}State`]: null,
                    [`${name === "presentCountry" ? "present" : "home"}District`]: null,
                };
            }
        }

        if (name === "presentState" || name === "homeState") {
            if (value !== "Kerala") {
                updatedFormData = {
                    ...updatedFormData,
                    [`${name === "presentState" ? "present" : "home"}District`]: null,
                };
            }
        }

        setFormData(updatedFormData);
        setErrors({ ...errors, [name]: "" });
    };

    const validate = () => {
        let validationErrors = {};

        if (!formData.presentCountry) {
            validationErrors.presentCountry = "Present country is required.";
        }
        if (!formData.homeCountry) {
            validationErrors.homeCountry = "Home country is required.";
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validate()) return;
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/locationDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };

    return (
        <div
            className="modal fade show"
            id="locationDetailsModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Location Details
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {/* Present Country */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="presentCountry"
                                        value={formData.presentCountry || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="presentCountry">Present Country</label>
                                    {errors.presentCountry && (
                                        <div className="invalid-feedback">{errors.presentCountry}</div>
                                    )}
                                </div>
                            </div>

                            {/* Present State */}
                            {formData.presentCountry === "India" && (
                                <div className="col-md-6 mb-5">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            className="form-control form-control-sm"
                                            name="presentState"
                                            value={formData.presentState || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select State</option>
                                            {states["India"].map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="presentState">Present State</label>
                                    </div>
                                </div>
                            )}

                            {/* Present District */}
                            {formData.presentState === "Kerala" && (
                                <div className="col-md-6 mb-5">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            className="form-control form-control-sm"
                                            name="presentDistrict"
                                            value={formData.presentDistrict || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select District</option>
                                            {districts["Kerala"].map((district) => (
                                                <option key={district} value={district}>
                                                    {district}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="presentDistrict">Present District</label>
                                    </div>
                                </div>
                            )}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="presentCity"
                                        value={formData.presentCity || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="presentCity">Present City</label>
                                </div>
                            </div>
                            {/* Home Country */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="homeCountry"
                                        value={formData.homeCountry || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Country</option>
                                        {countries.map((country) => (
                                            <option key={country} value={country}>
                                                {country}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="homeCountry">Home Country</label>
                                    {errors.homeCountry && (
                                        <div className="invalid-feedback">{errors.homeCountry}</div>
                                    )}
                                </div>
                            </div>

                            {/* Home State */}
                            {formData.homeCountry === "India" && (
                                <div className="col-md-6 mb-5">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            className="form-control form-control-sm"
                                            name="homeState"
                                            value={formData.homeState || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select State</option>
                                            {states["India"].map((state) => (
                                                <option key={state} value={state}>
                                                    {state}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="homeState">Home State</label>
                                    </div>
                                </div>
                            )}

                            {/* Home District */}
                            {formData.homeState === "Kerala" && (
                                <div className="col-md-6 mb-5">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            className="form-control form-control-sm"
                                            name="homeDistrict"
                                            value={formData.homeDistrict || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="">Select District</option>
                                            {districts["Kerala"].map((district) => (
                                                <option key={district} value={district}>
                                                    {district}
                                                </option>
                                            ))}
                                        </select>
                                        <label htmlFor="homeDistrict">Home District</label>
                                    </div>
                                </div>
                            )}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm"
                                        name="homeCity"
                                        value={formData.homeCity || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="homeCity">Home City</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LocationDetailsModal;
