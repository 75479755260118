import React, { useState, useEffect } from 'react';
import { ScrollView, TouchableOpacity } from 'react-native';
import { AiOutlineEdit } from 'react-icons/ai';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoCheckmarkCircle, IoClose } from 'react-icons/io5';
import Modal from 'react-modal'
import { TextInput } from 'react-native-web';
import { Toaster, toast } from 'react-hot-toast';

const ViewProfile = () => {
    const navigate = useNavigate();
    const [aboutModal, setAboutModal] = useState(false);
    const [PartnerModal, setPartnerModal] = useState(false);
    const [description, setDescription] = useState("");
    const [basicDetails, setBasicDetails] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const [educationDetails, setEducationDetails] = useState([]);
    const [physicalDetails, setPhysicalDetails] = useState([]);
    const [familyDetails, setFamilyDetails] = useState([]);
    const [partnerPreferences, setPartnerPreferences] = useState([]);
    const [locationDetails, setLocationDetails] = useState([]);
    const [personalDetails, setPersonalDetails] = useState([]);
    const [religiousDetails, setReligiousDetails] = useState([]);
    const [lifeStyle, setLifeStyle] = useState([]);
    const [partnerDescription, setPartnerDescription] = useState(
        partnerPreferences ? partnerPreferences?.looking_for || "" : ''
    );
    const [isLoading, setLoading] = useState(false);
    const [photo, setPhoto] = useState('https://admin.steptonikah.com/assets/img/man.jpg');

    const fetchData = async () => {
        setLoading(true);
        try {
            const token = await localStorage.getItem('token');
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get('https://admin.steptonikah.com/api/myProfileDetails', config);
            console.log(response.data, "Response Profiles");

            if (response.data.status) {
                setBasicDetails(response.data.basicDetails);
                setContactDetails(response.data.contactDetails);
                setEducationDetails(response.data.educationDetails);
                setPhysicalDetails(response.data.physicalDetails);

                const familyDetails = response.data.familyDetails;
                const fatherDetail = familyDetails.father_detail
                    ? familyDetails.father_detail.split(",")
                    : ["Unknown", "Unknown"];
                const motherDetail = familyDetails.mother_detail
                    ? familyDetails.mother_detail.split(",")
                    : ["Unknown", "Unknown"];

                familyDetails.father_name = fatherDetail[0];
                familyDetails.father_alive = fatherDetail[1];
                familyDetails.mother_name = motherDetail[0];
                familyDetails.mother_alive = motherDetail[1];

                setFamilyDetails(familyDetails);

                setPartnerPreferences(response.data.partnerPreferences);
                setLocationDetails(response.data.locationDetails);
                setPersonalDetails(response.data.personalDetails);
                setReligiousDetails(response.data.religiousDetails);
                setLifeStyle(response.data.lifeStyles);

                const profilePicture = response.data.photos && Array.isArray(response.data.photos)
                    ? response.data.photos.find(photo => photo.profile_picture === "yes")
                    : null;
                setPhoto(profilePicture ? profilePicture.photo_url : null);

            } else {
                console.log("Error in response status");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        console.log(basicDetails, "basic deails in useEffect");
    }, [basicDetails]);
    useEffect(() => {
        console.log(contactDetails, "contact details in useEffect");
    }, [contactDetails]);
    useEffect(() => {
        console.log(educationDetails, "educational details in useEffect");
    }, [educationDetails]);
    useEffect(() => {
        console.log(physicalDetails, "Physical details");
    }, [physicalDetails]);
    useEffect(() => {
        console.log(familyDetails, "family details");
    }, [familyDetails]);
    useEffect(() => {
        console.log(religiousDetails, "religious details");
    }, [religiousDetails]);
    useEffect(() => {
        console.log(partnerPreferences, "PartnetPreferences");
    }, [partnerPreferences]);
    useEffect(() => {
        console.log(photo, "Photo");
    }, [photo]);
    useEffect(() => {
        console.log(locationDetails, "Location details");
    }, [locationDetails]);
    useEffect(() => {
        console.log(personalDetails, "Personal Details");
    }, [personalDetails]);
    useEffect(() => {
        console.log(lifeStyle, "Life Style");
    }, [lifeStyle]);
    // useEffect(() => {
    //     if (partnerPreferences?.looking_for) {
    //         setPartnerDescription(partnerPreferences.looking_for);
    //     }
    // }, [partnerPreferences.looking_for]);

    const renderInfoItem = (label, value, isEditable = false, onEdit = () => { }) => (
        <div className="infoItem">
            <span className="infoText">{label}</span>
            <span className="infoSubText">{value}</span>
            {isEditable && (
                <TouchableOpacity onClick={onEdit}>
                    <AiOutlineEdit size={30} color="#572d71" />
                </TouchableOpacity>
            )}
        </div>
    );

    const handleDescription = async () => {
        console.log(description, "description");

        try {
            const token = localStorage.getItem("token");
            console.log("Token:", token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileUpdate/profileDescription",
                { profileDescription: description },
                config
            );

            console.log("Response:", response.data);

            if (response.data.status) {
                setAboutModal(false);

                toast.success(response.data.message);

                setTimeout(() => {
                    fetchData();
                }, 1500);
            }
        } catch (error) {
            console.error("Error:", error);

            setAboutModal(false);

            toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    const handlePartnerDescription = async () => {
        console.log(partnerDescription, "partnerDescription");

        try {
            const token = localStorage.getItem("token");
            console.log("Token:", token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileUpdate/profileDescription",
                { lookingFor: partnerDescription },
                config
            );

            console.log("Response:", response.data);

            if (response.data.status) {
                setPartnerModal(false);

                toast.success(response.data.message);

                setTimeout(() => {
                    fetchData();
                }, 1500);
            }
        } catch (error) {
            console.error("Error:", error);

            setPartnerModal(false);

            toast.error(error.response?.data?.message || "An error occurred. Please try again.");
        }
    };

    return (
        <div className="view-container">
            <ScrollView className="scrollView">
                <div className="imageContainer">
                    {photo ? (
                        <img src={photo} alt="Profile" className="image" />
                    ) : (
                        <p style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '500px' }}>No profile picture available</p>
                    )}

                    <button onClick={() => navigate('/account')} className="backButton">
                        <MdArrowBackIosNew size={24} color="#fff" />
                    </button>
                    <div onClick={() => navigate('/edit-Photo')} className="menuButton">
                        <AiOutlineEdit size={26} color="#fff" />
                    </div>
                    <div className="overlayContainer">
                        <h2 className="profileName">{basicDetails.name}</h2>
                        <p className="profileDetails">{basicDetails.age} yrs, {physicalDetails.height} cm, {personalDetails.marital_status}</p>
                        <p className="profileDetails">{locationDetails.home_district !== "null" ? locationDetails.home_district : ""}</p>
                    </div>
                </div>

                <div className="sectionContainer">

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">About</h3>
                        <div onClick={() => setAboutModal(true)}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    <p className="sectionContent">{personalDetails.profile_description}</p>

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Basic Info</h3>
                        <div onClick={() => navigate('/edit-basicinfo', { state: { basicDetails, personalDetails, physicalDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Name', basicDetails.name || '-')}
                    {renderInfoItem('Profile ID', basicDetails.profile_id || '-')}
                    {renderInfoItem('Email', basicDetails.email || '-')}
                    {renderInfoItem('Age', basicDetails.age || '-')}
                    {renderInfoItem('Gender', basicDetails.gender || '-')}
                    {renderInfoItem("Marital Status", personalDetails.marital_status || "-")}
                    {renderInfoItem('Creating Profile for', basicDetails.profile_for || '-')}
                    {/* {renderInfoItem('Health/Disability Issues?', 'No')}
                    {renderInfoItem('Mother Tongue', 'Malayalam')} */}

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Contact & Location Details</h3>
                        <div onClick={() => navigate('/edit-contact', { state: { locationDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Contact Person', contactDetails.contact_person || '-')}
                    {renderInfoItem('Primary Number', contactDetails.primary_number || '-')}
                    {renderInfoItem('Secondary Number', contactDetails.secondary_number || '-')}
                    {renderInfoItem('Contact Type', contactDetails.preferred_contact_type || '-')}
                    {renderInfoItem('Contact Time', contactDetails.preferred_contact_time || '-')}

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Educational Details</h3>
                        <div onClick={() => navigate('/edit-education', { state: { educationDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Highest Education', educationDetails.highest_education || '-')}
                    {renderInfoItem('Profession', educationDetails.profession || '-')}
                    {renderInfoItem('Education Institution', educationDetails.education_institute || '-')}
                    {renderInfoItem('Company Name', educationDetails.company_name || '-')}
                    {renderInfoItem('Profession Type', educationDetails.profession_type || '-')}
                    {renderInfoItem('Job Details', educationDetails.job_details || '-',)}
                    {renderInfoItem('Annual Income', educationDetails.annual_income || '-',)}

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Religious Details</h3>
                        <div onClick={() => navigate('/edit-religion-info', { state: { religiousDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Religion', religiousDetails.religion || 'None')}
                    {renderInfoItem('Community', religiousDetails.community || 'None')}
                    {renderInfoItem('Religiousness', religiousDetails.religiousness || 'None')}
                    {renderInfoItem('Perform Namaz', religiousDetails.perform_namaz || 'None')}
                    {renderInfoItem('Read Quran', religiousDetails.read_quran || 'None')}
                    {renderInfoItem('Mahallu Name', religiousDetails.mahallu_name || 'None')}
                    {renderInfoItem('Madrasa Education', religiousDetails.madrasa_education || 'None')}

                    <div style={{ display: "flex", gap: 10, alignItems: "center", margin: "10px 0 20px" }}>
                        <h3 className="sectionTitle">ID Verification</h3>
                        <IoCheckmarkCircle size={22} color="#572d71" style={{ marginTop: 15 }} />
                    </div>
                    <div className="content-box" style={{ backgroundColor: "#fff" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <p>Verify your Identity</p>
                            <button type="button" style={{ backgroundColor: '#572d71', borderRadius: "10px", width: "35%", border: "none" }} onClick={() => navigate('/idVerification')}>
                                <p style={{ color: '#fff', fontSize: "large", margin: "10px 0" }}>Verify</p>
                            </button>
                        </div>
                    </div>

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Physical Details</h3>
                        <div onClick={() => navigate('/edit-apprearance', { state: { physicalDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Height', `${physicalDetails?.height + ' cm' || '-'}`)}
                    {renderInfoItem('Weight', `${physicalDetails?.weight + ' kg' || '-'}`)}
                    {renderInfoItem('Blood Group', physicalDetails.blood_group || '-')}
                    {renderInfoItem('Body Type', physicalDetails.body_type || '-')}
                    {renderInfoItem('Skin Color', physicalDetails.skin_tone || '-')}
                    {renderInfoItem('Hair Color', physicalDetails.hair_color || '-')}
                    {renderInfoItem('Hair Type', physicalDetails.hair_type || '-')}
                    {renderInfoItem('Facial Hair', physicalDetails.facial_hair || '-')}
                    {renderInfoItem('Eye Color', physicalDetails.eye_color || '-')}
                    {renderInfoItem('Eye Wear', physicalDetails.eye_wear || '-')}

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Family Details</h3>
                        <div onClick={() => navigate('/edit-family-info', { state: { familyDetails } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Family Type', familyDetails.family_type || '-')}
                    {renderInfoItem('Family Values', familyDetails.family_values || '-')}
                    {renderInfoItem('Financial Status', familyDetails.financial_status || '-')}
                    {renderInfoItem('Home Type', familyDetails.home_type || '-')}
                    {renderInfoItem('Living Situation', familyDetails.living_situations || '-')}
                    {renderInfoItem('Father Name', familyDetails.father_name || '-')}
                    {renderInfoItem('Father Alive?', familyDetails.father_alive || 'Unknown')}
                    {renderInfoItem('Father Occupation', familyDetails.father_profession || '-')}
                    {renderInfoItem('Mother Name', familyDetails.mother_name || '-')}
                    {renderInfoItem('Mother Alive?', familyDetails.mother_alive || 'Unknown')}
                    {renderInfoItem('Mother Occupation', familyDetails.mother_profession || '-')}
                    {renderInfoItem('No. Elder Brothers', familyDetails.no_of_elder_brothers || '0')}
                    {renderInfoItem('No. Younger Brothers', familyDetails.no_of_younger_brothers || '0')}
                    {renderInfoItem('No. Married Brothers', familyDetails.no_of_married_brothers || '0')}
                    {renderInfoItem('No. Elder Sisters', familyDetails.no_of_elder_sisters || '0')}
                    {renderInfoItem('No. Younger Sisters', familyDetails.no_of_younger_sisters || '0')}
                    {renderInfoItem('No. Married Sisters', familyDetails.no_of_married_sisters || '0')}

                    <div className="sectionHeader">
                        <h3 className="sectionTitle">Living Details</h3>
                        <div onClick={() => navigate('/edit-lifestyle', { state: { lifeStyle } })}>
                            <AiOutlineEdit size={30} color="#572d71" />
                        </div>
                    </div>
                    {renderInfoItem('Eating Habits', lifeStyle.eating_habits || 'None')}
                    {renderInfoItem('Smoking Habits', lifeStyle.smoking_habits || 'None')}
                    {renderInfoItem('Drinking Habits', lifeStyle.drinking_habits || 'None')}
                    {renderInfoItem('Hobbies', lifeStyle.hobbies ? JSON.parse(lifeStyle.hobbies).join(', ') : 'None')}
                    {renderInfoItem('Favorite Food', lifeStyle.favorite_food || 'None')}
                    {renderInfoItem('Favorite Sport', lifeStyle.favorite_sport || 'None')}
                    {renderInfoItem('Favorite Place', lifeStyle.favorite_place || 'None')}
                    {renderInfoItem('Favorite Book', lifeStyle.favorite_books || 'None')}
                    {renderInfoItem('Places Visited', lifeStyle.places_visited && lifeStyle.places_visited !== "[]" ? lifeStyle.places_visited : 'None')}
                    {renderInfoItem('Favorite Movie and Music', lifeStyle.movie_and_music && lifeStyle.movie_and_music !== '[]' ? lifeStyle.movie_and_music : 'None')}
                    {renderInfoItem('Sense of Humour', lifeStyle.sense_of_humor && lifeStyle.sense_of_humor !== '[]' ? lifeStyle.sense_of_humor : 'None')}
                    {renderInfoItem('Favorite Dress', lifeStyle.dress_sense && lifeStyle.dress_sense !== '[]' ? lifeStyle.dress_sense : 'None')}
                    {renderInfoItem('Personality', lifeStyle.personality ? JSON.parse(lifeStyle.personality).join(', ') : 'None')}

                    {partnerPreferences && (
                        <>
                            <p style={{ fontSize: 18, textAlign: "center" }}><b>Partner Preferences</b></p>

                            <div className="sectionHeader">
                                <h3 className="sectionTitle">Partner Basic Preferences</h3>
                                <div onClick={() => navigate('/edit-preference', { state: { partnerPreferences } })}>
                                    <AiOutlineEdit size={30} color="#572d71" />
                                </div>
                            </div>
                            {renderInfoItem(
                                "Partner Age Preferences",
                                partnerPreferences.age_from && partnerPreferences.age_to
                                    ? `${partnerPreferences.age_from} - ${partnerPreferences.age_to} Yrs`
                                    : "Any"
                            )}
                            {renderInfoItem(
                                "Partner Height Preferences",
                                partnerPreferences.height_from && partnerPreferences.height_to
                                    ? `${partnerPreferences.height_from} - ${partnerPreferences.height_to} cm`
                                    : "Any"
                            )}
                            {renderInfoItem(
                                "Marital Status Preference",
                                partnerPreferences.marital_status
                                    ? JSON.parse(partnerPreferences.marital_status).length > 0
                                        ? JSON.parse(partnerPreferences.marital_status).join(",")
                                        : "None"
                                    : "None"
                            )}
                            {renderInfoItem(
                                "Physical Preferences",
                                partnerPreferences.physical_status
                                    ? JSON.parse(partnerPreferences.physical_status).length > 0
                                        ? JSON.parse(partnerPreferences.physical_status).join(",")
                                        : "None"
                                    : "None"
                            )}
                            {renderInfoItem(
                                "Eating Preferences",
                                partnerPreferences.eating_preference
                                    ? JSON.parse(partnerPreferences.eating_preference).length >
                                        0
                                        ? JSON.parse(partnerPreferences.eating_preference).join(
                                            ", "
                                        )
                                        : "None"
                                    : "None"
                            )}
                            {renderInfoItem(
                                "Drinking Preferences",
                                partnerPreferences.drinking_preference
                                    ? JSON.parse(partnerPreferences.drinking_preference)
                                        .length > 0
                                        ? JSON.parse(partnerPreferences.drinking_preference).join(
                                            ","
                                        )
                                        : "None"
                                    : "None"
                            )}
                            {renderInfoItem(
                                "Smoking Preferences",
                                partnerPreferences.smoking_preference
                                    ? JSON.parse(partnerPreferences.smoking_preference).length >
                                        0
                                        ? JSON.parse(partnerPreferences.smoking_preference).join(
                                            ","
                                        )
                                        : "None"
                                    : "None"
                            )}
                            {renderInfoItem(
                                "Language Preferences",
                                partnerPreferences.languages_preference
                                    ? JSON.parse(partnerPreferences.languages_preference)
                                        .length > 0
                                        ? JSON.parse(
                                            partnerPreferences.languages_preference
                                        ).join(",")
                                        : "None"
                                    : "None"
                            )}

                            <div className="sectionHeader">
                                <h3 className="sectionTitle">Partner Expectation</h3>
                                <div onClick={() => setPartnerModal(true)}>
                                    <AiOutlineEdit size={30} color="#572d71" />
                                </div>
                            </div>
                            <p className='info-subtext'>{partnerDescription}</p>
                        </>
                    )}
                    <Toaster />
                </div>

                <Modal
                    isOpen={aboutModal}
                    onRequestClose={() => setAboutModal(false)}
                    style={{ overlay: {}, content: { padding: 20 }, }}
                >
                    <div className="modalHeader">
                        <h4 className="modalTitle">Profile Description</h4>
                        <button onClick={() => setAboutModal(false)} className="closeButton">
                            <IoClose size={25} color="#000" />
                        </button>
                    </div>
                    <TextInput
                        className="descriptionInput"
                        placeholder="Profile Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        multiline
                        style={{
                            width: '100%', paddingVertical: 12, paddingHorizontal: 15, fontSize: 16, borderRadius: 10, borderWidth: 1, borderColor: '#ccc', backgroundColor: '#f7f7f7', color: '#333', minHeight: 100, maxHeight: 300, overflowY: 'auto'
                        }}
                    />
                    <button onClick={handleDescription} className="submitButton">SAVE</button>
                </Modal>

                <Modal
                    isOpen={PartnerModal}
                    onRequestClose={() => setPartnerModal(false)}
                    style={{ overlay: {}, content: { padding: 20 } }}
                >
                    <div className="modalHeader">
                        <h4 className="modalTitle">Basic Information</h4>
                        <button onClick={() => setPartnerModal(false)} className="closeButton">
                            <IoClose size={25} color="#000" />
                        </button>
                    </div>
                    <TextInput
                        className="descriptionInput"
                        placeholder="Profile Description"
                        value={partnerDescription}
                        onChange={(e) => setPartnerDescription(e.target.value)}
                        multiline
                        style={{
                            width: '100%', paddingVertical: 12, paddingHorizontal: 15, fontSize: 16, borderRadius: 10, borderWidth: 1, borderColor: '#ccc', backgroundColor: '#f7f7f7', color: '#333', minHeight: 100, maxHeight: 300, overflowY: 'auto'
                        }}
                    />
                    <button onClick={handlePartnerDescription} className="submitButton">SAVE</button>
                </Modal>
            </ScrollView>
        </div>
    );
};

export default ViewProfile;
