import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoChatbubblesSharp, IoStarSharp, IoHeart, IoClose, IoBanOutline, IoTrashOutline, IoCheckmarkCircleOutline, IoCloseCircleOutline, IoLockClosedSharp, IoCheckmarkCircleSharp, IoCloseCircleSharp, IoHourglassOutline } from 'react-icons/io5';
import { MdArrowBackIosNew } from 'react-icons/md';
import Button from '@mui/material/Button';
import axios from 'axios';
import Modal from 'react-modal';
import { Toaster, toast } from 'react-hot-toast';
import HomeScreen from '../HomeScreen';
// import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

const initialData = [
    { id: '1', name: 'Rahmath.kk', age: '36yrs', height: '163 cm', maritalStatus: 'Never Married', education: 'Higher Secondary', location: 'Calicut, Kozhikode, India', imageUrl: require('../../assets/images/img1.jpg'), liked: false },
    { id: '2', name: 'Fatima.kk', age: '28yrs', height: '150 cm', maritalStatus: 'Never Married', education: 'Graduate', location: 'Malappuram, Kerala, India', imageUrl: require('../../assets/images/img2.jpg'), liked: false },
    { id: '3', name: 'Asna', age: '23yrs', height: '140 cm', maritalStatus: 'Never Married', education: 'Graduate', location: 'Malappuram, Kerala, India', imageUrl: require('../../assets/images/img6.jpg'), liked: false },
];

const Matches = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState('Interests');
    const [data, setData] = useState(initialData);
    const [profileVisitorsStatus, setProfileVisitorsStatus] = useState(false);
    const [profileVisitedStatus, setProfileVisitedStatus] = useState(false);
    const [visitedProfiles, setVisitedProfiles] = useState([]);
    const [profileVisitors, setProfileVisitors] = useState([]);
    const [blockedStatus, setBlockedStatus] = useState(false);
    const [blockedList, setBlockedList] = useState([]);
    const [favStatus, setFavStatus] = useState(false);
    const [favList, setFavList] = useState([]);
    const [favDeleteModal, setFavDeleteModal] = useState(false);
    const [selectedFavId, setSelectedFavId] = useState('');
    const [passedList, setPassedList] = useState([]);
    const [passedStatus, setPassedStatus] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [interestSentStatus, setInterestSentStatus] = useState(false);
    const [interestSent, setInterestSent] = useState([]);
    const [receivedStatus, setReceivedStatus] = useState(false);
    const [receivedList, setReceivedList] = useState([]);
    const [acceptedStatus, setAcceptedStatus] = useState(false);
    const [acceptedList, setAcceptedList] = useState([]);
    const [declinedStatus, setDeclinedStatus] = useState(false);
    const [declinedList, setDeclinedList] = useState([]);
    const [interestTab, setInterestTab] = useState('Received');
    const [requestTab, setRequestTab] = useState("Photo Requests");
    const [showProfileId, setProfileId] = useState("");
    const [photoRequestList, setPhotoRequestList] = useState([]);
    const [contactRequestList, setContactRequestList] = useState([]);

    const fetchProfileVisitors = async () => {
        try {
            const token = await localStorage.getItem('token');
            console.log(token);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.get('https://admin.steptonikah.com/api/profileVisitors', config);
            console.log(response.data, "Profile visitors");
            setProfileVisitorsStatus(response.data.status);
            if (response.data.status) {
                setProfileVisitors(response.data.visitorsProfiles);
            }
            setLoading(false);
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchProfilesVisited = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('Token not found. Please log in again.');
            }

            setLoading(true);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get('https://admin.steptonikah.com/api/profileVisits', config);

            console.log(response.data, 'Profile visited');
            setProfileVisitedStatus(response.data.status);
            setVisitedProfiles(response.data.visitedProfiles);

            setLoading(false);

        } catch (error) {
            if (error.response) {
                console.error('Error Response:', error.response.data);
                console.error('Status Code:', error.response.status);

                toast.error(error.response.data.message || 'Failed to fetch profiles visited.');
            } else {
                console.error('Error Message:', error.message);
                toast.error(error.message || 'An unexpected error occurred.');
            }

            setLoading(false);
        }
    };

    const fetchBlock = async () => {
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.get('https://admin.steptonikah.com/api/blockLists/view', config);
            setBlockedStatus(response.data.status);
            if (response.data.status) {
                setBlockedList(response.data.blockedProfiles);
                console.log(response.data.blockedProfiles, "Blocked List");
            }
        } catch (error) {
            toast.error(error.response?.data?.message || 'Something went wrong. Please try again later');
        }
    };

    const fetchFavourites = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const userId = await localStorage.getItem('userId');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/shortlists/view', config);
            console.log(response.data, "Favorites");
            setLoading(false);
            setFavStatus(response.data.status);
            if (response.data.status) {
                setFavList(response.data.shortlistedProfiles);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchPassed = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/ignoreList/view', config);
            setLoading(false);
            setPassedStatus(response.data.status);

            if (response.data.status) {
                setPassedList(response.data.ignoredProfiles);
                console.log(response.data.ignoredProfiles, "Passed response");

            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchSentInterest = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/interests/sent/view', config);
            console.log(response.data, "Sent interest");
            setInterestSentStatus(response.data.status);
            if (response.data.status) {
                setInterestSent(response.data.sentInterests);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchReceived = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/interests/received/view', config);
            console.log(response.data, "Received interest");
            setReceivedStatus(response.data.status);
            if (response.data.status) {
                setReceivedList(response.data.receivedInterests);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchAccepted = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/interests/accepted/view', config);
            console.log(response.data, "Received interest");
            setAcceptedStatus(response.data.status);
            if (response.data.status) {
                setAcceptedList(response.data.acceptedInterests);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchDeclined = async () => {
        try {
            setLoading(true);
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            };
            const response = await axios.get('https://admin.steptonikah.com/api/interests/declined/view', config);
            console.log(response.data, "declined interest");
            setDeclinedStatus(response.data.status);
            if (response.data.status) {
                setDeclinedList(response.data.rejectedInterests);
                setLoading(false);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }
    const fetchContactRequest = async (showProfileId) => {
        if (!showProfileId) {
            console.log("Invalid Profile ID for fetchContactRequest");
            return;
        }

        setLoading(true);
        try {
            const token = await localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.post(
                "https://admin.steptonikah.com/api/partnerProfile/getContactRequest",
                { profileId: showProfileId },
                config
            );

            console.log(response.data, "Response in fetchContactRequest");

            if (response.data.status) {
                const sentRequests = response.data.sentContactRequests || [];
                const receivedRequests = response.data.receivedContactRequests || [];
                const combinedRequests = [
                    ...sentRequests.map((req) => ({ ...req, type: "sent" })),
                    ...receivedRequests.map((req) => ({ ...req, type: "received" })),
                ];
                setContactRequestList(combinedRequests);
                console.log(combinedRequests, "Combined Contact List");
            } else {
                setContactRequestList([]);
            }
        } catch (error) {
            console.error("Error in fetchContactRequest:", error);
            toast.error(
                error.response?.data?.message || "Something went wrong. Please try again later"
            );
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        console.log(photoRequestList, "Photo request list");
    }, []);
    useEffect(() => {
        fetchSentInterest();
    }, []);
    useEffect(() => {
        fetchReceived();
    }, []);
    useEffect(() => {
        fetchAccepted();
    }, []);
    useEffect(() => {
        fetchDeclined();
    }, []);
    useEffect(() => {
        fetchPassed();
    }, []);
    useEffect(() => {
        fetchBlock();
        console.log(blockedList, "blocked list");
    }, [blockedStatus]);
    useEffect(() => {
        fetchProfileVisitors();
        console.log(profileVisitorsStatus, "Profile status");
    }, [profileVisitorsStatus])
    useEffect(() => {
        fetchProfilesVisited();
    }, [profileVisitedStatus]);
    useEffect(() => {
        console.log(visitedProfiles, "Visited Profiles in useEffect");
    }, [visitedProfiles]);
    useEffect(() => {
        fetchFavourites();
    }, [favStatus]);
    useEffect(() => {
        console.log(favStatus, "Fav status");
        console.log(favList, "Favorites List");
    }, [favList]);
    const toggleLike = (id) => {
        setData(prevData => prevData.map(profile =>
            profile.id === id ? { ...profile, liked: !profile.liked } : profile
        ));
    };
    useEffect(() => {
        fetchId();
    }, []);

    useEffect(() => {
        console.log("Profile ID:", showProfileId);
    }, [showProfileId]);

    useEffect(() => {
        if (showProfileId) {
            console.log("Valid showProfileId detected:", showProfileId);
            fetchPhotoRequest(showProfileId);
        }
    }, [showProfileId]);

    useEffect(() => {
        if (showProfileId) {
            console.log("Valid showProfileId detected:", showProfileId);
            fetchContactRequest(showProfileId);
        }
    }, [showProfileId]);

    const fetchId = async () => {
        try {
            const token = await localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.get(
                "https://admin.steptonikah.com/api/myProfileDetails",
                config
            );
            if (response.data.status && response.data.basicDetails?.id) {
                setProfileId(response.data.basicDetails.id);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchPhotoRequest = async (showProfileId) => {
        if (!showProfileId) {
            console.log("Invalid Profile ID for fetchPhotoRequest");
            return;
        }

        setLoading(true);
        try {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/partnerProfile/getPhotoRequest",
                { profileId: showProfileId },
                config
            );

            console.log(response.data, "Response in fetchPhotoRequest");

            if (response.data.status) {
                setPhotoRequestList(response.data.photoRequests || []);
            } else {
                setPhotoRequestList([]);
            }
        } catch (error) {
            console.error("Error in fetchPhotoRequest:", error);
            toast.error(
                error.response?.data?.message || "Something went wrong in Photo request. Please try again later."
            );
        } finally {
            setLoading(false);
        }
    };

    const filterProfiles = () => {
        switch (activeTab) {
            case 'Interests':
                return initialData;
            // case 'Favourites':
            //   return initialData.filter(profile => profile.location.includes('Kozhikode'));
            case 'Profile Visited':
            case 'Profile Visitors':
                return data;
            case 'Passed':
                return [];
            case 'Blocked':
                return [];
            default:
                return initialData;
        }
    };
    const handleFavDelete = async (id) => {
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            console.log(selectedFavId, "Select fav id", token);
            const response = await axios.post(`https://admin.steptonikah.com/api/shortlists/${selectedFavId}/remove`, {}, config);
            console.log(response.data.status);
            if (response.data.status) {
                toast.success('Removed from favourites.');
                setTimeout(() => {
                    fetchFavourites();
                }, 1500);
            }
            setFavDeleteModal(false);

        } catch (error) {
            console.log(error);
            setFavDeleteModal(false);
            toast.error('Something went wrong. Please try again later.');
        }
    }
    const handleRemoveSent = async (id) => {
        console.log(id);
        try {
            const token = await localStorage.getItem('token');
            if (!token) {
                toast.error("Authorization token not found");
                return;
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(`https://admin.steptonikah.com/api/interest/${id}/cancelSent`, {}, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success('Deleted from sent interest.');
                setTimeout(() => {
                    fetchSentInterest();
                }, 2000);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
        }
    };

    const handelRemoveIgnore = async (id) => {
        console.log(id);
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(`https://admin.steptonikah.com/api/ignoreList/${id}/remove`, {}, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success('Deleted from passed profiles.');
                setTimeout(() => {
                    fetchPassed();
                }, 2000);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
        }
    }
    const handleContactAccept = async (id) => {
        console.log(id);
        try {
            const token = localStorage.getItem("token");
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const response = await axios.post(
                `https://admin.steptonikah.com/api/partnerProfile/contactRequest/accept`,
                { requestId: id },
                config
            );
            console.log(response.data, "Photo Accepted data");
            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    fetchContactRequest(showProfileId);
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again later.");
        }
    };

    const handleContactReject = async (id) => {
        console.log(id, "in reject");
    };

    const renderProfile = (item) => (
        <div className="profile-card" key={item.id} >
            <div className="profile-image-container">
                <button className="close-button">
                    <IoClose size={24} color="white" />
                </button>
                <img src={item.imageUrl} alt={item.name} className="profile-image" onClick={() => navigate(`/profile-view`, { state: { item } })} />
                <div className="overlay-container">
                    <p className="profile-name">{item.name}</p>
                    <p className="profile-details">{item.age}, {item.height}, {item.maritalStatus}</p>
                    <p className="profile-details">{item.location}</p>
                </div>
            </div>
        </div>
    );

    const filteredData = filterProfiles(activeTab);

    const deleteProfileVisit = async (id) => {
        console.log(id, "id");
        console.log(id);
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(`https://admin.steptonikah.com/api/profileVisits/${id}/delete`, {}, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    fetchProfilesVisited();
                }, 2000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error('Error occurred while removing block:', error)
            toast.error('Something went wrong. Please try again later.');
        }
    };

    const removeBlock = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(
                `https://admin.steptonikah.com/api/blockLists/${id}/remove`,
                {},
                config
            );

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    fetchBlock();
                }, 2000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later');
        }
    };

    const handleAccept = async (id) => {
        console.log(id);
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(`https://admin.steptonikah.com/api/interest/${id}/accept`, {}, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong.Please try again later');
        }
    }
    const handleDecline = async (id) => {
        console.log(id);
        try {
            const token = await localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post(`https://admin.steptonikah.com/api/interest/${id}/reject`, {}, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error('Something went wrong.Please try again later');
        }
    }

    const sendPhotoRequest = async (profileId) => {
        console.log(profileId, "Profile in send");

        const token = await localStorage.getItem('token');
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        };
        try {
            const response = await axios.post('https://admin.steptonikah.com/api/partnerProfile/photoRequest/store',
                { receiver_id: profileId },
                config
            );
            console.log(response.data, "Rsponse in send request");
            toast.success(response.data.message);
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            if (error.response) {
                const statusCode = error.response.status;
                if (statusCode === 400 && error.response.data.message === "A photo request has already been sent to this user.") {
                    toast.error("A photo request has already been sent to this user");
                } else {
                    toast.error(error.message);
                }
            } else {
                toast.error("Please check your internet connection.");
            }
        }
    };

    const handlePhotoAccept = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                'https://admin.steptonikah.com/api/partnerProfile/photoRequest/accept',
                { requestId: id },
                config
            );

            console.log(response.data, "Photo Accepted data");

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    fetchPhotoRequest(showProfileId);
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again later.");
        }
    };

    const handlePhotoReject = async (id) => {
        try {
            const token = localStorage.getItem('token');
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                'https://admin.steptonikah.com/api/partnerProfile/photoRequest/reject',
                { requestId: id },
                config
            );

            console.log(response.data, "Photo Rejected data");

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    fetchPhotoRequest(showProfileId);
                }, 1000);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            toast.error("Something went wrong. Please try again later.");
        }
    };

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                height: '100vh',
                backgroundColor: '#FDF7FD',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="spinner" style={{ width: '50px', height: '50px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>
            </div>
        )
    }

    return (
        <div className="matches-container">
            {/* <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} />
            </div> */}
            <div className="swipe-container" style={{ paddingTop: 15 }}>
                <div className="tab-bar">
                    {['Interests', 'Favourites', 'Profile Visited', 'Profile Visitors', 'Passed', 'Requests', 'Blocked'].map(tab => (
                        <Button
                            key={tab}
                            className={`tab-item ${activeTab === tab ? 'active' : ''}`}
                            onClick={() => setActiveTab(tab)}
                        >
                            {tab}
                        </Button>
                    ))}
                </div>
            </div>

            {activeTab === 'Interests' && (
                <>
                    {filteredData.length !== 0 ? (
                        <>
                            <div className="swipe-container" style={{ padding: 0 }}>
                                <div className="tab-bar-mini">
                                    {['Received', 'Sent', 'Accepted', 'Declined'].map((tab) => (
                                        <Button
                                            key={tab}
                                            onClick={() => setInterestTab(tab)}
                                            style={{
                                                padding: '7px 30px',
                                                borderRadius: '2px',
                                                cursor: 'pointer',
                                                borderBottom: interestTab === tab ? '1px solid #572d71' : 'none',
                                                textTransform: 'capitalize'
                                            }}
                                        >
                                            <p style={{ color: interestTab === tab ? '#572d71' : '#c0c0c0', margin: 0, }}> {tab}</p>
                                        </Button>
                                    ))}
                                </div>
                            </div>

                            {interestTab === 'Sent' && (
                                <>
                                    {interestSentStatus === false ? (
                                        <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                            <div className="icon-round">
                                                <img
                                                    src={require('../../assets/images/heartFill.png')}
                                                    alt="heart icon"
                                                    style={{ height: 75, width: 80, }}
                                                />
                                            </div>
                                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven't sent any interests yet</p>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            {interestSent.map(item => (
                                                <div className="profile-card" key={item.id} >
                                                    <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                                        <div className='button-container' onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveSent(item.id);
                                                        }}>
                                                            <button className="close-button">
                                                                <IoTrashOutline size={24} color="white" />
                                                            </button>
                                                        </div>
                                                        <img src={item.photos} alt={item.name} className="profile-image" />
                                                        {item.photoPrivacy === 'protected' && (
                                                            <div className="image-overlay">
                                                                <IoLockClosedSharp size={24} color="white" />
                                                                <p className="lockedText">Photo Locked</p>
                                                                <button className="requestButton" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    sendPhotoRequest(item.partnerId);
                                                                }}>
                                                                    <p className="requestButtonText">Request To View</p>
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className="overlay-container">
                                                            <p className="profile-name">{item.name || ''}</p>
                                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}, {item.maritalStatus || "N/A"}</p>
                                                        </div>
                                                        <button className="accepted-button">
                                                            <span className="accepted-button-text">Interest Sent</span>
                                                            <IoCheckmarkCircleOutline size={20} color='#319431' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                            {interestTab === 'Received' && (
                                <>
                                    {receivedStatus === false ? (
                                        <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                            <div className="icon-round">
                                                <img
                                                    src={require('../../assets/images/heartFill.png')}
                                                    alt="heart icon"
                                                    style={{ height: 75, width: 80, }}
                                                />
                                            </div>
                                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven’t received any interests yet</p>
                                        </div>
                                    ) : (
                                        <div>
                                            {receivedList.map(item => (
                                                <div className="profile-card" key={item.id} >
                                                    <div className="profile-image-container">
                                                        <img src={item.photos} alt={item.name} className="profile-image" />
                                                        {item.photoPrivacy === 'protected' && (
                                                            <div className="image-overlay">
                                                                <IoLockClosedSharp size={24} color="white" />
                                                                <p className="lockedText">Photo Locked</p>
                                                                <button className="requestButton" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    sendPhotoRequest(item.partnerId);
                                                                }}>
                                                                    <p className="requestButtonText">Request To View</p>
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className="overlay-container">
                                                            <p className="profile-name">{item.name || ''}</p>
                                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                                        </div>
                                                        {item.status === "pending" && (
                                                            <div className="action-buttons">
                                                                <button
                                                                    className="accept-button"
                                                                    onClick={() => handleAccept(item.id)}
                                                                >
                                                                    Accept
                                                                </button>
                                                                <button
                                                                    className="decline-button"
                                                                    onClick={() => handleDecline(item.id)}
                                                                >
                                                                    Decline
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                            {interestTab === 'Accepted' && (
                                <>
                                    {acceptedStatus === false ? (
                                        <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                            <div className="icon-round">
                                                <img
                                                    src={require('../../assets/images/heartFill.png')}
                                                    alt="heart icon"
                                                    style={{ height: 75, width: 80, }}
                                                />
                                            </div>
                                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven’t accepted any interests yet</p>
                                        </div>
                                    ) : (
                                        <div>
                                            {acceptedList.map(item => (
                                                <div className="profile-card" key={item.id} >
                                                    <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                                        <div className='button-container' onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleRemoveSent(item.id);
                                                        }}>
                                                            <button className="close-button">
                                                                <IoTrashOutline size={24} color="white" />
                                                            </button>
                                                        </div>
                                                        <img src={item.photo} alt={item.name} className="profile-image" />
                                                        {item.photoPrivacy === 'protected' && (
                                                            <div className="image-overlay">
                                                                <IoLockClosedSharp size={24} color="white" />
                                                                <p className="lockedText">Photo Locked</p>
                                                                <button className="requestButton" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    sendPhotoRequest(item.partnerId);
                                                                }}>
                                                                    <p className="requestButtonText">Request To View</p>
                                                                </button>
                                                            </div>
                                                        )}
                                                        <img src={item.photo} alt={item.name} className="profile-image" />
                                                        <div className="overlay-container" >
                                                            <p className="profile-name">{item.name || ''}</p>
                                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                                        </div>
                                                        <button className="accepted-button">
                                                            <span className="accepted-button-text">You Accepted</span>
                                                            <IoCheckmarkCircleOutline size={20} color='#319431' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}

                            {interestTab === 'Declined' && (
                                <>
                                    {declinedStatus === false ? (
                                        <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                            <div className="icon-round">
                                                <img
                                                    src={require('../../assets/images/heartFill.png')}
                                                    alt="heart icon"
                                                    style={{ height: 75, width: 80, }}
                                                />
                                            </div>
                                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven’t declined any interests yet</p>
                                        </div>
                                    ) : (
                                        <div>
                                            {declinedList.map(item => (
                                                <div className="profile-card" key={item.id} >
                                                    <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                                        <img src={item.photo} alt={item.name} className="profile-image" />
                                                        {item.photoPrivacy === 'protected' && (
                                                            <div className="image-overlay">
                                                                <IoLockClosedSharp size={24} color="white" />
                                                                <p className="lockedText">Photo Locked</p>
                                                                <button className="requestButton" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    sendPhotoRequest(item.partnerId);
                                                                }}>
                                                                    <p className="requestButtonText">Request To View</p>
                                                                </button>
                                                            </div>
                                                        )}
                                                        <div className="overlay-container">
                                                            <p className="profile-name">{item.name || ''}</p>
                                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                                        </div>
                                                        <button className="accepted-button" style={{ backgroundColor: "#d50000" }}>
                                                            <span className="accepted-button-text" style={{ color: "white" }}>You Declined</span>
                                                            <IoCloseCircleOutline size={20} color='#fff' />
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <div>
                            {filteredData.map((item) => renderProfile(item))}
                        </div>
                    )}
                </>
            )
            }

            {
                activeTab === 'Profile Visitors' && (
                    <>
                        {profileVisitorsStatus === false ? (
                            <div className="empty-message-container">
                                <div className="icon-round">
                                    <img
                                        src={require('../../assets/images/read.png')}
                                        alt="dislike icon"
                                        className="icon"
                                    />
                                </div>
                                <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>No Profile Visitors Yet</p>
                            </div>
                        ) : (
                            <div>
                                {profileVisitors.map(item => (
                                    <div className="profile-card" key={item.id} >
                                        <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                            <img src={item.photo} alt={item.name} className="profile-image" />
                                            {item.photoPrivacy === 'protected' && (
                                                <div className="image-overlay">
                                                    <IoLockClosedSharp size={24} color="white" />
                                                    <p className="lockedText">Photo Locked</p>
                                                    <button className="requestButton" onClick={(e) => {
                                                        e.stopPropagation();
                                                        sendPhotoRequest(item.partnerId);
                                                    }}>
                                                        <p className="requestButtonText">Request To View</p>
                                                    </button>
                                                </div>
                                            )}
                                            <div className="overlay-container">
                                                <p className="profile-name">{item.name || ''}</p>
                                                <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )
            }

            {activeTab === 'Profile Visited' && (
                <>
                    {profileVisitedStatus === false ? (
                        <div className="empty-message-container">
                            <div className="icon-round">
                                <img
                                    src={require('../../assets/images/read.png')}
                                    alt="dislike icon"
                                    className="icon"
                                />
                            </div>
                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>
                                No Profile Visited Yet
                            </p>
                        </div>
                    ) : (
                        <>
                            {visitedProfiles.map(item => (
                                <div className="profile-card" key={item.id} >
                                    <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                        <div className='button-container' onClick={(e) => {
                                            e.stopPropagation();
                                            deleteProfileVisit(item.id);
                                        }}>
                                            <button className="close-button">
                                                <IoTrashOutline size={24} color="white" />
                                            </button>
                                        </div>
                                        <img src={item.photo} alt={item.name} className="profile-image" />
                                        {item.photoPrivacy === 'protected' && (
                                            <div className="image-overlay">
                                                <IoLockClosedSharp size={24} color="white" />
                                                <p className="lockedText">Photo Locked</p>
                                                <button className="requestButton" onClick={(e) => {
                                                    e.stopPropagation();
                                                    sendPhotoRequest(item.partnerId);
                                                }}>
                                                    <p className="requestButtonText">Request To View</p>
                                                </button>
                                            </div>
                                        )}
                                        <div className="overlay-container">
                                            <p className="profile-name">{item.name || ''}</p>
                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}, {item.maritalStatus || "N/A"}</p>
                                            <p className="profile-details">{item.location || "Location not specified"}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </>
                    )}
                </>
            )}

            {activeTab === 'Passed' && (
                <>
                    {passedStatus === false ? (
                        <div className="empty-message-container">
                            <div className="icon-round">
                                <img
                                    src={require('../../assets/images/ignore.png')}
                                    alt="dislike icon"
                                    className="icon"
                                />
                            </div>
                            <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>No profiles have been passed yet</p>
                        </div>
                    ) : (
                        <>
                            {passedList.map(item => (
                                <div className="profile-card" key={item.id} >
                                    <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                        <div className='button-container' onClick={(e) => {
                                            e.stopPropagation();
                                            handelRemoveIgnore(item.id);
                                        }}>
                                            <button className="close-button">
                                                <IoTrashOutline size={24} color="white" />
                                            </button>
                                        </div>
                                        <img src={item.photo} alt={item.name} className="profile-image" />
                                        {item.photoPrivacy === 'protected' && (
                                            <div className="image-overlay">
                                                <IoLockClosedSharp size={24} color="white" />
                                                <p className="lockedText">Photo Locked</p>
                                                <button className="requestButton" onClick={(e) => {
                                                    e.stopPropagation();
                                                    sendPhotoRequest(item.partnerId);
                                                }}>
                                                    <p className="requestButtonText">Request To View</p>
                                                </button>
                                            </div>
                                        )}
                                        <div className="overlay-container">
                                            <p className="profile-name">{item.name || ''}</p>
                                            <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </>
                    )}
                </>
            )}

            {activeTab === "Requests" && (
                <>
                    <div className="swipe-container" style={{ padding: 0 }}>
                        <div className="tab-bar-mini" >
                            {['Photo Requests', 'Contact Requests'].map((tab) => (
                                <Button
                                    key={tab}
                                    onClick={() => setRequestTab(tab)}
                                    style={{
                                        padding: '7px 30px',
                                        borderRadius: '2px',
                                        cursor: 'pointer',
                                        borderBottom: requestTab === tab ? '1px solid #572d71' : 'none',
                                        textTransform: 'capitalize',
                                        marginBottom: '15px',
                                        width: '50%'
                                    }}
                                >
                                    <p style={{ color: requestTab === tab ? '#572d71' : '#c0c0c0', margin: 0, }}> {tab}</p>
                                </Button>
                            ))}
                        </div>
                    </div>

                    {requestTab === "Photo Requests" && (
                        <>
                            {photoRequestList.length === 0 ? (
                                <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                    <div className="icon-round">
                                        <img
                                            src={require('../../assets/images/people.png')}
                                            alt="heart icon"
                                            style={{ height: 75, width: 80, }}
                                        />
                                    </div>
                                    <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven’t received any photo requests yet</p>
                                </div>
                            ) : (
                                <>
                                    {photoRequestList.map((request) => {
                                        const { action, photoRequest } = request;
                                        const isPending = photoRequest.status === "pending" && action === "received";
                                        const isRejected = photoRequest.status === "rejected" && action === "received";
                                        const isAccepted = photoRequest.status === "accepted" && action === "received";

                                        const isProtected = photoRequest.photo_privacy === "protected";

                                        const actionMessage =
                                            action === "sent"
                                                ? `You sent a photo request to ${photoRequest.partner_name}.`
                                                : `You received a photo request from ${photoRequest.partner_name}.`;

                                        return (
                                            <div
                                                key={photoRequest.id}
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    padding: "10px",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "8px",
                                                    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                {/* Photo */}
                                                <div style={{ position: "relative", marginRight: "10px" }}>
                                                    <img
                                                        src={photoRequest.photo}
                                                        alt="Profile"
                                                        style={{
                                                            width: "50px",
                                                            height: "50px",
                                                            borderRadius: "50%",
                                                            opacity: isProtected ? 0.5 : 1,
                                                        }}
                                                    />
                                                    {isProtected && (
                                                        <div
                                                            style={{
                                                                position: "absolute",
                                                                top: 0,
                                                                left: 0,
                                                                width: "50px",
                                                                height: "50px",
                                                                borderRadius: "50%",
                                                                backgroundColor: "rgba(0,0,0,0.3)",
                                                            }}
                                                        ></div>
                                                    )}
                                                </div>

                                                {/* Details */}
                                                <div style={{ flex: 1 }}>
                                                    <p style={{ fontWeight: "600", fontSize: "14px" }}>{actionMessage}</p>
                                                    {isPending && (
                                                        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end" }}>
                                                            <button
                                                                onClick={() => handlePhotoAccept(photoRequest.id)}
                                                                style={{
                                                                    backgroundColor: "green",
                                                                    color: "#fff",
                                                                    border: "none",
                                                                    padding: "5px 15px",
                                                                    borderRadius: "5px",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Accept
                                                            </button>
                                                            <button
                                                                onClick={() => handlePhotoReject(photoRequest.id)}
                                                                style={{
                                                                    backgroundColor: "red",
                                                                    color: "#fff",
                                                                    border: "none",
                                                                    padding: "5px 15px",
                                                                    borderRadius: "5px",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                Reject
                                                            </button>
                                                        </div>
                                                    )}
                                                    {isRejected && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: 10,
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor: "red",
                                                                    borderRadius: 5,
                                                                    width: 100,
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    padding: "5px 10px"
                                                                }}
                                                            // disabled={true}
                                                            >
                                                                <IoCloseCircleSharp
                                                                    size={16}
                                                                    color="white"
                                                                />
                                                                <p
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "12px",
                                                                        marginBottom: 0
                                                                    }}
                                                                >
                                                                    Rejected
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {isAccepted && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: 10,
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#572d71",
                                                                    borderRadius: 5,
                                                                    width: 100,
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    padding: "5px 10px"
                                                                }}
                                                            // disabled={true}
                                                            >
                                                                <IoCheckmarkCircleSharp
                                                                    size={16}
                                                                    color="white"
                                                                />
                                                                <p
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "12px",
                                                                        marginBottom: 0
                                                                    }}
                                                                >
                                                                    Accepted
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {photoRequest.status === 'rejected' && action === 'sent' && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: 10,
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor: "red",
                                                                    borderRadius: 5,
                                                                    width: 100,
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    padding: "5px 10px"
                                                                }}
                                                            // disabled={true}
                                                            >
                                                                <IoCloseCircleSharp
                                                                    size={16}
                                                                    color="white"
                                                                />
                                                                <p
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "12px",
                                                                        marginBottom: 0
                                                                    }}
                                                                >
                                                                    Rejected
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {photoRequest.status === 'accepted' && action === 'sent' && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: 10,
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#572d71",
                                                                    borderRadius: 5,
                                                                    width: 100,
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    padding: "5px 10px"
                                                                }}
                                                            // disabled={true}
                                                            >
                                                                <IoCheckmarkCircleSharp
                                                                    size={16}
                                                                    color="white"
                                                                />
                                                                <p
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "12px",
                                                                        marginBottom: 0
                                                                    }}
                                                                >
                                                                    Accepted
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {photoRequest.status === 'pending' && action === 'sent' && (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                gap: 10,
                                                                justifyContent: "flex-end",
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#59B9D8",
                                                                    borderRadius: 5,
                                                                    width: 100,
                                                                    display: "flex",
                                                                    gap: 8,
                                                                    padding: "5px 10px"
                                                                }}
                                                            >
                                                                <IoHourglassOutline
                                                                    size={14}
                                                                    color="white"
                                                                />
                                                                <p
                                                                    style={{
                                                                        color: "#fff",
                                                                        fontSize: "12px",
                                                                        marginBottom: 0
                                                                    }}
                                                                >
                                                                    Pending
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </>
                            )}
                        </>
                    )}

                    {requestTab === 'Contact Requests' && (
                        <>
                            {contactRequestList.length === 0 ? (
                                <div className="empty-message-container" style={{ paddingTop: 80 }}>
                                    <div className="icon-round">
                                        <img
                                            src={require('../../assets/images/people.png')}
                                            alt="heart icon"
                                            style={{ height: 75, width: 80, }}
                                        />
                                    </div>
                                    <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>You haven’t accepted any requests yet</p>
                                </div>
                            ) : (
                                contactRequestList.map((request) => (
                                    <div
                                        key={request.id}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            margin: '10px 0',
                                            padding: '10px 15px',
                                            backgroundColor: '#fff',
                                            borderRadius: '10px',
                                            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
                                        }}
                                    >
                                        <img
                                            src={request.photo}
                                            alt="User"
                                            style={{ width: '50px', height: '50px', borderRadius: '25px' }}
                                        />
                                        <div style={{ flexGrow: 1, marginLeft: '10px' }}>
                                            <span
                                                style={{
                                                    color: '#343a40',
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontWeight: 500,
                                                }}
                                            >
                                                {request.type === 'sent'
                                                    ? `You sent a contact request to ${request.partner_name}`
                                                    : `Contact request from ${request.partner_name}`}
                                            </span>
                                            {request.type === 'sent' && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    <button
                                                        style={{
                                                            marginTop: '5px',
                                                            padding: '5px 15px',
                                                            borderRadius: '5px',
                                                            border: 'none',
                                                            backgroundColor:
                                                                request.status === 'pending'
                                                                    ? '#59B9D8'
                                                                    : request.status === 'accepted'
                                                                        ? 'green'
                                                                        : 'red',
                                                            color: 'white',
                                                            fontSize: '12px',
                                                        }}
                                                    >
                                                        {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                                                    </button>
                                                </div>
                                            )}

                                            {request.type === 'received' && (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        gap: '10px',
                                                        justifyContent: 'flex-end',
                                                    }}
                                                >
                                                    {request.status === 'pending' && (
                                                        <>
                                                            <button
                                                                style={{
                                                                    backgroundColor: 'green',
                                                                    padding: '5px 15px',
                                                                    borderRadius: '5px',
                                                                    border: 'none',
                                                                    color: 'white',
                                                                    fontSize: '12px',
                                                                }}
                                                                onClick={() => handleContactAccept(request.id)}
                                                            >
                                                                Accept
                                                            </button>
                                                            <button
                                                                style={{
                                                                    backgroundColor: 'red',
                                                                    padding: '5px 15px',
                                                                    borderRadius: '5px',
                                                                    border: 'none',
                                                                    color: 'white',
                                                                    fontSize: '12px',
                                                                }}
                                                                onClick={() => handleContactReject(request.id)}
                                                            >
                                                                Reject
                                                            </button>
                                                        </>
                                                    )}
                                                    {request.status === 'accepted' && (
                                                        <div
                                                            style={{
                                                                backgroundColor: '#572d71',
                                                                padding: '5px 10px',
                                                                borderRadius: '5px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                color: 'white',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            <IoCheckmarkCircleSharp
                                                                size={16}
                                                                color="white"
                                                            />
                                                            <p
                                                                style={{
                                                                    color: "#fff",
                                                                    fontSize: "12px",
                                                                    marginBottom: 0
                                                                }}
                                                            >
                                                                Accepted
                                                            </p>
                                                        </div>
                                                    )}
                                                    {request.status === 'rejected' && (
                                                        <div
                                                            style={{
                                                                backgroundColor: 'red',
                                                                padding: '5px 10px',
                                                                borderRadius: '5px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                gap: 8,
                                                                color: 'white',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            <IoCloseCircleSharp
                                                                size={16}
                                                                color="white"
                                                            />
                                                            <p
                                                                style={{
                                                                    color: "#fff",
                                                                    fontSize: "12px",
                                                                    marginBottom: 0
                                                                }}
                                                            >
                                                                Rejected
                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                ))
                            )}
                        </>
                    )}
                </>
            )
            }

            {
                activeTab === 'Blocked' && (
                    <>
                        {blockedStatus === false ? (
                            <div className="empty-message-container">
                                <img
                                    src={require('../../assets/images/blocked.png')}
                                    alt="dislike icon"
                                    className="icon"
                                />
                                <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>No Blocked Profiles</p>
                            </div>
                        ) : (
                            <>
                                {blockedList.map(item => (
                                    <div className="profile-card" key={item.id} >
                                        <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                            <div className='button-container' onClick={(e) => {
                                                e.stopPropagation();
                                                removeBlock(item.id);
                                            }}>
                                                <button className="close-button">
                                                    <IoTrashOutline size={24} color="white" />
                                                </button>
                                            </div>
                                            <img src={item.photo} alt={item.name} className="profile-image" />
                                            {item.photoPrivacy === 'protected' && (
                                                <div className="image-overlay">
                                                    <IoLockClosedSharp size={24} color="white" />
                                                    <p className="lockedText">Photo Locked</p>
                                                    <button className="requestButton" onClick={(e) => {
                                                        e.stopPropagation();
                                                        sendPhotoRequest(item.partnerId);
                                                    }}>
                                                        <p className="requestButtonText">Request To View</p>
                                                    </button>
                                                </div>
                                            )}
                                            <div className="overlay-container">
                                                <p className="profile-name">{item.name || ''}</p>
                                                <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}, {item.maritalStatus || "N/A"}</p>
                                                <p className="profile-details">{item.location || "Location not specified"}</p>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </>
                        )}
                    </>
                )
            }

            {
                activeTab === 'Favourites' && (
                    <>
                        {favStatus === false ? (
                            <div className="empty-message-container">
                                <div className="icon-round">
                                    <img
                                        src={require('../../assets/images/favorite.png')}
                                        alt="dislike icon"
                                        className="icon"
                                    />
                                </div>
                                <p style={{ color: "#572d71", fontSize: 17, fontWeight: 600 }}>No profiles have been favorited yet</p>
                            </div>
                        ) : (
                            <div>
                                {favList.map(item => (
                                    <div className="profile-card" key={item.id} >
                                        <div className="profile-image-container" onClick={() => navigate(`/profile-view`, { state: { item: { ...item, id: item.partnerId } } })} >
                                            <div className='button-container' onClick={(e) => {
                                                e.stopPropagation();
                                                handleFavDelete(item.id);
                                            }}>
                                                <button className="close-button">
                                                    <IoTrashOutline size={24} color="white" />
                                                </button>
                                            </div>
                                            <img src={item.photo} alt={item.name} className="profile-image" />
                                            {item.photoPrivacy === 'protected' && (
                                                <div className="image-overlay">
                                                    <IoLockClosedSharp size={24} color="white" />
                                                    <p className="lockedText">Photo Locked</p>
                                                    <button className="requestButton" onClick={(e) => {
                                                        e.stopPropagation();
                                                        sendPhotoRequest(item.partnerId);
                                                    }}>
                                                        <p className="requestButtonText">Request To View</p>
                                                    </button>
                                                </div>
                                            )}
                                            <div className="overlay-container">
                                                <p className="profile-name">{item.name || ''}</p>
                                                <p className="profile-details">{item.age || ''}, {item.height ? `${item.height} cm` : 'Height not available'}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>
                )
            }
            <Modal
                isOpen={favDeleteModal}
                onRequestClose={() => setFavDeleteModal(false)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <div className="popup_modal-content">
                    <h2>Do you want to remove this Profile from favourites ?</h2>
                    <div className="modal-buttons">
                        <button onClick={handleFavDelete} className="modal-button yes" style={{ padding: '10px 30px' }}>Yes</button>
                        <button onClick={() => setFavDeleteModal(false)} className="modal-button no" style={{ padding: '10px 30px' }}>No</button>
                    </div>
                </div>
            </Modal>

            <Toaster />
            <HomeScreen />

        </div >
    );
};

export default Matches;
