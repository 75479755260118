import React from 'react';
import { IoMail } from 'react-icons/io5';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const Support = () => {
    const navigate = useNavigate();

    const openEmail = () => {
        const email = "info@steptonikah.com";
        const subject = "Support Request";

        const emailUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}`;

        try {
            window.location.href = emailUrl;
        } catch (err) {
            console.error("Error opening email app:", err);
        }
    };

    return (
        <div className="help-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px', color: '#572d71' }}>Help & Support</h2>

            <div className="support-info">
                <p className="subtitle">Need assistance with the app?</p>
                <p className="subtitle">Contact Us</p>
                <div className="contact-container" onClick={openEmail}>
                    <div className="contact-info">
                        <div className="email-icon">
                            <IoMail size={22} color='white' />
                        </div>
                        <div>
                            <p className="contact-text" style={{ marginBottom: "3px" }}>For Email Support</p>
                            <p className="contact-text">info@steptonikah.com</p>
                        </div>
                    </div>
                    <div className="email-button">
                        Email Us
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Support;
