import React, { useEffect, useState } from "react";
import BasicPreference from "./BasicPreferences";
import EducationPreference from "./EducationPreference";
import LookingFor from "./LookingFor";
import { API_URL } from "../../../assets/Constants";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import LocationPreference from "./LocationPreference";

const PartnerPreference = ({ profilePhoto, partnerPreferenceDetails, basicDetails}) => {
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/web/homepage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setUser(response.data);

        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className="card mt-3 px-5 py-2">
            <div className="card-body p-2">
                <div className="row px-5">
                    <div className="col-3">
                        {user ? 
                            <img className="rounded" src={user.photoUrl} height="100" width="100" alt="View Profile" />
                            :
                            <Skeleton height={100} width={100}/>
                        }
                    </div>
                    <div className="col-6 py-10">
                        Your profile matches with {partnerPreferenceDetails?.preferenceScore} / {partnerPreferenceDetails?.preferenceCount} of <b>{basicDetails?.name}'s</b> preferences!
                    </div>
                    <div className="col-3 text-end">
                        {profilePhoto ?
                            <img className="rounded" src={profilePhoto} height="100" width="100" alt="View Profile" />
                            :
                            <Skeleton height={100} width={100}/>
                        }
                    </div>
                </div>
            </div>
            {partnerPreferenceDetails?.basicPreference && Object.keys(partnerPreferenceDetails.basicPreference).length > 0 && (
                <BasicPreference basicPreference={partnerPreferenceDetails.basicPreference} />
            )}

            {partnerPreferenceDetails?.educationPreference && partnerPreferenceDetails.educationPreference.length > 0 && (
                <EducationPreference educationPreference={partnerPreferenceDetails.educationPreference} />
            )}

            {partnerPreferenceDetails?.locationPreference && partnerPreferenceDetails.locationPreference.length > 0 && (
                <LocationPreference locationPreference={partnerPreferenceDetails.locationPreference} />
            )}

            {partnerPreferenceDetails?.lookingFor && partnerPreferenceDetails.lookingFor.trim() !== "" && (
                <LookingFor lookingFor={partnerPreferenceDetails.lookingFor} />
            )}
        </div>
    );
}

export default PartnerPreference;
