import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select';

const ReligiousDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { height, weight, maritalStatus, bodyType, hairColor, eyeColor, physicallyChallenged, complexion, ethnicGroup, highestEducation, course, professionType, profession, homeCountry, homeDistrict, homeState, homePlace, presentCountry, presentDistrict, presentState, presentPlace, preferredContactType, secondaryNumber, primaryNumber } = location.state || {};
    console.log(height, weight, maritalStatus, bodyType, hairColor, eyeColor, physicallyChallenged, complexion, ethnicGroup, highestEducation, course, professionType, profession, homeCountry, homeDistrict, homeState, homePlace, presentCountry, presentDistrict, presentState, presentPlace, preferredContactType, secondaryNumber, primaryNumber);

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedEatingHabits, setSelectedEatingHabits] = useState('');
    const [selectedFinancialStatus, setSelectedFinancialStatus] = useState('');
    const [description, setDescription] = useState('');
    const [selectedSection, setSelectedSection] = useState('');
    const [selectedRating, setSelectedRating] = useState('');
    const [selectedNamazTiming, setSelectedNamazTiming] = useState('');

    const language = [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'English', label: 'English' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Mizo', label: 'Mizo' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Odia', label: 'Odia' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Sikkimese', label: 'Sikkimese' },
        { value: 'Nepali', label: 'Nepali' }
    ];

    const financialStatus = [
        { value: 'Wealthy/Affluent', label: 'Wealthy/Affluent' },
        { value: 'Upper Middle Class', label: 'Upper Middle Class' },
        { value: 'Middle Class', label: 'Middle Class' },
        { value: 'Lower Middle Class', label: 'Lower Middle Class' },
        { value: 'Struggling Financially', label: 'Struggling Financially' },
        { value: 'Working Class', label: 'Working Class' },
        { value: 'Well-off', label: 'Well-off' },
        { value: 'Affluent', label: 'Affluent' },
        { value: 'High net worth family', label: 'High net worth family' },
        { value: 'Inherited wealth', label: 'Inherited wealth' },
        { value: 'Business family', label: 'Business family' },
        { value: 'Entrepreneurial family', label: 'Entrepreneurial family' },
        { value: 'Established family', label: 'Established family' },
        { value: 'Retired family income', label: 'Retired family income' },
        { value: 'Financially stable', label: 'Financially stable' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const IslamicSection = [
        { value: 'A Muslim', label: 'A Muslim' },
        { value: 'Ahamadi', label: 'Ahamadi' },
        { value: 'Ahle Hadees', label: 'Ahle Hadees' },
        { value: 'Bohra', label: 'Bohra' },
        { value: 'Hanabali', label: 'Hanabali' },
        { value: 'Hanafi', label: 'Hanafi' },
        { value: 'Ibadi', label: 'Ibadi' },
        { value: 'Ismaili', label: 'Ismaili' },
        { value: 'Jamat Islami', label: 'Jamat Islami' },
        { value: 'Maliki', label: 'Maliki' },
        { value: 'Pathan', label: 'Pathan' },
        { value: 'Prefer not to say', label: 'Prefer not to say' },
        { value: 'Salafi', label: 'Salafi' },
        { value: 'Salafi(KNM)', label: 'Salafi(KNM)' },
        { value: 'Salafi(Markaz Dawa)', label: 'Salafi(Markaz Dawa)' },
        { value: 'Salafi(Wisdom)', label: 'Salafi(Wisdom)' },
        { value: 'Sayyid', label: 'Sayyid' },
        { value: 'Shafi', label: 'Shafi' },
        { value: 'Shia', label: 'Shia' },
        { value: 'Sufism', label: 'Sufism' },
        { value: 'Sunni', label: 'Sunni' },
        { value: 'Sunni(AP)', label: 'Sunni(AP)' },
        { value: 'Sunni(EK)', label: 'Sunni(EK)' },
        { value: 'Thableegh Jamaat', label: 'Thableegh Jamaat' },
        { value: 'Urdu Muslim', label: 'Urdu Muslim' },
        { value: 'Zahiri', label: 'Zahiri' }
    ];

    const religiousnessRating = [
        { value: 'Devot', label: 'Devot' },
        { value: 'Very Religious', label: 'Very Religious' },
        { value: 'Religious', label: 'Religious' },
        { value: 'Moderately Religious', label: 'Moderately Religious' },
        { value: 'Occasionally Religious', label: 'Occasionally Religious' },
        { value: 'Cultural but non-practicing', label: 'Cultural but non-practicing' },
        { value: 'Spiritual but not religious', label: 'Spiritual but not religious' },
        { value: 'Religious but not practicing', label: 'Religious but not practicing' },
        { value: 'Open to exploring religion', label: 'Open to exploring religion' },
        { value: 'Agnostic', label: 'Agnostic' },
        { value: 'Secular', label: 'Secular' },
        { value: 'Open to all beliefs', label: 'Open to all beliefs' },
        { value: 'Not religious', label: 'Not religious' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const namazTiming = [
        { value: 'Yes,Five times daily', label: 'Yes, Five times daily' },
        { value: 'Yes,regularly(but not five times daily)', label: 'Yes, regularly (but not five times daily)' },
        { value: 'Yes,most days', label: 'Yes, most days' },
        { value: 'Yes,occasionally', label: 'Yes, occasionally' },
        { value: 'Only on special occasions(e.g Eid)', label: 'Only on special occasions (e.g., Eid)' },
        { value: 'Only during Ramadan', label: 'Only during Ramadan' },
        { value: 'Only on Fridays', label: 'Only on Fridays' },
        { value: 'Rarely', label: 'Rarely' },
        { value: 'No,but open to it', label: 'No, but open to it' },
        { value: 'No,but I respect it', label: 'No, but I respect it' },
        { value: 'No', label: 'No' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const eatingHabits = [
        { value: 'Veg', label: 'Veg' },
        { value: 'Eggetarian', label: 'Eggetarian' },
        { value: 'Occasionally Non-veg', label: 'Occasionally Non-veg' },
        { value: 'Non-Veg', label: 'Non-Veg' }
    ];

    const handleProfileCreation = async () => {
        if (![selectedEatingHabits, selectedFinancialStatus, selectedNamazTiming, selectedRating, selectedSection, description].every(Boolean)) {
            toast.error('Please Enter Required Fields');
            return;
        }

        const userId = localStorage.getItem('userId');
        navigate('/upload-image', {
            state: {
                height,
                weight,
                maritalStatus,
                bodyType,
                hairColor,
                eyeColor,
                physicallyChallenged,
                complexion,
                ethnicGroup,
                highestEducation,
                professionType,
                profession,
                homeCountry,
                homeDistrict,
                homeState,
                homePlace,
                presentCountry,
                presentDistrict,
                presentState,
                presentPlace,
                preferredContactType,
                secondaryNumber,
                primaryNumber,
                eatingHabits: selectedEatingHabits,
                financialStatus: selectedFinancialStatus,
                performNamaz: selectedNamazTiming,
                religiousness: selectedRating,
                community: selectedSection,
                profileDescription: description,
                contactPerson: "Myself",
            }
        })
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <h2>Create Profile</h2>
            <p>Please fill the details below.</p>

            <div className="input-container">
                <label>Mother Tongue</label>
                <Select
                    options={language}
                    onChange={(selected) => setSelectedLanguage(selected.value)}
                    placeholder="Select language"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedLanguage ? { value: selectedLanguage, label: selectedLanguage } : null}
                />
            </div>

            <div className="input-container">
                <label>Eating Habits</label>
                <Select
                    options={eatingHabits}
                    onChange={(selected) => setSelectedEatingHabits(selected.value)}
                    placeholder="Select Eating Habits"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedEatingHabits ? { value: selectedEatingHabits, label: selectedEatingHabits } : null}
                />
            </div>

            <div className="input-container">
                <label>Sects of Islam</label>
                <Select
                    options={IslamicSection}
                    onChange={(selected) => setSelectedSection(selected.value)}
                    placeholder="Search Sect of Islam"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedSection ? { value: selectedSection, label: selectedSection } : null}
                />
            </div>

            <div className="input-container">
                <label>Financial Status</label>
                <Select
                    options={financialStatus}
                    onChange={(selected) => setSelectedFinancialStatus(selected.value)}
                    placeholder="Select Financial Status"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedFinancialStatus ? { value: selectedFinancialStatus, label: selectedFinancialStatus } : null}
                />
            </div>

            <div className="input-container">
                <label>Namaz/Salah Performance</label>
                <Select
                    options={namazTiming}
                    onChange={(selected) => setSelectedNamazTiming(selected.value)}
                    placeholder="Select Namaz/salah performance"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedNamazTiming ? { value: selectedNamazTiming, label: selectedNamazTiming } : null}
                />
            </div>

            <div className="input-container">
                <label>Religiousness Rating</label>
                <Select
                    options={religiousnessRating}
                    onChange={(selected) => setSelectedRating(selected.value)}
                    placeholder="Select Religiousness Rating"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedRating ? { value: selectedRating, label: selectedRating } : null}
                />
            </div>

            <div className="input-container">
                <h3>Profile Description</h3>
                <label>Write a short description about yourself</label>
                <textarea
                    className="text-field"
                    placeholder="Profile Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleProfileCreation}>
                    NEXT
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default ReligiousDetails;
