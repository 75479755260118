import React from "react";
import { Link } from "react-router-dom";
import logo from '../assets/img/logo/logo.png';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/register');
    };

    return (
        <div className="mil-top-panel">
            <div className="container">
                <Link to="/" className="mil-logo">
                    <img src={logo} alt="step-to-nikah" height="50" />
                </Link>
                <nav className="mil-top-menu">
                    <ul>
                        <li>
                            {/* <Link to="/">Home</Link> */}
                            <a href="#!">Home</a>
                        </li>
                        <li>
                            {/* <Link to="/about">About us</Link> */}
                            <a href="#!">About us</a>
                        </li>
                        <li>
                            {/* <Link to="/contact">Contact us</Link> */}
                            <a href="#!">Contact us</a>
                        </li>
                    </ul>
                </nav>
                <div className="mil-menu-buttons">
                    <a href="/register" className="mil-btn mil-sm" style={{ height: '40px', width: '100px' }}>
                        Register
                    </a>
                    {/* <div className="mil-menu-btn">
                        <span></span>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default Header;
