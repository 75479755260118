import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { API_URL } from '../../assets/Constants';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SavedSearch = () => {
    const [savedSearch, setSavedSearch] = useState([]);
    const navigate = useNavigate();

    const fetchSavedSearch = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/profile/savedSearches`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data.status) {
                setSavedSearch(response.data.savedSearches);
            }

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchSavedSearch();
    }, []);

    const applySearch= async (id) => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/web/profileSearch/${id}/applySearch`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.status === 200) {
                console.log(response.data);
                localStorage.setItem("search_id", JSON.stringify(id));
                navigate("/search-apply");  
            }

        }catch(error){
            console.error('Error fetching data:', error);
        }
    }

    const deleteSearch = async (id) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/profile/savedSearches/${id}/remove`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.status) {
                toast.success("Profile Search deleted!");
                setSavedSearch((prev) =>
                    prev.filter((search) => search.id !== id)
                );
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error deleting data:", error);
            toast.error("Error deleting data");
        }
    };

    return (
        <div className="tab-pane fade" id="nav-saved-search" role="tabpanel">
            <div className="row">
                <h5 className="card-header">Saved Searches</h5>
                <div className="table-responsive text-nowrap">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>SL No</th>
                                <th>Search Name</th>
                                <th>View Result</th>
                                <th>Delete</th>
                            </tr>
                        </thead>
                        <tbody>
                            {savedSearch.map((search, index) => (
                                <tr key={search.id}>
                                    <td>{index + 1}</td>
                                    <td>{search.search_name}</td>
                                    <td>
                                        <button
                                            className="btn btn-primary btn-sm"
                                            type="button"
                                            onClick={() => applySearch(search.id)}
                                        >
                                            <i className="ri ri-eye-line me-2"></i>
                                            View
                                        </button>
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-danger btn-sm"
                                            type="button"
                                            onClick={() => deleteSearch(search.id)}
                                        >
                                            <i className="ri ri-delete-bin-line me-2"></i>
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SavedSearch;
