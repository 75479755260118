import React, { useEffect, useState } from "react";
import PhysicalAttributeModal from "./Modals/PhysicalAttributesModal";

const PhysicalAttributes = ({physicalDetails : initialPhysicalDetails}) => {
    const [showModal, setShowModal] = useState(false);
    const [physicalDetails, setPhysicalDetails] = useState(initialPhysicalDetails);

    useEffect(() => {
        setPhysicalDetails(initialPhysicalDetails);
    }, [initialPhysicalDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setPhysicalDetails(prev => ({
            ...prev,
            height: updatedDetails.height,
            weight: updatedDetails.weight,
            complexion: updatedDetails.complexion,
            bodyType: updatedDetails.bodyType,
            bloodGroup: updatedDetails.bloodGroup,
            hairColor: updatedDetails.hairColor,
            hairType: updatedDetails.hairType,
            facialHair: updatedDetails.facialHair,
            eyeColor: updatedDetails.eyeColor,
            eyeWear: updatedDetails.eyeWear,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Physical Attributes</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Height</th>
                            <td>: {physicalDetails?.height || 'N/A'}</td>
                            <th>Weight</th>
                            <td>: {physicalDetails?.weight || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Complexion</th>
                            <td>: {physicalDetails?.skin_tone || 'N/A'}</td>
                            <th>Body Type</th>
                            <td>: {physicalDetails?.body_type || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Blood Group</th>
                            <td>: {physicalDetails?.blood_group || 'N/A'}</td>
                            <th>Hair Color</th>
                            <td>: {physicalDetails?.hair_color || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Hair Type</th>
                            <td>: {physicalDetails?.hair_type || 'N/A'}</td>
                            <th>Facial Hair</th>
                            <td>: {physicalDetails?.facial_hair || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Eye Color</th>
                            <td>: {physicalDetails?.eye_color || 'N/A'}</td>
                            <th>Eye Wear</th>
                            <td>: {physicalDetails?.eye_wear || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <PhysicalAttributeModal
                    details={{
                        height: physicalDetails?.height,
                        weight: physicalDetails?.weight,
                        complexion: physicalDetails?.skin_tone,
                        bodyType: physicalDetails?.body_type,
                        bloodGroup: physicalDetails?.blood_group,
                        hairColor: physicalDetails?.hair_color,
                        hairType: physicalDetails?.hair_type,
                        facialHair: physicalDetails?.facial_hair,
                        eyeColor: physicalDetails?.eye_color,
                        eyeWear: physicalDetails?.eye_wear,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default PhysicalAttributes;