import React from "react";

const FamilyDetails = ({ familyDetails }) => {
    return(
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Family Details</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Family Type</th>
                            <td>: {familyDetails?.family_type || 'N/A'}</td>
                            <th>Financial Status</th>
                            <td>: {familyDetails?.financial_status || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Father Details</th>
                            <td>: {familyDetails?.father_detail || 'N/A'} {familyDetails?.father_profession ? `(${familyDetails.father_profession})` : ''}</td>
                            <th>Mother Details</th>
                            <td>: {familyDetails?.mother_detail || 'N/A'} {familyDetails?.mother_profession ? `(${familyDetails.mother_profession})` : ''}</td>
                        </tr>
                        <tr>
                            <th>Home Type</th>
                            <td>: {familyDetails?.home_type || 'N/A'}</td>
                            <th>Living Situation</th>
                            <td>: {familyDetails?.living_situations || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Family Values</th>
                            <td>: {familyDetails?.family_values || 'N/A'}</td>
                            <th>No. Elder Sisters</th>
                            <td>: {familyDetails?.no_of_elder_sisters || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Younger Sisters</th>
                            <td>: {familyDetails?.no_of_younger_sisters || 'N/A'}</td>
                            <th>No. Married Sisters</th>
                            <td>: {familyDetails?.no_of_married_sisters || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Elder Brothers</th>
                            <td>: {familyDetails?.no_of_elder_brothers || 'N/A'}</td>
                            <th>No. Younger Brothers</th>
                            <td>: {familyDetails?.no_of_younger_brothers || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Married Brothers</th>
                            <td>: {familyDetails?.no_of_married_brothers || 'N/A'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default FamilyDetails;
