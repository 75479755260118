import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack, IoArrowForward, IoFemale, IoMale } from 'react-icons/io5';

const Colors = {
    primary: "#572d71",
    background: "#f4f4f5",
    grey: "#C0C0C0",
}

const SelectGender = () => {
    const [selectedGender, setSelectedGender] = useState(null);
    const navigate = useNavigate();

    const handleGenderSelection = (gender) => {
        setSelectedGender(gender);
    };

    const handleNavigation = () => {
        if (selectedGender) {
            localStorage.setItem("gender", selectedGender);
            navigate('/select-dob');
        }
    };

    return (
        <div className="select-gender-container">
            <div className="content">
                <h1 className="title">Create Account</h1>
                <p className="subTitle">Select gender</p>

                <div className="gender-container">
                    <div className={`gender-option ${selectedGender === 'Male' ? 'selected' : ''}`}
                        onClick={() => handleGenderSelection('Male')}>
                        <button className={`icon-container ${selectedGender === 'Male' ? 'selected' : ''}`}>
                            <div className="gender-icon">
                                <IoMale name="male" size={24} color="#FFF" />
                            </div>
                        </button>
                        <p className="gender-text">Male</p>
                    </div>

                    <div className={`gender-option ${selectedGender === 'Female' ? 'selected' : ''}`}
                        onClick={() => handleGenderSelection('Female')}>
                        <button className={`icon-container ${selectedGender === 'Female' ? 'selected' : ''}`}>
                            <div className="gender-icon">
                                <IoFemale name="female" size={24} color="#FFF" />
                            </div>
                        </button>
                        <p className="gender-text">Female</p>
                    </div>
                </div>
            </div>

            <div className="navigation-container">
                <button
                    className="nav-button"
                    onClick={() => navigate(-1)}
                    style={{ backgroundColor: Colors.primary }}
                >
                    <IoArrowBack type="arrow-left" style={{ fontSize: '24px', color: Colors.background }} />
                </button>

                <button
                    className={`nav-button ${selectedGender ? 'active' : ''}`}
                    onClick={handleNavigation}
                    disabled={!selectedGender}
                >
                    <IoArrowForward type="arrow-right" style={{ fontSize: '24px', color: selectedGender ? Colors.background : Colors.grey }} />
                </button>
            </div>
        </div>
    );
};

export default SelectGender;
