import axios from 'axios';
import React, { useState } from 'react'
import { API_URL } from '../../assets/Constants';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const SearchForm = () => {
    const [formData, setFormData] = useState({
        ageFrom: 18,
        ageTo: 60,
        heightFrom: 140,
        heightTo: 180,
        weightFrom: 40,
        weightTo: 100,
        bodyType: ['Any'],
        physicalStatus: ['Normal Person'],
        maritalStatus: ['Any'],
        community: ['Any'],
        performNamaz: ['Any'],
        districtsPreference: ['Any'],
        presentCountry: ['Any'],
        education: ['Any'],
        profession: ['Any'],
        professionType: ['Any'],
        financialStatus: ['Any'],
        sortBy: 'login',
        showWithPhoto: 'yes',
        saveSearch: false,
        ethnicity: ["Any"],
        religiousness: ["Any"],
        eatingPreference: ["Any"],
    });

    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleChange = (selected) => {
        setSelectedOptions(selected || []); 
    };
    

    const handleInputChange = (field, value) => {
        setFormData(prevState => ({ ...prevState, [field]: value }));
    };
    const handleSelectInputChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: [value]
        }));
    };
    const handleMultiSelectChange = (field, value) => {
        setFormData(prevState => {
            const updatedValues = prevState[field].includes(value)
                ? prevState[field].filter(item => item !== value)
                : [...prevState[field], value];
            return { ...prevState, [field]: updatedValues };
        });
    };

    const handleCheckboxChange = (status) => {
        setFormData((prevState) => {
            const updatedStatuses = prevState.maritalStatus.includes(status)
                ? prevState.maritalStatus.filter((s) => s !== status)
                : [...prevState.maritalStatus, status];
            return { ...prevState, maritalStatus: updatedStatuses };
        });
    };

    const navigate = useNavigate();
    const handleSubmit = async () =>  {
        console.log('Submitted Data:', formData);
        try {
            const token = localStorage.getItem("token") || '';
    
            const response = await axios.post(
                `${API_URL}/api/web/profileSearch`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
    
            if (response.status === 200) {     
                if(response.data.data.length > 0) {
                    localStorage.setItem("form_data", JSON.stringify(formData));
                    navigate("/search-view");           
                } else {
                    toast.error(response.data.message || 'error occurred !!');
                }
            } else {
                toast.error(response.data.message || 'error occurred !!');
            }
        } catch (error) {
            if (error.response && error.response.status === 500) {
                toast.error(error.response.data.message || 'error occurred !!');
            } else {
                toast.error('Some error occurred !!');
            }
        }
    };
    //options
    const maritalStatusOptions = [
        'Any',
        'Never Married',
        'Divorced',
        'Widowed/Widower',
        'Nikah Divorce',
        'Awaiting Divorce',
      ];
    return (
        <div className="tab-pane fade active show" id="nav-search" role="tabpanel">
            <h5>Search Profiles</h5>
            <div className="row mb-3">
                <label className="col-3">Age</label>
                <div className="col-3">
                    <select
                        className="form-control form-control-sm"
                        value={formData.ageFrom}
                        onChange={(e) => handleInputChange('ageFrom', e.target.value)}
                    >
                        {[...Array(43).keys()].map(age => (
                            <option key={age} value={age + 18}>{age + 18}</option>
                        ))}
                    </select>
                </div>
                <label className="col-1">To</label>
                <div className="col-3 text-start">
                    <select
                        className="form-control form-control-sm"
                        value={formData.ageTo}
                        onChange={(e) => handleInputChange('ageTo', e.target.value)}
                    >
                        {[...Array(43).keys()].map(age => (
                            <option key={age} value={age + 18}>{age + 18}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="row mb-3">
                <label for="exampleInputEmail2" className="col-sm-3">Height</label>
                <div className="col-3">
                    <select
                        className="form-control form-control-sm"
                        value={formData.heightFrom}
                        onChange={(e) => handleInputChange('heightFrom', e.target.value)}
                    >
                        {[...Array(41).keys()].map(height => (
                            <option key={height} value={height + 140}>{height + 140}</option>
                        ))}
                    </select>
                </div>
                <label className="col-1">To</label>
                <div className="col-3 text-start">
                    <select
                        className="form-control form-control-sm"
                        value={formData.heightTo}
                        onChange={(e) => handleInputChange('heightTo', e.target.value)}
                    >
                        {[...Array(41).keys()].map(height => (
                            <option key={height} value={height + 140}>{height + 140}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label htmlFor="exampleInputEmail2" className="col-sm-3">Weight</label>
                <div className="col-3">
                    <select
                        className="form-control form-control-sm"
                        value={formData.weightFrom}
                        onChange={(e) => handleInputChange('weightFrom', e.target.value)}
                    >
                        {[...Array(61).keys()].map(weight => (
                            <option key={weight} value={weight + 40}>{weight + 40}</option>
                        ))}
                    </select>
                </div>
                <label className="col-1">To</label>
                <div className="col-3 text-start">
                    <select
                        className="form-control form-control-sm"
                        value={formData.weightTo}
                        onChange={(e) => handleInputChange('weightTo', e.target.value)}
                    >
                        {[...Array(61).keys()].map(weight => (
                            <option key={weight} value={weight + 40}>{weight + 40}</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label htmlFor="exampleInputMobile" className="col-sm-3">
                    Marital Status
                </label>
                <div className="col-sm-9">
                    <div>
                    {maritalStatusOptions.map((status, index) => (
                        <div key={index} className="form-check form-check-inline">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id={`status-${index}`}
                                checked={formData.maritalStatus.includes(status)}
                                onChange={() => handleCheckboxChange(status)}
                            />
                            <label className="form-check-label" htmlFor={`status-${index}`}>
                                {status}
                            </label>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-3">Body Type</label>
                <div className="col-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.bodyType[0]}
                        onChange={(e) => handleSelectInputChange('bodyType', e.target.value)} >
                        <option value="Any">Any</option>
                        <option value="Muscular">Muscular</option>
                        <option value="Athletic">Athletic</option>
                        <option value="Average">Average</option>
                        <option value="Slim">Slim</option>
                        <option value="Fat">Fat</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3 ">Physical Status</label>
                <div className="col-sm-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.physicalStatus[0]}
                        onChange={(e) => handleSelectInputChange('physicalStatus', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Normal Person'>Normal Person</option>
                        <option value='Deaf'>Deaf</option>
                        <option value='Blind'>Blind</option>
                        <option value='Physically Challenged'>Physically Challenged</option>
                        <option value='Mentally Challenged'>Mentally Challenged</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3 ">Community</label>
                <div className="col-sm-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.community[0]}
                        onChange={(e) => handleSelectInputChange('community', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Sunni'>Sunni</option>
                        <option value='Jamat Islami'>Jamat Islami</option>
                        <option value='Urdu Muslim'>Urdu Muslim</option>
                        <option value='Shafi'>Shafi</option>
                        <option value='Hanafi'>Hanafi</option>
                        <option value='Hanabali'>Hanabali</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3 ">Perform Namaz</label>
                <div className="col-sm-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.performNamaz[0]}
                        onChange={(e) => handleSelectInputChange('performNamaz', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Five times daily'>Five times daily</option>
                        <option value='Regularly'>Regularly</option>
                        <option value='Most Days'>Most Days</option>
                        <option value='Occasionally'>Occasionally</option>
                        <option value='Rarely'>Rarely</option>
                        <option value='No'>No</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3">Education</label>
                <div className="col-sm-9">
                <select
                        className="form-control form-control-sm"
                        value={formData.education[0]}
                        onChange={(e) => handleSelectInputChange('education', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Any Graduation and Above'>Any Graduation and Above</option>
                        <option value='Post Graduation and Above'>Post Graduation and Above</option>
                        <option value='Higher Secondary'>Higher Secondary</option>
                        <option value='Doctorate'>Doctorate</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3">Profession</label>
                <div className="col-sm-9">
                <select
                        className="form-control form-control-sm"
                        value={formData.profession[0]}
                        onChange={(e) => handleSelectInputChange('profession', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Civil Engineer'>Civil Engineer</option>
                        <option value='Software Developer'>Software Developer</option>
                        <option value='Teacher'>Teacher</option>
                        <option value='Doctor'>Doctor</option>
                        <option value='Nurse'>Nurse</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3">Profession Type</label>
                <div className="col-sm-9">
                <select
                        className="form-control form-control-sm"
                        value={formData.professionType[0]}
                        onChange={(e) => handleSelectInputChange('professionType', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Full-Time'>Full-Time</option>
                        <option value='Part-Time'>Part-Time</option>
                        <option value='Trainee'>Trainee</option>
                        <option value='Freelancer'>Freelancer</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3">Financial Status</label>
                <div className="col-sm-9">
                <select
                        className="form-control form-control-sm"
                        value={formData.financialStatus[0]}
                        onChange={(e) => handleSelectInputChange('financialStatus', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='Rich'>Rich</option>
                        <option value='Upper Middle Class'>Upper Middle Class</option>
                        <option value='Middle Class'>Middle Class</option>
                        <option value='Lower Middle Class'>Lower Middle Class</option>
                        <option value='Poor'>Poor</option>
                    </select>
                </div>
            </div>
            <h5 className="card-title mt-3 mb-3">Location</h5>
            <div className="row mb-3">
                <label className="col-sm-3">Home District</label>
                <div className="col-sm-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.districtsPreference[0]}
                        onChange={(e) => handleSelectInputChange('districtsPreference', e.target.value)} >
                        <option value='' selected>Any</option>
                        <option value="Alappuzha">Alappuzha</option>
                        <option value="Ernakulam">Ernakulam</option>
                        <option value="Idukki">Idukki</option>
                        <option value="Kannur">Kannur</option>
                        <option value="Kasaragod">Kasaragod</option>
                        <option value="Kollam">Kollam</option>
                        <option value="Kottayam">Kottayam</option>
                        <option value="Kozhikode">Kozhikode</option>
                        <option value="Malappuram">Malappuram</option>
                        <option value="Palakkad">Palakkad</option>
                        <option value="Pathanamthitta">Pathanamthitta</option>
                        <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                        <option value="Thrissur">Thrissur</option>
                        <option value="Wayanad">Wayanad</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <label className="col-sm-3">Present Country</label>
                <div className="col-sm-9">
                    <select
                        className="form-control form-control-sm"
                        value={formData.presentCountry[0]}
                        onChange={(e) => handleSelectInputChange('presentCountry', e.target.value)} >
                        <option value='Any'>Any</option>
                        <option value='India'>India</option>
                        <option value='UAE'>UAE</option>
                        <option value='Qatar'>Qatar</option>
                        <option value='Bahrain'>Bahrain</option>
                        <option value='Saudi Arabia'>Saudi Arabia</option>
                    </select>
                </div>
            </div>
            {/* <div className="row mb-3">
                <label for="exampleInputMobile" className="col-sm-3">Sort By</label>
                <div className="col-sm-9">
                    <div className="mb-3">
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" name="radioDefault" id="radioDefault" />
                            <label className="form-check-label" for="radioDefault">
                                Recent Login
                            </label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input type="radio" className="form-check-input" name="radioDefault" id="radioDefault1" />
                            <label className="form-check-label" for="radioDefault1">
                                Recent Registration
                            </label>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="row mb-3">
                <label htmlFor="saveSearch" className="col-sm-3">
                    Save Search
                </label>
                <div className="col-sm-9">
                    <div className="mb-3">
                    <div className="form-check form-check-inline">
                        <input
                        type="checkbox"
                        className="form-check-input"
                        id="saveSearch"
                        checked={formData.saveSearch}
                        onChange={(e) => handleInputChange('saveSearch', e.target.checked)}
                        />
                        <label className="form-check-label" htmlFor="saveSearch">
                        Save This Search
                        </label>
                    </div>
                    </div>
                </div>
            </div>

            
            {/* Submit Button */}
            <button type="button" className="btn btn-primary" onClick={handleSubmit}>
                Search
            </button>
        </div>
    );
};

export default SearchForm;
