import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logo from '../assets/images/logo.png';
import { MdChevronRight } from 'react-icons/md';
import { Toaster, toast } from 'react-hot-toast';

const EmailVerification = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        setLoading(true);
        if (email === '' || password === '') {
            toast.error('Please fill in the required fields');
            setLoading(false);
            return;
        }

        const networkState = navigator.onLine;
        if (!networkState) {
            toast.error('Please check your internet connection and try again');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`https://admin.steptonikah.com/api/login`, {
                email,
                password,
            });

            if (response.data) {
                const { token, id, status } = response.data;

                console.log(response.data, "Response");
                // toast.success('Login Success');

                localStorage.setItem('token', token);
                localStorage.setItem('userId', String(id));
                localStorage.setItem('userStatus', status);

                const navigationPath = status === "active" ? '/home' : '/create-profile';
                const successMessage = status === "active"
                    ? 'Login Success!'
                    : 'Profile creation incomplete. Redirecting...';

                setTimeout(() => {
                    navigate(navigationPath);
                }, 500);

                toast.success(successMessage);
            }
        } catch (error) {
            if (error.response) {
                const errorMessage = error.response.data?.message || 'Invalid Email or Password';
                toast.error(errorMessage);
            } else if (error.request) {
                toast.error('Unable to reach the server. Please try again later.');
            } else {
                toast.error(`Something went wrong: ${error.message}`);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="email-container">
            <Toaster />
            <div className="login-box">
                <div className="logo-container">
                    <img src={Logo} alt="Logo" className="logo" />
                </div>
                <h2 className="login-title">Login</h2>
                <p className="login-subtitle">Please sign in to continue</p>
                <input
                    type="email"
                    className="input-field"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="password-container">
                    <input
                        type={showPassword ? "text" : "password"}
                        className="input-field-pass"
                        placeholder="Enter your password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className="toggle-password" onClick={togglePasswordVisibility}>
                        <i className={showPassword ? "ri-eye-line" : "ri-eye-off-line"}></i>
                    </span>
                </div>
                <p className="forgot-password" onClick={() => navigate('/forget-password')}>
                    Forgot password?
                </p>
                <button className="submit-button" onClick={handleLogin} disabled={loading}>
                    LOGIN
                </button>
            </div>
            <button onClick={() => navigate('/select-profile')} className="register-button">
                <span>Create a new account</span>
                <MdChevronRight size={16} />
            </button>
        </div>
    );
};

export default EmailVerification;
