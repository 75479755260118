import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditEducation = () => {
    const location = useLocation();
    const { educationDetails } = location.state;
    const [selectedEducation, setSelectedEducation] = useState(educationDetails.highest_education);
    const [selectedCourse, setSelectedCourse] = useState(educationDetails.qualification);
    const [selectedOccupation, setSelectedOccupation] = useState(educationDetails.profession);
    const [selectedProfession, setSelectedProfession] = useState(educationDetails.profession_type);
    const [educationInstitute, setEducationInstitute] = useState(educationDetails.education_institute);
    const [companyName, setCompanyName] = useState(educationDetails.company_name);
    const [selectedAnnualIncome, setSelectedAnnualIncome] = useState(educationDetails.annual_income);
    const [educationDescription, setEducationDescription] = useState(educationDetails.education_details);
    const [jobDetails, setJobDetails] = useState(educationDetails.job_details);

    const navigate = useNavigate();

    const education = [
        { value: 'Doctorate', label: 'Doctorate' },
        { value: 'High/Secondary School', label: 'High/Secondary School' },
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Bachelors Degree', label: 'Bachelors Degree' },
        { value: 'Masters Degree', label: 'Masters Degree' },
        { value: 'PhD', label: 'PhD' },
        { value: 'Trade School/TTC/ITI', label: 'Trade School/TTC/ITI' },
        { value: 'Other', label: 'Other' },
    ];

    const occupation = [
        { value: 'Not Working', label: 'Not Working' },
        { value: 'Student', label: 'Student' },
        { value: 'Non-Mainstream Professional', label: 'Non-Mainstream Professional' },
        { value: 'Acting professional', label: 'Acting professional' },
        { value: 'Administration Professional', label: 'Administration Professional' },
        { value: 'Air Hostess', label: 'Air Hostess' },
        { value: 'Doctor', label: 'Doctor' },
        { value: 'Engineer', label: 'Engineer' },
        { value: 'Teacher', label: 'Teacher' },
        { value: 'Lawyer', label: 'Lawyer' },
        { value: 'Accountant', label: 'Accountant' },
        { value: 'Software Developer', label: 'Software Developer' },
        { value: 'Architect', label: 'Architect' },
        { value: 'Designer', label: 'Designer' },
        { value: 'Writer', label: 'Writer' },
        { value: 'Artist', label: 'Artist' },
        { value: 'Musician', label: 'Musician' },
        { value: 'Actor', label: 'Actor' },
        { value: 'Salesperson', label: 'Salesperson' },
        { value: 'Marketing Professional', label: 'Marketing Professional' },
        { value: 'Finance Professional', label: 'Finance Professional' },
        { value: 'Human Resources Professional', label: 'Human Resources Professional' },
        { value: 'Customer Service Representative', label: 'Customer Service Representative' },
        { value: 'Administrative Assistant', label: 'Administrative Assistant' },
        { value: 'Technician', label: 'Technician' }
    ];

    const professionType = [
        { value: 'Student', label: 'Student' },
        { value: 'Professional', label: 'Professional' },
        { value: 'Business Owner', label: 'Business Owner' },
        { value: 'Government Employee', label: 'Government Employee' },
        { value: 'Homemaker', label: 'Homemaker' },
        { value: 'Retired', label: 'Retired' },
        { value: 'Other', label: 'Other' }
    ];

    const annualIncome = [
        { value: 'Student', label: 'Student' },
        { value: 'No Income', label: 'No Income' },
        { value: 'Less than 50,000', label: 'Less than 50,000' },
        { value: '50,000 - 1 lakhs', label: '50,000 - 1 lakhs' },
        { value: '1 lakhs - 2 lakhs', label: '1 lakhs - 2 lakhs' },
        { value: '2 lakhs - 3 lakhs', label: '2 lakhs - 3 lakhs' },
        { value: '3 lakhs - 5 lakhs', label: '3 lakhs - 5 lakhs' },
        { value: '5 lakhs - 10 lakhs', label: '5 lakhs - 10 lakhs' },
        { value: '10 lakhs - 15 lakhs', label: '10 lakhs - 15 lakhs' },
        { value: '15 lakhs - 20 lakhs', label: '15 lakhs - 20 lakhs' },
        { value: '20 lakhs above', label: '20 lakhs above' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const coursesByEducation = {
        "Doctorate": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Masters Degree": [
            { value: 'IES', label: 'IES' },
            { value: 'Master of Veterinary Science', label: 'Master of Veterinary Science' },
            { value: 'MSc Nursing', label: 'MSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'M Pharm', label: 'M Pharm' },
            { value: 'Master of Law', label: 'Master of Law' },
            { value: 'LLM', label: 'LLM' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA', label: 'CA' },
            { value: 'ICWA', label: 'ICWA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'IRS', label: 'IRS' },
            { value: 'MAMS', label: 'MAMS' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'MUMS', label: 'MUMS' },
            { value: 'Pharm D', label: 'Pharm D' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'MSc Biotechnology', label: 'MSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'MSc Visual Communication', label: 'MSc Visual Communication' },
            { value: 'Msc Radiology', label: 'Msc Radiology' },
            { value: 'M.Voc Cardiac Care Technology', label: 'M.Voc Cardiac Care Technology' },
            { value: 'M.Design', label: 'M.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'M.Sc', label: 'M.Sc' },
            { value: 'MBA', label: 'MBA' },
            { value: 'M.Arch', label: 'M.Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'MA', label: 'MA' },
            { value: 'MCA', label: 'MCA' },
            { value: 'M Com', label: 'M Com' },
            { value: 'MEd', label: 'MEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'MFM(Financial Management)', label: 'MFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'MHM', label: 'MHM' },
            { value: 'MD/MS(Medical)', label: 'MD/MS(Medical)' },
            { value: 'MPT', label: 'MPT' },
            { value: 'MLIS', label: 'MLIS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'MSc Computer Science', label: 'MSc Computer Science' },
            { value: 'MSc IT', label: 'MSc IT' },
            { value: 'MS(Engg)', label: 'MS(Engg)' },
            { value: 'PGDCA', label: 'PGDCA' },
            { value: 'M Phill', label: 'M Phill' },
            { value: 'MSW', label: 'MSW' },
            { value: 'PGDM', label: 'PGDM' },
            { value: 'MDS', label: 'MDS' },
            { value: 'MHMS', label: 'MHMS' },
            { value: 'M Tech', label: 'M Tech' },
            { value: 'ME', label: 'ME' }
        ],
        "Bachelors Degree": [
            { value: 'IRS', label: 'IRS' },
            { value: 'BAMS', label: 'BAMS' },
            { value: 'Bachelor of Veterinary Science', label: 'Bachelor of Veterinary Science' },
            { value: 'B Pharm', label: 'B Pharm' },
            { value: 'BSc Nursing', label: 'BSc Nursing' },
            { value: 'Medical Laboratory Technology', label: 'Medical Laboratory Technology' },
            { value: 'Bachelor of Law', label: 'Bachelor of Law' },
            { value: 'LLB', label: 'LLB' },
            { value: 'Course in Legal', label: 'Course in Legal' },
            { value: 'CA Intern', label: 'CA Intern' },
            { value: 'CA', label: 'CA' },
            { value: 'Company Secretary(CS)', label: 'Company Secretary(CS)' },
            { value: 'CFA(Chartered Financial Analyst)', label: 'CFA(Chartered Financial Analyst)' },
            { value: 'Finance', label: 'Finance' },
            { value: 'IAS', label: 'IAS' },
            { value: 'IPS', label: 'IPS' },
            { value: 'BHMS', label: 'BHMS' },
            { value: 'IES', label: 'IES' },
            { value: 'IFS', label: 'IFS' },
            { value: 'Civil Service', label: 'Civil Service' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'BUMS', label: 'BUMS' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Audiology', label: 'Audiology' },
            { value: 'BSc Biotechnology', label: 'BSc Biotechnology' },
            { value: 'ACCA', label: 'ACCA' },
            { value: 'BSc Visual Communication', label: 'BSc Visual Communication' },
            { value: 'BSc Radiology', label: 'BSc Radiology' },
            { value: 'B.Voc Cardiac Care Technology', label: 'B.Voc Cardiac Care Technology' },
            { value: 'B.Design', label: 'B.Design' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'BPT', label: 'BPT' },
            { value: 'BBA', label: 'BBA' },
            { value: 'B Arch', label: 'B Arch' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'BA', label: 'BA' },
            { value: 'BCom', label: 'BCom' },
            { value: 'BCA', label: 'BCA' },
            { value: 'BEd', label: 'BEd' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'BGL', label: 'BGL' },
            { value: 'BFM(Financial Management)', label: 'BFM(Financial Management)' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'BHM', label: 'BHM' },
            { value: 'MBBS', label: 'MBBS' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'BLIS', label: 'BLIS' },
            { value: 'BSc', label: 'BSc' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'BFA', label: 'BFA' },
            { value: 'B Plan', label: 'B Plan' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'BSc Computer Science', label: 'BSc Computer Science' },
            { value: 'BSc IT', label: 'BSc IT' },
            { value: 'Bachelor of Engineering', label: 'Bachelor of Engineering' },
            { value: 'B phil', label: 'B phil' },
            { value: 'BSW', label: 'BSW' },
            { value: 'BDS', label: 'BDS' },
            { value: 'BTech', label: 'BTech' },
            { value: 'BE', label: 'BE' }
        ],
        "Diploma": [
            { value: 'Science', label: 'Science' },
            { value: 'Agriculture', label: 'Agriculture' },
            { value: 'Mass Communication', label: 'Mass Communication' },
            { value: 'D Pharm', label: 'D Pharm' },
            { value: 'Draftman', label: 'Draftman' },
            { value: 'Religious Education', label: 'Religious Education' },
            { value: 'Other Education', label: 'Other Education' },
            { value: 'Nursing', label: 'Nursing' },
            { value: 'Medicine - Other', label: 'Medicine - Other' },
            { value: 'Administrative Services', label: 'Administrative Services' },
            { value: 'Social Works', label: 'Social Works' },
            { value: 'Philosophy', label: 'Philosophy' },
            { value: 'Aeronautical Engineering', label: 'Aeronautical Engineering' },
            { value: 'Fine Arts', label: 'Fine Arts' },
            { value: 'Travel & Tourism', label: 'Travel & Tourism' },
            { value: 'Shipping', label: 'Shipping' },
            { value: 'Advertising/Marketing', label: 'Advertising/Marketing' },
            { value: 'Office Administration', label: 'Office Administration' },
            { value: 'Paramedical', label: 'Paramedical' },
            { value: 'Medicine - Allopathic', label: 'Medicine - Allopathic' },
            { value: 'Law', label: 'Law' },
            { value: 'Home Science', label: 'Home Science' },
            { value: 'Finance', label: 'Finance' },
            { value: 'Fashion', label: 'Fashion' },
            { value: 'Education', label: 'Education' },
            { value: 'Computers/IT', label: 'Computers/IT' },
            { value: 'Commerce', label: 'Commerce' },
            { value: 'Arts', label: 'Arts' },
            { value: 'Armed Forces', label: 'Armed Forces' },
            { value: 'Architecture', label: 'Architecture' },
            { value: 'Administration/Management', label: 'Administration/Management' },
            { value: 'Engineering/Technology', label: 'Engineering/Technology' }
        ],
        "Trade School/TTC/ITI": [
            { value: 'TTC', label: 'TTC' },
            { value: 'ITI', label: 'ITI' }
        ]
    };

    const handleEducation = async () => {
        const formatData = {
            highestEducation: selectedEducation,
            course: selectedCourse,
            educationInstitute: educationInstitute,
            educationDetails: educationDescription,
            profession: selectedOccupation,
            professionType: selectedProfession,
            companyName: companyName,
            jobDetails: jobDetails,
            annualIncome: selectedAnnualIncome,
        };

        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(
                'https://admin.steptonikah.com/api/profileUpdate/educationDetails',
                formatData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate('/my-profile');
                }, 1500);
            }
        } catch (error) {
            toast.error('Something went wrong');
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Education Information</h2>

            <div className="input-container">
                <label>Highest Education</label>
                <Select
                    options={education}
                    onChange={(selected) => setSelectedEducation(selected.value)}
                    placeholder="Search Education"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedEducation ? { value: selectedEducation, label: selectedEducation } : null}
                />
            </div>

            {selectedEducation && coursesByEducation[selectedEducation] && (
                <div className="input-container">
                    <label>Course</label>
                    <Select
                        options={coursesByEducation[selectedEducation]}
                        onChange={(selected) => setSelectedCourse(selected.value)}
                        placeholder="Select Course"
                        className="custom-select"
                        styles={customSelectStyles}
                        value={selectedCourse ? { value: selectedCourse, label: selectedCourse } : null}
                    />
                </div>
            )}

            <div className="input-container">
                <label>Profession</label>
                <Select
                    options={occupation}
                    onChange={(selected) => setSelectedOccupation(selected.value)}
                    placeholder="Search Occupation"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedOccupation ? { value: selectedOccupation, label: selectedOccupation } : null}
                />
            </div>

            {selectedOccupation === "Not Working" ||
                ("Student" && (
                    <div className="input-container">
                        <label>Profession Type</label>
                        <Select
                            options={professionType}
                            onChange={(selected) => setSelectedProfession(selected.value)}
                            placeholder="Select profession type"
                            className="custom-select"
                            styles={customSelectStyles}
                            value={selectedProfession ? { value: selectedProfession, label: selectedProfession } : null}
                        />
                    </div>
                ))}

            <div className="input-container">
                <label>Educational Institute</label>
                <input
                    type="text"
                    placeholder="Enter institute name"
                    className="input-field"
                    value={educationInstitute}
                    onChange={(e) => setEducationInstitute(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Education details in a brief description</label>
                <textarea
                    className="text-field"
                    placeholder="Educational Description"
                    value={educationDescription}
                    onChange={(e) => setEducationDescription(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Company Name</label>
                <input
                    type="text"
                    placeholder="Enter company name"
                    className="input-field"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Annual Income</label>
                <Select
                    options={annualIncome}
                    onChange={(selected) => setSelectedAnnualIncome(selected.value)}
                    placeholder="Select annual income"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedAnnualIncome ? { value: selectedAnnualIncome, label: selectedAnnualIncome } : null}
                />
            </div>

            <div className="input-container">
                <label>Job details in a brief description</label>
                <textarea
                    className="text-field"
                    placeholder="Job Description"
                    value={jobDetails}
                    onChange={(e) => setJobDetails(e.target.value)}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleEducation}>Done</button>
            </div>

            <Toaster />
        </div>
    );
};

export default EditEducation;
