import React from "react";
import Skeleton from "react-loading-skeleton";
const LookingFor = ({lookingFor}) => {
    return(
        <div className="card mt-3 mb-5 p-0">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">I am Looking For</h5>
            </div>
            <div className="card-body border py-5">
                <p className="justify-content">
                    {lookingFor ? (
                            <>
                                {lookingFor.looking_for ? lookingFor.looking_for : ''}
                            </>
                        ) : <Skeleton count={3} />
                    }
                </p>
            </div>
        </div>
    );
};

export default LookingFor;