import React, { useState, useEffect } from 'react';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select'
import HomeScreen from '../HomeScreen';
import Slider from "react-slider";
import Button from '@mui/material/Button';

const Colors = {
    primary: "#572d71"
}

const MobileSearch = () => {
    const [activeTab, setActiveTab] = useState("Search");
    const [profileId, setProfileId] = useState("");
    const [isSaved, setIsSaved] = useState(false);
    const [saveName, setSaveName] = useState("");
    const [ageRange, setAgeRange] = useState([18, 60]);
    const [weightRange, setWeightRange] = useState([40, 140]);
    const [heightRange, setHeightRange] = useState([101, 250]);
    const [selectedReligion, setSelectedReligion] = useState("Muslim");
    const [selectedMaritalStatus, setSelectedMaritalStatus] = useState([]);
    const [selectedBodyType, setSelectedBodyType] = useState("");
    const [selectedPhysicalStatus, setSelectedPhysicalStatus] = useState([]);
    const [selectedEthnicGroup, setSelectedEthnicGroup] = useState("");
    const [selectedSection, setSelectedSection] = useState("");
    const [selectedRating, setSelectedRating] = useState('');
    const [selectedNamazTiming, setSelectedNamazTiming] = useState('');
    const [sortBy, setSortBy] = useState('Recent Login');
    const [showPhoto, setShowPhoto] = useState('Show');
    const [selectedEducation, setSelectedEducation] = useState('');
    const navigate = useNavigate();

    const maritalStatus = [
        { value: "Never Married", label: "Never Married" },
        { value: "Divorced", label: "Divorced" },
        { value: "Nikah Divorce", label: "Nikah Divorce" },
        { value: "Widower", label: "Widower" },
        { value: "Awaiting Divorce", label: "Awaiting Divorce" },
        { value: "Separated", label: "Separated" },
    ];

    const bodyTypes = [
        { value: 'Slim', label: 'Slim' },
        { value: 'Average', label: 'Average' },
        { value: 'Athletic', label: 'Athletic' },
        { value: 'Heavy', label: 'Heavy' }
    ];

    const physicalStatus = [
        { value: "Does not matter", label: "Does not matter" },
        { value: "Physically fit", label: "Physically fit" },
        { value: "Physically Challenged", label: "Physically Challenged" },
        { value: "Hearing Impaired", label: "Hearing Impaired" },
        { value: "Vision Impaired", label: "Vision Impaired" },
        { value: "Speech Impaired", label: "Speech Impaired" },
        { value: "Minor Health Issues", label: "Minor Health Issues" },
        { value: "Major Health Issues", label: "Major Health Issues" },
        { value: "Mental Health Issues", label: "Mental Health Issues" },
    ];

    const ethnicGroups = [
        { value: 'Afghan', label: 'Afghan' },
        { value: 'African', label: 'African' },
        { value: 'African American', label: 'African American' },
        { value: 'American', label: 'American' },
        { value: 'Arab', label: 'Arab' },
        { value: 'Asian', label: 'Asian' },
        { value: 'Australian', label: 'Australian' },
        { value: 'Bangladeshi', label: 'Bangladeshi' },
        { value: 'Berber', label: 'Berber' },
        { value: 'Caribbean', label: 'Caribbean' },
        { value: 'Caucasian/White', label: 'Caucasian/White' },
        { value: 'Central African', label: 'Central African' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'East African', label: 'East African' },
        { value: 'Egyptian', label: 'Egyptian' },
        { value: 'European', label: 'European' },
        { value: 'Far East Asian', label: 'Far East Asian' },
        { value: 'Hispanic', label: 'Hispanic' },
        { value: 'Indian', label: 'Indian' },
        { value: 'Indonesian', label: 'Indonesian' },
        { value: 'Iraqi', label: 'Iraqi' },
        { value: 'Japanese', label: 'Japanese' },
        { value: 'Jewish', label: 'Jewish' },
        { value: 'Korean', label: 'Korean' },
        { value: 'Kurdish', label: 'Kurdish' },
        { value: 'Latino', label: 'Latino' },
        { value: 'Malay', label: 'Malay' },
        { value: 'Mixed Race', label: 'Mixed Race' },
        { value: 'Nigerian', label: 'Nigerian' },
        { value: 'North American', label: 'North American' },
        { value: 'Other', label: 'Other' },
        { value: 'Pakistani', label: 'Pakistani' },
        { value: 'Persian', label: 'Persian' },
        { value: 'Philippines', label: 'Philippines' },
        { value: 'Russian', label: 'Russian' },
        { value: 'Somali', label: 'Somali' },
        { value: 'South African', label: 'South African' },
        { value: 'South American', label: 'South American' },
        { value: 'SriLankan', label: 'SriLankan' },
        { value: 'Sudanese', label: 'Sudanese' },
        { value: 'Turkish', label: 'Turkish' },
        { value: 'West African', label: 'West African' }
    ];

    const IslamicSection = [
        { value: 'A Muslim', label: 'A Muslim' },
        { value: 'Ahamadi', label: 'Ahamadi' },
        { value: 'Ahle Hadees', label: 'Ahle Hadees' },
        { value: 'Bohra', label: 'Bohra' },
        { value: 'Hanabali', label: 'Hanabali' },
        { value: 'Hanafi', label: 'Hanafi' },
        { value: 'Ibadi', label: 'Ibadi' },
        { value: 'Ismaili', label: 'Ismaili' },
        { value: 'Jamat Islami', label: 'Jamat Islami' },
        { value: 'Maliki', label: 'Maliki' },
        { value: 'Pathan', label: 'Pathan' },
        { value: 'Prefer not to say', label: 'Prefer not to say' },
        { value: 'Salafi', label: 'Salafi' },
        { value: 'Salafi(KNM)', label: 'Salafi(KNM)' },
        { value: 'Salafi(Markaz Dawa)', label: 'Salafi(Markaz Dawa)' },
        { value: 'Salafi(Wisdom)', label: 'Salafi(Wisdom)' },
        { value: 'Sayyid', label: 'Sayyid' },
        { value: 'Shafi', label: 'Shafi' },
        { value: 'Shia', label: 'Shia' },
        { value: 'Sufism', label: 'Sufism' },
        { value: 'Sunni', label: 'Sunni' },
        { value: 'Sunni(AP)', label: 'Sunni(AP)' },
        { value: 'Sunni(EK)', label: 'Sunni(EK)' },
        { value: 'Thableegh Jamaat', label: 'Thableegh Jamaat' },
        { value: 'Urdu Muslim', label: 'Urdu Muslim' },
        { value: 'Zahiri', label: 'Zahiri' }
    ];

    const religiousnessRating = [
        { value: 'Devot', label: 'Devot' },
        { value: 'Very Religious', label: 'Very Religious' },
        { value: 'Religious', label: 'Religious' },
        { value: 'Moderately Religious', label: 'Moderately Religious' },
        { value: 'Occasionally Religious', label: 'Occasionally Religious' },
        { value: 'Cultural but non-practicing', label: 'Cultural but non-practicing' },
        { value: 'Spiritual but not religious', label: 'Spiritual but not religious' },
        { value: 'Religious but not practicing', label: 'Religious but not practicing' },
        { value: 'Open to exploring religion', label: 'Open to exploring religion' },
        { value: 'Agnostic', label: 'Agnostic' },
        { value: 'Secular', label: 'Secular' },
        { value: 'Open to all beliefs', label: 'Open to all beliefs' },
        { value: 'Not religious', label: 'Not religious' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const namazTiming = [
        { value: 'Yes,Five times daily', label: 'Yes, Five times daily' },
        { value: 'Yes,regularly(but not five times daily)', label: 'Yes, regularly (but not five times daily)' },
        { value: 'Yes,most days', label: 'Yes, most days' },
        { value: 'Yes,occasionally', label: 'Yes, occasionally' },
        { value: 'Only on special occasions(e.g Eid)', label: 'Only on special occasions (e.g., Eid)' },
        { value: 'Only during Ramadan', label: 'Only during Ramadan' },
        { value: 'Only on Fridays', label: 'Only on Fridays' },
        { value: 'Rarely', label: 'Rarely' },
        { value: 'No,but open to it', label: 'No, but open to it' },
        { value: 'No,but I respect it', label: 'No, but I respect it' },
        { value: 'No', label: 'No' },
        { value: 'Prefer not to say', label: 'Prefer not to say' }
    ];

    const education = [
        { value: 'Doctorate', label: 'Doctorate' },
        { value: 'High/Secondary School', label: 'High/Secondary School' },
        { value: 'Diploma', label: 'Diploma' },
        { value: 'Bachelors Degree', label: 'Bachelors Degree' },
        { value: 'Masters Degree', label: 'Masters Degree' },
        { value: 'PhD', label: 'PhD' },
        { value: 'Trade School/TTC/ITI', label: 'Trade School/TTC/ITI' },
        { value: 'Other', label: 'Other' },
    ];

    const handleChange = (setter) => (selectedOptions) => {
        setter(selectedOptions || []);
    };

    const onHeightChange = (values) => {
        setHeightRange(values);
    };

    const onWeightChange = (values) => {
        setWeightRange(values);
    };

    const onValuesChange = (values) => {
        setAgeRange(values);
    };

    const screenWidth = window.innerWidth;

    const handleIdSearch = async () => {
        console.log(activeTab);

        const token = localStorage.getItem("token");
        console.log(token, "token", profileId);

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };

        if (activeTab === "ID Search") {
            console.log("Pressed");

            try {
                const response = await axios.get(
                    `https://admin.steptonikah.com/api/profile/idSearch?profileId=${profileId}`,
                    config
                );
                console.log(response.data, "response");
                if (response.data.status) {
                    const item = response.data.basicDetails;
                    console.log(item, "item");
                    navigate("/profile-view", { state: { item } });
                }
            } catch (error) {
                toast.error("Something went wrong: " + (error.response?.data?.message || error.message));
            }
        } else if (activeTab === "Search") {
            const formData = {
                ageFrom: ageRange[0],
                ageTo: ageRange[1],
                heightFrom: heightRange[0],
                heightTo: heightRange[1],
                weightFrom: weightRange[0] || "",
                weightTo: weightRange[1] || "",
                bodyType: Array.isArray(selectedBodyType) ? selectedBodyType.map(status => status.value) : [selectedBodyType],
                physicalStatus: Array.isArray(selectedPhysicalStatus) ? selectedPhysicalStatus.map(status => status.value) : [selectedPhysicalStatus.value],
                maritalStatus: Array.isArray(selectedMaritalStatus) ? selectedMaritalStatus.map(status => status.value) : [selectedMaritalStatus],
                ethnicity: Array.isArray(selectedEthnicGroup) ? selectedEthnicGroup.map(status => status.value) : [selectedEthnicGroup],
                community: Array.isArray(selectedSection) ? selectedSection.map(status => status.value) : [selectedSection],
                religiousness: Array.isArray(selectedReligion) ? selectedReligion.map(status => status.value) : [selectedReligion],
                performNamaz: Array.isArray(selectedNamazTiming) ? selectedNamazTiming.map(status => status.value) : [selectedNamazTiming],
                districtsPreference: ["Any"],
                presentCountry: ["Any"],
                education: Array.isArray(selectedEducation) ? selectedEducation.map(status => status.value) : [selectedEducation],
                profession: ["Any"],
                professionType: ["Any"],
                financialStatus: ["Any"],
                eatingPreference: ["Any"],
                showWithPhoto: showPhoto,
                sortBy: sortBy,
                saveSearch: isSaved,
                searchName: saveName,
            };

            console.log(formData, "Updated Form Data");

            try {
                const response = await axios.post(
                    "https://admin.steptonikah.com/api/profileSearch",
                    formData,
                    config
                );
                console.log("Registration successful:", response.data);
                if (response.data.status) {
                    toast.success(response.data.message || "Search completed successfully.");
                } else {
                    toast.error(response.data.message || "Something went wrong. Please try again.");
                }
            } catch (error) {
                console.error("Error:", error);
                toast.error(
                    error.response?.data?.message || "An error occurred. Please try again later."
                );
            }
        }
    };

    const handleSavedProfiles = async () => {
        const token = await localStorage.getItem('token');

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        };
        try {
            const response = await axios.get('https://admin.steptonikah.com/api/profile/savedSearches', config);
            console.log(response.data);
            // setSavedSearch(response.data);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        handleSavedProfiles();
    }, []);

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
        option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#007bff' : 'white',
            color: isFocused ? 'white' : 'black',
            cursor: 'pointer'
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#f0f0f0',
            borderRadius: '4px'
        }),
        multiValueLabel: (base) => ({
            ...base,
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#007bff',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white'
            }
        })
    };

    return (
        <div className="search-container">
            <Toaster />
            <div className="status-bar" />
            <div className="container" style={{ paddingTop: 0, marginTop: 0 }}>
                {/* <div className="back-button" onClick={() => navigate(-1)}>
                    <MdArrowBackIosNew size={24} />
                </div> */}
                <div className="swipe-container">
                    <div className="tab-bar" style={{ justifyContent: "space-between", width: "100%" }}>
                        {['Search', 'ID Search', 'Saved'].map(tab => (
                            <Button
                                key={tab}
                                onClick={() => setActiveTab(tab)}
                                className={`tab-item-container ${activeTab === tab ? 'active' : ''}`}
                                style={{ padding: "0 10px" }}
                            >
                                <div className={`tab-item ${activeTab === tab ? 'active' : ''}`}>{tab}</div>
                            </Button>
                        ))}
                    </div>
                </div>
                {activeTab === 'Search' && (
                    <>
                        <div
                            style={{
                                margin: "10px",
                                backgroundColor: "#fff",
                                padding: "15px",
                                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                                borderRadius: "10px",
                                marginBottom: "20px",
                            }}
                        >
                            <div style={{ marginBottom: "20px" }}>
                                <span className="label">Height</span>
                                <div className="sliderContainer">
                                    <Slider
                                        min={101}
                                        max={250}
                                        step={1}
                                        value={heightRange}
                                        onChange={onHeightChange}
                                        renderTrack={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: state.index === 0 ? "#fe5d94" : "#ddd",
                                                }}
                                            />
                                        )}
                                        renderThumb={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: "#fe5d94",
                                                    borderRadius: "50%",
                                                    height: "12px",
                                                    width: "12px",
                                                    marginTop: "-5px"
                                                }}
                                            />
                                        )}
                                    />
                                    <span className="selectedRange">{heightRange[0]} - {heightRange[1]} cm</span>
                                </div>
                            </div>

                            <div style={{ marginBottom: "20px" }}>
                                <span className="label">Weight</span>
                                <div className="sliderContainer">
                                    <Slider
                                        min={40}
                                        max={140}
                                        step={1}
                                        value={weightRange}
                                        onChange={onWeightChange}
                                        renderTrack={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: state.index === 0 ? "#fe5d94" : "#ddd",
                                                }}
                                            />
                                        )}
                                        renderThumb={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: "#fe5d94",
                                                    borderRadius: "50%",
                                                    height: "12px",
                                                    width: "12px",
                                                    marginTop: "-5px"
                                                }}
                                            />
                                        )}
                                    />
                                    <span className="selectedRange">{weightRange[0]} - {weightRange[1]} Kg</span>
                                </div>
                            </div>

                            <div>
                                <span className="label">Age</span>
                                <div className="sliderContainer">
                                    <Slider
                                        min={18}
                                        max={60}
                                        step={1}
                                        value={ageRange}
                                        onChange={onValuesChange}
                                        renderTrack={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: state.index === 0 ? "#fe5d94" : "#ddd",
                                                }}
                                            />
                                        )}
                                        renderThumb={(props, state) => (
                                            <div
                                                {...props}
                                                style={{
                                                    ...props.style,
                                                    backgroundColor: "#fe5d94",
                                                    borderRadius: "50%",
                                                    height: "12px",
                                                    width: "12px",
                                                    marginTop: "-5px"
                                                }}
                                            />
                                        )}
                                    />
                                    <span className="selectedRange">{ageRange[0]} - {ageRange[1]} Yrs</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Marital Status</label>
                            <Select
                                isMulti
                                options={maritalStatus}
                                onChange={handleChange(setSelectedMaritalStatus)}
                                placeholder="Select Marital Status"
                                className='custom-select'
                                styles={customSelectStyles}
                                value={selectedMaritalStatus}
                            />
                        </div>

                        <div className="form-group">
                            <label>Body Type</label>
                            <Select
                                options={bodyTypes}
                                onChange={(selected) => setSelectedBodyType(selected.value)}
                                placeholder="Select bodyType"
                                className='custom-select'
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Physical Status</label>
                            <Select
                                isMulti
                                options={physicalStatus}
                                onChange={handleChange(setSelectedPhysicalStatus)}
                                placeholder="Select Physical Status"
                                className='custom-select'
                                styles={customSelectStyles}
                                value={selectedPhysicalStatus}
                            />
                        </div>

                        <div className="form-group">
                            <label>Ethnic Group</label>
                            <Select
                                options={ethnicGroups}
                                onChange={(selected) => setSelectedEthnicGroup(selected.value)}
                                placeholder="Select ethnicGroup"
                                className='custom-select'
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Community</label>
                            <Select
                                options={IslamicSection}
                                onChange={(selected) => setSelectedSection(selected.value)}
                                placeholder="Search Community"
                                className="custom-select"
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Religiousness Rating</label>
                            <Select
                                options={religiousnessRating}
                                onChange={(selected) => setSelectedRating(selected.value)}
                                placeholder="Select Religiousness Rating"
                                className="custom-select"
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Namaz/Salah Performance</label>
                            <Select
                                options={namazTiming}
                                onChange={(selected) => setSelectedNamazTiming(selected.value)}
                                placeholder="Select Namaz/salah performance"
                                className="custom-select"
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Highest Education</label>
                            <Select
                                options={education}
                                onChange={(selected) => setSelectedEducation(selected.value)}
                                placeholder="Search Education"
                                className="custom-select"
                                styles={customSelectStyles}
                            />
                        </div>

                        <div className="form-group">
                            <label>Profile Photo</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="Show"
                                        checked={showPhoto === 'Show'}
                                        onChange={() => setShowPhoto('Show')}
                                        style={{ marginRight: "10px" }}
                                    />
                                    Show
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Don't Show"
                                        checked={showPhoto === 'Don`t show'}
                                        onChange={() => setShowPhoto('Don`t show')}
                                        style={{ marginRight: "10px" }}
                                    />
                                    Don't Show
                                </label>
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Sort By</label>
                            <div className="radio-group">
                                <label>
                                    <input
                                        type="radio"
                                        value="Recent Login"
                                        checked={sortBy === 'Recent Login'}
                                        onChange={() => setSortBy('Recent Login')}
                                        style={{ marginRight: "10px" }}
                                    />
                                    Recent Login
                                </label>
                                <label>
                                    <input
                                        type="radio"
                                        value="Recent Registration"
                                        checked={sortBy === 'Recent Registration'}
                                        onChange={() => setSortBy('Recent Registration')}
                                        style={{ marginRight: "10px" }}
                                    />
                                    Recent Registration
                                </label>
                            </div>
                        </div>

                        <div className="checkbox-container">
                            <input
                                type="checkbox"
                                checked={isSaved}
                                onChange={(e) => setIsSaved(e.target.checked)}
                                className="checkbox"
                            />
                            <label className="checkbox-label">Save Search</label>
                        </div>
                        {isSaved && (
                            <input
                                type="text"
                                size={22}
                                placeholder="Enter save name"
                                value={saveName}
                                onChange={(e) => setSaveName(e.target.value)}
                                color={isSaved ? Colors.primary : undefined}
                                className="save-name-input"
                            />
                        )}
                    </>
                )}
                {activeTab === 'ID Search' && (
                    <div className="search-container">
                        <h2 className="title">Search by Profile ID</h2>
                        <div className="inputContainer">
                            <input
                                className="input"
                                type="text"
                                placeholder="Enter your ID"
                                value={profileId}
                                onChange={(e) => setProfileId(e.target.value)}
                            />
                        </div>
                    </div>
                )}
                {activeTab === 'Saved' && (
                    <div className="search-container">
                        <div className="inputContainer">

                        </div>
                    </div>
                )}
            </div>

            <div className="submit-container">
                {activeTab !== 'Saved' && (
                    <button className="submit-button" onClick={handleIdSearch}>
                        SEARCH
                    </button>
                )}
            </div>

            <HomeScreen />

        </div >
    );
};

export default MobileSearch;
