import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../assets/Constants";
import { Navigate, useNavigate } from "react-router-dom";

const Notification = () => {
    const [notifications, setNotifications] = useState([]);

    const fetchNotifications = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(`${API_URL}/api/notifications`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.data.status) {
                setNotifications(response.data.data);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, []);

    const navigate = useNavigate();
    const partnerProfileView = (profileId) => {        
        localStorage.setItem("profile-details", profileId);
        navigate("/profile-view")
    }

    return (
        <div className="card mt-3">
            <div className="card-header border d-flex align-items-center justify-content-between">
                <div className="card-title mb-0">
                    <h5 className="mb-0 me-2">Notification</h5>
                </div>
            </div>
            <div className="card-body border pt-5">
                {notifications.slice(0, 6).length > 0 ? (
                    notifications.slice(0, 6).map((notification) => (
                        <div
                            key={notification.id}
                            className="justify-content-between align-items-center mb-6"
                            onClick={() => partnerProfileView(notification.profileId)}
                        >
                            <div className="d-flex">
                                <div className="me-4">
                                    <img
                                        src={notification.photo}
                                        alt="Avatar"
                                        className="rounded-circle"
                                        height="80"
                                        width="80"
                                    />
                                </div>
                                <div>
                                    <h6
                                        className="mb-0 justify-content"
                                        style={{ fontSize: "13px" }}
                                    >
                                        {notification.title}
                                    </h6>
                                    <p
                                        className="justify-content"
                                        style={{ fontSize: "12px" }}
                                    >
                                        {notification.content}
                                    </p>
                                </div>
                            </div>
                            <p
                                className="text-muted text-end"
                                style={{ fontSize: "12px" }}
                            >
                                {notification.sent_on}
                            </p>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-muted">No notifications available</p>
                )}
                <div className="text-end p-0">
                    <a href="/notifications" className="card-link">View All</a>
                </div>
            </div>
        </div>
    );
};

export default Notification;
