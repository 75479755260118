import React, { useEffect, useState } from "react";
import ReligiousInformationModal from "./Modals/ReligiousInformationModal";

const ReligiousInformation = ({religiousDetails : initialReligiousDetails}) => {
    const [showModal, setShowModal] = useState(false);
    const [religiousDetails, setReligiousDetails] = useState(initialReligiousDetails);

    useEffect(() => {
        setReligiousDetails(initialReligiousDetails);
    }, [initialReligiousDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setReligiousDetails(prev => ({
            ...prev,
            religion: updatedDetails.religion,
            community: updatedDetails.community,
            religiousness: updatedDetails.religiousness,
            perform_namaz: updatedDetails.performNamaz,
            read_quran: updatedDetails.readQuran,
            mahallu_name: updatedDetails.mahalluName,
            madrasa_education: updatedDetails.madrasaEducation,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Religious Information</h5>
                </div>
                <div>
                    <button 
                        className="btn btn-sm btn-primary float-end"
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table class="table border">
                <tbody>
                        <tr>
                            <th>Religion</th>
                            <td>: {religiousDetails?.religion || 'N/A'}</td>
                            <th>Religiousness</th>
                            <td>: {religiousDetails?.religiousness || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Community</th>
                            <td>: {religiousDetails?.community || 'N/A'}</td>
                            <th>Perform Namaz</th>
                            <td>: {religiousDetails?.perform_namaz || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Read Quran</th>
                            <td>: {religiousDetails?.read_quran || 'N/A'}</td>
                            <th>Mahallu Name</th>
                            <td>: {religiousDetails?.mahallu_name || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Madrasa Education</th>
                            <td>: {religiousDetails?.madrasa_education || 'N/A'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <ReligiousInformationModal
                    details={{
                        religion: religiousDetails?.religion,
                        community: religiousDetails?.community,
                        religiousness: religiousDetails?.religiousness,
                        perform_namaz: religiousDetails?.perform_namaz,
                        read_quran: religiousDetails?.read_quran,
                        mahallu_name: religiousDetails?.mahallu_name,
                        madrasa_education: religiousDetails?.madrasa_education,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default ReligiousInformation;