import React from "react";

const ReligiousInformation = ({religiousDetails}) => {
    return(
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Religious Information</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Religion</th>
                            <td>: {religiousDetails?.religion || 'N/A'}</td>
                            <th>Mother Tongue</th>
                            <td>: {religiousDetails?.mother_tongue || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Community</th>
                            <td>: {religiousDetails?.community || 'N/A'}</td>
                            <th>Religiousness</th>
                            <td>: {religiousDetails?.religiousness || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Perform Namaz</th>
                            <td>: {religiousDetails?.perform_namaz || 'N/A'}</td>
                            <th>Read Quran</th>
                            <td>: {religiousDetails?.read_quran || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Mahallu Name</th>
                            <td>: {religiousDetails?.mahallu_name || 'N/A'}</td>
                            <th>Madrasa Education</th>
                            <td>: {religiousDetails?.madrasa_education || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ReligiousInformation;