import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditReligionInfo = () => {
    const location = useLocation();
    const { religiousDetails } = location.state;

    const [selectedSection, setSelectedSection] = useState(religiousDetails.community);
    const [selectedRating, setSelectedRating] = useState(religiousDetails.religiousness);
    const [selectedNamazTiming, setSelectedNamazTiming] = useState(religiousDetails.perform_namaz);
    const [selectedReligion, setSelectedReligion] = useState(religiousDetails.religion);
    const [mahalName, setMahalName] = useState(religiousDetails.mahallu_name);
    const [selectedQuranReading, setSelectedQuranReading] = useState(religiousDetails.read_quran);
    const [selectedMadrasa, setSelectedMadrasa] = useState(religiousDetails.madrasa_education);

    const navigate = useNavigate();

    const IslamicSection = [
        { value: "A Muslim", label: "A Muslim" },
        { value: "Ahamadi", label: "Ahamadi" },
        { value: "Ahle Hadees", label: "Ahle Hadees" },
        { value: "Bohra", label: "Bohra" },
        { value: "Hanabali", label: "Hanabali" },
        { value: "Hanafi", label: "Hanafi" },
        { value: "Ibadi", label: "Ibadi" },
        { value: "Ismaili", label: "Ismaili" },
        { value: "Jamat Islami", label: "Jamat Islami" },
        { value: "Maliki", label: "Maliki" },
        { value: "Pathan", label: "Pathan" },
        { value: "Prefer not to say", label: "Prefer not to say" },
        { value: "Salafi", label: "Salafi" },
        { value: "Salafi(KNM)", label: "Salafi(KNM)" },
        { value: "Salafi(Markaz Dawa)", label: "Salafi(Markaz Dawa)" },
        { value: "Salafi(Wisdom)", label: "Salafi(Wisdom)" },
        { value: "Sayyid", label: "Sayyid" },
        { value: "Shafi", label: "Shafi" },
        { value: "Shia", label: "Shia" },
        { value: "Sufism", label: "Sufism" },
        { value: "Sunni", label: "Sunni" },
        { value: "Sunni(AP)", label: "Sunni(AP)" },
        { value: "Sunni(EK)", label: "Sunni(EK)" },
        { value: "Thableegh Jamaat", label: "Thableegh Jamaat" },
        { value: "Urdu Muslim", label: "Urdu Muslim" },
        { value: "Zahiri", label: "Zahiri" },
    ];

    const religiousnessRating = [
        { value: "Devot", label: "Devot" },
        { value: "Very Religious", label: "Very Religious" },
        { value: "Religious", label: "Religious" },
        { value: "Moderately Religious", label: "Moderately Religious" },
        { value: "Occasionally Religious", label: "Occasionally Religious" },
        { value: "Cultural but non-practicing", label: "Cultural but non-practicing" },
        { value: "Spiritual but not religious", label: "Spiritual but not religious" },
        { value: "Religious but not practicing", label: "Religious but not practicing" },
        { value: "Open to exploring religion", label: "Open to exploring religion" },
        { value: "Agnostic", label: "Agnostic" },
        { value: "Secular", label: "Secular" },
        { value: "Open to all beliefs", label: "Open to all beliefs" },
        { value: "Not religious", label: "Not religious" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const namazTiming = [
        { value: "Yes,Five times daily", label: "Yes, Five times daily" },
        { value: "Yes,regularly(but not five times daily)", label: "Yes, regularly (but not five times daily)" },
        { value: "Yes,most days", label: "Yes, most days" },
        { value: "Yes,occasionally", label: "Yes, occasionally" },
        { value: "Only on special occasions(e.g Eid)", label: "Only on special occasions (e.g. Eid)" },
        { value: "Only during Ramadan", label: "Only during Ramadan" },
        { value: "Only on Fridays", label: "Only on Fridays" },
        { value: "Rarely", label: "Rarely" },
        { value: "No,but open to it", label: "No, but open to it" },
        { value: "No,but I respect it", label: "No, but I respect it" },
        { value: "No", label: "No" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const religions = [
        { value: "Buddhism", label: "Buddhism" },
        { value: "Bahai", label: "Bahai" },
        { value: "Jainism", label: "Jainism" },
        { value: "Jewish", label: "Jewish" },
        { value: "Christian", label: "Christian" },
        { value: "Hindu", label: "Hindu" },
        { value: "Muslim", label: "Muslim" },
        { value: "Parsi", label: "Parsi" },
        { value: "Sikhism", label: "Sikhism" },
        { value: "Shintoism", label: "Shintoism" },
        { value: "No Religion", label: "No Religion" },
        { value: "Inter Religion", label: "Inter Religion" },
        { value: "Others", label: "Others" },
    ];

    const quranReading = [
        { value: "Yes,Regularly", label: "Yes, Regularly" },
        { value: "Weekly", label: "Weekly" },
        { value: "Occasionally", label: "Occasionally" },
        { value: "Only during Ramadan", label: "Only during Ramadan" },
        { value: "Every Friday", label: "Every Friday" },
        { value: "Rarely", label: "Rarely" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const madrasa = [];
    for (let i = 0; i <= 12; i++) {
        madrasa.push({ value: `${i} cm`, label: `${i} cm` });
    }

    const handleInfo = async () => {
        const formatData = {
            religion: selectedReligion,
            community: selectedSection,
            religiousness: selectedRating,
            performNamaz: selectedNamazTiming,
            readQuran: selectedQuranReading,
            mahalluName: mahalName,
            madrasaEducation: selectedMadrasa
        };
        console.log(formatData, "Format data");
        try {
            const token = await localStorage.getItem('token');
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.post('https://admin.steptonikah.com/api/profileUpdate/religiousDetails', formatData, config);
            console.log(response.data);
            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/my-profile");
                }, 1500);
            }
        } catch (error) {
            console.error(error);

            toast.error(
                error.response?.data?.message || "Something went wrong. Please try again."
            );
        }
    }

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Religious Information</h2>

            <div className="input-container">
                <label>Religion</label>
                <Select
                    options={religions}
                    onChange={(selected) => setSelectedReligion(selected.value)}
                    placeholder="Select religion"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedReligion ? { value: selectedReligion, label: selectedReligion } : null}
                />
            </div>

            <div className="input-container">
                <label>Community</label>
                <Select
                    options={IslamicSection}
                    onChange={(selected) => setSelectedSection(selected.value)}
                    placeholder="Select community"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedSection ? { value: selectedSection, label: selectedSection } : null}
                />
            </div>

            <div className="input-container">
                <label>Religiousness Rating</label>
                <Select
                    options={religiousnessRating}
                    onChange={(selected) => setSelectedRating(selected.value)}
                    placeholder="Select religiousness rating"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedRating ? { value: selectedRating, label: selectedRating } : null}
                />
            </div>

            <div className="input-container">
                <label>Namaz/Salah Performance</label>
                <Select
                    options={namazTiming}
                    onChange={(selected) => setSelectedNamazTiming(selected.value)}
                    placeholder="Select namaz/salah performance"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedNamazTiming ? { value: selectedNamazTiming, label: selectedNamazTiming } : null}
                />
            </div>

            <div className="input-container">
                <label>Mahal Name</label>
                <input
                    type="text"
                    placeholder="Enter mahal name"
                    className="input-field"
                    value={mahalName}
                    onChange={(e) => setMahalName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Quran Reading</label>
                <Select
                    options={quranReading}
                    onChange={(selected) => setSelectedQuranReading(selected.value)}
                    placeholder="Select quran reading"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedQuranReading ? { value: selectedQuranReading, label: selectedQuranReading } : null}
                />
            </div>

            <div className="input-container">
                <label>Madrasa Educatoin</label>
                <Select
                    options={madrasa}
                    onChange={(selected) => setSelectedMadrasa(selected.value)}
                    placeholder="Select madrasa educatoin"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedMadrasa ? { value: selectedMadrasa, label: selectedMadrasa } : null}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleInfo}>Done</button>
            </div>

            <Toaster />
        </div>
    );
};

export default EditReligionInfo;