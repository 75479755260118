import React from "react";
import AccountDetails from "./AccountDetails";
import Actions from "./Actions";
import Notification from "./Notifications";

const Sidebar = () => {
    return(
        <div className="col-xxl-3 col-xl-3 col-md-6">
            <AccountDetails />
            <Actions />
            <Notification />
        </div>
    );
};

export default Sidebar;