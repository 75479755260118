import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const LocationPreferenceModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    //options
    const countries = [
        "India",
        "Saudi Arabia",
        "United Arab Emirates",
        "Qatar",
        "Kuwait",
        "Bahrain",
        "Oman",
        "Canada",
        "United States",
        "Mexico",
        "Brazil",
        "Argentina",
        "Poland",
        "Chile",
        "China",
        "Japan",
        "Australia",
        "United Kingdom",
        "France",
        "Germany",
        "Italy",
        "Spain",
        "Russia",
        "South Africa",
        "Egypt",
        "Nigeria",
        "South Korea",
        "Indonesia",
        "Yemen"
    ];

    const districts = [
        "Alappuzha",
        "Ernakulam",
        "Idukki",
        "Kannur",
        "Kasaragod",
        "Kollam",
        "Kottayam",
        "Kozhikode",
        "Malappuram",
        "Palakkad",
        "Pathanamthitta",
        "Thiruvananthapuram",
        "Thrissur",
        "Wayanad",
    ];
    const handleSave = async () => {
        try {
            const token = localStorage.getItem("token");
    
            const payload = {
                ...formData,
                districtsPreference: formData.districtsPreference ? [formData.districtsPreference] : [],
                presentCountry: formData.presentCountry ? [formData.presentCountry] : [],
            };
    
            const response = await axios.post(
                `${API_URL}/api/locationPreferences/store`,
                payload,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );
    
            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(payload);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    
    return (
        <div
            className="modal fade show"
            id="LocationPreferenceModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Location Preferences
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Districts Preference</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="districtsPreference"
                                    value={formData.districtsPreference || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Select Districts Preference</option>
                                    {districts.map((district) => (
                                        <option key={district} value={district}>
                                            {district}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <label className="col-sm-3 ">Present Country</label>
                            <div className="col-sm-9">
                                <select
                                    className="form-control form-control-sm"
                                    name="presentCountry"
                                    value={formData.presentCountry || ""} 
                                    onChange={handleInputChange}     
                                >
                                    <option value="">Select Present Country</option>
                                    {countries.map((country) => (
                                        <option key={country} value={country}>
                                            {country}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default LocationPreferenceModal;