import React, { useEffect, useState } from 'react';
import profileNoPhoto from '../../assets/img/profiles/no-photo.jpg';
import axios from 'axios';
import { API_URL } from '../../assets/Constants';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const listImage = {
    height: 300,
};

const PhotoList = () => {
    const [photos, setPhotos] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                const token = localStorage.getItem("token") || '';
                const response = await axios.get(`${API_URL}/api/photos/view`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                if (response.status === 200 && response.data.status) {
                    setPhotos(response.data.photos || []);
                }
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchPhotos();
    }, []);

    const photoSlots = [...photos, ...Array(6 - photos.length).fill(null)];

    const imageUpload = () => {
        navigate('/manage-photos/upload');
    };

    // Change profile picture (set as main)
    const handleChangeMain = async (photoId) => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(
                `${API_URL}/api/photo/${photoId}/profilePicture/change`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200 && response.data.status) {
                setPhotos((prevPhotos) =>
                    prevPhotos.map((photo) =>
                        photo.id === photoId
                            ? { ...photo, profile_picture: 'yes' }
                            : { ...photo, profile_picture: 'no' }
                    )
                );
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to update profile picture.');
        }
    };

    // Delete photo
    const handleDeletePhoto = async (photoId) => {
        try {
            const token = localStorage.getItem("token") || '';
            const response = await axios.post(
                `${API_URL}/api/photo/${photoId}/remove`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (response.status === 200 && response.data.status) {
                setPhotos((prevPhotos) => prevPhotos.filter((photo) => photo.id !== photoId));
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log(error);
            toast.error('Failed to delete photo.');
        }
    };

    return (
        <div className="tab-pane fade active show" id="nav-manage-photo" role="tabpanel">
            <div className="row">
                {photoSlots.map((photo, index) => (
                    <div className="col-md-4 mb-5" key={index}>
                        <div className="card p-3">
                            <img
                                className="rounded"
                                src={photo ? photo.photo_url : profileNoPhoto}
                                style={listImage}
                                alt="profile"
                            />
                            <div className="card-body text-center p-3">
                                <>
                                    {photo ? (
                                        <>
                                            {photo.profile_picture === 'yes' && (
                                                <div>
                                                    <span className="badge bg-success py-1 mb-2">
                                                        Main
                                                    </span>
                                                </div>
                                            )}
                                            {photo.profile_picture === 'no' && (
                                                <div
                                                    onClick={() => handleChangeMain(photo.id)}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    <span className="badge bg-secondary py-1 mb-2">
                                                        Set as main
                                                    </span>
                                                </div>
                                            )}
                                            <button
                                                type="button"
                                                className="btn btn-danger waves-effect"
                                                onClick={() => handleDeletePhoto(photo.id)}
                                            >
                                                <i className="ri-delete-bin-fill me-2"></i>
                                                Delete
                                            </button>
                                        </>
                                    ) : (
                                        <button
                                            type="button"
                                            className="btn btn-primary waves-effect"
                                            onClick={imageUpload}
                                        >
                                            <i className="ri-image-add-fill me-2"></i>
                                            Add Photo
                                        </button>
                                    )}
                                </>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PhotoList;
