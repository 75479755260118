import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoLocationSharp, IoChatboxEllipsesSharp, IoMailOutline, IoCall } from 'react-icons/io5';
import axios from 'axios';
import { GoogleLogin } from '@react-oauth/google';
import { Toaster, toast } from 'react-hot-toast';

const Colors = {
    primary: "#572d71",
    background: "#f4f4f5",
    backgroundPink: "#ffe6ee",
    secondary: "#ff5c8a",
    grey: "#C0C0C0",
};

const LoginScreen = () => {
    const navigate = useNavigate();


    const handleGoogleSuccess = async (credentialResponse) => {
        try {
            console.log(credentialResponse.credential);
            const response = await axios.post('https://admin.steptonikah.com/api/login/google', {
                token: credentialResponse.credential,
            });

            localStorage.setItem('token', response.data.token);
            toast.success('Logged in with Google successfully!');
            navigate('/home');
        } catch (error) {
            if (error.response?.status === 401) {
                toast.error('User not registered.');
            } else {
                console.log(error);
                toast.error('Google login failed. Please try again.');
            }
        }
    };

    const handleGoogleFailure = () => {
        toast.error('Google login was unsuccessful. Please try again.');
    };

    // useEffect(() => {
    //     const token = localStorage.getItem("token") ?? '';
    //     if (token) {
    //         navigate('/home');
    //     }
    // }, []);

    return (
        <div className="login-container">
            <div className="circles-container">
                <div className="middle-circle" />
                <div className="pink-fill" />
                <div className="inner-circle">
                    <img
                        className="logo"
                        src={require('../assets/images/logo.png')}
                        alt="Logo"
                    />
                </div>

                <img src={require('../assets/images/couple1.jpg')} className="profile-image profile1" alt="Couple 1" />
                <img src={require('../assets/images/couple2.jpg')} className="profile-image profile2" alt="Couple 2" />
                <img src={require('../assets/images/couple3.jpg')} className="profile-image profile3" alt="Couple 3" />
                <img src={require('../assets/images/couple4.jpg')} className="profile-image profile4" alt="Couple 4" />

                <div className="outer-circle" />
                <IoLocationSharp className="icon icon3" size={27} color={Colors.secondary} />
                <IoChatboxEllipsesSharp className="icon icon2" size={26} color='#000' />
            </div>

            <h1 className="title">Find your perfect match and start your journey together.</h1>

            <button className="button" onClick={() => navigate('/email-verification')}>
                <div className="icon-container">
                    <IoMailOutline size={20} color={Colors.primary} />
                </div>
                <span className="button-text">Login with Email</span>
            </button>
            <button className="button" onClick={() => navigate('/otp')}>
                <div className="icon-container">
                    <IoCall size={18} color={Colors.primary} />
                </div>
                <span className="button-text">Login with Phone</span>
            </button>

            <div style={{ marginTop: "10px" }}>
                <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleFailure}
                />
            </div>

            <p className="signup-text">
                Don't have an account? <span className="signup-link" onClick={() => navigate('/select-profile')}>Sign Up</span>
            </p>
            <Toaster />
        </div>
    );
};

export default LoginScreen;
