
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import Select from 'react-select';

const CreateProfile = () => {
    const navigate = useNavigate();

    const [selectedHeight, setSelectedHeight] = useState('');
    const [selectedWeight, setSelectedWeight] = useState('');
    const [selectedBodyType, setSelectedBodyType] = useState('');
    const [selectedHair, setSelectedHair] = useState('');
    const [selectedEyes, setSelectedEyes] = useState('');
    const [selectedComplexion, setSelectedComplexion] = useState('');
    const [selectedEthnicGroup, setSelectedEthnicGroup] = useState('');
    const [selectedOption, setSelectedOption] = useState('');
    const [physicallyChallenged, setPhysicallyChallenged] = useState('');

    const options = ["Never Married", "Divorced", "Nikah Divorce", "Widower", "Awaiting Divorce", "Separated"];

    const heights = [];
    for (let i = 101; i <= 250; i++) {
        heights.push({ value: `${i}`, label: `${i} cm` });
    }

    const weights = [];
    for (let i = 40; i <= 140; i++) {
        weights.push({ value: `${i}`, label: `${i} kg` });
    }

    const bodyTypes = [
        { value: 'Slim', label: 'Slim' },
        { value: 'Average', label: 'Average' },
        { value: 'Athletic', label: 'Athletic' },
        { value: 'Heavy', label: 'Heavy' }
    ];

    const hairs = [
        { value: 'Black', label: 'Black' },
        { value: 'Brown', label: 'Brown' },
        { value: 'Blonde', label: 'Blonde' },
        { value: 'Gray', label: 'Gray' },
        { value: 'White', label: 'White' },
        { value: 'Salt and pepper', label: 'Salt and pepper' },
        { value: 'Other', label: 'Other' }
    ];

    const eyes = [
        { value: 'Black', label: 'Black' },
        { value: 'Brown', label: 'Brown' },
        { value: 'Blue', label: 'Blue' },
        { value: 'Green', label: 'Green' },
        { value: 'Hazel', label: 'Hazel' },
        { value: 'White', label: 'White' },
        { value: 'Other', label: 'Other' }
    ];

    const complexion = [
        { value: 'Fair', label: 'Fair' },
        { value: 'Wheatish', label: 'Wheatish' },
        { value: 'Dark', label: 'Dark' },
        { value: 'Fair to medium', label: 'Fair to medium' },
        { value: 'Medium to dark', label: 'Medium to dark' }
    ];

    const ethnicGroups = [
        { value: 'Afghan', label: 'Afghan' },
        { value: 'African', label: 'African' },
        { value: 'African American', label: 'African American' },
        { value: 'American', label: 'American' },
        { value: 'Arab', label: 'Arab' },
        { value: 'Asian', label: 'Asian' },
        { value: 'Australian', label: 'Australian' },
        { value: 'Bangladeshi', label: 'Bangladeshi' },
        { value: 'Berber', label: 'Berber' },
        { value: 'Caribbean', label: 'Caribbean' },
        { value: 'Caucasian/White', label: 'Caucasian/White' },
        { value: 'Central African', label: 'Central African' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'East African', label: 'East African' },
        { value: 'Egyptian', label: 'Egyptian' },
        { value: 'European', label: 'European' },
        { value: 'Far East Asian', label: 'Far East Asian' },
        { value: 'Hispanic', label: 'Hispanic' },
        { value: 'Indian', label: 'Indian' },
        { value: 'Indonesian', label: 'Indonesian' },
        { value: 'Iraqi', label: 'Iraqi' },
        { value: 'Japanese', label: 'Japanese' },
        { value: 'Jewish', label: 'Jewish' },
        { value: 'Korean', label: 'Korean' },
        { value: 'Kurdish', label: 'Kurdish' },
        { value: 'Latino', label: 'Latino' },
        { value: 'Malay', label: 'Malay' },
        { value: 'Mixed Race', label: 'Mixed Race' },
        { value: 'Nigerian', label: 'Nigerian' },
        { value: 'North American', label: 'North American' },
        { value: 'Other', label: 'Other' },
        { value: 'Pakistani', label: 'Pakistani' },
        { value: 'Persian', label: 'Persian' },
        { value: 'Philippines', label: 'Philippines' },
        { value: 'Russian', label: 'Russian' },
        { value: 'Somali', label: 'Somali' },
        { value: 'South African', label: 'South African' },
        { value: 'South American', label: 'South American' },
        { value: 'SriLankan', label: 'SriLankan' },
        { value: 'Sudanese', label: 'Sudanese' },
        { value: 'Turkish', label: 'Turkish' },
        { value: 'West African', label: 'West African' }
    ];

    const handlePhysicalDetails = () => {
        console.log("Selected height: ", selectedHeight);
        console.log(selectedHeight, selectedWeight, selectedOption, selectedBodyType, selectedEyes, selectedHair, selectedComplexion, physicallyChallenged);
        if (!selectedHeight || !selectedWeight || !selectedOption || !selectedBodyType || !selectedEyes || !selectedHair || !selectedComplexion || !physicallyChallenged) {
            toast.error('Please enter all required fields');
            return;
        }

        navigate('/education-details', {
            state: {
                height: selectedHeight,
                weight: selectedWeight,
                maritalStatus: selectedOption,
                bodyType: selectedBodyType,
                hairColor: selectedHair,
                eyeColor: selectedEyes,
                complexion: selectedComplexion,
                physicallyChallenged,
                ethnicGroup: selectedEthnicGroup
            }
        });
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
    };

    return (
        <div className="create-profile-container">
            <h2>Create Profile</h2>
            <p>Physical Details</p>

            <div className="form-group">
                <label>Height</label>
                <Select
                    options={heights}
                    onChange={(selected) => setSelectedHeight(selected?.value || '')}
                    placeholder="Select height"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHeight ? { value: selectedHeight, label: `${selectedHeight} cm` } : null}
                />
            </div>

            <div className="form-group">
                <label>Weight</label>
                <Select
                    options={weights}
                    onChange={(selected) => setSelectedWeight(selected.value)}
                    placeholder="Select weight"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedWeight ? { value: selectedWeight, label: `${selectedWeight} kg` } : null}
                />
            </div>

            <div className="form-group">
                <label>Marital Status</label>
                <div className="options-container">
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={`option-button ${selectedOption === option ? 'selected' : ''}`}
                            onClick={() => setSelectedOption(option)}
                            value={selectedOption ? { value: selectedOption, label: selectedOption } : null}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>

            <div className="form-group">
                <label>Body Type</label>
                <Select
                    options={bodyTypes}
                    onChange={(selected) => setSelectedBodyType(selected.value)}
                    placeholder="Select bodyType"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedBodyType ? { value: selectedBodyType, label: selectedBodyType } : null}
                />
            </div>

            <div className="form-group">
                <label>hair Color</label>
                <Select
                    options={hairs}
                    onChange={(selected) => setSelectedHair(selected.value)}
                    placeholder="Select hair color"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHair ? { value: selectedHair, label: selectedHair } : null}
                />
            </div>

            <div className="form-group">
                <label>Eye Color</label>
                <Select
                    options={eyes}
                    onChange={(selected) => setSelectedEyes(selected.value)}
                    placeholder="Select eye colors"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEyes ? { value: selectedEyes, label: selectedEyes } : null}
                />
            </div>

            <div className="form-group">
                <label>Complexion</label>
                <Select
                    options={complexion}
                    onChange={(selected) => setSelectedComplexion(selected.value)}
                    placeholder="Select complexion"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedComplexion ? { value: selectedComplexion, label: selectedComplexion } : null}
                />
            </div>

            <div className="form-group">
                <label>Ethnic Group</label>
                <Select
                    options={ethnicGroups}
                    onChange={(selected) => setSelectedEthnicGroup(selected.value)}
                    placeholder="Select ethnicGroup"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEthnicGroup ? { value: selectedEthnicGroup, label: selectedEthnicGroup } : null}
                />
            </div>

            <div className="form-group">
                <label>Physically Challenged</label>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="Yes"
                            checked={physicallyChallenged === 'Yes'}
                            onChange={() => setPhysicallyChallenged('Yes')}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="No"
                            checked={physicallyChallenged === 'No'}
                            onChange={() => setPhysicallyChallenged('No')}
                        />
                        No
                    </label>
                </div>
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handlePhysicalDetails}>
                    <b>Next</b>
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default CreateProfile;
