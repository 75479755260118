import React, { useEffect, useState } from "react";
import FamilyDetailsModal from "./Modals/FamilyDetailsModal";

const FamilyDetails = ({ familyDetails : initialFamilyDetails }) => {

    const [showModal, setShowModal] = useState(false);
    const [familyDetails, setFamilyDetails] = useState(initialFamilyDetails);

    useEffect(() => {
        setFamilyDetails(initialFamilyDetails);
    }, [initialFamilyDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setFamilyDetails(prev => ({
            ...prev,
            familyType: updatedDetails.familyType,
            financialStatus: updatedDetails.financialStatus,
            homeType: updatedDetails.homeType,
            livingSituations: updatedDetails.livingSituations,
            fatherDetail: updatedDetails.fatherDetail,
            fatherProfession: updatedDetails.fatherProfession,
            motherDetail: updatedDetails.motherDetail,
            motherProfession: updatedDetails.motherProfession,
            numberOfElderBrothers: updatedDetails.numberOfElderBrothers,
            numberOfYoungerBrothers: updatedDetails.numberOfYoungerBrothers,
            numberOfMarriedBrothers: updatedDetails.numberOfMarriedBrothers,
            numberOfElderSisters: updatedDetails.numberOfElderSisters,
            numberOfYoungerSisters: updatedDetails.numberOfYoungerSisters,
            numberOfMarriedSisters: updatedDetails.numberOfMarriedSisters,
            familyValues: updatedDetails.familyValues,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Family Details</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table class="table border">
                <tbody>
                        <tr>
                            <th>Family Type</th>
                            <td>: {familyDetails?.family_type || 'N/A'}</td>
                            <th>Financial Status</th>
                            <td>: {familyDetails?.financial_status || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Father Details</th>
                            <td>: {familyDetails?.father_detail || 'N/A'} {familyDetails?.father_profession ? `(${familyDetails.father_profession})` : ''}</td>
                            <th>Mother Details</th>
                            <td>: {familyDetails?.mother_detail || 'N/A'} {familyDetails?.mother_profession ? `(${familyDetails.mother_profession})` : ''}</td>
                        </tr>
                        <tr>
                            <th>Home Type</th>
                            <td>: {familyDetails?.home_type || 'N/A'}</td>
                            <th>Living Situation</th>
                            <td>: {familyDetails?.living_situations || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Family Values</th>
                            <td>: {familyDetails?.family_values || 'N/A'}</td>
                            <th>No. Elder Sisters</th>
                            <td>: {familyDetails?.no_of_elder_sisters || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Younger Sisters</th>
                            <td>: {familyDetails?.no_of_younger_sisters || 'N/A'}</td>
                            <th>No. Married Sisters</th>
                            <td>: {familyDetails?.no_of_married_sisters || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Elder Brothers</th>
                            <td>: {familyDetails?.no_of_elder_brothers || 'N/A'}</td>
                            <th>No. Younger Brothers</th>
                            <td>: {familyDetails?.no_of_younger_brothers || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>No. Married Brothers</th>
                            <td>: {familyDetails?.no_of_married_brothers || 'N/A'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <FamilyDetailsModal
                    details={{
                        familyType: familyDetails?.family_type,
                        financialStatus: familyDetails?.financial_status,
                        homeType: familyDetails?.home_type,
                        livingSituations: familyDetails?.living_situations,
                        fatherDetail: familyDetails?.father_detail,
                        fatherProfession: familyDetails?.father_profession,
                        motherDetail: familyDetails?.mother_detail,
                        motherProfession: familyDetails?.mother_profession,
                        numberOfElderBrothers: familyDetails?.no_of_elder_brothers,
                        numberOfYoungerBrothers: familyDetails?.no_of_younger_brothers,
                        numberOfMarriedBrothers: familyDetails?.no_of_married_brothers,
                        numberOfElderSisters: familyDetails?.no_of_elder_sisters,
                        numberOfYoungerSisters: familyDetails?.no_of_younger_sisters,
                        numberOfMarriedSisters: familyDetails?.no_of_married_sisters,
                        familyValues: familyDetails?.family_values,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default FamilyDetails;