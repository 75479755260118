import React from "react";
import Skeleton from "react-loading-skeleton";
const ProfileDescription = ({personalDetails}) => {
    return(
        <div className="card mt-3 mb-3 p-0">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Profile Description</h5>
            </div>
            <div className="card-body border py-5">
                <p className="justify-content">
                    {personalDetails ? (
                            <>
                                {personalDetails.profile_description ? personalDetails.profile_description : ''}
                            </>
                        ) : <Skeleton count={3} />
                    }
                </p>
            </div>
        </div>
    );
};

export default ProfileDescription;