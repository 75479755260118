import React from 'react'
import SearchForm from './SearchForm';
import SavedSearch from './SavedSearch';
import IdSearch from './IdSearch';

const Search = () => {
    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="card mb-6">
                <div className="card-header p-0">
                    <div className="nav-align-top">
                        <ul className="nav nav-tabs nav-fill" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button type="button" className="nav-link waves-effect active" role="tab" data-bs-toggle="tab" data-bs-target="#nav-search" aria-controls="nav-search" aria-selected="false" tabindex="-1">
                                    <span className="d-none d-sm-block">
                                        <i className="tf-icons ri-user-line me-2"></i> 
                                        Search Profiles
                                    </span>
                                    <i className="ri-home-smile-line ri-20px d-sm-none"></i>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-search-id" aria-controls="nav-search-id" aria-selected="false" tabindex="-1">
                                    <span className="d-none d-sm-block">
                                        <i className="tf-icons ri-id-card-line me-2"></i>
                                        ID Search
                                    </span>
                                    <i className="ri-git-repository-private-line ri-20px d-sm-none"></i>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button type="button" className="nav-link waves-effect" role="tab" data-bs-toggle="tab" data-bs-target="#nav-saved-search" aria-controls="nav-saved-search" aria-selected="false" tabindex="-1">
                                    <span className="d-none d-sm-block">
                                        <i className="tf-icons ri-article-line me-2"></i>
                                        Saved Searches
                                    </span>
                                    <i className="ri-git-repository-private-line ri-20px d-sm-none"></i>
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="card-body pt-5">
                    <div className="tab-content p-0">
                        <SearchForm />
                        <IdSearch />
                        <SavedSearch/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Search;
