import axios from "axios";
import React, { useState } from "react";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const FamilyDetailsModal = ({details, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        ...details,
        fatherName: details?.fatherDetail?.split(",")[0] || "",
        fatherAliveStatus: details?.fatherDetail?.split(",")[1] || "",
        motherName: details?.motherDetail?.split(",")[0] || "",
        motherAliveStatus: details?.motherDetail?.split(",")[1] || "",
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" });
    };

    //options
    const familyTypeOptions = [
        "Nuclear Family",
        "Single Parent Family",
        "Joint Family",
        "Extended Family",
        "Blended Family",
        "Adoptive Family",
        "One-Child Family",
        "Divorced Parent Family",
        "Live Alone",
        "Prefer Not to Say",
    ];

    const financialStatusOptions = [
        "Wealthy /Affluent",
        "Upper Middle Class",
        "Middle Class",
        "Lower Middle Class",
        "Well-off",
        "Struggling Financially",
        "Business Family",
        "Financially Stable",
        "Inherited Wealth",
        "Prefer Not to Say",
    ];

    const homeTypeOptions = [
        "House / Villa",
        "Rent House",
        "Apartment / Flat",
        "Farm",
        "Bungalow / Mansion",
        "Town House",
        "Prefer Not to Say",
    ];

    const livingSituationOptions = [
        "Live Alone",
        "Live with family",
        "Live with friends",
        "Live with kids",
        "Live with parents",
        "Live with pets",
        "Live with roommates",
        "Shared Housing",
        "Living Abroad",
        "Other",
        "Prefer Not to Say",
    ];

    const handleSave = async () => {
        const finalData = {
            ...formData,
            fatherDetail: `${formData.fatherName},${formData.fatherAliveStatus}`,
            motherDetail: `${formData.motherName},${formData.motherAliveStatus}`,
        };

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/familyDetails`,
                finalData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };
    return (
        <div
            className="modal fade show"
            id="familyDetailsModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Family Details
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="familyType"
                                        value={formData.familyType || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Family Type</option>
                                        {familyTypeOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="familyType">Family Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="financialStatus"
                                        value={formData.financialStatus || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Financial Status</option>
                                        {financialStatusOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="financialStatus">Financial Status</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="homeType"
                                        value={formData.homeType || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Home Type</option>
                                        {homeTypeOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="homeType">Home Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="livingSituations"
                                        value={formData.livingSituations || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Select Living Situations</option>
                                        {livingSituationOptions.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor="livingSituations">Living Situations</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Father Name"
                                        name="fatherName"
                                        value={formData.fatherName || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="fatherName">Father Name</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="fatherAliveStatus"
                                        value={formData.fatherAliveStatus || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Alive">Alive</option>
                                        <option value="Not Alive">Not Alive</option>
                                    </select>
                                    <label htmlFor="fatherAliveStatus">Father Alive or Not</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm" placeholder="Father Profession"
                                        name="fatherProfession"
                                        value={formData.fatherProfession || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="fatherProfession">Father Profession</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Mother Name"
                                        name="motherName"
                                        value={formData.motherName || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="motherName">Mother Name</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        className="form-control form-control-sm"
                                        name="motherAliveStatus"
                                        value={formData.motherAliveStatus || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Alive">Alive</option>
                                        <option value="Not Alive">Not Alive</option>
                                    </select>
                                    <label htmlFor="motherAliveStatus">Mother Alive or Not</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="text" className="form-control form-control-sm" placeholder="Mother Profession"
                                        name="motherProfession"
                                        value={formData.motherProfession || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="motherProfession">Mother Profession</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfElderBrothers"
                                        value={formData.numberOfElderBrothers || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfElderBrothers">No of Elder Brothers</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfYoungerBrothers"
                                        value={formData.numberOfYoungerBrothers || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfYoungerBrothers">No of Younger Brothers</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfMarriedBrothers"
                                        value={formData.numberOfMarriedBrothers || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfMarriedBrothers">No of Married Brothers</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfElderSisters"
                                        value={formData.numberOfElderSisters || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfElderSisters">No of Elder Sisters</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfYoungerSisters"
                                        value={formData.numberOfYoungerSisters || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfYoungerSisters">No of Younger Sisters</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input type="number" className="form-control form-control-sm"
                                        name="numberOfMarriedSisters"
                                        value={formData.numberOfMarriedSisters || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="numberOfMarriedSisters">No of Married Sisters</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );

}
export default FamilyDetailsModal;