import React, { useEffect, useState } from "react";
import LocationDetailsModal from "./Modals/LocationDetailsModals";

const formatLocation = (...fields) => {
    return fields.filter(field => field).join(', ');
};

const LocationDetails = ({ locationDetails : initialLocationDetails }) => {

    const [showModal, setShowModal] = useState(false);
    const [locationDetails, setLocationDetails] = useState(initialLocationDetails);

    useEffect(() => {
        setLocationDetails(initialLocationDetails);
    }, [initialLocationDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setLocationDetails(prev => ({
            ...prev,
            homeCountry: updatedDetails.homeCountry,
            homeState: updatedDetails.homeState,
            homeDistrict: updatedDetails.homeDistrict,
            homeCity: updatedDetails.homeCity,
            presentCountry: updatedDetails.presentCountry,
            presentState: updatedDetails.presentState,
            presentDistrict: updatedDetails.presentDistrict,
            presentCity: updatedDetails.presentCity,
        }));

        setShowModal(false);
    };

    return(
        <div class="card mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Location Details</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div class="table-responsive text-nowrap">
                <table class="table border">
                <tbody>
                        <tr>
                            <th width="200">Present Location</th>
                            <td style={{ whiteSpace: 'normal' }}>
                                : {formatLocation(
                                    locationDetails?.present_city,
                                    locationDetails?.present_district,
                                    locationDetails?.present_state,
                                    locationDetails?.present_country
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th width="200">Home Location</th>
                            <td style={{ whiteSpace: 'normal' }}>
                                : {formatLocation(
                                    locationDetails?.home_city,
                                    locationDetails?.home_district,
                                    locationDetails?.home_state,
                                    locationDetails?.home_country
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <LocationDetailsModal
                    details={{
                        homeCountry: locationDetails?.home_country,
                        homeState: locationDetails?.home_state,
                        homeDistrict: locationDetails?.home_district,
                        homeCity: locationDetails?.home_city,
                        presentCountry: locationDetails?.present_country,
                        presentState: locationDetails?.present_state,
                        presentDistrict: locationDetails?.present_district,
                        presentCity: locationDetails?.present_city,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default LocationDetails;