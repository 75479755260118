import React, { useState } from 'react';
import { IoArrowForward } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const Colors = {
    background: '#f4f4f5'
};

const SelectProfile = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();
    const options = ["Myself", "My Son", "My Daughter", "My Friend", "My Sister", "My Brother", "My Relative"];

    const handleNavigation = () => {
        if (selectedOption) {
            localStorage.setItem("profile_for", selectedOption);
            navigate("/select-gender");
        }
    };

    return (
        <div className="select-profile-container">
            <div className="header">
                <h1 className="title">Create Account</h1>
                <p className="subtitle">Creating profile for</p>

                <div className="options-container">
                    {options.map((option, index) => (
                        <button
                            key={index}
                            className={`option-button ${selectedOption === option ? 'selected-option' : ''}`}
                            onClick={() => setSelectedOption(option)}
                        >
                            {option}
                        </button>
                    ))}
                </div>
            </div>

            <div className="navigation-footer">
                <button
                    className={`next-button ${selectedOption ? 'active' : ''}`}
                    onClick={handleNavigation}
                    disabled={!selectedOption}
                >
                    <IoArrowForward type="arrow-right" style={{ fontSize: '24px', color: selectedOption ? Colors.background : Colors.grey }} />
                </button>
            </div>
        </div>
    );
};

export default SelectProfile;
