import React, { useState } from "react";

const LookingForModal = ({ existingLookingFor, onClose, onSave }) => {

    const [lookingForPartner, setLookingForPartner] = useState(existingLookingFor);

    const handleSave = () => {
        onSave(lookingForPartner);
    };

    return (
        <div
            className="modal fade show"
            id="profileDescriptionModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Profile Description
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={onClose}
                            aria-label="Close"
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12 mb-2">
                                <div className="form-floating form-floating-outline">
                                    <textarea
                                        className="form-control"
                                        name="profileDescription"
                                        id="profileDescription"
                                        rows="4"
                                        value={lookingForPartner}
                                        onChange={(e) => setLookingForPartner(e.target.value)}
                                    ></textarea>
                                    <label htmlFor="profileDescription">Profile Description</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LookingForModal;
