import React, { useEffect, useState } from 'react';
import profileImage1 from '../../../assets/img/profiles/woman1.jpg';
import { Link } from 'react-router-dom';
import imgEmpty from '../../../assets/img/illustrations/illustration-upgrade-account.png';
import { API_URL } from '../../../assets/Constants';
import axios from 'axios';
import toast from 'react-hot-toast';

const Shortlist = () => {
    const [shortlists, setShortlists] = useState([]);

    const fetchData = async () => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.get(`${API_URL}/api/shortlists/view`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setShortlists(response.data.shortlistedProfiles || []);
            }
        } catch (error) {
            console.error('Error fetching shortlist data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const removeShortlist = async (id) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(`${API_URL}/api/shortlists/${id}/remove`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchData(); // Refresh the list
                toast.success('Shortlist removed successfully');
            }
        } catch (error) {
            console.error('Error removing shortlist:', error);
            toast.error('An error occurred!');
        }
    };

    return (
        <div className="demo-inline-spacing mt-4">
            {shortlists.length > 0 ? (
                <div className="list-group">
                    {shortlists.map((profile) => (
                        <div
                            key={profile.id}
                            className="list-group-item list-group-item-action d-flex align-items-center cursor-pointer waves-effect"
                        >
                            <img
                                src={profile.photo || profileImage1}
                                alt={profile.name}
                                className="rounded-circle me-3"
                                width="120"
                            />
                            <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="user-info">
                                        <h6 className="mb-1">{profile.name}</h6>
                                        <div>
                                            <p className="mb-1">
                                                {profile.age} Years, {profile.height} cm,
                                            </p>
                                            <p className="mb-1">Profile ID  : {profile.profileId}</p>
                                        </div>
                                        <small className="text-muted">Shortlisted On: {profile.listed_on}</small>
                                    </div>
                                    <div className="add-btn">
                                        <button
                                            className="btn btn-danger btn-sm waves-effect waves-light"
                                            onClick={() => removeShortlist(profile.id)}
                                        >
                                            <i className="ri ri-close-circle-line me-2"></i>
                                            Remove
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="misc-wrapper border rounded">
                    <div className="text-center">
                        <h4 className="mb-2">No Shortlisted Profiles</h4>
                        <p className="mb-6 mx-2">You have not shortlisted any profiles yet.</p>
                    </div>
                    <div className="d-flex justify-content-center mt-9">
                        <div className="d-flex flex-column align-items-center">
                            <img
                                src={imgEmpty}
                                alt="No Data"
                                className="img-fluid z-1"
                                width="150"
                            />
                            <div>
                                <Link to="/home">
                                    <button className="btn btn-primary btn-sm text-center my-10 waves-effect waves-light">
                                        Back to Home
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Shortlist;
