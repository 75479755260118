import React, { useState, useEffect } from "react";
import BasicDetailsModal from "./Modals/BasicDetailsModal";

const BasicInformation = ({ 
    basicDetails: initialBasicDetails, 
    personalDetails: initialPersonalDetails, 
    physicalDetails: initialPhysicalDetails 
}) => {
    const [showModal, setShowModal] = useState(false);
    const [basicDetails, setBasicDetails] = useState(initialBasicDetails);
    const [personalDetails, setPersonalDetails] = useState(initialPersonalDetails);
    const [physicalDetails, setPhysicalDetails] = useState(initialPhysicalDetails);

    useEffect(() => {
        setBasicDetails(initialBasicDetails);
        setPersonalDetails(initialPersonalDetails);
        setPhysicalDetails(initialPhysicalDetails);
    }, [initialBasicDetails, initialPersonalDetails, initialPhysicalDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setBasicDetails(prev => ({
            ...prev,
            name: updatedDetails.name,
            gender: updatedDetails.gender,
            dob: updatedDetails.dob,
            profile_for: updatedDetails.profileFor,
        }));

        setPersonalDetails(prev => ({
            ...prev,
            marital_status: updatedDetails.maritalStatus,
            ethnic_group: updatedDetails.ethnicGroup,
            willing_to_relocate: updatedDetails.willingToRelocate,
            marriage_plan: updatedDetails.marriagePlan,
            languages_known: updatedDetails.languagesKnown,
        }));

        setPhysicalDetails(prev => ({
            ...prev,
            physically_challenged: updatedDetails.physicallyChallenged,
            physical_status: updatedDetails.physicalStatus,
        }));

        setShowModal(false);
    };

    return (
        <div className="card mb-3 mt-3">
            <div className="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div className="card-title mb-0">
                    <h5 className="m-0 me-2 mb-1">Basic Information</h5>
                </div>
                <div>
                    <button
                        className="btn btn-sm btn-primary float-end"
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>: {basicDetails?.name || ''}</td>
                            <th>Profile ID</th>
                            <td>: {basicDetails?.profile_id ? `STN${basicDetails.profile_id}` : ''}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>: {basicDetails?.gender || ''}</td>
                            <th>Age</th>
                            <td>: {basicDetails?.age ? `${basicDetails.age} Years` : ''}</td>
                        </tr>
                        <tr>
                            <th>Marital Status</th>
                            <td>: {personalDetails?.marital_status || ''}</td>
                            <th>Profile Created for</th>
                            <td>: {basicDetails?.profile_for || ''}</td>
                        </tr>
                        <tr>
                            <th>Ethnic Group</th>
                            <td>: {personalDetails?.ethnic_group || ''}</td>
                            <th>Marriage Plan</th>
                            <td>: {personalDetails?.marriage_plan || ''}</td>
                        </tr>
                        <tr>
                            <th>Willing to Relocate</th>
                            <td>: {personalDetails?.willing_to_relocate || ''}</td>
                            <th>Languages Known</th>
                            <td>: {personalDetails?.languages_known || ''}</td>
                        </tr>
                        <tr>
                            <th>Physical Status</th>
                            <td>: {physicalDetails?.physical_status || ''}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <BasicDetailsModal
                    details={{
                        name: basicDetails?.name,
                        gender: basicDetails?.gender,
                        dob: basicDetails?.dob,
                        profileFor: basicDetails?.profile_for,
                        maritalStatus: personalDetails?.marital_status,
                        ethnicGroup: personalDetails?.ethnic_group,
                        willingToRelocate: personalDetails?.willing_to_relocate,
                        marriagePlan: personalDetails?.marriage_plan,
                        languagesKnown: personalDetails?.languages_known,
                        physicallyChallenged: physicalDetails?.physically_challenged,
                        physicalStatus: physicalDetails?.physical_status,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default BasicInformation;
