import React from "react";
import { Link } from "react-router-dom";
const Actions = () =>{
    return(
        <div className="card mt-3 mb-3">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Actions</h5>
                </div>
            </div>
            <div className="card-body py-5 border">
                <Link to={"/home"}>
                    <div className="d-flex align-items-center mb-5">
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-home-5-line ri-24px"></i>
                            </div>
                        </div>
                        <div className="ms-3 d-flex flex-column">
                            <h6 className="mb-1">Home</h6>
                        </div>
                    </div>
                </Link>
                <Link to={"/search"}>
                    <div className="d-flex align-items-center mb-5">
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-search-line ri-24px"></i>
                            </div>
                        </div>
                        <div className="ms-3 d-flex flex-column">
                            <h6 className="mb-1">Search</h6>
                        </div>
                    </div>
                </Link>
                <Link to={"/explore"}>
                    <div className="d-flex align-items-center mb-5">
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-focus-2-fill ri-24px"></i>
                            </div>
                        </div>
                        <div className="ms-3 d-flex flex-column">
                            <h6 className="mb-1">Explore</h6>
                        </div>
                    </div>
                </Link>
                <Link to={"/manage-photos"}>
                    <div className="d-flex align-items-center mb-3">
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-image-2-line ri-24px"></i>
                            </div>
                        </div>
                        <div className="ms-3 d-flex flex-column">
                            <h6 className="mb-1">Manage Photos</h6>
                        </div>
                    </div>
                </Link>
                <Link to={"/my-profile"}>
                    <div className="d-flex align-items-center mb-5">
                        <div className="avatar">
                            <div className="avatar-initial bg-label-primary rounded">
                                <i className="ri-pencil-line ri-24px"></i>
                            </div>
                        </div>
                        <div className="ms-3 d-flex flex-column">
                            <h6 className="mb-1">Edit Profile</h6>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default Actions;