import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ImageViewer from 'react-simple-image-viewer';

const ImageCarouselScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const { images } = location.state; 
  const [isViewerOpen, setIsViewerOpen] = useState(true);

  const closeImageViewer = () => {
    setIsViewerOpen(false);
    navigate(-1);
  };

  return (
    <div className="image-carousel-screen">
      {isViewerOpen && images.length > 0 && (
        <ImageViewer
          src={images}
          currentIndex={0}
          onClose={closeImageViewer}
          backgroundStyle={{ backgroundColor: 'rgba(0,0,0,0.9)' }}
          closeOnClickOutside
        />
      )}
    </div>
  );
};

export default ImageCarouselScreen;

