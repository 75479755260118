import React, { useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditBasicInfo = () => {
    const location = useLocation();
    const { basicDetails, personalDetails, physicalDetails } = location.state;

    const [editName, setEditName] = useState(basicDetails.name);
    const [editGender, setEditGender] = useState(basicDetails.gender);
    const [editProfileFor, setEditProfileFor] = useState(basicDetails.profile_for);
    const [editMaritalStatus, setEditMaritalStatus] = useState(personalDetails.marital_status);
    const [editPhysicalChallenge, setEditPhysicalChallenge] = useState(physicalDetails.physically_challenged);
    const [selectedLanguage, setSelectedLanguage] = useState(personalDetails.mother_tongue);
    const [selectedKnownLanguages, setSelectedKnownLanguages] = useState(personalDetails.languages_known ? [personalDetails.languages_known].map((lang) => ({ value: lang, label: lang }))
        : []
    );
    const [selectedEthnicGroup, setselectedEthnicGroup] = useState(personalDetails.ethnic_group);
    const [selectedRelocate, setselectedRelocate] = useState(personalDetails.willing_to_relocate);
    const [selectedMarriagePlan, setselectedMarriagePlan] = useState(personalDetails.marriage_plan);;

    const navigate = useNavigate();

    const handleLanguageChange = (selectedOptions) => {
        setSelectedKnownLanguages(selectedOptions || []);
    };

    const options = ["Never Married", "Divorced", "Nikah Divorce", "Widower", "Awaiting Divorce", "Separated"];
    const profileOptions = ["Myself", "My Son", "My Daughter", "My Friend", "My Sister", "My Brother", "My Relative"];
    const ethnicGroups = [
        { value: 'Afghan', label: 'Afghan' },
        { value: 'African', label: 'African' },
        { value: 'African American', label: 'African American' },
        { value: 'American', label: 'American' },
        { value: 'Arab', label: 'Arab' },
        { value: 'Asian', label: 'Asian' },
        { value: 'Australian', label: 'Australian' },
        { value: 'Bangladeshi', label: 'Bangladeshi' },
        { value: 'Berber', label: 'Berber' },
        { value: 'Caribbean', label: 'Caribbean' },
        { value: 'Caucasian/White', label: 'Caucasian/White' },
        { value: 'Central African', label: 'Central African' },
        { value: 'Chinese', label: 'Chinese' },
        { value: 'East African', label: 'East African' },
        { value: 'Egyptian', label: 'Egyptian' },
        { value: 'European', label: 'European' },
        { value: 'Far East Asian', label: 'Far East Asian' },
        { value: 'Hispanic', label: 'Hispanic' },
        { value: 'Indian', label: 'Indian' },
        { value: 'Indonesian', label: 'Indonesian' },
        { value: 'Iraqi', label: 'Iraqi' },
        { value: 'Japanese', label: 'Japanese' },
        { value: 'Jewish', label: 'Jewish' },
        { value: 'Korean', label: 'Korean' },
        { value: 'Kurdish', label: 'Kurdish' },
        { value: 'Latino', label: 'Latino' },
        { value: 'Malay', label: 'Malay' },
        { value: 'Mixed Race', label: 'Mixed Race' },
        { value: 'Nigerian', label: 'Nigerian' },
        { value: 'North American', label: 'North American' },
        { value: 'Other', label: 'Other' },
        { value: 'Pakistani', label: 'Pakistani' },
        { value: 'Persian', label: 'Persian' },
        { value: 'Philippines', label: 'Philippines' },
        { value: 'Russian', label: 'Russian' },
        { value: 'Somali', label: 'Somali' },
        { value: 'South African', label: 'South African' },
        { value: 'South American', label: 'South American' },
        { value: 'SriLankan', label: 'SriLankan' },
        { value: 'Sudanese', label: 'Sudanese' },
        { value: 'Turkish', label: 'Turkish' },
        { value: 'West African', label: 'West African' }
    ];
    const language = [
        { value: 'Telugu', label: 'Telugu' },
        { value: 'English', label: 'English' },
        { value: 'Assamese', label: 'Assamese' },
        { value: 'Hindi', label: 'Hindi' },
        { value: 'Konkani', label: 'Konkani' },
        { value: 'Gujarati', label: 'Gujarati' },
        { value: 'Kannada', label: 'Kannada' },
        { value: 'Malayalam', label: 'Malayalam' },
        { value: 'Marathi', label: 'Marathi' },
        { value: 'Manipuri', label: 'Manipuri' },
        { value: 'Mizo', label: 'Mizo' },
        { value: 'Punjabi', label: 'Punjabi' },
        { value: 'Odia', label: 'Odia' },
        { value: 'Tamil', label: 'Tamil' },
        { value: 'Sikkimese', label: 'Sikkimese' },
        { value: 'Nepali', label: 'Nepali' }
    ];
    const relocate = [
        { value: 'Yes', label: 'Yes' },
        { value: 'Maybe, Open to discussion', label: 'Maybe, Open to discussion' },
        { value: 'Not sure yet', label: 'Not sure yet' },
        { value: 'No', label: 'No' }
    ];
    const marriagePlan = [
        { value: 'As soon as possible', label: 'As soon as possible' },
        { value: 'Within 1-2 years', label: 'Within 1-2 years' },
        { value: 'Within 3-5 years', label: 'Within 3-5 years' },
        { value: 'Not sure yet', label: 'Not sure yet' },
        { value: 'In the future', label: 'In the future' },
        { value: 'No immediate Plans', label: 'No immediate Plans' },
        { value: 'Flexible about timing', label: 'Flexible about timing' }
    ];


    const handleBasicInfo = async () => {
        console.log("Name", editName, "Gender", editGender, "Profile for", editProfileFor,
            "MaritalStatus", editMaritalStatus, "Ethnic Group", selectedEthnicGroup,
            "Willing to relocate", selectedRelocate, "Languages Known", selectedKnownLanguages,
            "Mother tongue", selectedLanguage, "Physically challenged", editPhysicalChallenge,
            "Marriage Plan", selectedMarriagePlan);

        const formatData = {
            name: editName,
            gender: editGender,
            dob: basicDetails.dob,
            profileFor: editProfileFor,
            maritalStatus: editMaritalStatus,
            ethnicGroup: selectedEthnicGroup,
            willingToRelocate: selectedRelocate,
            physicalStatus: physicalDetails.physical_status,
            marriagePlan: selectedMarriagePlan,
            laguagesKnown: selectedKnownLanguages,
            motherTongue: selectedLanguage,
            physicallyChallenged: editPhysicalChallenge,
        };
        console.log(formatData, "FormatData in edit");

        try {
            const token = localStorage.getItem("token");
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileUpdate/personalDetails",
                formatData,
                config
            );
            console.log(response.data);

            if (response.data.status) {
                toast.success(response.data.message);
                setTimeout(() => {
                    navigate("/my-profile"); 
                }, 1500);
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong");
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
        option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#007bff' : 'white',
            color: isFocused ? 'white' : 'black',
            cursor: 'pointer'
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#f0f0f0',
            borderRadius: '4px'
        }),
        multiValueLabel: (base) => ({
            ...base,
            fontFamily: 'Poppins, sans-serif'
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#007bff',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white'
            }
        })
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Basic Information</h2>

            <div className="input-container">
                <label>Name</label>
                <input
                    type="text"
                    placeholder="Enter your name"
                    className="input-field"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Gender</label>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="Male"
                            checked={editGender === 'Male'}
                            onChange={() => setEditGender('Male')}
                        />
                        Male
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="Female"
                            checked={editGender === 'Female'}
                            onChange={() => setEditGender('Female')}
                        />
                        Female
                    </label>
                </div>
            </div>

            <div className="input-container">
                <label>Marital Status</label>
                <div className="options-container">
                    {options.map((options, index) => (
                        <button
                            key={index}
                            className={`option-button ${editMaritalStatus === options ? 'selected' : ''}`}
                            onClick={() => setEditMaritalStatus(options)}
                        >
                            {options}
                        </button>
                    ))}
                </div>
            </div>

            <div className="input-container">
                <label>Physically Challenged</label>
                <div className="radio-group">
                    <label>
                        <input
                            type="radio"
                            value="Yes"
                            checked={editPhysicalChallenge === 'Yes'}
                            onChange={() => setEditPhysicalChallenge('Yes')}
                        />
                        Yes
                    </label>
                    <label>
                        <input
                            type="radio"
                            value="No"
                            checked={editPhysicalChallenge === 'No'}
                            onChange={() => setEditPhysicalChallenge('No')}
                        />
                        No
                    </label>
                </div>
            </div>

            <div className="input-container">
                <label>Profile For</label>
                <div className="options-container">
                    {profileOptions.map((profileOptions, index) => (
                        <button
                            key={index}
                            className={`option-button ${editProfileFor === profileOptions ? 'selected' : ''}`}
                            onClick={() => setEditProfileFor(profileOptions)}
                        >
                            {profileOptions}
                        </button>
                    ))}
                </div>
            </div>

            <div className="input-container">
                <label>Mother Tongue</label>
                <Select
                    options={language}
                    onChange={(selected) => setSelectedLanguage(selected.value)}
                    placeholder="Select language"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedLanguage ? { value: selectedLanguage, label: selectedLanguage } : null}
                />
            </div>

            <div className="input-container">
                <label>Known Languages</label>
                <Select
                    options={language}
                    isMulti
                    onChange={handleLanguageChange}
                    placeholder="Select known languages"
                    className="custom-select"
                    styles={customSelectStyles}
                    value={selectedKnownLanguages}
                />
            </div>

            <div className="input-container">
                <label>Ethnic Group</label>
                <Select
                    options={ethnicGroups}
                    onChange={(selected) => setselectedEthnicGroup(selected.value)}
                    placeholder="Select ethnicGroup"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEthnicGroup ? { value: selectedEthnicGroup, label: selectedEthnicGroup } : null}
                />
            </div>

            <div className="input-container">
                <label>Willing to relocate</label>
                <Select
                    options={ethnicGroups}
                    onChange={(selected) => setselectedRelocate(selected.value)}
                    placeholder="Select Relocate Status"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedRelocate ? { value: selectedRelocate, label: selectedRelocate } : null}
                />
            </div>

            <div className="input-container">
                <label>Marriage Planning</label>
                <Select
                    options={ethnicGroups}
                    onChange={(selected) => setselectedMarriagePlan(selected.value)}
                    placeholder="Select Marriage plan Status"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedMarriagePlan ? { value: selectedMarriagePlan, label: selectedMarriagePlan } : null}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleBasicInfo}>Done</button>
            </div>
            <Toaster />
        </div>
    );
};

export default EditBasicInfo;