import React, { useEffect, useState } from "react";
import ProfileList from "../Profiles/ProfileList";
import { Link } from "react-router-dom";
import TopNav from "../../layouts/Header/TopNav";
import BottomNav from "../../layouts/Header/BottomNav";
import Footer from "../../layouts/Footer";
import axios from "axios";
import { API_URL } from "../../assets/Constants";
import Skeleton from "react-loading-skeleton";
import Notification from "./Notifications";

const Home = () => {
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
        try {
            const token = localStorage.getItem("token");

            const response = await axios.get(
                `${API_URL}/api/web/homepage`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            setUser(response.data);
        } catch (error) {
            console.error('Error fetching user:', error);
        }
    };

    useEffect(() => {
        fetchUser();
    }, []);

    return (
        <div className="layout-wrapper layout-navbar-full layout-horizontal layout-without-menu">
            <div className="layout-container">
                <TopNav />
                <div className="layout-page">
                    <div className="content-wrapper">
                        <BottomNav />
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row g-6">
                                <div className="col-md-12 col-xxl-6">
                                    <div className="card">
                                        <div className="d-flex align-items-end row">
                                            <div className="col-md-7 order-2 order-md-1">
                                                <div className="card-body">
                                                    <h4 className="card-title mb-2">
                                                        <span className="fw-bold">{user ? user.name : <Skeleton />}
                                                        </span>{user ? `(STN${user.profileId})` : <Skeleton />}
                                                    </h4>
                                                    <p className="mb-0">
                                                        <strong>Account Type : Free</strong>
                                                    </p>
                                                    {/* <p>Upgrade your membership to premium to enjoy full features.</p> */}
                                                    <Link to={"/my-profile"}>
                                                        <a href="my-profile" className="btn btn-primary mt-3 mb-5 me-2">My Profile</a>
                                                    </Link>
                                                    {/* <a href="#!" className="btn btn-danger">Upgrade Membership</a> */}
                                                </div>
                                            </div>
                                            <div className="col-md-5 text-md-end order-1 order-md-2 py-1 px-8">
                                                {user ?
                                                    <img className="rounded" src={user.photoUrl} height="200" width="150" alt="View Profile" />
                                                    :
                                                    <Skeleton height={200} width={150} />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-xxl-6">
                                    <div className="card">
                                        <div className="d-flex align-items-end row">
                                            <div className="col-md-6">
                                                <div className="card-body">
                                                    <Link to={"/search"}>
                                                        <div className="d-flex align-items-center mb-5">
                                                            <div className="avatar">
                                                                <div className="avatar-initial bg-label-primary rounded">
                                                                    <i className="ri-search-line ri-24px"></i>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex flex-column">
                                                                <h6 className="mb-1">Search</h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to={"/explore"}>
                                                        <div className="d-flex align-items-center mb-5">
                                                            <div className="avatar">
                                                                <div className="avatar-initial bg-label-primary rounded">
                                                                    <i className="ri-focus-2-fill ri-24px"></i>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex flex-column">
                                                                <h6 className="mb-1">Explore</h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card-body">
                                                    <Link to={"/manage-photos"}>
                                                        <div className="d-flex align-items-center mb-5">
                                                            <div className="avatar">
                                                                <div className="avatar-initial bg-label-primary rounded">
                                                                    <i className="ri-image-2-line ri-24px"></i>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex flex-column">
                                                                <h6 className="mb-1">Manage Photos</h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                    <Link to={"/my-profile"}>
                                                        <div className="d-flex align-items-center mb-5">
                                                            <div className="avatar">
                                                                <div className="avatar-initial bg-label-primary rounded">
                                                                    <i className="ri-pencil-line ri-24px"></i>
                                                                </div>
                                                            </div>
                                                            <div className="ms-3 d-flex flex-column">
                                                                <h6 className="mb-1">Edit Profile</h6>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Notification />
                                <ProfileList />
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Home;