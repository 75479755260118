import React from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from 'axios';
import toast from "react-hot-toast";
import { API_URL } from "../../assets/Constants";

const BottomNav = () => {
    const navigate = useNavigate();

    //logout function
    const handleLogout = async (e) => {
        try {
            const token = localStorage.getItem("token") ?? '';
            
            if (!token) {
                console.warn('No token found, redirecting to login.');
                navigate('/');
                return;
            }
    
            //send the logout request with the token
            const response = await axios.post(`${API_URL}/api/logout`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
    
            localStorage.removeItem('token');
            toast.success('Logged out Successfully!');
            navigate('/');
    
        } catch (error) {
            console.error('Logout failed:', error);
            toast.error('An error occurred!');
        }
    };
    
    return(
        <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0">
            <div className="container-xxl d-flex h-100">
                <ul className="menu-inner">
                    <li className="menu-item">
                        <Link to={"/home"}>
                            <a href="/home" className="menu-link">
                                <i className="menu-icon tf-icons ri-home-smile-line"></i>
                                <div data-i18n="Home">Home</div>
                            </a>
                        </Link>
                        
                    </li>
                    <li className="menu-item">
                        <Link to={"/search"}>
                            <a href="search" className="menu-link">
                                <i className="menu-icon tf-icons ri-search-line"></i>
                                <div data-i18n="Home">Search</div>
                            </a>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to={"/explore"}>
                            <a href="/explore" className="menu-link">
                                <i className="menu-icon tf-icons ri-focus-2-line"></i>
                                <div data-i18n="Home">Explore</div>
                            </a>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/chat">
                            <a href="/chat" className="menu-link">
                                <i className="menu-icon tf-icons ri-message-3-line"></i>
                                <div data-i18n="Home">Chat</div>
                            </a>
                        </Link>
                    </li>
                    {/* <li className="menu-item">
                        <Link to="/upgrade-plan">
                            <a href="/upgrade-plan" className="menu-link">
                                <i className="menu-icon tf-icons ri-add-box-line"></i>
                                <div data-i18n="Home">Upgrade</div>
                            </a>
                        </Link>
                    </li> */}
                    <li className="menu-item">
                        <Link to="/help">
                            <a href="/help" className="menu-link">
                            <i className="menu-icon tf-icons ri-hand-heart-line"></i>
                                <div data-i18n="Help">Help</div>
                            </a>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <Link to="/settings">
                            <a href="/settings" className="menu-link">
                                <i className="menu-icon tf-icons ri-settings-line"></i>
                                <div data-i18n="Settings">Settings</div>
                            </a>
                        </Link>
                    </li>
                    <li className="menu-item">
                        <a href="#!" className="menu-link text-danger" onClick={handleLogout}>
                            <i className="menu-icon tf-icons ri-logout-box-r-line"></i>
                            <div data-i18n="Settings">Logout</div>
                        </a>
                    </li>
                </ul>
            </div>
        </aside>
    );
};
export default BottomNav;