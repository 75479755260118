import React from "react";

const LifeStyle = ({ lifestyleDetails }) => {
    return(
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Lifestyle</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th width="200">Smoking Habits</th>
                            <td>: {lifestyleDetails?.smoking_habits || 'N/A'}</td>
                            <th width="200">Drinking Habits</th>
                            <td>: {lifestyleDetails?.drinking_habits || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Eating Habits</th>
                            <td>: {lifestyleDetails?.eating_habits || 'N/A'}</td>
                            <th style={{ whiteSpace: "normal"}}>Personality</th>
                            <td style={{ whiteSpace: "normal"}}> 
                                : {lifestyleDetails?.personality || 'N/A'}
                            </td>
                        </tr>
                        <tr>
                            <th>Hobbies</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.hobbies || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Food</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_food || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Sport</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_sport || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Place</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_place || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Favorite Books</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.favorite_books || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Places Visited</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.places_visited || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Movie and Music Preferences</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.movie_and_music || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Dress Sense</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.dress_sense || 'N/A'}</td>
                        </tr>
                        <tr>
                            <th>Sense of Humor</th>
                            <td colSpan="3" style={{ whiteSpace: "normal"}}>: {lifestyleDetails?.sense_of_humor || 'N/A'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LifeStyle;
