import React, { useEffect, useState } from "react";
import ContactDetailsModal from "./Modals/ContactDetailsModal";

const ContactDetails = ({ contactDetails : initialContactDetails }) => {

    const [showModal, setShowModal] = useState(false);
    const [contactDetails, setContactDetails] = useState(initialContactDetails);

    useEffect(() => {
        setContactDetails(initialContactDetails);
    }, [initialContactDetails]);

    const handleSave = (updatedDetails) => {
        // Assume these are the correct updated details
        setContactDetails(prev => ({
            ...prev,
            primaryNumber: updatedDetails.primaryNumber,
            secondaryNumber: updatedDetails.secondaryNumber,
            preferredContactType: updatedDetails.preferredContactType,
            preferredContactTime: updatedDetails.preferredContactTime,
            contactPerson: updatedDetails.contactPerson,
        }));

        setShowModal(false);
    };

    return (
        <div className="card mb-3 border border-primary border-4">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">Contact Details</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Primary Number</th>
                            <td>: {contactDetails?.primary_number || 'NA'}</td>
                            <th>Secondary Number</th>
                            <td>: {contactDetails?.secondary_number || 'NA'}</td>
                        </tr>
                        <tr>
                            <th>Preferred Contact Type</th>
                            <td>: {contactDetails?.preferred_contact_type || 'NA'}</td>
                            <th>Preferred Contact Time</th>
                            <td>: {contactDetails?.preferred_contact_time || 'NA'}</td>
                        </tr>
                        <tr>
                            <th>Contact Person</th>
                            <td>: {contactDetails?.contact_person || 'NA'}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            {showModal && (
                <ContactDetailsModal
                    details={{
                        primaryNumber: contactDetails?.primary_number,
                        secondaryNumber: contactDetails?.secondary_number,
                        preferredContactType: contactDetails?.preferred_contact_type,
                        preferredContactTime: contactDetails?.preferred_contact_time,
                        contactPerson: contactDetails?.contact_person,
                    }}
                    onClose={() => setShowModal(false)}
                    onSave={handleSave}
                />
            )}
        </div>
    );
};

export default ContactDetails;
