import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../../../assets/Constants";

const BadgeModal = ({ onClose, fetchBadge }) => {
    const [idName, setIdName] = useState("");
    const [idFile, setIdFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);

    const handleFileChange = (e) => {
        setIdFile(e.target.files[0]);
    };

    const handleSubmit = async () => {
        if (!idName || !idFile) {
            toast.error("Please fill all fields and upload a file.");
            return;
        }

        const formData = new FormData();
        formData.append("idName", idName);
        formData.append("idFile", idFile);

        try {
            setIsUploading(true);
            const token = localStorage.getItem("token");

            const response = await axios.post(`${API_URL}/api/badge/idStore`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.data.status) {
                toast.success("Badge uploaded successfully!");
                onClose();
                try {
                    await fetchBadge();
                } catch (fetchError) {
                    console.error("Error fetching badge:", fetchError);
                    toast.error("Failed to refresh badge data.");
                }
            } else {
                toast.error("Failed to upload badge.");
            }
        } catch (error) {
            console.error("Upload error:", error);
            toast.error("Something went wrong. Please try again.", error);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <div
            className="modal fade show"
            id="badgeUploadModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Upload Identity File
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        placeholder="Enter Id Type"
                                        value={idName}
                                        onChange={(e) => setIdName(e.target.value)}
                                    />
                                    <label htmlFor="idType">Id Type</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-2">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="file"
                                        className="form-control form-control-sm"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="idType">Id File</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSubmit}
                            disabled={isUploading}
                        >
                            {isUploading ? "Uploading..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BadgeModal;
