import React, { useEffect, useState } from 'react';
import '../../assets/vendor/css/pages/app-chat.css';
import profileImage1 from '../../assets/img/profiles/woman1.jpg';
import profileImage2 from '../../assets/img/profiles/woman2.jpg';
import myProfileImage from '../../assets/img/profiles/man1.jpg';
import './chat.css';
import axios from 'axios';
import Pusher from 'pusher-js';

const Chat = () => {

    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get('https://admin.steptonikah.com/api/chat/users', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                setUsers(response.data.users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
      
        fetchUsers();
      
        // Initialize Pusher (only once)
        const pusher = new Pusher('49989a0d554bd12bdefc', {
            cluster: 'ap2',
            authEndpoint: 'https://admin.steptonikah.com/api/broadcasting/auth',
            auth: {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Accept': 'application/json',
                },
            },
        });

        console.log('Pusher initialized');
      
        const channel = pusher.subscribe('private-chat-channel');
        console.log('Subscribed to chat-channel');
      
        channel.bind('chat-event', (data) => {
            console.log("New message received:", data);

            if (!selectedUser) {
                console.warn('No user selected, skipping message processing');
                return;
            }

            // Check if the message is relevant for the current chat
            if (data.sender_id == selectedUser || data.receiver_id == selectedUser) {
                setMessages(prevMessages => [...prevMessages, data]);
            }
        });


        channel.bind('pusher:subscription_succeeded', () => {
            console.log('Pusher subscription succeeded');
        });
    
        // Handle potential errors
        channel.bind('pusher:subscription_error', (error) => {
            console.error('Pusher subscription error:', error);
        });
      
        // Cleanup on component unmount
        return () => {
            console.log('Cleaning up Pusher subscription');
            channel.unbind_all();
            pusher.unsubscribe('chat-channel');
        };
    }, [selectedUser]);

    const fetchMessages = async (userId) => {
        setSelectedUser(userId);
        try {
            const response = await axios.get(`https://admin.steptonikah.com/api/messages/${userId}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setMessages(response.data.messages);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };
      
    const sendMessage = async (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            try {
                await axios.post('https://admin.steptonikah.com/api/messages',
                    { receiver_id: selectedUser, message: newMessage },
                    { headers: { Authorization: `Bearer ${localStorage.getItem('token')}` } }
                );
                setNewMessage('');
            } catch (error) {
                console.error('Error sending message:', error);
            }
        }
    };

    return (
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <div className="app-chat card overflow-hidden">
                <div className="row">
                    <div className="col app-chat-contacts app-sidebar flex-grow-0 overflow-hidden border-end" id="app-chat-contacts">
                        <div class="sidebar-header px-5 py-3 border-bottom d-flex align-items-center">
                            <h5 className="text-primary mb-0">Chats</h5>
                        </div>
                        <div className="sidebar-body" style={{ maxHeight: '650px', overflowY: 'auto' }}>
                            <ul className="list-unstyled chat-contact-list py-2 mb-0" id="chat-list">
                                {users.map((user) => (
                                    <li className="chat-contact-list-item mb-1"
                                        key={user.id}
                                        onClick={() => fetchMessages(user.id)}
                                        style={{
                                            padding: '10px',
                                            cursor: 'pointer',
                                            background: selectedUser === user.id ? '#f0f0f0' : 'transparent',
                                        }}
                                    >
                                        <a className="d-flex align-items-center" href='#!'>
                                            <div className="flex-shrink-0 avatar">
                                                <img src={profileImage2} alt="Avatar" className="rounded-circle" />
                                            </div>
                                            <div className="chat-contact-info flex-grow-1 ms-4">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h6 className="chat-contact-name text-truncate m-0 fw-normal">{user.name}</h6>
                                                    <small className="text-muted">2 Days</small>
                                                </div>
                                                {/* <small className="chat-contact-status text-truncate">Allowed you to view locked photos</small> */}
                                            </div>
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col app-chat-history">
                        <div className="chat-history-wrapper">
                            <div className="chat-history-header border-bottom">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className="d-flex overflow-hidden align-items-center">
                                        <i className="ri-menu-line ri-24px cursor-pointer d-lg-none d-block me-4" data-bs-toggle="sidebar" data-overlay="" data-target="#app-chat-contacts"></i>
                                        <div className="flex-shrink-0 avatar">
                                            <img src={profileImage1} alt="Avatar" className="rounded-circle" data-bs-toggle="sidebar" data-overlay="" data-target="#app-chat-sidebar-right" />
                                        </div>
                                        <div className="chat-contact-info flex-grow-1 ms-4">
                                            <h6 className="m-0 fw-normal">Jamsheena</h6>
                                            <small className="user-status text-body">22 years, 155 cm, Never Married</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="chat-history-body" style={{ maxHeight: '600px', overflowY: 'auto' }}>
                                <ul className="list-unstyled chat-history">
                                    {messages.map((msg, index) => (
                                        <li 
                                            key={index} 
                                            className={`chat-message ${msg.sender_id == selectedUser ? '' : 'chat-message-right'}`}
                                        >
                                            <div className="d-flex overflow-hidden">
                                                {msg.sender_id == selectedUser && (
                                                    <div className="user-avatar flex-shrink-0 me-4">
                                                        <div className="avatar avatar-sm">
                                                            <img src={profileImage1} alt="Avatar" className="rounded-circle" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="chat-message-wrapper flex-grow-1">
                                                    <div className="chat-message-text">
                                                        <p className="mb-0">
                                                            {msg.message}
                                                        </p>
                                                    </div>
                                                </div>
                                                {msg.sender_id != selectedUser && (
                                                    <div className="user-avatar flex-shrink-0 ms-4">
                                                        <div className="avatar avatar-sm">
                                                            <img src={myProfileImage} alt="Avatar" className="rounded-circle" />
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>

                            <div className="chat-history-footer">
                                <form onSubmit={sendMessage} className="form-send-message d-flex justify-content-between align-items-center ">
                                    <input className="form-control message-input me-4 shadow-none"
                                        type="text"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        placeholder="Type your message here..." 
                                    />
                                    <div className="message-actions d-flex align-items-center">
                                        <button type='submit' className="btn btn-primary d-flex send-msg-btn">
                                            <span className="align-middle">Send</span>
                                            <i className="ri-send-plane-line ri-16px ms-md-2 ms-0"></i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;