import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoArrowBack, IoChatbubblesSharp, IoClose, IoStar, IoHeart, IoCheckmarkCircle, IoInformationCircleOutline, IoBanOutline, IoAlertCircleOutline, IoEyeOffOutline, IoCallSharp, IoLockClosedSharp, IoCheckmarkCircleSharp, IoStarSharp } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';
import axios from 'axios';
import Modal from 'react-modal';
import { BsShieldExclamation } from 'react-icons/bs';
import { Toaster, toast } from 'react-hot-toast';

const DetailsScreen = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const item = location.state?.item;
    const [data, setData] = useState([]);
    const [userDetails, setUserDetails] = useState('');
    const [basicDetails, setBasicDetails] = useState([]);
    const [contactDetails, setContactDetails] = useState([]);
    const [educationdetails, setEducationalDetails] = useState([]);
    const [familyDetails, setFamilyDetails] = useState([]);
    const [physicalDetails, setPhysicalDetails] = useState([]);
    const [personalDetails, setPersonalDetails] = useState([]);
    const [religiousDetails, setReligiousDetails] = useState([]);
    const [locationDetails, setLocationDetails] = useState([]);
    const [lifeStyle, setLifeStyle] = useState([]);
    const [preferenceData, setPreferenceData] = useState([]);
    const [photo, setPhoto] = useState('://admin.steptonikah.com/assets/img/women.jpg');
    const [modalVisible, setModalVisible] = useState(false);
    const [blockModal, setBlockModal] = useState(false);
    const [reportModal, setReportModal] = useState(false);
    const [elseReasonModal, setElseReasonModal] = useState(false);
    const [reason, setReason] = useState('');
    const [selectedProfileId, setSelectedProfileId] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [interestModal, setInterestModal] = useState(false);
    const [photoList, setPhotoList] = useState([]);
    const [userPhoto, setUserPhoto] = useState('');

    useEffect(() => {
        if (!item) {
            console.error("No item found in location state. Redirecting to homepage.");
            navigate('/');
            return;
        }
        fetchData();
    }, [item]);

    const fetchData = async () => {
        if (!item || !item.id) {
            console.log("Item or Item ID is not defined");
            return;
        }
        console.log("fetchData function called", "Item id:", item.id);
        try {
            const token = await localStorage.getItem('token');
            if (!token) {
                console.log("Token not found in AsyncStorage");
                return;
            }
            console.log("Token retrieved:", token);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.get(`https://admin.steptonikah.com/api/partnerProfile/${item.id}/view`, config);
            console.log("API Response:", response.data);
            setBasicDetails(response.data.basicDetails);
            setContactDetails(response.data.contactDetails);
            setEducationalDetails(response.data.educationDetails);
            setFamilyDetails(response.data.familyDetails);
            setLifeStyle(response.data.lifeStyles);
            setPersonalDetails(response.data.personalDetails);
            setLocationDetails(response.data.locationDetails);
            setReligiousDetails(response.data.religiousDetails);
            setPhysicalDetails(response.data.physicalDetails);
            setPreferenceData(response.data.partnerPreferencesData);
            // setPhoto(response.data.photos);
            const photos = response.data.photos;
            setPhotoList(response.data.photos)
            const defaultPhoto = "https://admin.steptonikah.com/assets/img/women.jpg";
            const profilePhoto = Array.isArray(photos) && photos.length > 0 ? photos[0].photo_url : defaultPhoto;

            setPhoto(profilePhoto);
            setLoading(false);
        } catch (error) {
            console.error("Error during API call:", error.response ? error.response.data : error.message);
        }
    };

    const fetchProfileData = async () => {
        setLoading(true);
        try {
            const token = await localStorage.getItem('token');
            console.log(token);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };
            const response = await axios.get('https://admin.steptonikah.com/api/myProfileDetails', config);
            if (response.data.status) {
                setLoading(false);
                setUserDetails(response.data.basicDetails);
                console.log(userDetails, "User Details");
                const profilePicture = response.data.photos.find(
                    (photo) => photo.profile_picture === "yes"
                );

                if (profilePicture) {
                    setUserPhoto(profilePicture.photo_url);
                } else if (response.data.profilePhoto) {
                    setUserPhoto(response.data.profilePhoto);
                } else {
                    setUserPhoto(null); // Or set a default image URL
                }
            }
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        console.log(photo, "Photo");
    }, [userPhoto]);
    useEffect(() => {
        fetchProfileData();
    }, []);
    useEffect(() => {
        console.log('Basic Details', basicDetails);
    }, [basicDetails]);
    useEffect(() => {
        console.log("Contact Details", contactDetails);
    }, [contactDetails]);
    useEffect(() => {
        console.log("Educational Details", educationdetails);
    }, [educationdetails]);
    useEffect(() => {
        console.log("Family Details", familyDetails);
    }, [familyDetails]);
    useEffect(() => {
        console.log("Location Details", locationDetails);
    }, [locationDetails]);
    useEffect(() => {
        console.log("LifeStyle", lifeStyle);
    }, [lifeStyle]);
    useEffect(() => {
        console.log("Physical Details", physicalDetails);
    }, [physicalDetails]);
    useEffect(() => {
        console.log("Religious Detail", religiousDetails);
    }, [religiousDetails]);
    useEffect(() => {
        console.log("Personal Details", personalDetails);
    }, [personalDetails]);
    useEffect(() => {
        console.log('Preference data', preferenceData);
    }, [preferenceData]);
    useEffect(() => {
        console.log('Photo', photo);
        console.log("Photo List", photoList);
    }, [photo]);

    const toggleLike = (id) => {
        setData((prevData) => {
            return prevData.map((profile) =>
                profile.id === id ? { ...profile, liked: !profile.liked } : profile
            );
        });
        setModalVisible(false);
        setSelectedProfileId(null);
    };

    const handleBlockModal = () => {
        setModalVisible(false);
        setBlockModal(true);
    };

    const handleElseModal = () => {
        setBlockModal(false);
        setElseReasonModal(true);
    };

    const handleReasonSubmit = () => {
        setElseReasonModal(false);
    };

    const handleBlock = () => {
        setBlockModal(false);
        toast.success("You have blocked this account");
    };

    const handleReportModal = () => {
        setModalVisible(false);
        setReportModal(true);
    };

    const handlePass = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            console.log(token, "Token", id, "Selected ID");

            const response = await axios.post(
                `https://admin.steptonikah.com/api/ignoreList/${id}/store`,
                {},
                config
            );

            console.log(response.data, "In Pass");

            if (response.data.status) {
                toast.success("You’ve successfully skipped this profile.");

                setTimeout(() => {
                    fetchData();
                }, 2000);
            }
        } catch (error) {
            console.error("Error in handlePass:", error);
            toast.error("An error occurred. Please try again.");
        }
    };

    const handleContactRequest = async (id) => {
        console.log(id, "Id");
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("Token is missing");
                toast.error("Token is missing");
                return;
            }
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };
            const payload = { receiver_id: id };
            console.log("Payload:", payload);

            const response = await axios.post(
                "https://admin.steptonikah.com/api/partnerProfile/contactRequest/store",
                payload,
                config
            );

            if (response.data.status) {
                toast.success("Contact Request Sent: " + response.data.message);
            } else {
                toast.error(response.data.message || "Something went wrong.");
            }
            console.log(response.data, "contact request");
        } catch (error) {
            console.error("Error:", error.response?.data || error.message);
            if (error.response) {
                const statusCode = error.response.status;
                if (
                    statusCode === 400 &&
                    error.response.data.message ===
                    "A contact request has already been sent to this user."
                ) {
                    toast.error("Request Already Sent: " + error.response.data.message);
                } else {
                    toast.error("Something went wrong: " + error.message);
                }
            } else {
                toast.error("Network Error: Please check your internet connection.");
            }
        }
    };

    const handleFav = async (id) => {
        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            console.log(token, "Token", id, "Selected ID");

            const response = await axios.post(
                `https://admin.steptonikah.com/api/shortlist/${id}/store`,
                {},
                config
            );

            console.log(response.data, "In fav");

            if (response.data.status) {
                toast.success("Added to favourites");
            }
        } catch (error) {
            console.error("Error in handleFav:", error);
            toast.error("An error occurred while adding to favourites.");
        }
    };

    const handleHeartPress = async (id) => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error('No token found');
                return;
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const response = await axios.post(
                `https://admin.steptonikah.com/api/interest/${id}/store`,
                {},
                config
            );

            console.log(response.data, "Interest data");

            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "An error occurred while processing your request.");
            }
        } catch (error) {
            console.error("Error in handleHeartPress:", error);
            toast.error(error.message || "An error occurred. Please try again.");
        }
    };

    const handlePhotoRequest = async (id) => {
        console.log("Receiver ID:", id);

        try {
            const token = await localStorage.getItem('token');
            if (!token) {
                console.error("Token is missing");
                return;
            }

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            };

            const payload = { receiver_id: id };
            console.log("Payload:", payload);

            const response = await axios.post(
                'https://admin.steptonikah.com/api/partnerProfile/photoRequest/store',
                payload,
                config
            );

            if (response.data.status) {
                toast.success(response.data.message);
            } else {
                toast.error(response.data.message || "Something went wrong");
            }
            console.log(response.data, "photo request");

        } catch (error) {
            console.error("Error:", error.response?.data || error.message);

            if (error.response) {
                const statusCode = error.response.status;

                if (statusCode === 400 && error.response.data.message === "A photo request has already been sent to this user.") {
                    toast.error("A photo request has already been sent to this user");
                } else {
                    toast.error(error.message);
                }
            } else {
                toast.error("Please check your internet connection");
            }
        }
    };

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                height: '100vh',
                backgroundColor: '#FDF7FD',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <div className="spinner" style={{ width: '50px', height: '50px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>
            </div>
        );
    }

    return (
        <div className="details-container">
            <Toaster />
            <div className="image-container">
                <button className="back-button" onClick={() => navigate(-1)} aria-label="Go back">
                    <IoArrowBack size={25} color="white" />
                </button>
                <button className="menu-button" onClick={() => setModalVisible(true)}>
                    <MdMoreVert size={25} color="white" />
                </button>
                <img src={photo} alt="Profile" className="profile-image" onClick={() => {
                    if (photo !== "https://admin.steptonikah.com/assets/img/women.jpg" || "https://admin.steptonikah.com/assets/img/man.jpg") {
                        navigate(`/image-carousel`, { state: { images: photoList } })
                    }
                }} />
                {item.photoPrivacy === 'protected' && (
                    <div className="image-overlay" style={{ borderRadius: "0" }}>
                        <IoLockClosedSharp size={24} color="white" />
                        <p className="lockedText">Photo Locked</p>
                        <button className="requestButton" onClick={(e) => {
                            e.stopPropagation();
                            handlePhotoRequest(item.id);
                        }}>
                            <p className="requestButtonText">Request to View</p>
                        </button>
                    </div>
                )}
                <div className="overlay-container">
                    <h3 className="profile-name" style={{ color: "white" }}>{basicDetails.name ? basicDetails.name : "Any"}</h3>
                    <p className="profile-details">{basicDetails.age}, {physicalDetails.height}, {personalDetails.marital_status}</p>
                    <p className="profile-details">{locationDetails.home_city !== "null" && locationDetails.home_city ? locationDetails.home_city + ", " : ""} {locationDetails.home_district !== 'null' && locationDetails.home_district ? locationDetails.home_district + ", " : ""}{locationDetails.home_state !== 'null' && locationDetails.home_state ? locationDetails.home_state + ", " : ""}{locationDetails.home_country}</p>
                </div>
            </div>

            <div className="content-container">
                <section className="about-section">
                    <h4>About</h4>
                    <p className="about-text">{personalDetails.profile_description}</p>
                </section>
                {/* <section className="interest-section">
                    <h4 style={{ marginBottom: '8px' }}>Interests</h4>
                    <div className="interest-tags">
                        {['Reading', 'Dancing', 'Cooking'].map((interest, idx) => (
                            <span key={idx} className="interest-tag">{interest}</span>
                        ))}
                    </div>
                </section> */}
                <section className="info-section">
                    <h4>Basic Information</h4>
                    <div className="info-row">
                        <p className="info-label">Name</p>
                        <p className="info-value">{basicDetails.name}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Profile Id</p>
                        <p className="info-value">STN{basicDetails.profile_id}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Age</p>
                        <p className="info-value">{basicDetails.age}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Gender</p>
                        <p className="info-value">{basicDetails.gender}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Marital Status</p>
                        <p className="info-value">{personalDetails.marital_status}</p>
                    </div>
                    {/* <div className="info-row">
                        <p className="info-label">Profile Created by</p>
                        <p className="info-value">Father</p>
                    </div> */}
                    <div className="info-row">
                        <p className="info-label">Health/Disability Issues?</p>
                        <p className="info-value">{physicalDetails.physically_challenged}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Mother Tongue</p>
                        <p className="info-value">{personalDetails.mother_tongue}</p>
                    </div>
                </section>
                <section className='location-section'>
                    <h4>Location Details</h4>
                    <div className="info-row">
                        <p className="info-label">Present Location</p>
                        <p className="info-value">{locationDetails.present_city !== 'null' && locationDetails.present_city ? locationDetails.present_city + ", " : ""}
                            {locationDetails.present_district !== 'null' && locationDetails.present_district ? locationDetails.present_district + ", " : ""}
                            {locationDetails.present_state !== 'null' && locationDetails.present_state ? locationDetails.present_state + ", " : ""}{locationDetails.present_country}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Home Location</p>
                        <p className="info-value">{locationDetails.home_city !== 'null' && locationDetails.home_city ? locationDetails.home_city + ", " : "" !== 'null' && locationDetails.present_city !== 'null' && locationDetails.present_city ? locationDetails.present_city + ", " : "" ? locationDetails.present_city !== 'null' && locationDetails.present_city ? locationDetails.present_city + ", " : "" + ", " : ""}
                            {locationDetails.home_district !== 'null' && locationDetails.home_district ? locationDetails.home_district + ", " : ""}
                            {locationDetails.home_state !== 'null' && locationDetails.home_state ? locationDetails.home_state + ", " : ""}
                            {locationDetails.home_country}</p>
                    </div>
                </section>
                <section className='contact-section'>
                    <h4>Contact Details</h4>
                    <div className="info-row" style={{ margin: "15px 0" }}>
                        <button type="button" style={{ display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: '#572d71', borderRadius: "5px", height: "45px", width: "80%", border: "none", gap: "7px" }} onClick={() => handleContactRequest(item.id)}>
                            <IoCallSharp size={20} style={{ color: '#fff', margin: "7px 0" }} />
                            <p style={{ color: '#fff', margin: "10px 0", fontWeight: "bold", fontSize: "14px" }}> Request Contact Details</p>
                        </button>
                    </div>
                </section>
                <section className='education-section'>
                    <h4>Educational Details</h4>
                    <div className="info-row">
                        <p className="info-label">Education</p>
                        <p className="info-value">{educationdetails.highest_education}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Profession Type</p>
                        <p className="info-value">{educationdetails.profession_type}</p>
                    </div>
                </section>
                <section className='physical-section'>
                    <h4>Physical Details</h4>
                    <div className="info-row">
                        <p className="info-label">Height</p>
                        <p className="info-value">{physicalDetails.height} cm</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Weight</p>
                        <p className="info-value">{physicalDetails.weight} kg</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Blood Group</p>
                        <p className="info-value">{physicalDetails.blood_group ? physicalDetails.blood_group : '-'}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Body Type</p>
                        <p className="info-value">{physicalDetails.body_type ? physicalDetails.body_type : '-'}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Hair Color</p>
                        <p className="info-value">{physicalDetails.hair_color}</p>
                    </div>
                    <div className="info-row">
                        <p className="info-label">Eye Color</p>
                        <p className="info-value">{physicalDetails.eye_color}</p>
                    </div>
                </section>

                <div className="match-container">
                    <div className="centered-item">
                        <img src={userPhoto} alt="userPhoto " className="match-image" />
                        <p className="info-subtext" style={{ marginTop: "8px" }}>{userDetails.name}</p>
                    </div>
                    <div className="centered-item">
                        <img src={photo} alt={basicDetails.name} className="match-image" />
                        <p className="info-subtext" style={{ marginTop: "8px" }}>{basicDetails.name}</p>
                    </div>
                </div>
                <p className="match-info" style={{ marginBottom: '55px' }}>Your profile matches with {preferenceData.preferenceCount}/7 of her preferences!</p>
                {preferenceData.preferenceCount !== 0 && (
                    <>
                        <section className="preference-section" style={{ paddingBottom: '70px' }}>
                            <h4>Partner Basic Preferences</h4>
                            <div className="info-row">
                                <p className="info-label">Age Preference</p>
                                <p><IoCheckmarkCircle size={24} color='#319431' className="info-value" /></p>
                            </div>
                            <div className="info-row">
                                <p className="info-label">Height Preference</p>
                                <p><IoCheckmarkCircle size={24} color='#319431' className="info-value" /></p>
                            </div>
                            <div className="info-row">
                                <p className="info-label">Marital Status Preference</p>
                                <p><IoCheckmarkCircle size={24} color='#319431' className="info-value" /></p>
                            </div>
                            <div className="info-row">
                                <p className="info-label">Smoking Habit Preference</p>
                                <p><IoCheckmarkCircle size={24} color='#319431' className="info-value" /></p>
                            </div>
                            <div className="info-row">
                                <p className="info-label">Drinking Habit Preference</p>
                                <p><IoCheckmarkCircle size={24} color='#319431' className="info-value" /></p>
                            </div>
                        </section>
                    </>
                )}
            </div>
            <div className="button-bar" style={{
                justifyContent:
                    item.interestStatus === "pending"
                        ? "space-between"
                        : "space-around",
            }}>
                <button className="icon-container chat-button" aria-label={`Chat with ${item.name}`} onClick={() => navigate('/chat-screen')}>
                    <IoChatbubblesSharp size={30} />
                </button>
                {item.interestStatus === "pending" ? (
                    <>
                        <div className="interest-button-container">
                            <IoCheckmarkCircleSharp size={20} color="white" />
                            <p className="interest-button-text">Interest Sent</p>
                        </div>
                    </>
                ) : (
                    <>
                        <button className="icon-container close" onClick={() => handlePass(item.id)}>
                            <IoClose size={30} />
                        </button>
                        <button
                            className={`icon-container star-button ${item.favouriteStatus === 'active' ? 'active' : 'inactive'}`}
                            aria-label={`Star ${item.name}`}
                            onClick={() => handleFav(item.id)}
                        >
                            <IoStarSharp size={30} />
                        </button>
                        <button
                            onClick={() => handleHeartPress(item.id)}
                            className={`icon-container heart-button ${item.liked ? "liked" : ""}`}
                            aria-label={item.liked ? `Unlike ${item.name}` : `Like ${item.name}`}
                        >
                            <IoHeart size={30} className={item.liked ? "liked" : ""} />
                        </button>
                    </>
                )}
            </div>

            <Modal
                isOpen={modalVisible}
                onRequestClose={() => setModalVisible(!modalVisible)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setModalVisible(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <div
                    className="modal-option"
                    onClick={handleBlockModal}
                    style={{ marginTop: "12px" }}
                >
                    <IoBanOutline size={22} color="#007BFF" />
                    <span>Block this Profile</span>
                </div>
                <div
                    className="modal-option"
                    onClick={handleReportModal}
                >
                    <IoAlertCircleOutline
                        size={22}
                        color="#007BFF"
                    />
                    <span>Report this Profile</span>
                </div>
            </Modal>

            <Modal
                isOpen={blockModal}
                onRequestClose={() => setBlockModal(!blockModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setBlockModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Block Profile</h2>
                <p>Please tell us why you are blocking this profile, we will not reveal your identity.</p>
                <div className="report-buttons">
                    <div className='report-option'>
                        <IoEyeOffOutline size={22} color='#572d71' />
                        <button onClick={handleBlock} className="report-option-button">I just don't want them to see me</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate messages</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate profile</button>
                    </div>
                    <div className='report-option'>
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Scam</button>
                    </div>
                    <div className='report-option'>
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Something else</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={reportModal}
                onRequestClose={() => setReportModal(!reportModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setReportModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Report Profile</h2>
                <p>Please tell us why you are reporting this profile, we will not reveal your identity.</p>
                <div className="report-buttons">
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate messages</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Inappropriate profile</button>
                    </div>
                    <div className="report-option">
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button"
                        >Scam</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleReportModal} className="report-option-button">Marriage fixed</button>
                    </div>
                    <div className="report-option">
                        <BsShieldExclamation size={19} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Wrong contact details</button>
                    </div>
                    <div className="report-option">
                        <IoInformationCircleOutline size={22} color='#572d71' />
                        <button onClick={handleElseModal} className="report-option-button">Something else</button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={elseReasonModal}
                onRequestClose={() => setElseReasonModal(!elseReasonModal)}
                className="report-modal"
                overlayClassName="modal-overlay"
            >
                <button className="modal-close-button" onClick={() => setElseReasonModal(false)}>
                    <IoClose size={22} color='#572d71' />
                </button>
                <h2>Tell us more</h2>
                <p>Please tell us why you are blocking this profile in detail, we will not reveal your identity.</p>
                <textarea
                    placeholder="Enter Reason"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    className="modal-textarea"
                />
                <button onClick={handleReasonSubmit} className="modal-submit-button">Submit</button>
            </Modal>

            <Modal
                isOpen={interestModal}
                onRequestClose={() => setInterestModal(false)}
                className="popup_modal"
                overlayClassName="modal-overlay"
            >
                <div className="popup_modal-content">
                    <h2>Do you want to send interest?</h2>
                    <div className="modal-buttons">
                        <button onClick={() => toggleLike(selectedProfileId)} className="modal-button yes">Yes</button>
                        <button onClick={() => setInterestModal(false)} className="modal-button no">No</button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default DetailsScreen;
