import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { API_URL } from "../../../assets/Constants";
import toast from "react-hot-toast";
import BlockModal from "./modals/BlockModal";

const carousalImage = {
    height: 500
};

const ProfileHeader = (
    {
        basicDetails, 
        personalDetails, 
        physicalDetails, 
        religiousDetails, 
        educationDetails, 
        locationDetails, 
        profilePhoto, 
        photos,
        photoPrivacy
    }
) => {
    const [interestStatus, setInterestStatus] = useState(false);
    const [shortlistStatus, setShortlistStatus] = useState(false);
    const [blockStatus, setBlockStatus] = useState(false);
    const [showBlockModal, setShowBlockModal] = useState(false);
    const [interestReceivedStatus, setInterestReceivedStatus] = useState(false);
    const [photoRequestStatus, setPhotoRequestStatus] = useState(false);
    const [photoRequestReceivedStatus, setPhotoRequestReceivedStatus] = useState(false);

    const fetchData = async () => {
        if (!basicDetails || !basicDetails.id) {
            return;
        }

        try {
            const token = localStorage.getItem("token");
            
            if (!token) {
                console.error("No authentication token found");
                return;
            }

            const response = await axios.post(
                `${API_URL}/api/web/partnerProfile/interests`, 
                { profileId: basicDetails.id }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data && response.data.status) {
                setInterestStatus(response.data.interestStatus); 
                setShortlistStatus(response.data.shortlistStatus); 
                setBlockStatus(response.data.blockStatus); 
                setInterestReceivedStatus(response.data.interestReceivedStatus); 
            } else {
                console.warn("Failed to fetch interest statuses");
            }
        } catch (error) {
            if (error.response) {
                console.error("Data fetch error:", error.response.data);
                console.error("Status code:", error.response.status);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
            toast.error("Failed to load profile details");
        }
    };
    useEffect(() => {
        if (basicDetails && basicDetails.id) {
            fetchData();
        }
    }, [basicDetails]);

    const sendInterest = async (id) => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/store`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Interest Sent!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error sending interest:", error);
            toast.error("Error sending interest");
        }
    };

    const cancelInterest = async (id) => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/cancelSent`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            );
        
            if (response.data.status) {
                toast.success("Interest Canceled!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error canceling interest:", error);
            toast.error("Error canceling interest");
        }
    };
    const declineInterest = async (id) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/reject`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.status) {
                toast.success("Interest Declined!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error declining interest:", error);
            toast.error("Error declining interest");
        }
    };
    const acceptInterest = async (id) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/accept`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.status) {
                fetchData();
                toast.success("Interest Accepted!");
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error accepting interest:", error);
            toast.error("Error accepting interest");
        }
    };
    const declineAcceptedInterest = async (id) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/cancelAccepted`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.status) {
                fetchData();
                toast.success("Interest Declined!");
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error declining interest:", error);
            toast.error("Error declining interest");
        }
    };
    
    //shortlist profile
    const shortlistProfile = async (id) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/shortlist/${id}/store`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data.status) {
                setShortlistStatus(true);
                toast.success("Profile Shortlisted!");
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error shortlisting:", error);
            toast.error("Error in shortlisting");
        }  
    }

    //photo request
    const fetchPhotoRequest = async () => {
        if (!basicDetails || !basicDetails.id) {
            return;
        }

        try {
            const token = localStorage.getItem("token");
            
            if (!token) {
                console.error("No authentication token found");
                return;
            }

            const response = await axios.post(
                `${API_URL}/api/partnerProfile/getPhotoRequest`, 
                { profileId: basicDetails.id }, 
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );

            if (response.data && response.data.status) {
                if(response.data.action == 'sent'){
                    setPhotoRequestStatus(response.data.photoRequest); 
                }else if(response.data.action == 'received'){
                    setPhotoRequestReceivedStatus(response.data.photoRequest); 
                }
            } else {
                console.warn("Failed to fetch photo request");
            }
        } catch (error) {
            if (error.response) {
                console.error("Data fetch error:", error.response.data);
                console.error("Status code:", error.response.status);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error setting up request:", error.message);
            }
            toast.error("Failed to load photo request");
        }
    };
    useEffect(() => {
        if (basicDetails && basicDetails.id) {
            fetchPhotoRequest();
        }
    }, [basicDetails]);

    const handleSendRequest = async (receiver_id) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/photoRequest/store`,
                { receiver_id: receiver_id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchPhotoRequest();
                toast.success('Photo request sent successfully.');
            } else {
                toast.error(response.data.message || 'Failed to send photo request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };

    const declineSentRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/photoRequest/delete`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchPhotoRequest();
                toast.success('Photo request deleted successfully.');
            } else {
                toast.error(response.data.message || 'Failed to delete photo request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };
    const acceptPhotoRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/photoRequest/accept`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchPhotoRequest();
                toast.success('Photo request accepted successfully.');
            } else {
                toast.error(response.data.message || 'Failed to accept photo request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };
    const rejectPhotoRequest = async (requestId) => {
        try {
            const token = localStorage.getItem('token') || '';
            const response = await axios.post(
                `${API_URL}/api/partnerProfile/photoRequest/reject`,
                { requestId : requestId },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 201) {
                fetchPhotoRequest();
                toast.success('Photo request rejected successfully.');
            } else {
                toast.error(response.data.message || 'Failed to reject photo request.');
            }
        } catch (error) {
            const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
            toast.error(errorMessage);
        }
    };

    //block
    const handleBlockProfile = async (blockReason) => {
        if (!basicDetails?.id) return;

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/blockList/${basicDetails.id}/store`,
                { blockReason },
                { headers: { Authorization: `Bearer ${token}` } }
            );

            if (response.data?.status) {
                setBlockStatus(true);
                toast.success("Profile blocked successfully");
                setShowBlockModal(false);
            } else {
                toast.error(response.data.message || "Failed to block profile");
            }
        } catch (error) {
            console.error("Error blocking profile:", error);
            toast.error("Failed to block profile");
        }
    };
    return (
        <div className="card">
            <div className="card-header border">
            <div className="text-end">
                <div className="dropdown">
                        <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="ri-more-2-line"></i>
                        </button>
                        <div className="dropdown-menu">
                            <a className="dropdown-item waves-effect text-danger" href="#!"
                                onClick={() => setShowBlockModal(true)}
                            >
                                <i className="ri-user-forbid-line me-1"></i> Block this profile
                            </a>
                        </div>
                    </div>
                </div>
                <h5 className="mb-0">
                    {basicDetails ? basicDetails.name : <Skeleton />} 
                    {basicDetails ? ` (STN${basicDetails.profile_id})` : ''}
                </h5>
            </div>
            <div className="card-body border py-5">
                <div className="row">
                    <div className="col-5">
                        <Carousel style={carousalImage}>
                            {photos && photos.length > 0 ? (
                                photos.map((photo) => (
                                    <Carousel.Item key={photo.id}>
                                        <img src={photo.photo_url} className="rounded" style={carousalImage} alt="profile-photo" />
                                    </Carousel.Item>
                                ))
                            ) : (
                                <Carousel.Item>
                                    <img 
                                        src={profilePhoto} 
                                        className="rounded" 
                                        style={carousalImage} 
                                        alt="Default Profile" 
                                    />
                                </Carousel.Item>
                            )}
                        </Carousel>
                        {(photoPrivacy && photoPrivacy == 'protected' && !photoRequestStatus) && (
                            <div className="text-center">
                                <button className="btn btn-warning btn-sm mt-3 text-white" type="button"
                                    onClick={() => handleSendRequest(basicDetails.id)}
                                >
                                    <i className="ri-image-2-fill ri-22px me-2"></i>
                                    Send Request for photos view
                                </button>
                            </div>
                        )}
                    </div>
                    
                    <div className="col-7">
                        <p className="mb-3">
                            <i className="ri-user-3-fill ri-22px text-primary me-2"></i>
                            {basicDetails ? `${basicDetails.age} yrs, ` : <Skeleton />} 
                            {physicalDetails?.height ? ` ${physicalDetails.height}, ` : ''} 
                            {basicDetails?.gender || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-group-3-fill ri-22px text-primary me-2"></i>
                            {religiousDetails ? `${religiousDetails.community}, ${religiousDetails.religion}` : <Skeleton />}                        
                        </p>
                        <p className="mb-3">
                            <i className="ri-shake-hands-fill ri-22px text-primary me-2"></i>
                            {personalDetails?.marital_status || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-graduation-cap-fill ri-22px text-primary me-2"></i>
                            {educationDetails ? (
                                <>
                                    {educationDetails.highest_education} 
                                    {educationDetails.qualification ? ` (${educationDetails.qualification})` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-map-pin-2-fill ri-22px text-primary me-2"></i>
                            {locationDetails ? (
                                <>
                                    {locationDetails.homeCity ? `, ${locationDetails.homeCity})` : ''}
                                    {locationDetails.homeDistrict ? `, ${locationDetails.homeDistrict})` : ''}
                                    {locationDetails.homeState ? `, ${locationDetails.homeState})` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-user-3-fill ri-22px text-primary me-2"></i>
                            {physicalDetails?.physical_status || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-creative-commons-by-fill ri-22px text-primary me-2"></i>
                            {educationDetails ? (
                                <>
                                    {educationDetails.profession ? ` ${educationDetails.profession}` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        <div className="card mt-1 border mt-10">
                            <div className="card-body">
                            {!interestReceivedStatus ? (
                                <div>
                                    <div>
                                        
                                        {!interestStatus ? (
                                            <>
                                                <div className="mb-3">
                                                    <h6 className="fw-bolder mb-1">Like this member?</h6>
                                                    <small>If you are interested in this profile, please send an Interest to this person.</small>
                                                </div>
                                                <button 
                                                    className="btn btn-danger btn-sm text-white me-2" 
                                                    type="button" 
                                                    onClick={() => sendInterest(basicDetails.id)}
                                                >
                                                    <i className="ri-heart-3-fill ri-22px me-2"></i>
                                                    INTEREST
                                                </button>
                                            </>
                                        ) : (
                                            <>
                                                <div className="mb-3">
                                                    <h6 className="fw-bolder mb-1">You sent an interest</h6>
                                                    <small>You have sent an interest to this profile, if accepted you can sent message to this profile.</small>
                                                </div>
                                                {interestStatus.status === 'pending' && (
                                                    <button 
                                                        className="btn btn-danger btn-sm text-white me-2" 
                                                        type="button" 
                                                        onClick={() => cancelInterest(interestStatus.id)}
                                                    >
                                                        <i className="ri-close-circle-fill ri-22px me-2"></i>
                                                        CANCEL INTEREST
                                                    </button>
                                                )}
                                                
                                                {interestStatus.status === 'cancelled' && (
                                                    <button 
                                                        className="btn btn-danger btn-sm text-white me-2" 
                                                        type="button" 
                                                        onClick={() => sendInterest(basicDetails.id)}
                                                    >
                                                        <i className="ri-heart-3-fill ri-22px me-2"></i>
                                                        INTEREST
                                                    </button>
                                                )}
                                                
                                                {interestStatus.status === 'rejected' && (
                                                    <button 
                                                        className="btn btn-danger btn-sm text-white me-2" 
                                                        type="button" 
                                                        disabled
                                                    >
                                                        <i className="ri-close-circle-fill ri-22px me-2"></i>
                                                        INTEREST REJECTED
                                                    </button>
                                                )}
                                                {interestStatus.status === 'accepted' && (
                                                    <button className="btn btn-success btn-sm text-white me-2"  type="button">
                                                        <i className="ri-check-fill ri-22px me-2"></i>
                                                        ACCEPTED
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        {!shortlistStatus ? (
                                                <button className="btn btn-primary btn-sm text-white me-2"
                                                    type="button"
                                                    onClick={() => shortlistProfile(basicDetails.id)}
                                                >
                                                    <i className="ri-star-fill ri-22px me-2"></i>
                                                    SHORTLIST
                                                </button>
                                            ):(
                                                <button className="btn btn-primary btn-sm text-white me-2"
                                                    type="button"
                                                >
                                                    <i className="ri-star-fill ri-22px me-2"></i>
                                                    SHORTLISTED
                                                </button>
                                            )
                                        }
                                        {(!shortlistStatus && !interestStatus && !interestReceivedStatus) && (
                                            <button className="btn btn-secondary btn-sm text-white me-2" type="button">
                                                <i className="ri-stop-circle-fill ri-22px me-2"></i>
                                                SKIP
                                            </button>
                                        ) }
                                    </div>
                                    {interestStatus && interestStatus.status === 'rejected' && (
                                        <div className="mt-3">
                                            <div className="mb-3">
                                                <h6 className="fw-bolder mb-1 text-danger">Interest Rejected</h6>
                                                <small className="text-danger">This member chose not to accept your interest. Don't be discouraged, there are many other members who are interested in you.</small>    
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ):(
                                <>
                                {interestReceivedStatus.status === 'pending' && (
                                    <>
                                        <div className="mt-3">
                                            <div className="mb-5">
                                                <h6 className="fw-bolder mb-1 text-danger">You received an interest</h6>
                                                <p className="text-danger">You have received an interest from this member, but you haven't responded yet. Please accept or decline the interest.</p>    
                                            </div>
                                        </div>
                                        <button 
                                            className="btn btn-success btn-sm text-white me-2" 
                                            type="button" 
                                            onClick={() => acceptInterest(interestReceivedStatus.id)}
                                        >
                                            <i className="ri-check-fill ri-22px me-2"></i>
                                            ACCEPT INTEREST
                                        </button>
                                        <button className="btn btn-danger btn-sm text-white me-2" 
                                            type="button"
                                            onClick={() => declineInterest(interestReceivedStatus.id)}
                                            >
                                            <i className="ri-close-fill ri-22px me-2"></i>
                                            DECLINE INTEREST
                                        </button>
                                    </>
                                )}{interestReceivedStatus.status === 'accepted' &&(
                                    <>
                                        <div className="mt-3">
                                            <div className="mb-5">
                                                <h6 className="fw-bolder mb-1 text-danger">You accepted the interest</h6>
                                                <p className="text-danger">You accepted an interest from this member, you can now message to take next step.</p>    
                                            </div>
                                        </div>
                                        <button className="btn btn-success btn-sm text-white me-2" type="button">
                                            <i className="ri-check-fill ri-22px me-2"></i>
                                            YOU ACCEPTED
                                        </button>
                                        <button className="btn btn-danger btn-sm text-white"
                                            type="button"
                                            onClick={() => declineAcceptedInterest(interestReceivedStatus.id)}
                                        >
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            DECLINE
                                        </button>
                                    </>
                                )}
                                {interestReceivedStatus.status === 'rejected' &&(
                                    <>
                                        <div className="mt-3">
                                            <div className="mb-5">
                                                <h6 className="fw-bolder mb-1 text-danger">You rejected the interest</h6>
                                                <p className="text-danger">You rejected the interest from this member.</p>    
                                            </div>
                                        </div>
                                        <button className="btn btn-danger btn-sm text-white" type="button">
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            REJECTED
                                        </button>
                                    </>
                                )}
                                </>
                            )}
                            </div>
                        </div>
                        {(photoRequestStatus && photoRequestStatus.status === 'pending') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1">Photo Request sent</h6>
                                            <p>You have sent a photo request to this member</p>    
                                        </div>
                                    </div>
                                    <button className="btn btn-danger btn-sm text-white" type="button"
                                        onClick={() => declineSentRequest(photoRequestStatus.id)}
                                    >
                                        <i className="ri-close-circle-fill ri-22px me-2"></i>
                                        Cancel Request
                                    </button>
                                </div>
                            </div>
                        )}
                        {(photoRequestStatus && photoRequestStatus.status === 'accepted') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1 text-success">Photo Request Accepted</h6>
                                            <p className="text-success">Your photo request accepted by this member</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(photoRequestStatus && photoRequestStatus.status === 'rejected') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1 text-danger">Photo Request Rejected</h6>
                                            <p className="text-danger">This member choose not to accept your photo request</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {(photoRequestReceivedStatus && photoRequestReceivedStatus.status === 'pending') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1">Photo Request Received</h6>
                                            <p>You have received a photo request from this member</p>    
                                        </div>
                                    </div>
                                    <button className="btn btn-success btn-sm text-white me-2" type="button"
                                        onClick={() => acceptPhotoRequest(photoRequestReceivedStatus.id)}
                                    >
                                        <i className="ri-check-fill ri-22px me-2"></i>
                                        Accept Request
                                    </button>
                                    <button className="btn btn-danger btn-sm text-white" type="button"
                                        onClick={() => rejectPhotoRequest(photoRequestReceivedStatus.id)}
                                    >
                                        <i className="ri-close-circle-fill ri-22px me-2"></i>
                                        Cancel Request
                                    </button>
                                </div>
                            </div>
                        )}
                        {(photoRequestReceivedStatus && photoRequestReceivedStatus.status === 'accepted') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1">Photo Request Accepted</h6>
                                            <p>You have accepted the photo request from this member</p>    
                                        </div>
                                    </div>
                                    <button className="btn btn-danger btn-sm text-white" type="button"
                                        onClick={() => rejectPhotoRequest(photoRequestReceivedStatus.id)}
                                    >
                                        <i className="ri-close-circle-fill ri-22px me-2"></i>
                                        Decline Request
                                    </button>
                                </div>
                            </div>
                        )}
                        {(photoRequestReceivedStatus && photoRequestReceivedStatus.status === 'rejected') &&(
                            <div className="card border mt-5">
                                <div className="card-body">
                                    <div className="mt-3">
                                        <div className="mb-5">
                                            <h6 className="fw-bolder mb-1 text-danger">Photo Request Rejected</h6>
                                            <p className="text-danger">You have rejected the photo request from this member</p>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* BlockModal Component */}
            {showBlockModal && (
                <BlockModal
                    onClose={() => setShowBlockModal(false)}
                    onSave={handleBlockProfile}
                />
            )}
        </div>
    );
};

export default ProfileHeader;