import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { API_URL } from "../../../assets/Constants";
import toast from "react-hot-toast";
import axios from "axios";
import LookingForModal from "./Modals/LookingForModal";
const LookingFor = ({lookingFor}) => {
    const [lookingForPartner, setLookingForPartner] = useState("");
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (lookingFor?.looking_for) {
            setLookingForPartner(lookingFor.looking_for);
        }
    }, [lookingFor]);

    const handleSaveLookingFor = async (newLookingFor) => {
        try {
            const token = localStorage.getItem("token");
    
            const response = await axios.post(
                `${API_URL}/api/lookingFor/store`,
                { lookingFor: newLookingFor },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.status === 200 && response.data.status) {
                setLookingForPartner(newLookingFor); 
                setShowModal(false);
                toast.success("Looking For updated");
            } else {
                toast.error(response.data.message || "Failed to update looking for");
            }
        } catch (error) {
            toast.error("Error updating looking for:", error);
        }
    };    
    return(
        <div className="card mt-3 mb-5 p-0">
            <div class="card-header border d-flex align-items-center justify-content-between px-5 py-3">
                <div class="card-title mb-0">
                    <h5 class="m-0 me-2 mb-1">I am Looking For</h5>
                </div>
                <div>
                    <button className="btn btn-sm btn-primary float-end" type="button"
                        onClick={() => setShowModal(true)}
                    >
                        Edit
                    </button>
                </div>
            </div>
            <div className="card-body border py-5">
            <p className="justify-content">
                    {lookingFor ? (
                            <>
                                {lookingFor.looking_for ? lookingFor.looking_for : ''}
                            </>
                        ) : <Skeleton count={3} />
                    }
                </p>
            </div>
            {showModal && (
                <LookingForModal
                    existingLookingFor={lookingForPartner}
                    onClose={() => setShowModal(false)}
                    onSave={handleSaveLookingFor}
                />
            )}
        </div>
    );
};

export default LookingFor;