import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoArrowBack, IoCall, IoCamera, IoImage, IoMic, IoVideocam } from 'react-icons/io5';
import { MdMoreVert } from 'react-icons/md';

const ChatScreen = () => {
    const navigate = useNavigate();

    return (
        <div className="chat-container">
            <header className="header">
                <button onClick={() => navigate(-1)} className="iconButton">
                    <IoArrowBack className="icon" />
                </button>
                <img
                    src={require('../assets/images/img1.jpg')}
                    alt="Profile"
                    className="profileImage"
                />
                <p className="userName">Shahnina</p>
                <div className="headerIcons">
                    <button className="iconButton" style={{marginRight: '5px'}}>
                        <IoCall className="icon" />
                    </button>
                    <button className="iconButton">
                        <IoVideocam className="icon" />
                    </button>
                    <button className="iconButton">
                        <MdMoreVert className="icon" />
                    </button>
                </div>
            </header>

            <div className="chatBackground">
            </div>

            <div className="inputContainer">
                <div className="textInputContainer">
                    <input
                        type="text"
                        placeholder="Type a message"
                        className="textInput"
                    />
                    <div className="iconButtonContainer">
                        <button className="iconButton">
                            <IoCamera className="icon" style={{color: 'grey'}}/>
                        </button>
                        <button className="iconButton">
                            <IoImage className="icon" style={{color: 'grey'}}/>
                        </button>
                    </div>
                </div>
                <button className="micButton">
                    <IoMic style={{ color: 'white', fontSize: '18px' }} />
                </button>
            </div>
        </div>
    );
};

export default ChatScreen;
