import React, { useEffect, useState } from 'react';
import homeImage from "../assets/img/home-1/11.png";
import Footer from "../../layouts/Footer";
import Header from '../layouts/Header';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link } from "react-router-dom";
import logo from '../assets/img/logo/logo.png';

const Register = () => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [gender, setGender] = useState('');
    const [profileFor, setProfileFor] = useState('');
    const [dob, setDob] = useState('');
    const [selectedReligion, setSelectedReligion] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');

    const isValidEmail = (email) =>
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const isValidPhone = (phone) =>
        /^\d{10}$/.test(phone);

    const isValidDate = (date) => {
        if (!date) return false;

        const currentDate = new Date();
        const inputDate = new Date(date);
        const age = currentDate.getFullYear() - inputDate.getFullYear();
        const isOver18 =
            age > 18 ||
            (age === 18 && currentDate >= new Date(inputDate.getFullYear() + 18, inputDate.getMonth(), inputDate.getDate()));

        return isOver18;
    };

    useEffect(() => {
        if (profileFor === 'daughter' || profileFor === 'sister') {
            setGender('Female');
        } else if (profileFor === 'son' || profileFor === 'brother') {
            setGender('Male');
        } else {
            setGender(''); 
        }
    }, [profileFor]);

    const handleRegister = async () => {
        if (![name, email, phone, password, profileFor, gender, dob, selectedReligion].every(Boolean)) {
            toast.error('Please fill all required fields');
            return;
        }

        if (!isValidEmail(email)) {
            toast.error('Enter a valid email address');
            return;
        }

        if (!isValidPhone(phone)) {
            toast.error('Enter a valid 10-digit phone number');
            return;
        }
        if (!isValidDate(dob)) {
            toast.error('You must be 18 years or older');
            return;
        }

        const formData = {
            brideGroomName: name.trim(),
            email: email.trim(),
            phoneNo: phone.trim(),
            password: password,
            profileFor: profileFor || "",
            gender: gender,
            dob: new Date(dob).toISOString().split("T")[0],
            religion: selectedReligion,
        };

        console.log("Payload being sent:", formData);

        setIsLoading(true);
        try {
            const response = await axios.post('https://admin.steptonikah.com/api/register', formData);
            console.log("Response:", response.data);

            if (response.data.status) {
                toast.success('Registered Successfully');
                localStorage.setItem('userStatus', response.data.user.status);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('userId', String(response.data.user.id));
                navigate('/profilecreation');
            } else {
                toast.error(response.data.message || 'Registration Failed. Please try again');
            }
        } catch (error) {
            console.error("Error Response:", error.response?.data || error.message);

            if (error.response?.data?.message?.includes('email')) {
                setEmailError('This email is already registered.');
            }
            if (error.response?.data?.message?.includes('phone')) {
                setPhoneError('This phone number is already registered.');
            }

            toast.error(error.response?.data?.message || 'Unable to register. Please try again later');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div id="smooth-wrapper" className="mil-wrapper">
            <div className="progress-wrap active-progress"></div>
            <div className="mil-top-panel">
                <div className="container">
                    <Link to="/" className="mil-logo">
                        <img src={logo} alt="step-to-nikah" height="50" />
                    </Link>
                    <nav className="mil-top-menu">
                        <ul>
                            <li>
                                {/* <Link to="/">Home</Link> */}
                                <a href="#!">Home</a>
                            </li>
                            <li>
                                {/* <Link to="/about">About us</Link> */}
                                <a href="#!">About us</a>
                            </li>
                            <li>
                                {/* <Link to="/contact">Contact us</Link> */}
                                <a href="#!">Contact us</a>
                            </li>
                        </ul>
                    </nav>
                    <div className="mil-menu-buttons">
                        <a href="/" className="mil-btn mil-sm" style={{ height: '40px', width: '100px' }}>
                            Log in
                        </a>
                        {/* <div className="mil-menu-btn">
                            <span></span>
                        </div> */}
                    </div>
                </div>
            </div>
            <div id="smooth-content">
                <div className="login-container">
                    <div className="mil-features mil-p-100-80" style={{ padding: '150px 0 150px 0' }}>
                        <div className="container">
                            <div className="row flex-sm-row-reverse justify-content-between align-items-center">
                                <div className="col-xl-7 reg-form">
                                    <div className="form-container">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="profilefor">Create Profile for *</label>
                                                    <select
                                                        className="form-control"
                                                        id="profilefor"
                                                        value={profileFor}
                                                        onChange={(e) => setProfileFor(e.target.value)}
                                                    >
                                                        <option value="" disabled>Select Profile</option>
                                                        <option value="self">Myself</option>
                                                        <option value="daughter">Daughter</option>
                                                        <option value="son">Son</option>
                                                        <option value="sister">Sister</option>
                                                        <option value="brother">Brother</option>
                                                        <option value="friend">Friend</option>
                                                        <option value="relative">Relative</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="name">Bride / Groom Name *</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        id="name"
                                                        value={name}
                                                        onChange={(e) => setName(e.target.value)}
                                                        placeholder="Enter Name"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="mobile">Phone Number *</label>
                                                    <input
                                                        type="tel"
                                                        className={`form-control ${phoneError ? 'is-invalid' : ''}`}
                                                        id="mobile"
                                                        value={phone}
                                                        onChange={(e) => {
                                                            setPhone(e.target.value);
                                                            setPhoneError('');
                                                        }}
                                                        placeholder="Enter Phone Number"
                                                        pattern="\d{10}"
                                                    />
                                                    {phoneError && <div className="invalid-feedback">{phoneError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0">
                                                    <label>Gender *</label>
                                                    <div className="d-flex align-items-center mt-2">
                                                        <div className="form-check">
                                                            <input
                                                                type="radio"
                                                                id="male"
                                                                name="gender"
                                                                value="Male"
                                                                className="form-check-input"
                                                                checked={gender === 'Male'}
                                                                onChange={() => setGender('Male')}
                                                            />
                                                            <label className="form-check-label" htmlFor="male">Male</label>
                                                        </div>
                                                        <div className="form-check ms-4">
                                                            <input
                                                                type="radio"
                                                                id="female"
                                                                name="gender"
                                                                value="Female"
                                                                className="form-check-input"
                                                                checked={gender === 'Female'}
                                                                onChange={() => setGender('Female')}
                                                            />
                                                            <label className="form-check-label" htmlFor="female">Female</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="dob">Date of Birth *</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        id="dob"
                                                        value={dob}
                                                        onChange={(e) => setDob(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="religion">Religion *</label>
                                                    <select
                                                        className="form-control"
                                                        id="religion"
                                                        value={selectedReligion}
                                                        onChange={(e) => setSelectedReligion(e.target.value)}
                                                    >
                                                        <option value="" disabled>Select Religion</option>
                                                        <option value="Muslim">Muslim</option>
                                                        <option value="Hindu">Hindu</option>
                                                        <option value="Christian">Christian</option>
                                                        <option value="Buddhist">Buddhist</option>
                                                        <option value="Bahai">Bahai</option>
                                                        <option value="Jainism">Jainism</option>
                                                        <option value="Jewish">Jewish</option>
                                                        <option value="Parsi">Parsi</option>
                                                        <option value="Shintoism">Shintoism</option>
                                                        <option value="Sikhism">Sikhism</option>
                                                        <option value="Taoism">Taoism</option>
                                                        <option value="Inter Religion">Inter Religion</option>
                                                        <option value="No Religion">No Religion</option>
                                                        <option value="Other">Other</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="email">Email ID *</label>
                                                    <input
                                                        type="email"
                                                        className={`form-control ${emailError ? 'is-invalid' : ''}`}
                                                        id="email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                            setEmailError('');
                                                        }}
                                                        placeholder="Enter Email"
                                                    />
                                                    {emailError && <div className="invalid-feedback">{emailError}</div>}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label htmlFor="password">Create Password *</label>
                                                    <input
                                                        type="password"
                                                        className="form-control"
                                                        id="password"
                                                        value={password}
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        placeholder="Enter Password"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-12 mt-4">
                                                <button
                                                    type="submit"
                                                    className="button primary-button w-100"
                                                    onClick={handleRegister}
                                                    disabled={isLoading}
                                                >
                                                    {isLoading ? 'Processing...' : 'Next'}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-5">
                                    <div className="mil-image-frame">
                                        <img src={homeImage} alt="Login" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Register;
