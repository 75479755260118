import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { API_URL } from "../../../assets/Constants";
import toast from "react-hot-toast";
import BadgeModal from "./Modals/BadgeModal";
import ViewBadgeModal from "./Modals/ViewBadgeModal";

const carousalImage = {
    height: 500
};

const badgeButton = {
    backgroundColor : '#096b0d'
};

const ProfileHeader = ({basicDetails, personalDetails, physicalDetails, religiousDetails, educationDetails, locationDetails, profilePhoto, photos}) => {

    const [badge, setBadge] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);

    const navigate = useNavigate();
    const gotoManagePhoto = () => {
        navigate('/manage-photos');
    };

    const fetchBadge = async () => {
        try {
            const token = localStorage.getItem("token");
            
            if (!token) {
                console.error("No authentication token found");
                return;
            }
          
            const response = await axios.get(`${API_URL}/api/badge/getBadge`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
          
            if (response.data && response.data.success) {
                setBadge(response.data.badge);
            } else {
                console.warn("Badge not found");
                setBadge(null);
            }
        } catch (error) {
            console.error("Error fetching badge data:", error.message);
            toast.error("Failed to load badge details");
        }
      };
    
      useEffect(() => {
        fetchBadge();
      }, []);
    
    const handleDeleteBadge = async () => {
        if (!badge || !badge.id) return;
    
        try {
            const token = localStorage.getItem("token");
            
            await axios.post(
              `${API_URL}/api/badge/${badge.id}/idDelete`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
    
            toast.success("Badge deleted successfully");
            setBadge(null);
        } catch (error) {
            console.error("Error deleting badge:", error.message);
            toast.error("Failed to delete badge");
        }
    };

    return (
        <div className="card">
            <div className="card-header border">
                <h5 className="mb-0">
                    {basicDetails ? basicDetails.name : <Skeleton />} 
                    {basicDetails ? ` (STN${basicDetails.profile_id})` : ''}
                </h5>
                <span className="text-muted">| Account Type : Free</span>
            </div>
            <div className="card-body border py-5">
                <div className="row">
                    <div className="col-5">
                        <Carousel style={carousalImage}>
                            {photos && photos.length > 0 ? (
                                photos.map((photo) => (
                                    <Carousel.Item key={photo.id}>
                                        <img src={photo.photo_url} className="rounded" style={carousalImage} alt="profile-photo" />
                                    </Carousel.Item>
                                ))
                            ) : (
                                <Carousel.Item>
                                    <img 
                                        src={profilePhoto} 
                                        className="rounded" 
                                        style={carousalImage} 
                                        alt="Default Profile" 
                                    />
                                </Carousel.Item>
                            )}
                        </Carousel>
                        <div className="text-center">
                            <button className="btn btn-warning btn-sm mt-3 text-white" type="button" onClick={gotoManagePhoto}>
                                <i className="ri-image-2-fill ri-22px me-2"></i>
                                Manage Photos
                            </button>
                        </div>
                    </div>
                    <div className="col-7">
                        <p className="mb-3">
                            <i className="ri-user-3-fill ri-22px text-primary me-2"></i>
                            {basicDetails ? `${basicDetails.age} yrs, ` : <Skeleton />} 
                            {physicalDetails?.height ? `${physicalDetails.height} cm, ` : ''} 
                            {basicDetails?.gender || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-group-3-fill ri-22px text-primary me-2"></i>
                            {religiousDetails ? `${religiousDetails.community}, ${religiousDetails.religion}` : <Skeleton />}                        
                        </p>
                        <p className="mb-3">
                            <i className="ri-shake-hands-fill ri-22px text-primary me-2"></i>
                            {personalDetails?.marital_status || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-graduation-cap-fill ri-22px text-primary me-2"></i>
                            {educationDetails ? (
                                <>
                                    {educationDetails.highest_education} 
                                    {educationDetails.qualification ? ` (${educationDetails.qualification})` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-map-pin-2-fill ri-22px text-primary me-2"></i>
                            {locationDetails ? (
                                <>
                                    {locationDetails.homeCity ? `, ${locationDetails.homeCity})` : ''}
                                    {locationDetails.homeDistrict ? `, ${locationDetails.homeDistrict})` : ''}
                                    {locationDetails.homeState ? `, ${locationDetails.homeState})` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-user-3-fill ri-22px text-primary me-2"></i>
                            {physicalDetails?.physical_status || <Skeleton />}
                        </p>
                        <p className="mb-3">
                            <i className="ri-creative-commons-by-fill ri-22px text-primary me-2"></i>
                            {educationDetails ? (
                                <>
                                    {educationDetails.profession ? `${educationDetails.profession}` : ''}
                                </>
                            ) : <Skeleton />}
                        </p>
                        {/* Badge Management */}
                        <div className="card mt-5 border">
                            <div className="card-body">
                                {!badge && (
                                    <div>
                                        <div>
                                            <h6 className="fw-bolder mb-1">Upload your identity</h6>
                                            <small>
                                                Get more responses by uploading your identity badge.
                                            </small>
                                        </div>
                                        <button
                                            className="btn btn-sm text-white mt-2"
                                            style={badgeButton}
                                            onClick={() => setShowModal(true)}
                                        >
                                            <i className="ri-shield-fill ri-22px me-2"></i>
                                            Badge Upload
                                        </button>
                                    </div>
                                )}
                                {badge && badge.status === "pending" && (
                                    <div>
                                        <div>
                                            <h6 className="fw-bolder mb-1">Id Uploaded</h6>
                                            <small>
                                                Your Id is uploaded pending Badge verification.
                                            </small>
                                        </div>
                                        <button
                                            className="btn btn-sm text-white mt-2 me-2"
                                            style={badgeButton}
                                            onClick={() => setShowViewModal(true)}
                                        >
                                            <i className="ri-shield-fill ri-22px me-2"></i>
                                            View ID
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger text-white mt-2"
                                            onClick={handleDeleteBadge}
                                        >
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            Delete ID
                                        </button>
                                    </div>
                                )}
                                {badge && badge.status === "verified" && (
                                    <div>
                                        <div>
                                            <h6 className="fw-bolder mb-1">Id Verified</h6>
                                            <small>Your Id is uploaded and Badge verified.</small>
                                        </div>
                                        <button
                                            className="btn btn-sm text-white mt-2 me-2"
                                            style={badgeButton}
                                            onClick={() => setShowViewModal(true)}
                                        >
                                            <i className="ri-shield-fill ri-22px me-2"></i>
                                            View ID
                                        </button>
                                        <button
                                            className="btn btn-sm btn-danger text-white mt-2"
                                            onClick={handleDeleteBadge}
                                        >
                                            <i className="ri-close-circle-fill ri-22px me-2"></i>
                                            Delete ID
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <BadgeModal
                    fetchBadge={fetchBadge}
                    onClose={() => setShowModal(false)}
                />
            )}
            {showViewModal && (
                <ViewBadgeModal
                    badgeDetails={badge}
                    onClose={() => setShowViewModal(false)}
                />
            )}
        </div>
    );
};

export default ProfileHeader;