import React from "react";
const BasicInformation = ({basicDetails, personalDetails, physicalDetails}) => {
    return(
        <div className="card mb-3 mt-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Basic Information</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <td>: {basicDetails?.name || ''}</td>
                            <th>Profile ID</th>
                            <td>: {basicDetails?.profile_id ? `STN${basicDetails.profile_id}` : ''}</td>
                        </tr>
                        <tr>
                            <th>Gender</th>
                            <td>: {basicDetails?.gender || ''}</td>
                            <th>Age</th>
                            <td>: {basicDetails?.age ? `${basicDetails.age} Years` : ''}</td>
                        </tr>
                        <tr>
                            <th>Marital Status</th>
                            <td>: {personalDetails?.marital_status || ''}</td>
                            <th>Profile Created for</th>
                            <td>: {basicDetails?.profile_for || ''}</td>
                        </tr>
                        <tr>
                            <th>Ethnic Group</th>
                            <td>: {personalDetails?.ethnic_group || ''}</td>
                            <th>Marriage Plan</th>
                            <td>: {personalDetails?.marriage_plan || ''}</td>
                        </tr>
                        <tr>
                            <th>Willing to Relocate</th>
                            <td>: {personalDetails?.willing_to_relocate || ''}</td>
                            <th>Languages Known</th>
                            <td>: {personalDetails?.languages_known || ''}</td>
                        </tr>
                        <tr>
                            <th>Physical Status</th>
                            <td>: {physicalDetails?.physical_status || ''}</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BasicInformation;