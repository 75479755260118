import React, { useState } from 'react';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';

const OtpScreen = () => {
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleEmail = async () => {
        if (!email) {
            toast.error("Please enter an email address");
            return;
        }

        setLoading(true);
        try {
            const response = await axios.post("https://admin.steptonikah.com/api/login/sendOtp", { email });
            console.log(response.data, "response");
            toast.success(response.data.message);
            setTimeout(() => {
                navigate('/otp-verification', { state: { email } })
            }, 1500)
        } catch (error) {
            console.error("Error:", error);
            toast.error(error.response?.data?.message || "An error occurred");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="otp-container">
            <button className="back-button" onClick={() => navigate('/')}>
                <IoArrowBack size={25} />
            </button>
            <div className="inner-container">
                <div className="image-container">
                    <img src={require("../../assets/images/OtpVerify.png")} alt="OTP Verification" className="image" />
                </div>
                <h2 className="title">OTP Verification</h2>
                <p className="subtitle">We will send you a One Time Password on your email</p>
                <input
                    type="email"
                    className="input"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className="submit-button" onClick={handleEmail} disabled={loading}>
                    {loading ? (
                        <div className="spinner" style={{
                            width: '20px', height: '20px', marginLeft: '45%', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite'
                        }}></div>) : (
                        <span>GET OTP</span>
                    )}
                </button>
            </div>
            <Toaster />
        </div>
    );
};

export default OtpScreen;
