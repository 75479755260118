import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import Select from 'react-select'
import { MdArrowBackIosNew } from 'react-icons/md';

const EditLifestyle = () => {
    const location = useLocation();
    const { lifeStyle } = location.state;

    const [selectedEatingHabits, setSelectedEatingHabits] = useState(lifeStyle.eating_habits);
    const [selectedSmokingHabits, setSelectedSmokingHabits] = useState(lifeStyle.smoking_habits);
    const [selectedDrinkingHabits, setSelectedDrinkingHabits] = useState(lifeStyle.drinking_habits);
    const [selectedHobbies, setSelectedHobbies] = useState(
        lifeStyle.hobbies ? JSON.parse(lifeStyle.hobbies).map((item) => ({ value: item, label: item, })) : []
    );
    const [favFood, setFavFood] = useState(lifeStyle.favorite_food);
    const [favPlace, setFavPlace] = useState(lifeStyle.favorite_place);
    const [favSport, setFavSport] = useState(lifeStyle.favorite_sport);
    const [favBook, setFavBook] = useState(lifeStyle.favorite_books);
    const [placesVisited, setPlacesVisited] = useState(lifeStyle.places_visited);
    const [movieAndMusic, setMovieAndMusic] = useState(lifeStyle.movie_and_music);
    const [dressSense, setDressSense] = useState(lifeStyle.dress_sense);
    const [humourSense, setHumourSense] = useState(lifeStyle.sense_of_humor);
    const [selectedPersonality, setSelectedPersonality] = useState(
        lifeStyle.personality ? JSON.parse(lifeStyle.personality).map((item) => ({ value: item, label: item, })) : []
    );

    const navigate = useNavigate();

    const eatingHabits = [
        { value: "Veg", label: "Veg" },
        { value: "Eggetarian", label: "Eggetarian" },
        { value: "Occasionally Non-veg", label: "Occasionally Non-veg" },
        { value: "Non-Veg", label: "Non-Veg" },
    ];

    const smokingHabits = [
        { value: "Yes,regularly", label: "Yes, regularly" },
        { value: "No,never", label: "No, never" },
        { value: "Occasionally", label: "Occasionally" },
        { value: "Trying to quit", label: "Trying to quit" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const drinkingHabits = [
        { value: "Yes,regularly", label: "Yes, regularly" },
        { value: "No,never", label: "No, never" },
        { value: "Occasionally", label: "Occasionally" },
        { value: "Trying to quit", label: "Trying to quit" },
        { value: "Prefer not to say", label: "Prefer not to say" },
    ];

    const hobbies = [
        { value: "Acting", label: "Acting" },
        { value: "Board Games", label: "Board Games" },
        { value: "Cinema", label: "Cinema" },
        { value: "Creative Writing", label: "Creative Writing" },
        { value: "Dancing", label: "Dancing" },
        { value: "Design", label: "Design" },
        { value: "Fashion", label: "Fashion" },
        { value: "Film Making", label: "Film Making" },
        { value: "Knitting", label: "Knitting" },
        { value: "Learning Languages", label: "Learning Languages" },
        { value: "Make Up", label: "Make Up" },
        { value: "Mehandi", label: "Mehandi" },
        { value: "Painting", label: "Painting" },
        { value: "Photography", label: "Photography" },
        { value: "Playing Music", label: "Playing Music" },
        { value: "Pottery", label: "Pottery" },
        { value: "Reading", label: "Reading" },
        { value: "Sewing", label: "Sewing" },
        { value: "Singing", label: "Singing" },
        { value: "Standup Comedy", label: "Standup Comedy" },
    ];

    const personality = [
        { value: "Active Listener", label: "Active Listener" },
        { value: "Adventurous", label: "Adventurous" },
        { value: "Affectionate", label: "Affectionate" },
        { value: "Ambitious", label: "Ambitious" },
        { value: "Animal Lover", label: "Animal Lover" },
        { value: "Assertive", label: "Assertive" },
        { value: "Bookworm", label: "Bookworm" },
        { value: "Cheerful", label: "Cheerful" },
        { value: "Conservative", label: "Conservative" },
        { value: "Extroverted", label: "Extroverted" },
        { value: "Family-Oriented", label: "Family-Oriented" },
        { value: "Funny", label: "Funny" },
        { value: "Romantic", label: "Romantic" },
    ];

    useEffect(() => {
        console.log("Updated Selected Personality State:", selectedPersonality);
    }, [selectedPersonality]);

    const handleSubmit = async () => {
        const formatData = {
            hobbies: selectedHobbies.map(option => option.value),
            eatingHabits: selectedEatingHabits,
            smokingHabits: selectedSmokingHabits,
            drinkingHabits: selectedDrinkingHabits,
            personality: selectedPersonality.map(option => option.value),
            favoriteFood: favFood,
            favoriteSport: favSport,
            favoritePlace: favPlace,
            favoriteBooks: favBook,
            placesVisited: placesVisited,
            movieAndMusic: movieAndMusic,
            senseOfHumor: humourSense,
            dressSense: dressSense,
        };

        console.log(formatData);

        try {
            const token = localStorage.getItem("token");
            console.log(token);

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            };

            const response = await axios.post(
                "https://admin.steptonikah.com/api/profileUpdate/lifeStyle",
                formatData,
                config
            );

            console.log(response.data);

            if (response.data.status) {
                toast.success(response.data.message);

                setTimeout(() => {
                    navigate("/my-profile");
                }, 1500);
            }
        } catch (error) {
            console.error(error);

            toast.error("Something went wrong");
        }
    };

    const customSelectStyles = {
        control: (base) => ({
            ...base,
            width: '100%',
            padding: '5px',
            borderRadius: '5px',
            border: '1px solid #ddd',
            fontSize: '16px',
        }),
        option: (base, { isFocused }) => ({
            ...base,
            backgroundColor: isFocused ? '#007bff' : 'white',
            color: isFocused ? 'white' : 'black',
            cursor: 'pointer'
        }),
        multiValue: (base) => ({
            ...base,
            backgroundColor: '#f0f0f0',
            borderRadius: '4px'
        }),
        multiValueLabel: (base) => ({
            ...base,
            fontFamily: 'Poppins, sans-serif'
        }),
        multiValueRemove: (base) => ({
            ...base,
            color: '#007bff',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#007bff',
                color: 'white'
            }
        })
    };

    return (
        <div className="create-profile-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <MdArrowBackIosNew size={24} color='#572d71' />
            </div>
            <h2 style={{ textAlign: 'center', margin: '-10px 0 10px' }}>Lifestyle Information</h2>

            <div className="input-container">
                <label>Eating Habits</label>
                <Select
                    options={eatingHabits}
                    onChange={(selected) => setSelectedEatingHabits(selected.value)}
                    placeholder="Select Eating habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedEatingHabits ? { value: selectedEatingHabits, label: selectedEatingHabits } : null}
                />
            </div>

            <div className="input-container">
                <label>Smoking Habits</label>
                <Select
                    options={smokingHabits}
                    onChange={(selected) => setSelectedSmokingHabits(selected.value)}
                    placeholder="Select Smoking habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedSmokingHabits ? { value: selectedSmokingHabits, label: selectedSmokingHabits } : null}
                />
            </div>

            <div className="input-container">
                <label>Drinking Habits</label>
                <Select
                    options={drinkingHabits}
                    onChange={(selected) => setSelectedDrinkingHabits(selected.value)}
                    placeholder="Select Drinking habits"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedDrinkingHabits ? { value: selectedDrinkingHabits, label: selectedDrinkingHabits } : null}
                />
            </div>

            <div className="input-container">
                <label>Hobbies</label>
                <Select
                    isMulti
                    options={hobbies}
                    onChange={(selectedOptions) => setSelectedHobbies(selectedOptions)}
                    placeholder="Select Hobbies"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedHobbies}
                />
            </div>

            <div className="input-container">
                <label>Favorite Food</label>
                <input
                    type="text"
                    placeholder="Enter favorite food"
                    className="input-field"
                    value={favFood}
                    onChange={(e) => setFavFood(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Favorite Sport</label>
                <input
                    type="text"
                    placeholder="Enter favorite sport"
                    className="input-field"
                    value={favSport}
                    onChange={(e) => setFavSport(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Favorite Place</label>
                <input
                    type="text"
                    placeholder="Enter favorite place"
                    className="input-field"
                    value={favPlace}
                    onChange={(e) => setFavPlace(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Favorite Book</label>
                <input
                    type="text"
                    placeholder="Enter favorite book"
                    className="input-field"
                    value={favBook}
                    onChange={(e) => setFavBook(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Places You Visited</label>
                <input
                    type="text"
                    placeholder="Enter visited places"
                    className="input-field"
                    value={placesVisited}
                    onChange={(e) => setPlacesVisited(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Favorite Movie and Music</label>
                <input
                    type="text"
                    placeholder="Enter movie and music"
                    className="input-field"
                    value={movieAndMusic}
                    onChange={(e) => setMovieAndMusic(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>You Humour Sense</label>
                <input
                    type="text"
                    placeholder="Enter your humour sense"
                    className="input-field"
                    value={humourSense}
                    onChange={(e) => setHumourSense(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Favorite Dress</label>
                <input
                    type="text"
                    placeholder="Enter dress"
                    className="input-field"
                    value={dressSense}
                    onChange={(e) => setDressSense(e.target.value)}
                />
            </div>

            <div className="input-container">
                <label>Personality</label>
                <Select
                    options={personality}
                    isMulti
                    onChange={(selectedOptions) => setSelectedPersonality(selectedOptions)}
                    placeholder="Select Personality"
                    className='custom-select'
                    styles={customSelectStyles}
                    value={selectedPersonality}
                />
            </div>

            <div className="submit-container" style={{ padding: '20px 0 10px' }}>
                <button className="submit-button" onClick={handleSubmit}>Done</button>
            </div>

            <Toaster />

        </div>
    )
}

export default EditLifestyle
