import React, { useEffect, useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import axios from 'axios';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

const notificationsData = [
  { id: '1', title: 'Interest Received', time: '8:28 am', date: 'Today, May 21', message: 'Interest received from Shafna', imageUrl: require('../assets/images/img1.jpg') },
  { id: '2', title: 'Interest Received', time: '6:36 am', date: 'Today, May 21', message: 'Interest received from Shafna', imageUrl: require('../assets/images/img1.jpg') },
  { id: '3', title: 'Interest Received', time: '11:15 am', date: 'Yesterday, May 20', message: 'Interest received from Shafna', imageUrl: require('../assets/images/img1.jpg') },
  { id: '4', title: 'Interest Received', time: '9:00 pm', date: 'May 19', message: 'Interest received from Shafna', imageUrl: require('../assets/images/img1.jpg') },
  { id: '5', title: 'Interest Received', time: '12:17 pm', date: 'May 19', message: 'Interest received from Shafna', imageUrl: require('../assets/images/img1.jpg') },
];

const Notification = () => {
  const [showDelete, setShowDelete] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = await localStorage.getItem("token");
      console.log(token);

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.get(
        "https://admin.steptonikah.com/api/notifications",
        config
      );
      if (response.data.status) {
        console.log(response.data.data, "Notifications");
        setNotifications(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching badge data:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    console.log("fetch data called:");
    fetchData();
  }, []);

  const handleLongPress = (id) => {
    setShowDelete(id);
  };

  // const handleDelete = (id) => {
  //   console.log('Delete', id);
  // };

  const renderItem = (item) => (
    <div className="notification-container" onContextMenu={(e) => {
      e.preventDefault(); handleLongPress(item.id);
    }}>
      <div className="notification-text-container">
        <img src={item.photo} alt="" className="notification-image" />
        <div className="notification-details">
          <h4 className="notification-title">{item.title}</h4>
          <p className="notification-message">{item.content}</p>
          <p className="notification-time">{item.sent_on}</p>
        </div>
      </div>
      {showDelete === item.id && (
        <button onClick={() => console.log('Delete', item.id)} className="delete-button">
          <FaTrash />
        </button>
      )}
    </div>
  );

  if (isLoading) {
    return (
      <div style={{
        display: 'flex',
        height: '100vh',
        backgroundColor: '#FDF7FD',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <div className="spinner" style={{ width: '50px', height: '50px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>
      </div>
    );
  }

  return (
    <div className="notification-page">
      <button className="back-button" onClick={() => navigate(-1)}>
        <IoArrowBack size={25} />
      </button>
      {/* <div className="notifications-list">
        {notificationsData.map(item => (
          <div key={item.id}>
            <p className="notification-date">{item.date}</p>
            {renderItem(item)}
          </div>
        ))}
      </div> */}
      {notifications && notifications.length > 0 ? (
        <div className="notifications-list">
          {notifications.map((item) => (
            <div key={item.id} className="notification-item">
              {renderItem(item)}
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-message-container">
          <img
            src={require('../assets/images/silent.png')}
            alt="dislike icon"
            style={{ width: "90px", height: "90px" }}
          />
          <p
            style={{
              color: "#572d71",
              fontSize: "17px",
              fontWeight: "500"
            }}
          >
            Currently no notifications
          </p>
        </div>
      )}
    </div>
  );
};

export default Notification;
