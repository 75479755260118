import React, { useEffect, useState } from "react";
import ProfileHeader from "./ProfileHeader";
import BasicInformation from "./BasicInformation";
import ReligiousInformation from "./ReligiousInformation";
import EducationDetails from "./EducationDetails";
import PhysicalAttributes from "./PhysicalAttributes";
import LocationDetails from "./LocationDetails";
import FamilyDetails from "./FamilyDetails";
import LifeStyle from "./LifeStyle";
import PartnerPreference from "./PartnerPreference";
import ProfileDescription from "./ProfileDescription";
import axios from "axios";
import { API_URL } from "../../../assets/Constants";
import ContactDetails from "./ContactDetails";

const MyProfile = () => {
    const [basicDetails, setBasicDetails] = useState(null);
    const [personalDetails, setPersonalDetails] = useState(null);
    const [physicalDetails, setPhysicalDetails] = useState(null);
    const [religiousDetails, setReligiousDetails] = useState(null);
    const [locationDetails, setLocationDetails] = useState(null);
    const [educationDetails, setEducationDetails] = useState(null);
    const [contactDetails, setContactDetails] = useState(null);
    const [familyDetails, setFamilyDetails] = useState(null);
    const [lifestyleDetails, setLifestyleDetails] = useState(null);
    const [partnerPreferenceDetails, setPartnerPreferenceDetails] = useState(null);
    const [profilePhoto, setProfilePhoto] = useState(null);
    const [photos, setPhotos] = useState(null);

    useEffect(() => {

        const fetchData = async () => {
            try {
                const token = localStorage.getItem("token") || '';
                const response = await axios.get(`${API_URL}/api/myProfileDetails`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                if(response.status === 200) {
                    console.log(response);
                    
                    if(response.data.status) {
                       setBasicDetails(response.data.basicDetails);
                       setPersonalDetails(response.data.personalDetails); 
                       setPhysicalDetails(response.data.physicalDetails); 
                       setReligiousDetails(response.data.religiousDetails); 
                       setEducationDetails(response.data.educationDetails); 
                       setLocationDetails(response.data.locationDetails); 
                       setContactDetails(response.data.contactDetails); 
                       setFamilyDetails(response.data.familyDetails); 
                       setLifestyleDetails(response.data.lifeStyles); 
                       setPartnerPreferenceDetails(response.data.partnerPreferences); 
                       setProfilePhoto(response.data.profilePhoto); 
                       setPhotos(response.data.photos); 
                    }
                }
            } catch (error) {
                console.log(error.message);
            }
        }
        fetchData();
    },[]);
    return(
        <div className="col-xxl-9 col-xl-9 col-md-12">
            <ProfileHeader 
                basicDetails     = {basicDetails}
                personalDetails  = {personalDetails}
                physicalDetails  = {physicalDetails}
                religiousDetails = {religiousDetails}
                educationDetails = {educationDetails}
                locationDetails  = {locationDetails}
                profilePhoto     = {profilePhoto}
                photos           = {photos}       
            />
            <ProfileDescription personalDetails = {personalDetails} />
            <BasicInformation 
                basicDetails    = {basicDetails}
                personalDetails = {personalDetails}
                physicalDetails = {physicalDetails}
            />
            <ReligiousInformation religiousDetails = {religiousDetails}/>
            <EducationDetails educationDetails = {educationDetails} />
            <PhysicalAttributes physicalDetails = {physicalDetails} />
            <LocationDetails locationDetails = {locationDetails} />
            <ContactDetails contactDetails = {contactDetails} />
            <FamilyDetails familyDetails = {familyDetails} />
            <LifeStyle lifestyleDetails = {lifestyleDetails} />
            <PartnerPreference partnerPreferenceDetails = {partnerPreferenceDetails} />
        </div>
    );
};
export default MyProfile;