import React from 'react'

const Plans = () => {
    return (
        <div>
            <div class="pricing-plans row gy-3 mt-3 px-lg-12">
                <div class="col-lg mb-lg-0 mb-3">
                    <div class="card border shadow-none">
                        <div class="card-body pt-8">
                            <h4 class="card-title text-center text-capitalize mb-2">Basic Package</h4>
                            <h5 class="text-center mb-3 fw-bold">2 Months</h5>
                            <div class="text-center">
                                <div class="d-flex justify-content-center">
                                    <sup class="h5 pricing-currency mt-2 mb-0 me-1 text-body">₹</sup>
                                    <h1 class="mb-0 text-primary">3000</h1>
                                </div>
                            </div>
                            <ul class="list-group ps-6 my-5 pt-4">
                                <li class="mb-4">Personalized messages to <b>40</b> profiles</li>
                                <li class="mb-4">Chat with prospects directly</li>
                                <li class="mb-4">View mobile numbers of <b>40</b> profiles</li>
                            </ul>
                            <button type='button' class="btn btn-outline-primary d-grid w-100 waves-effect">
                                Upgrade
                            </button>
                        </div>
                    </div>
                </div>

                <div class="col-lg mb-lg-0 mb-3">
                    <div class="card border-primary border shadow-none">
                        <div class="card-body position-relative pt-8">
                            <div class="position-absolute end-0 me-6 top-0 mt-3">
                                <span class="badge bg-label-primary rounded-pill">Popular</span>
                            </div>
                            <h4 class="card-title text-center text-capitalize mb-2">Silver Package</h4>
                            <h5 class="text-center mb-3 fw-bold">4 Months</h5>
                            <div class="text-center">
                                <div class="d-flex justify-content-center">
                                    <sup class="h5 pricing-currency mt-2 mb-0 me-1 text-body">₹</sup>
                                    <h1 class="price-toggle price-yearly text-primary mb-0">4200</h1>
                                </div>
                            </div>
                            <ul class="list-group ps-6 my-5 pt-4">
                                <li class="mb-4">Personalized messages to <b>80</b> profiles</li>
                                <li class="mb-4">Chat with prospects directly</li>
                                <li class="mb-4">View mobile numbers of <b>80</b> profiles</li>
                            </ul>
                            <button type='button' class="btn btn-primary d-grid w-100 waves-effect waves-light">Upgrade</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pricing-plans row gy-3 px-lg-12 mt-5">
                <div class="col-lg mb-lg-0 mb-3">
                    <div class="card border shadow-none">
                        <div class="card-body pt-8">
                            <h4 class="card-title text-center text-capitalize mb-2">Gold Package</h4>
                            <h5 class="text-center mb-3 fw-bold">6 Months</h5>
                            <div class="text-center">
                                <div class="d-flex justify-content-center">
                                    <sup class="h5 pricing-currency mt-2 mb-0 me-1 text-body">₹</sup>
                                    <h1 class="mb-0 text-primary">5200</h1>
                                </div>
                            </div>
                            <ul class="list-group ps-6 my-5 pt-4">
                                <li class="mb-4">Personalized messages to <b>160</b> profiles</li>
                                <li class="mb-4">Chat with prospects directly</li>
                                <li class="mb-4">View mobile numbers of <b>160</b> profiles</li>
                            </ul>
                            <button type='button' class="btn btn-outline-primary d-grid w-100 waves-effect">
                                Upgrade
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-lg mb-lg-0 mb-3">
                    <div class="card border shadow-none">
                        <div class="card-body pt-8">
                            <h4 class="card-title text-center text-capitalize mb-2">Platinum Package</h4>
                            <h5 class="text-center mb-3 fw-bold">12 Months</h5>
                            <div class="text-center">
                                <div class="d-flex justify-content-center">
                                    <sup class="h5 pricing-currency mt-2 mb-0 me-1 text-body">₹</sup>
                                    <h1 class="mb-0 text-primary">8500</h1>
                                </div>
                            </div>
                            <ul class="list-group ps-6 my-5 pt-4">
                                <li class="mb-4">Personalized messages to <b>250</b> profiles</li>
                                <li class="mb-4">Chat with prospects directly</li>
                                <li class="mb-4">View mobile numbers of <b>250</b> profiles</li>
                            </ul>
                            <button type='button' class="btn btn-outline-primary d-grid w-100 waves-effect">
                                Upgrade
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Plans
