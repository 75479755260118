import React from "react";

const formatLocation = (...fields) => {
    return fields.filter(field => field).join(', ');
};

const LocationDetails = ({ locationDetails }) => {
    return (
        <div className="card mb-3">
            <div className="card-header border px-5 py-3">
                <h5 className="mb-0">Location Details</h5>
            </div>
            <div className="table-responsive text-nowrap">
                <table className="table border">
                    <tbody>
                        <tr>
                            <th width="200">Present Location</th>
                            <td style={{ whiteSpace: 'normal' }}>
                                : {formatLocation(
                                    locationDetails?.present_city,
                                    locationDetails?.present_district,
                                    locationDetails?.present_state,
                                    locationDetails?.present_country
                                )}
                            </td>
                        </tr>
                        <tr>
                            <th width="200">Home Location</th>
                            <td style={{ whiteSpace: 'normal' }}>
                                : {formatLocation(
                                    locationDetails?.home_city,
                                    locationDetails?.home_district,
                                    locationDetails?.home_state,
                                    locationDetails?.home_country
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default LocationDetails;
