import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoArrowBack } from 'react-icons/io5';
import axios from 'axios';
import { Toaster, toast } from 'react-hot-toast';

const OtpVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const email = location.state?.email;
    const [selectedOtp, setSelectedOtp] = useState('');
    const [loading, setLoading] = useState(false);

    const handleOTP = async () => {
        setLoading(true);
        console.log(selectedOtp);
        try {
            const response = await axios.post(
                "https://admin.steptonikah.com/api/login/verifyOtp",
                { email: email, otp: selectedOtp }
            );
            console.log(response.data);
            if (response.data) {


                console.log(response.data, "Response");
                toast.success("Login Success ");
                setTimeout(() => {
                    navigate("/home", { screen: "Home" });
                }, 1000);
                await localStorage.setItem('token', response.data.token);
                localStorage.setItem('userId', String(response.data.id));
                localStorage.setItem('userStatus', response.data.status);
            }
        } catch (error) {
            toast.error(error.response?.data?.message || error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleOtpChange = (value, index) => {
        setSelectedOtp((prev) => {
            const otpArray = prev.split('');
            otpArray[index] = value;
            return otpArray.join('');
        });
    };

    return (
        <div className="otp-container">
            <div className="back-button" onClick={() => navigate(-1)}>
                <IoArrowBack style={{ fontSize: '24px', color: '#572d71' }} />
            </div>
            <div className="inner-container">
                <div className="image-container">
                    <img src={require("../../assets/images/OtpVerify.png")} alt="OTP Verification" className="image" />
                </div>
                <h2 className="title">OTP Verification</h2>
                <p className="subtitle">Enter Verification Code</p>
                <div className="otp-input-container">
                    {[...Array(6)].map((_, i) => (
                        <input
                            key={i}
                            type="number"
                            maxLength="1"
                            className="otp-input"
                            onChange={(e) => {
                                const value = e.target.value;
                                if (/^\d?$/.test(value)) {
                                    handleOtpChange(value, i);
                                    if (value && e.target.nextSibling) {
                                        e.target.nextSibling.focus();
                                    }
                                }
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Backspace' && !e.target.value && e.target.previousSibling) {
                                    e.target.previousSibling.focus();
                                }
                            }}
                        />
                    ))}
                </div>
                <button className="submit-button" onClick={handleOTP} disabled={loading}>
                    {loading ? (
                        <div className="spinner" style={{ width: '20px', height: '20px', border: '5px solid lightgray', borderRadius: '50%', borderTopColor: '#572d71', animation: 'spin 1s linear infinite' }}></div>) : (
                        <span>VERIFY</span>
                    )}
                </button>
                <p className="resend-text">
                    Didn't receive the verification OTP?{" "}
                    <span className="resend-link" onClick={() => navigate('/otp')}>Resend</span>
                </p>
            </div>
            <Toaster />
        </div>
    );
};

export default OtpVerification;
