import React, { useEffect } from 'react';
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.min';

const useCollapsibleCard = () => {
    useEffect(() => {
        const collapsibleElements = document.querySelectorAll(".card-collapsible");
        collapsibleElements.forEach((element) => {
            element.addEventListener("click", handleCollapseClick);
        });
        return () => {
            collapsibleElements.forEach((element) => {
                element.removeEventListener("click", handleCollapseClick);
            });
        };
    }, []);

    const handleCollapseClick = (e) => {
        e.preventDefault();

        const card = e.target.closest(".card");
        const collapseElement = card.querySelector(".collapse");
        const cardHeader = card.querySelector(".card-header");
        const icon = e.target.querySelector(".ri-arrow-down-s-line, .ri-arrow-up-s-line");

        new bootstrap.Collapse(collapseElement);

        cardHeader.classList.toggle("collapsed");

        if (icon) {
            icon.classList.toggle("ri-arrow-down-s-line");
            icon.classList.toggle("ri-arrow-up-s-line");
        }
    };
};

const BankDetails = () => {
    useCollapsibleCard();
    return (
        <div className='p-5'>
            <div className="card card-action mb-0">
                <div className="card-header">
                    <h5 className="card-action-title mb-0 text-primary">Sent to Bank Account</h5>
                    <div className="card-action-element">
                        <ul className="list-inline mb-0">
                            <li className="list-inline-item">
                                <a href="#!" className="card-collapsible">
                                    <i className="tf-icons ri-arrow-down-s-line"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="collapse show">
                    <div className="card-body">
                    <table className="table table-hover border rounded">
                            <thead>
                                <tr>
                                    <th>Bank</th>
                                    <th>Details</th>
                                </tr>
                            </thead>
                            <tbody className="border">
                                <tr>
                                    <th>SBI</th>
                                    <th>
                                        Branch - TEST<br />
                                        A/C Number : 000XXXX00123<br/>
                                        IFSC Code : TST001234
                                    </th>
                                </tr>
                                <tr>
                                    <th>AXIS BANK</th>
                                    <th>
                                        Branch - TEST<br />
                                        A/C Number : 000XXXX00123<br/>
                                        IFSC Code : TST001234
                                    </th>
                                </tr>
                                <tr>
                                    <th>FEDERAL BANK</th>
                                    <th>
                                        Branch - TEST<br />
                                        A/C Number : 000XXXX00123<br/>
                                        IFSC Code : TST001234
                                    </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BankDetails;
