import React from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../../assets/Constants";

const ProfileCard = (props) => {

    const {
        id,
        name,
        ageAndGender,
        place,
        religion,
        education,
        occupation,
        profileImage,
        interestSent,
        interestReceived,
        shortlistStatus,
        profileId,
        fetchData
    } = props;

    const navigate = useNavigate();
    const profileView = () => {
        localStorage.setItem("profile-details", profileId);
        navigate("/profile-view");
    };

    const sendInterest = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/interest/${id}/store`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Interest Sent!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error sending interest:", error);
            toast.error("Error sending interest");
        }
    };

    const acceptInterest = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/interest/${interestReceived.id}/accept`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Interest Accepted!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error accepting interest:", error);
            toast.error("Error accepting interest");
        }
    };

    const rejectInterest = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/interest/${interestReceived.id}/reject`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Interest Rejected!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error rejecting interest:", error);
            toast.error("Error rejecting interest");
        }
    };

    const cancelSentInterest = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/interest/${interestSent.id}/cancelSent`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Sent Interest Cancelled!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error cancelling sent interest:", error);
            toast.error("Error cancelling sent interest");
        }
    };

    const shortlistProfile = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/shortlist/${id}/store`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Profile Shortlisted!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error shortlisting profile:", error);
            toast.error("Error shortlisting profile");
        }
    };
    const ignoreProfile = async () => {
        try {
            const token = localStorage.getItem("token");
        
            const response = await axios.post(
                `${API_URL}/api/ignoreList/${id}/store`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
        
            if (response.data.status) {
                toast.success("Profile Ignored!");
                fetchData();
            } else {
                toast.error("Some error occurred!!");
            }
        } catch (error) {
            console.error("Error skipping profile:", error);
            toast.error("Error skipping profile");
        }
    };

    // Determine the interaction state
    const renderInterestActions = () => {
        // Case 1: No existing interest
        if (!interestSent && !interestReceived) {
            return (
                <div className="col-7">
                    <div className="row">
                        <div className="col-6 py-1 px-0">
                            <h6>Like this profile?</h6>
                        </div>
                        <div className="col-6 text-end">
                            <button 
                                type="button" 
                                className="btn btn-light btn-icon rounded-circle text-muted me-2"
                            >
                                <i className="ri-close-line ri-24px"></i>
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger btn-icon rounded-circle"
                                onClick={sendInterest}
                            >
                                <i className="ri-heart-line ri-24px"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        if (interestSent && interestSent.status === 'cancelled') {
            return (
                <div className="col-7">
                    <div className="row">
                        <div className="col-6 py-1 px-0">
                            <h6>Like this profile?</h6>
                        </div>
                        <div className="col-6 text-end">
                            <button 
                                type="button" 
                                className="btn btn-light btn-icon rounded-circle text-muted me-2"
                                onClick={ignoreProfile}
                            >
                                <i className="ri-close-line ri-24px"></i>
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger btn-icon rounded-circle"
                                onClick={sendInterest}
                            >
                                <i className="ri-heart-line ri-24px"></i>
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        // Case 2: Received Interest - Pending
        if (interestReceived && interestReceived.status === 'pending') {
            return (
                <div className="col-7">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span className="badge bg-info me-2">Interest Received</span>
                            <button 
                                type="button" 
                                className="btn btn-success btn-sm me-2"
                                onClick={acceptInterest}
                            >
                                Accept
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-danger btn-sm"
                                onClick={rejectInterest}
                            >
                                Reject
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        // Case 3: Sent Interest - Pending
        if (interestSent && interestSent.status === 'pending') {
            return (
                <div className="col-7">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span className="badge bg-warning me-2 py-2 mt-1">
                                <i className="ri ri-check-line me-2"></i>
                                Interest Sent
                            </span>
                            <button 
                                type="button" 
                                className="btn btn-danger btn-sm"
                                onClick={cancelSentInterest}
                            >
                                Decline
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        // Case 4: Interest Accepted
        if ((interestReceived && interestReceived.status === 'accepted') || 
            (interestSent && interestSent.status === 'accepted')) {
            return (
                <div className="col-7 float-end">
                    <div className="row">
                        <div className="col-12 text-center float-end">
                            <span className="badge bg-success py-2 mt-1">
                                <i className="ri ri-check-line me-2"></i>
                                Accepted
                            </span>
                        </div>
                    </div>
                </div>
            );
        }

        // Case 5: Received Interest - Rejected
        if (interestReceived && interestReceived.status === 'rejected') {
            return (
                <div className="col-7">
                    <div className="row">
                        <div className="col-12 text-center">
                            <span className="badge bg-danger">Rejected</span>
                        </div>
                    </div>
                </div>
            );
        }

        // Default case
        return null;
    };

    return (
        <div className="col-lg-6 col-xl-6 grid-margin stretch-card mb-3">
            <div className="card">
                <div className="card-body p-2">
                    <div className="row g-0">
                        <div className="col-md-4">
                            <img className="card-img card-img-left" src={profileImage} alt="Profile" />
                        </div>
                        <div className="col-md-8">
                            <div className="card-body py-0" onClick={profileView}>
                                <h6>{name}</h6>
                                <p className="card-subtitle mb-3 text-muted">
                                    {`${ageAndGender}`}
                                </p>
                                <p className="card-subtitle mb-3 text-muted">
                                    {`${place}`}
                                </p>
                                <p className="card-subtitle mb-3 text-muted">
                                    Religion: {religion}
                                </p>
                                <p className="card-subtitle mb-3 text-muted">
                                    Education: {education}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-footer">
                    <div className="row">
                        <div className="col-5">
                            {!shortlistStatus ? (
                                <button 
                                    type="button" 
                                    className="btn btn-icon btn-primary me-1" 
                                    title="Favorite" 
                                    onClick={shortlistProfile}
                                >
                                    <i className="ri-star-line"></i>
                                </button>
                            ):(
                                <button 
                                    type="button" 
                                    className="btn btn-icon btn-danger me-1" 
                                    title="Favorite" 
                                >
                                    <i className="ri-star-fill"></i>
                                </button>
                            )}
                            <button 
                                type="button" 
                                className="btn btn-icon btn-primary me-1" 
                                title="Chat"
                            >
                                <i className="ri-message-3-line"></i>
                            </button>
                            <button 
                                type="button" 
                                className="btn btn-icon btn-primary" 
                                title="Details" 
                                onClick={profileView}
                            >
                                <i className="ri-user-line"></i>
                            </button>
                        </div>
                        {renderInterestActions()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;