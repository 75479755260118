import React, { useState } from "react";
import axios from "axios";
import { API_URL } from "../../../../assets/Constants";
import toast from "react-hot-toast";

const BasicDetailsModal = ({ details, onClose, onSave }) => {
    const [formData, setFormData] = useState(details);
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrors({ ...errors, [name]: "" }); // Clear error when input changes
    };

    const validate = () => {
        let validationErrors = {};
        
        if (!formData.name || formData.name.trim().length < 3) {
            validationErrors.name = "Name must be at least 3 characters long.";
        }

        if (!formData.dob) {
            validationErrors.dob = "Date of Birth is required.";
        } else {
            const dobDate = new Date(formData.dob);
            const age = new Date().getFullYear() - dobDate.getFullYear();
            if (age < 18 || age > 100) {
                validationErrors.dob = "Age must be between 18 and 100.";
            }
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    const handleSave = async () => {
        if (!validate()) return; // Stop if validation fails

        try {
            const token = localStorage.getItem("token");
            const response = await axios.post(
                `${API_URL}/api/profileUpdate/personalDetails`,
                formData,
                {
                    headers: { Authorization: `Bearer ${token}` },
                }
            );

            if (response.status === 200 && response.data.status) {
                toast.success("Details updated successfully");
                onSave(formData);
            } else {
                toast.error(response.data.message || "Failed to update details");
            }
        } catch (error) {
            console.error("Error updating details:", error);
            toast.error("Failed to update details");
        }
    };

    return (
        <div
            className="modal fade show"
            id="basicDetailsModal"
            tabIndex="-1"
            aria-hidden="true"
            style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
        >
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title" id="exampleModalLabel3">
                            Edit Basic Details
                        </h4>
                        <button
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={onClose}
                        ></button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            {/* Name Field */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="text"
                                        name="name"
                                        className={`form-control form-control-sm ${errors.name ? "is-invalid" : ""}`}
                                        placeholder="Enter Name"
                                        value={formData.name || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="name">Name</label>
                                    {errors.name && <div className="invalid-feedback">{errors.name}</div>}
                                </div>
                            </div>

                            {/* Gender Field */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="gender"
                                        className="form-control form-control-sm"
                                        value={formData.gender || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                    </select>
                                    <label htmlFor="gender">Gender</label>
                                </div>
                            </div>

                            {/* Date of Birth Field */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <input
                                        type="date"
                                        name="dob"
                                        className={`form-control form-control-sm ${errors.dob ? "is-invalid" : ""}`}
                                        value={formData.dob || ""}
                                        onChange={handleInputChange}
                                    />
                                    <label htmlFor="dob">Date of Birth</label>
                                    {errors.dob && <div className="invalid-feedback">{errors.dob}</div>}
                                </div>
                            </div>

                            {/* Marital Status Field */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="maritalStatus"
                                        className="form-control form-control-sm"
                                        value={formData.maritalStatus || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="Never Married">Never Married</option>
                                        <option value="Divorced">Divorced</option>
                                        <option value="Nikah Divorce">Nikah Divorce</option>
                                        <option value="Widowed/Widow">Widower/Widow</option>
                                    </select>
                                    <label htmlFor="maritalStatus">Marital Status</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                            name="profileFor"
                                            className="form-control form-control-sm"
                                            value={formData.profileFor || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Self">Self</option>
                                        <option value="My Son">My Son</option>
                                        <option value="My Daughter">My Daughter</option>
                                        <option value="Friend">Friend</option>
                                    </select>
                                    <label htmlFor="profile-for">Profile For</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="ethnicGroup"
                                            className="form-control form-control-sm"
                                            value={formData.ethnicGroup || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Indian">Indian</option>
                                        <option value="Arab">Arab</option>
                                        <option value="Asian">Asian</option>
                                        <option value="Afgan">Afgan</option>
                                        <option value="European">European</option>
                                    </select>
                                    <label htmlFor="ethnic-group">Ethnic Group</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="marriagePlan"
                                            className="form-control form-control-sm"
                                            value={formData.marriagePlan || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="As Soon As Possible">As Soon As Possible</option>
                                        <option value="1 -2 Year">1 -2 Year</option>
                                        <option value="3 -4 Years">3 -4 Years</option>
                                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    </select>
                                    <label htmlFor="marriage-plan">Marriage Plan</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="willingToRelocate"
                                            className="form-control form-control-sm"
                                            value={formData.willingToRelocate || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                        <option value="Maybe">Maybe</option>
                                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    </select>
                                    <label htmlFor="willing-to-relocate">Willing to Relocate</label>
                                </div>
                            </div>
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                        <select
                                            name="languagesKnown"
                                            className="form-control form-control-sm"
                                            value={formData.languagesKnown || ""}
                                            onChange={handleInputChange}
                                        >
                                        <option value="Malayalam">Malayalam</option>
                                        <option value="English">English</option>
                                        <option value="Hindi">Hindi</option>
                                        <option value="Prefer Not to Say">Prefer Not to Say</option>
                                    </select>
                                    <label htmlFor="languages-known">Languages Known</label>
                                </div>
                            </div>

                            {/* Physically Challenged Field */}
                            <div className="col-md-6 mb-5">
                                <div className="form-floating form-floating-outline">
                                    <select
                                        name="physicallyChallenged"
                                        className="form-control form-control-sm"
                                        value={formData.physicallyChallenged || ""}
                                        onChange={handleInputChange}
                                    >
                                        <option value="No">No</option>
                                        <option value="Yes">Yes</option>
                                    </select>
                                    <label htmlFor="physicallyChallenged">
                                        Physically Challenged
                                    </label>
                                </div>
                            </div>

                            {/* Conditionally Rendered Physical Status Field */}
                            {formData.physicallyChallenged === "Yes" && (
                                <div className="col-md-6 mb-5">
                                    <div className="form-floating form-floating-outline">
                                        <select
                                            name="physicalStatus"
                                            className="form-control form-control-sm"
                                            value={formData.physicalStatus || ""}
                                            onChange={handleInputChange}
                                        >
                                            <option value="Deaf">Deaf</option>
                                            <option value="Blind">Blind</option>
                                            <option value="Mentally Challenged">
                                                Mentally Challenged
                                            </option>
                                        </select>
                                        <label htmlFor="physicalStatus">
                                            Physical Status
                                        </label>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={onClose}
                        >
                            Close
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleSave}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BasicDetailsModal;
